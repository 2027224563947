<template>
  <div class="container transition-opacity">
    <h4 class="title">
      <div>
        <i class="ri-settings-5-line title-icon" />
        Settings
      </div>
      <i @click="onCloseButtonClicked" class="ri-close-fill cursor-pointer" />
    </h4>
    <div class="items">
      <!-- <div class="item-container" :class="{ 'item-container-collapse': !isShipStatusExpand }">
        <div class="item-title">
          <h5>Ship status</h5>
          <i @click="setIsShipStatusExpand(!isShipStatusExpand)" class="cursor-pointer"
            :class="{ 'ri-arrow-up-s-line': isShipStatusExpand, 'ri-arrow-down-s-line': !isShipStatusExpand }" />
        </div>
        <div class="item">
          <img src="@/assets/icons/map-settings/ship-red.svg" style="margin-right: 10px;" />
          <span>Offline</span>
          <b-form-checkbox v-model="isOffline" name="check-button" button-variant="success" switch :disabled="undefined"
            style="margin-left: auto;" />
        </div>
        <div class="item">
          <img src="@/assets/icons/map-settings/ship-green.svg" style="margin-right: 10px;" />
          <span>Online</span>
          <b-form-checkbox v-model="isOnline" name="check-button" button-variant="success" switch :disabled="undefined"
            style="margin-left: auto;" />
        </div>
      </div> -->
      <div class="item-container" :class="{ 'item-container-collapse': !isShipElementsExpand }">
        <div class="item-title">
          <h5>Ship elements</h5>
          <i @click="setIsShipElementsExpand(!isShipElementsExpand)" class="cursor-pointer"
            :class="{ 'ri-arrow-up-s-line': isShipElementsExpand, 'ri-arrow-down-s-line': !isShipElementsExpand }" />
        </div>
        <div class="item">
          <img src="@/assets/icons/map-settings/label.svg" style="margin-right: 10px;" />
          <span>Ship Label</span>
          <b-form-checkbox v-model="showAllTooltip" name="check-button" button-variant="success" switch
            style="margin-left: auto;" />
        </div>
        <div class="item">
          <img src="@/assets/icons/map-settings/trail.svg" style="margin-right: 10px;" />
          <span>Ship Trail</span>
          <b-form-checkbox v-model="isShowShipTrail" name="check-button"
            button-variant="success" switch :disabled="isLoadingShipTrail" style="margin-left: auto;" />
        </div>
      </div>
      <div class="item-container" :class="{ 'item-container-collapse': !isTrailSettingsExpand }">
        <div class="item-title">
          <h5>Trail settings</h5>
          <i @click="setIsTrailSettingsExpand(!isTrailSettingsExpand)" class="cursor-pointer"
            :class="{ 'ri-arrow-up-s-line': isTrailSettingsExpand, 'ri-arrow-down-s-line': !isTrailSettingsExpand }" />
        </div>
        <div>
          <div class="item-title">
            <span>Trail Width</span>
            <span class="trail-number">{{ trailWeight }}</span>
          </div>
          <b-form-input :disabled="!isShowShipTrail" id="range-1" v-model="trailWeight" type="range" min="1" max="5" />
        </div>
        <div>
          <div class="item-title">
            <span>Trail Opacity</span>
            <span class="trail-number">{{ trailOpacity * 100 }}%</span>
          </div>
          <b-form-input :disabled="!isShowShipTrail" id="range-1" v-model="trailOpacity" type="range" min="0.1" max="1"
            step="0.1" />
        </div>
        <div>
          <span>Trail Length</span>
          <div class="dropdown-container">
            <b-form-select :disabled="!isShowShipTrail" v-model="trailSelected" :options="trailLength.options" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'map-settings',
  data() {
    return {
      isShipStatusExpand: true,
      isShipElementsExpand: true,
      isTrailSettingsExpand: true,
      trailWeight: 5,
      trailOpacity: 1,
      trailSelected: 2,
      trailLength: {
        options: [
          { value: 1, text: '24 Hours' },
          { value: 2, text: '48 Hours' },
          { value: 3, text: '72 Hours' },
          { value: 4, text: '4 Days' },
          { value: 5, text: '5 Days' },
          { value: 6, text: '6 Days' },
          { value: 7, text: '1 Week' },
        ],
      },
      // isShowShipLabel: false,
      // isShowShipTrail: false,
      isOnline: true,
      isOffline: true,
    };
  },
  props: {
    isShown: { type: Boolean, default: false },
    isLoadingShipTrail: { type: Boolean, default: false },
    showAllTooltip: { type: Boolean, default: false },
    isShowShipTrail: { type: Boolean, default: false },
  },
  watch: {
    showAllTooltip(newVal, oldVal) {
      if (newVal !== oldVal) {
        console.info('isShowShipLabel', newVal);
        // this.isShowShipLabel = newVal;
        this.$emit('handelToggleShipLabel', newVal);
      }
    },
    isShowShipTrail(newVal, oldVal) {
      if (newVal !== oldVal) {
        console.info('isShowShipLabel', newVal);
        this.isShowShipTrail = newVal;
        this.$emit('handelToggleShipTrail', newVal);
      }
    },
    trailWeight(newVal, oldVal) {
      if (newVal !== oldVal) {
        console.info('trailWeight', newVal);
        this.trailWeight = newVal;
        this.$emit('handelChangeTrailWeight', newVal);
      }
    },
    trailOpacity(newVal, oldVal) {
      if (newVal !== oldVal) {
        console.info('trailOpacity', newVal);
        this.trailOpacity = newVal;
        this.$emit('handelChangeTrailOpacity', newVal);
      }
    },
    isOnline(newVal, oldVal) {
      if (newVal !== oldVal) {
        console.info('isOnline', newVal);
        this.isOnline = newVal;
        this.$emit('handleChangeShipStatus', { type: 'online', value: newVal });
      }
    },
    isOffline(newVal, oldVal) {
      if (newVal !== oldVal) {
        console.info('isOffline', newVal);
        this.isOffline = newVal;
        this.$emit('handleChangeShipStatus', { type: 'offline', value: newVal });
      }
    },
    trailSelected(newVal, oldVal) {
      if (newVal !== oldVal) {
        console.info('trailSelected', newVal);
        this.$emit('handleChangeTrailLength', newVal);
        this.trailSelected = newVal;
      }
    },
  },
  methods: {
    onCloseButtonClicked() {
      this.$emit('hideMapSettings');
    },
    setIsShipStatusExpand(state) {
      this.isShipStatusExpand = state;
    },
    setIsShipElementsExpand(state) {
      this.isShipElementsExpand = state;
    },
    setIsTrailSettingsExpand(state) {
      this.isTrailSettingsExpand = state;
    },
    // toggleShipTrail() {
    //   this.isShowShipTrail = !this.isShowShipTrail;
    // },
  },
};
</script>

<style scoped>
.z-10000 {
  z-index: 10000 !important;
}

.container {
  position: absolute;
  z-index: 10001;
  right: 70px;
  top: 20px;
  width: 300px;
  padding: 14px;
  background-color: #ecf1fc;
  border-radius: 8px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-icon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  color: #93a1b0;
}

.items {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  margin-top: 14px;
}

.item-container {
  padding: 14px;
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  background-color: white;
  border-radius: 14px;
}

.item-container-collapse {
  max-height: 50px;
  overflow: hidden;
}

.item-title {
  display: flex;
  justify-content: space-between;
}

.item {
  display: flex;
  align-items: center;
}

.trail-number {
  padding: 0 11px;
  background-color: #E5E9EC;
  border-radius: 5px;
}

.dropdown-container select {
  -moz-appearance: none;
  -webkit-appearance: none !important;
}

.dropdown-container select::-ms-expand {
  display: none;
}
</style>
