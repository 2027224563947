<template>
  <div class="rp-footer-wrapper">
    <div>Report generated at : {{ currentTime }}</div>
    <div>{{ page }}/{{ lastPage }}</div>
  </div>
</template>

<script>
export default {
  name: 'ReportFooter',
  props: ['currentTime', 'page', 'lastPage'],
};
</script>

<style scoped>
.rp-footer-wrapper {
  border-top: 3px solid #012b65;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  /* position: absolute; */
  color: black;
  width: 100%;
  bottom: 30px;
}
</style>
