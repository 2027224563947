<template>
  <b-form :inline="isInline" @submit.prevent="onSubmit">
    <!-- <pre>{{localDateParam}}</pre> -->
    <label for="analyticInterval" class="sr-only">Interval</label>
    <select
      class="form-control mr-3 mb-0"
      id="analyticInterval"
      v-model="selected.dateInterval"
      v-if="enableInterval"
      @change="intervalChanged">
      <option
        v-for="item in intervalOptions"
        v-bind:key="`interval-${item.value}`"
        :value="item.value"
        :disabled="item.selectable === false">
        {{ item.label }}
      </option>
    </select>
    <!-- <DatePicker v-model='start' /> -->
    <DatePicker
        ref="pickie"
        v-if="selected.range"
        v-model="selected.range"
        mode="dateTime"
        :popover="{visibility: visibility}"
        :masks="masks"
        @input="dateSelected"
        is-range
        is24hr
      >
      <template v-slot="{ inputValue, inputEvents, isDragging }">
        <div class="form-group">
          <label>Between</label>
          <input
            class="ml-3 mr-3 form-control"
            :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
            :value="inputValue.start"
            v-on="inputEvents.start"
          />
          <label>And</label>
          <input
            class="ml-3 mr-3 form-control"
            :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
            :value="inputValue.end"
            v-on="inputEvents.end"
          />
        </div>
      </template>
      <template v-slot:footer>
        <div class="text-center p-2">
          <button
            class="btn btn-warning btn-md"
            @click.prevent="closePopup"
            >
            Done
          </button>
        </div>
      </template>
    </DatePicker>
    <select
      class="form-control mr-3 mb-0"
      id="dataInterval"
      v-model="selected.dataInterval"
      @change="dataIntervalChanged">
      <option
        v-for="(item, index) in dataIntervalOptions"
        v-bind:key="`interval-${index}`"
        :value="item.value">
        {{ item.label }}
      </option>
    </select>
    <b-button
      variant="primary"
      size="md"
      type="submit"
      :disabled="isLoading">
      <i class="fa" :class="isLoading ? 'ri-loader-line' : 'ri-search-line'"></i>
      Apply
    </b-button>
  </b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import dateUtil from '@/util/date';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';

export default {
  name: 'date-picker',
  components: {
    DatePicker,
  },
  props: ['isInline', 'isLoading', 'enableInterval', 'dateParam', 'isSingleEngine'],
  data() {
    return {
      visibility: 'click',
      masks: {
        input: 'YYYY-MM-DD h:mm A',
      },
      dataIntervalOptions: dateUtil.dataIntervalOptions,
      intervalOptions: dateUtil.intervals,
      dateRange: undefined,
      selected: {
        dateInterval: undefined,
        dataInterval: undefined,
        range: undefined,
      },
      localDateParam: undefined,
    };
  },
  validations: {
    start: {
      required,
    },
    end: {
      required,
    },
  },
  methods: {
    closePopup() {
      this.$nextTick(() => {
        // console.log('Close...', this.$refs.pickie);
        this.$refs.pickie.hidePopover();
        // this.visibility = 'hidden';
      });
      // this.$nextTick(() => this.$refs.pickie.$el.blur());
    },
    dateSelected() {
      console.log('Date changed... ', this.selected.range);
      this.selected.dateInterval = 'custom';
      this.localDateParam.range.start = this.selected.range.start.getTime();
      this.localDateParam.range.end = this.selected.range.end.getTime();
    },
    intervalChanged() {
      this.localDateParam = dateUtil.byInterval(this.selected.dateInterval);
      this.selected.range = this.localDateParam.range;
      this.selected.dataInterval = this.localDateParam.aggregatedUnit;
      console.log('Interval changed... ', this.localDateParam);
    },
    dataIntervalChanged() {
      console.log('DataInterval changed... ', this.selected.dataInterval);
      this.localDateParam.aggregatedUnit = this.selected.dataInterval;
      // this.selected.dataInterval = this.localDateParam.aggregatedUnit;
    },
    onSubmit() {
      this.$emit('onSubmitRange', this.localDateParam);
    },
  },
  mounted() {
    // console.log('Datepicker mounted using param : ', this.dateParam);
    this.localDateParam = this.dateParam;
    this.selected.dateInterval = this.localDateParam.value;
    this.selected.dataInterval = this.localDateParam.aggregatedUnit;
    this.selected.range = this.localDateParam.range;
  },
};
</script>
