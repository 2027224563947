<template>
  <section class="sign-in-page bg-white">
    <b-container fluid class="h-100">
      <b-row class="h-100" align-v="center">
        <b-col md="10" offset-md="1">
          <router-view></router-view>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
// import { sofbox } from '@/config/pluginInit';

export default {
  name: 'BlankLayout',
  // mounted() {
  //   sofbox.mainIndex();
  // },
};
</script>
