<template>
  <b-container fluid>
    <ForbiddenIndex/>
  </b-container>
</template>

<script>
import ForbiddenIndex from '../components/authorized/forbidden/Index';

export default {
  name: 'ForbiddenView',
  components: { ForbiddenIndex },
};
</script>

<style scoped>

</style>
