<template>
  <b-container fluid>
    <AssetTypeIndex/>
  </b-container>
</template>

<script>

import AssetTypeIndex from '@/components/asset/type/Index';

export default {
  name: 'AssetType',
  components: { AssetTypeIndex },
  data() {
    return {};
  },
};
</script>

<style scoped>

</style>
