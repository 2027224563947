<template>
  <b-container fluid>
    <b-row class="row-eq-height">
      <!-- <pre>{{lastActiveAgo}}</pre> -->
      <b-col md="12" class="mt-3">
        <b-tabs v-if="activeAsset">
          <b-tab
              class="mr-1"
              v-for="(item, index) in assets" v-bind:key="index"
              :variant="filter.devcMassId === item.massId ? 'primary' : 'outline-primary'"
              :active="activeAsset.massId === item.massId"
              @click="setActive(item)">
            <template #title>
              <span>{{item.massName}}</span>
              <span v-if="item.fromNow > 0 && item.fromNow < 300" class="badge ml-2 badge-success">{{item.fromNow | toHHMMSS}}</span>
              <span v-if="item.fromNow > 300 && item.fromNow < 600" class="badge ml-2 badge-warning">{{item.fromNow | toHHMMSS}}</span>
              <span v-if="item.fromNow > 600" class="badge ml-2 badge-danger">{{item.fromNow | toHHMMSS}}</span>
            </template>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <b-row class="row-eq-height">
      <b-col md="12">
        <iq-card body-class="fullBodyCard">
          <template v-slot:body>
            <b-row>
              <b-col md="12" class="mb-3 mt-3" v-if="dateParam && !isLoading">
                <date-picker
                  :dateParam="dateParam"
                  :isInline="true"
                  :isLoading="isLoading"
                  :enableInterval="true"
                  :isSingleEngine="isSingleEngine"
                  @onSubmitRange="onSubmitRange"/>
              </b-col>
            </b-row>
            <b-row md="12" class="mb-3" v-if="lastAvailableGPSData">
              <b-col>
                <b-alert :show="true" variant=" " class="text-white bg-danger">
                  <div class="iq-alert-icon">
                    <i class="ri-information-line"></i>
                  </div>
                  <div class="iq-alert-text">
                    <!-- <b>No Data On Selected Timerange</b> -->
                    <h5 class="alert-heading">No Data On Selected Timerange!</h5>
                    <!-- <p>Aww yeah, you successfully read this important alert message. This example text is going to run a bit longer so that you can see how spacing within an alert works with this kind of content.</p> -->
                    <hr>
                    <p class="mb-0">Last seen {{lastAvailableGPSData.timestamp | toLocalDate}}</p>
                  </div>
                </b-alert>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <div v-if="!isLoading&&filter.devcMassId">
      <!-- <pre>{{assetObject[filter.devcMassId].lastData.rpm}}</pre> -->
      <!-- <div v-for="deviceId in sortedDeviceKeys" :key="deviceId"> -->
      <b-row v-if="assetObject[filter.devcMassId].massId != 42&&assetObject[filter.devcMassId].lastData&&assetObject[filter.devcMassId].lastData.rpm" class="row-eq-height">
        <b-col lg="6" md="12" v-for="r in Object.values(assetObject[filter.devcMassId].lastData.rpm).filter((port) => port.type !== 'MAINENGINE')" :key="`rpmData-${r.type}`">
          <Tachometer :data="r" :summaries="assetObject[filter.devcMassId].summaries"/>
        </b-col>
      </b-row>
      <b-row v-if="assetObject[filter.devcMassId].massId == 42&&assetObject[filter.devcMassId].lastData&&assetObject[filter.devcMassId].lastData.rpm" class="row-eq-height">
        <!-- <b-col offset-lg="3" lg="6" md="12" v-for="r in Object.values(assetObject[filter.devcMassId].lastData.rpm).filter((port) => port.type === 'MAINENGINE')" :key="`rpmData-${r.type}`"> -->
        <b-col lg="12" md="12" v-for="r in Object.values(assetObject[filter.devcMassId].lastData.rpm).filter((port) => port.type === 'MAINENGINE')" :key="`rpmData-${r.type}`">
          <Tachometer :data="r" :summaries="assetObject[filter.devcMassId].summaries"/>
        </b-col>
        <b-col lg="12" md="12">
          <iq-card class="text-center">
            <template v-slot:headerTitle>
              <h4 class="card-title" v-if="assetObject[filter.devcMassId].massId == 42">MAINENGINE</h4>
            </template>
            <template v-slot:body>
              <div v-if="assetObject[filter.devcMassId].massId == 42 &&assetObject[filter.devcMassId].lastData.flowmeter.portFlow !== undefined">
                <h5 class="text-dark mb-0">Fuel Consumption <span v-if="assetObject[filter.devcMassId].summaries.flowmeter.portEngineCons">{{assetObject[filter.devcMassId].summaries.flowmeter.portEngineCons.toFixed(2)}} Liter</span></h5>
                <b-progress class="mt-2" :max="20" show-value>
                  <b-progress-bar :value="assetObject[filter.devcMassId].lastData.flowmeter.portFlow" variant="success" :label="`${(assetObject[filter.devcMassId].lastData.flowmeter.portFlow).toFixed(1)} L/h`"></b-progress-bar>
                  <b-progress-bar v-if="assetObject[filter.devcMassId].summaries.flowmeter.portAverageFlow - assetObject[filter.devcMassId].lastData.flowmeter.portFlow > 0" :value="assetObject[filter.devcMassId].summaries.flowmeter.portAverageFlow - assetObject[filter.devcMassId].lastData.flowmeter.portFlow" variant="warning" :label="`${(assetObject[filter.devcMassId].summaries.flowmeter.portAverageFlow).toFixed(1)}`"></b-progress-bar>
                  <!-- <b-progress-bar :value="value * (1.5 / 10)" variant="danger"></b-progress-bar> -->
                </b-progress>
                <h2 class="text-primary">{{assetObject[filter.devcMassId].lastData.flowmeter.portFlow.toFixed(2)}}<small class="ml-3">Liter/Hour</small></h2>
                <small>Average Flow {{assetObject[filter.devcMassId].summaries.flowmeter.portAverageFlow.toFixed(2)}} Liter/Hour</small>
                <!-- <pre>Current fuel cons : {{assetObject[filter.devcMassId].lastData.flowmeter.portFuelCons}}</pre> -->
                <!-- <pre>Average fuel cons : {{assetObject[filter.devcMassId].summaries.flowmeter.portTotalAverageFlow / (assetObject[filter.devcMassId].summaries.rpm.runningTime.PORT / 3600)}} L/h</pre> -->
                <!-- <pre>Current fuel flow : {{assetObject[filter.devcMassId].lastData.flowmeter.portFlow}}</pre>
                <pre>Avg : {{assetObject[filter.devcMassId].summaries.flowmeter.portAverageFlow}}</pre>
                <pre>In density : {{assetObject[filter.devcMassId].lastData.flowmeter.portInDens}}</pre>
                <pre>Out density : {{assetObject[filter.devcMassId].lastData.flowmeter.portOutDens}}</pre>
                <pre>Engine consumption : {{assetObject[filter.devcMassId].summaries.flowmeter.portEngineCons}}</pre> -->
              </div>
            </template>
          </iq-card>
        </b-col>
      </b-row>
      <b-row v-if="assetObject[filter.devcMassId].lastData&&assetObject[filter.devcMassId].lastData.flowmeter" class="row-eq-height">
        <b-col lg="6" md="12">
          <iq-card class="text-center">
            <template v-slot:headerTitle>
              <h4 class="card-title" v-if="assetObject[filter.devcMassId].massId != 42">Port Flowmeter</h4>
            </template>
            <template v-slot:body>
              <div v-if="assetObject[filter.devcMassId].massId != 42 &&assetObject[filter.devcMassId].lastData.flowmeter.portFlow !== undefined">
                <h5 class="text-dark mb-0">Fuel Consumption <span v-if="assetObject[filter.devcMassId].summaries.flowmeter.portEngineCons">{{assetObject[filter.devcMassId].summaries.flowmeter.portEngineCons.toFixed(2)}} Liter</span></h5>
                <b-progress class="mt-2" :max="20" show-value>
                  <b-progress-bar :value="assetObject[filter.devcMassId].lastData.flowmeter.portFlow" variant="success" :label="`${(assetObject[filter.devcMassId].lastData.flowmeter.portFlow).toFixed(1)} L/h`"></b-progress-bar>
                  <b-progress-bar v-if="assetObject[filter.devcMassId].summaries.flowmeter.portAverageFlow - assetObject[filter.devcMassId].lastData.flowmeter.portFlow > 0" :value="assetObject[filter.devcMassId].summaries.flowmeter.portAverageFlow - assetObject[filter.devcMassId].lastData.flowmeter.portFlow" variant="warning" :label="`${(assetObject[filter.devcMassId].summaries.flowmeter.portAverageFlow).toFixed(1)}`"></b-progress-bar>
                  <!-- <b-progress-bar :value="value * (1.5 / 10)" variant="danger"></b-progress-bar> -->
                </b-progress>
                <h2 class="text-primary">{{assetObject[filter.devcMassId].lastData.flowmeter.portFlow.toFixed(2)}}<small class="ml-3">Liter/Hour</small></h2>
                <small>Average Flow {{assetObject[filter.devcMassId].summaries.flowmeter.portAverageFlow.toFixed(2)}} Liter/Hour</small>
                <!-- <pre>Current fuel cons : {{assetObject[filter.devcMassId].lastData.flowmeter.portFuelCons}}</pre> -->
                <!-- <pre>Average fuel cons : {{assetObject[filter.devcMassId].summaries.flowmeter.portTotalAverageFlow / (assetObject[filter.devcMassId].summaries.rpm.runningTime.PORT / 3600)}} L/h</pre> -->
                <!-- <pre>Current fuel flow : {{assetObject[filter.devcMassId].lastData.flowmeter.portFlow}}</pre>
                <pre>Avg : {{assetObject[filter.devcMassId].summaries.flowmeter.portAverageFlow}}</pre>
                <pre>In density : {{assetObject[filter.devcMassId].lastData.flowmeter.portInDens}}</pre>
                <pre>Out density : {{assetObject[filter.devcMassId].lastData.flowmeter.portOutDens}}</pre>
                <pre>Engine consumption : {{assetObject[filter.devcMassId].summaries.flowmeter.portEngineCons}}</pre> -->
              </div>
            </template>
          </iq-card>
        </b-col>
        <b-col lg="6" md="12">
          <iq-card class="text-center">
            <template v-slot:headerTitle>
              <h4 class="card-title" v-if="assetObject[filter.devcMassId].massId != 42">Starboard Flowmeter</h4>
            </template>
            <template v-slot:body>
              <div v-if="assetObject[filter.devcMassId].massId != 42  &&assetObject[filter.devcMassId].lastData.flowmeter.stbFlow !== undefined">
                <!-- <pre>Current fuel cons : {{assetObject[filter.devcMassId].lastData.flowmeter.stbFuelCons}}</pre> -->
                <!-- <pre>Average fuel cons : {{assetObject[filter.devcMassId].summaries.flowmeter.starboardTotalAverageFlow / (assetObject[filter.devcMassId].summaries.rpm.runningTime.STARBOARD / 3600)}} L/h</pre> -->
                <h5 class="text-dark mb-0">Fuel Consumption <span v-if="assetObject[filter.devcMassId].summaries.flowmeter.stbEngineCons">{{assetObject[filter.devcMassId].summaries.flowmeter.stbEngineCons.toFixed(2)}} Liter</span></h5>
                <b-progress class="mt-2" :max="20" show-value>
                  <b-progress-bar :value="assetObject[filter.devcMassId].lastData.flowmeter.stbFlow" variant="success" :label="`${(assetObject[filter.devcMassId].lastData.flowmeter.stbFlow).toFixed(1)} L/h`"></b-progress-bar>
                  <b-progress-bar v-if="assetObject[filter.devcMassId].summaries.flowmeter.starboardAverageFlow - assetObject[filter.devcMassId].lastData.flowmeter.stbFlow > 0" :value="assetObject[filter.devcMassId].summaries.flowmeter.starboardAverageFlow - assetObject[filter.devcMassId].lastData.flowmeter.stbFlow" variant="warning" :label="`${(assetObject[filter.devcMassId].summaries.flowmeter.starboardAverageFlow).toFixed(1)}`"></b-progress-bar>
                  <!-- <b-progress-bar :value="value * (1.5 / 10)" variant="danger"></b-progress-bar> -->
                </b-progress>
                <h2 class="text-primary">{{assetObject[filter.devcMassId].lastData.flowmeter.stbFlow.toFixed(2)}}<small class="ml-3">Liter/Hour</small></h2>
                <small>Average Flow {{assetObject[filter.devcMassId].summaries.flowmeter.starboardAverageFlow.toFixed(2)}} Liter/Hour</small>
                <!-- <pre>Current fuel flow : {{assetObject[filter.devcMassId].lastData.flowmeter.stbFlow}}</pre>
                <pre>Avg : {{assetObject[filter.devcMassId].summaries.flowmeter.starboardAverageFlow}}</pre>
                <pre>In density : {{assetObject[filter.devcMassId].lastData.flowmeter.stbInDens}}</pre>
                <pre>Out density : {{assetObject[filter.devcMassId].lastData.flowmeter.stbOutDens}}</pre>
                <pre>Engine consumption : {{assetObject[filter.devcMassId].summaries.flowmeter.stbEngineCons}}</pre> -->
              </div>
            </template>
          </iq-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <iq-card v-if="assetObject[filter.devcMassId].lastData&&assetObject[filter.devcMassId].lastData.gps" body-class="fullBodyCard">
            <template v-slot:headerTitle>
              <h4 class="card-title">{{devicesObject[assetObject[filter.devcMassId].lastData.gps.deviceId].devcName}}</h4>
            </template>
            <template v-slot:headerAction>
              <ul class="nav nav-pills">
                <!-- <li class="nav-item mr-3"> -->
                <li class="nav-item">
                  <a href="#" class="nav-link" @click.prevent="showDataLog">Show Data Log</a>
                </li>
                <!-- <li class="nav-item">
                  <b-dropdown id="dropdownMenuButton1" right variant=" iq-bg-secondary" data-toggle="dropdown">
                    <template v-slot:button-content>
                      Options <i class="ri-arrow-down-s-line ml-1 text-secondary"></i>
                    </template>
                    <b-dropdown-item @click="showDataLog"><i class="ri-eye-fill mr-2"></i>View Data</b-dropdown-item>
                    <b-dropdown-item><i class="ri-file-download-fill mr-2"></i>Download Data</b-dropdown-item>
                  </b-dropdown>
                </li> -->
              </ul>
            </template>
            <template v-slot:body>
              <!-- <Map
                :center="related.centerMap ? related.centerMap : [assetObject[filter.devcMassId].devices[deviceId].data[0].latitude, assetObject[filter.devcMassId].devices[deviceId].data[0].longitude]"
                :data="assetObject"
                class="row h-100"
                style="position: sticky;"/> -->
              <Map
                :center="[0 , 0]"
                :data="selectedAssetObject"
                :showSummary="true"
                class="row dashboardMap"
                style="position: sticky;"/>
            </template>
            <template v-slot:footer>
              <!-- <small>{{assetObject[filter.devcMassId].devices[deviceId].data[assetObject[filter.devcMassId].devices[deviceId].data.length-1].timestamp | toLocalDate}}</small> -->
            </template>
          </iq-card>
          <iq-card v-else>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{assetObject[filter.devcMassId].massName}}</h4>
            </template>
            <template v-slot:headerAction>
              <ul class="nav nav-pills">
                <li class="nav-item">
                  <a href="#" class="nav-link" @click.prevent="showDataLog">Show Data Log</a>
                </li>
              </ul>
            </template>
            <template v-slot:body>
              <strong>No Data</strong>
            </template>
          </iq-card>
        </b-col>
      </b-row>
      <b-row v-if="assetObject[filter.devcMassId].lastData&&assetObject[filter.devcMassId].lastData.ae" class="row-eq-height">
        <b-col md="4" v-for="(ae, no) in Object.values(assetObject[filter.devcMassId].lastData.ae).sort()" :key="no">
          <iq-card>
            <template v-slot:body>
              <b-row>
                <b-col lg="12">
                  <h6 class="card-title text-uppercase text-secondary mb-0">AE{{ae.no + 1}}</h6>
                  <span class="h2 text-dark mb-0 counter d-inline-block w-100">
                    <span v-if="ae.runningTime > 0">ON</span>
                    <span v-else>OFF</span>
                  </span>
                  <span class="text-info mr-2">Fuel Consumption {{ae.fuelConsumption.toFixed(2)}}</span>
                </b-col>
              </b-row>
              <p class="mb-0 text-muted mt-1">
                <small>Last update {{ae.timestamp | toLocalDate}}</small>
              </p>
            </template>
          </iq-card>
        </b-col>
      </b-row>
      <!-- <b-row v-if="assetObject[filter.devcMassId].devices[deviceId].devcType=='flowmeter'" class="row-eq-height">
        <b-col md="12" v-for="(dt, dtIdx) in assetObject[filter.devcMassId].devices[deviceId].data" :key="`fmData-${dtIdx}`">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{assetObject[filter.devcMassId].devices[deviceId].devcName}}</h4>
            </template>
            <template v-slot:body>
              <pre>{{dt}}</pre>
            </template>
          </iq-card>
        </b-col>
      </b-row> -->
      <!-- </div> -->
      <b-row v-if="Object.keys(assetObject[filter.devcMassId].datas).length > 0">
        <b-col md="12">
          <iq-card body-class="p-0">
            <template v-slot:headerAction>
              <ul class="nav nav-pills">
                <li class="nav-item">
                  <a href="#" class="nav-link" @click.prevent="showDataLog">Show Data Log</a>
                </li>
                <li v-if="assetObject[filter.devcMassId].massId == 42" class="nav-item">
                  <a href="#" class="nav-link" @click.prevent="download">Download</a>
                </li>
              </ul>
            </template>
            <template v-slot:headerTitle>
              <h4 class="card-title">RPM vs Consumption</h4>
            </template>
            <template v-slot:body>
              <RGraph
                ref="chartGraph"
                @onZoomed="chartIndexUpdated"
                @onScrolled="chartIndexUpdated"
                :data="assetObject[filter.devcMassId]"/>
                <b-table-simple v-if="assetObject[filter.devcMassId].massId == 42" id="tableDashboardRpmCons" small striped hover responsive class="text-center mt-2 font-weight-bold" hidden>
                <b-tbody>
                  <b-tr>
                    <b-td style="border: 1px solid black; width: 100px !important; font-size: 10px; background-color: #002a64 !important; color: white;">Hour</b-td>
                    <b-td style="border: 1px solid black; width: 40px !important; font-size: 10px; background-color: lightgray !important;"  v-for="hour in Array.from({ length: Object.keys(assetObject[filter.devcMassId].datas).length }, (_, i) => i + 1)" v-bind:key="hour">{{hour}}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td style="border: 1px solid black; width: 100px !important; font-size: 10px; background-color: #002a64 !important; color: white;" >ME RPM</b-td>
                    <b-td style="border: 1px solid black; width: 40px !important; font-size: 10px;" v-for="(x, idx) in assetObject[filter.devcMassId].datas" v-bind:key="idx">{{ x.rpm.MAINENGINE ? roundValue(x.rpm.MAINENGINE.rpm) : '-' }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td style="border: 1px solid black; width: 100px !important; font-size: 10px; background-color: #002a64 !important; color: white;" >Fuel Cons</b-td>
                    <b-td style="border: 1px solid black; width: 40px !important; font-size: 10px;" v-for="(x, idx) in assetObject[filter.devcMassId].datas" v-bind:key="idx">{{ x.flowmeter ? roundValue(x.flowmeter.portFuelCons) : '-' }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td style="border: 1px solid black; width: 100px !important; font-size: 10px; background-color: #002a64 !important; color: white;" >ME Running Time</b-td>
                    <b-td style="border: 1px solid black; width: 40px !important; font-size: 10px;" v-for="(x, idx) in assetObject[filter.devcMassId].datas" v-bind:key="idx">{{ x.rpm.MAINENGINE ? roundValue(x.rpm.MAINENGINE.runningTime) : '-' }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td style="border: 1px solid black; width: 100px !important; font-size: 10px; background-color: #002a64 !important; color: white;" >Speed</b-td>
                    <b-td style="border: 1px solid black; width: 40px !important; font-size: 10px;" v-for="(x, idx) in assetObject[filter.devcMassId].datas" v-bind:key="idx">{{ x.gps ? roundValue(x.gps.speed) : '-' }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td style="border: 1px solid black; width: 100px !important; font-size: 10px; background-color: #002a64 !important; color: white;" >Distance</b-td>
                    <b-td style="border: 1px solid black; width: 40px !important; font-size: 10px;" v-for="(x, idx) in assetObject[filter.devcMassId].datas" v-bind:key="idx">{{ x.gps ? roundValue(x.gps.polyLength) : '-' }}</b-td>
                  </b-tr>
                </b-tbody>
            </b-table-simple>
            </template>
          </iq-card>
          <b-table id="tableLogs" selectable sticky-header="300px" striped hover fixed small>
             <b-tr class="text-center">
                <b-th colspan="1"></b-th>
                <b-th colspan="4">GPS</b-th>
                <b-th colspan="4">RPM</b-th>
                <b-th colspan="6">AE</b-th>
                <b-th colspan="17">Flowmeter</b-th>
              </b-tr>
              HALO
          </b-table>
        </b-col>
      </b-row>
      <!-- <b-row v-if="showRealtimeChart">
        <b-col md="12">
          <iq-card body-class="p-0">
            <template v-slot:headerAction>
              <ul class="nav nav-pills">
                <li class="nav-item">
                  <a href="#" class="nav-link" @click.prevent="showDataLog">Show Data Log</a>
                </li>
              </ul>
            </template>
            <template v-slot:headerTitle>
              <h4 class="card-title">RPM vs Consumption</h4>
            </template>
            <template v-slot:body>
              <RChart :data="widget"/>
            </template>
          </iq-card>
        </b-col>
      </b-row> -->
    </div>
  </b-container>
</template>

<script>
import DatePicker from '@/components/directives/date-picker';

import AssetSvc from '@/services/AssetSvc';
// import CanvasDirectives from '@/components/directives/canvas';
import DeviceSvc from '@/services/DeviceSvc';
import SeriesSvc from '@/services/SeriesSvc';
import Map from '@/components/directives/iq-map';
import Tachometer from '@/components/directives/tachometer';
import RGraph from '@/components/directives/report-graph';
// import RChart from '@/components/directives/realtime-chart';
import dateUtil from '@/util/date';

import requestBuilder from '@/util/request.builder';
import PdfGenerator from '@/services/PdfGenerator';
import { sofbox } from '../../config/pluginInit.js';

export default {
  name: 'DashboardIndex',
  // components: { CanvasDirectives },
  components: {
    Map,
    DatePicker,
    Tachometer,
    RGraph,
    // RChart,
  },
  data() {
    return {
      badgeClass: 'badge-success',
      lastActiveAgo: 0,
      lastDataTimestamp: 0,
      showRealtimeChart: false,
      dateParam: undefined,
      activeAsset: undefined,
      related: {},
      widget: {
        PORT: {
          rpm: [],
          fm: {
            IN: [],
            OUT: [],
          },
        },
        MAINENGINE: {
          rpm: [],
          fm: {
            IN: [],
            OUT: [],
          },
        },
        STARBOARD: {
          rpm: [],
          fm: {
            IN: [],
            OUT: [],
          },
        },
      },
      assets: [],
      selectedAssetObject: {},
      assetObject: {},
      assetsLocation: [],
      lastAvailableGPSData: undefined,
      // sortedDeviceKeys: [],
      gpsId: undefined,
      isSingleEngine: false,
      devices: [],
      devicesObject: {},
      devicesData: {},
      filter: {
        devcMassId: undefined,
      },
      isLoading: false,
    };
  },
  async mounted() {
    sofbox.index();
    await this.getAssets();
    // console.log('Asset nya lo : ', this.assetObject, this.$route.query);
    if (this.$route.query && this.$route.query.massId) {
      this.activeAsset = this.assetObject[this.$route.query.massId];
    } else {
      this.activeAsset = this.assets[0];
    }
    // console.log('Load Assets : ', this.assets);
    // const lastData = await SeriesSvc.getLastData('gps', ['gps-1', 'gps-2']);
    // console.log('LLLL : ', lastData);
    await this.load();
    /* add last seen counter */
    this.$options.timer = window.setTimeout(this.updateDateTime, 1000);
  },
  beforeDestroy() {
    window.clearTimeout(this.$options.timer);
  },
  methods: {
    chartIndexUpdated(xaxis) {
      this.dateParam = dateUtil.byInterval('custom');
      this.dateParam.range = {};
      const timeIndex = Object.keys(this.assetObject[this.filter.devcMassId].datas);
      let updatedStart = timeIndex[xaxis.min];
      let updatedEnd = timeIndex[xaxis.max];
      if (!timeIndex[xaxis.min]) {
        updatedStart = timeIndex[0];
      }
      if (!timeIndex[xaxis.max]) {
        updatedEnd = timeIndex[timeIndex.length - 1];
      }
      if (xaxis.min < 0) {
        const indexBefore = xaxis.min * -1;
        const interval = parseInt((parseInt(updatedEnd) - parseInt(updatedStart)) / timeIndex.length);
        updatedStart -= indexBefore * interval;
      }

      this.dateParam.range.start = parseInt(updatedStart) * 1000;
      this.dateParam.range.end = parseInt(updatedEnd) * 1000;
      this.dateParam.aggregatedUnit = 'MINUTE';
      console.log('date params', this.dateParam);
      this.onSubmitRange(this.dateParam);
      // console.log('Load data based on chart index : ', xaxis, timeIndex, timeIndex[xaxis.min], timeIndex[xaxis.max]);
    },
    updateDateTime() {
      const now = parseInt(new Date().getTime() / 1000);
      // console.log(this.lastDataTimestamp);
      if (this.lastDataTimestamp > 0) {
        this.lastActiveAgo = now - this.lastDataTimestamp;
        if (this.lastActiveAgo > 300 && this.lastActiveAgo <= 600) {
          this.badgeClass = 'badge-warning';
        } else if (this.lastActiveAgo > 600) {
          this.badgeClass = 'badge-danger';
        } else {
          this.badgeClass = 'badge-success';
        }
        // if (this.lastActiveAgo % 10 === 0) {
        //   this.load();
        // }
      }
      this.$options.timer = window.setTimeout(this.updateDateTime, 1000);
    },
    async getAssets() {
      this.isLoading = true;
      try {
        const res = await AssetSvc.getAssets();
        if (res.data.status.error === 0) {
          this.assets = res.data.data;
          this.assets.forEach((asset) => {
            this.assetObject[asset.massId] = asset;
          });
        }
      } catch (e) {
        this.$toast.error(e.message);
        // this.doLogOut();
      } finally {
        this.isLoading = false;
      }
    },
    async load() {
      const asset = this.activeAsset;
      const massIds = Object.keys(this.assetObject);
      console.log('User has access to these assets : ', massIds);
      this.isLoading = true;
      this.lastDataTimestamp = 0;
      this.showRealtimeChart = false;
      this.filter.devcMassId = asset.massId;
      const resp = await DeviceSvc.getDevices({
        params: {
          ...requestBuilder.buildFilters({}, this.filter),
        },
      });
      this.devices = resp.data.data;
      for (let i = 0; i < this.devices.length; i++) {
        const device = this.devices[i];
        if (device.devcType === 'gps') {
          this.gpsId = device.devcUniqueId;
        }
        this.devicesObject[device.devcUniqueId] = device;
      }
      if (this.$route.query.value) {
        console.log('Data period defined... using query...');
        this.dateParam = dateUtil.queryToDateParam(this.$route.query);
      } else {
        console.log('--->> Should be showing latest data <<---');
        this.dateParam = dateUtil.byInterval('today');
        // this.dateParam.aggregatedUnit = 'HOUR';
        const lastData = await SeriesSvc.getLastData('gps', this.gpsId);
        // this.lastAvailableGPSData = lastData.data.data.series[0];
        // console.log('LD : ', this.lastAvailableData.series[0]);
        if (lastData && lastData.data && lastData.data.data && lastData.data.data.series && new Date().getTime() - (lastData.data.data.series[0].timestamp * 1000) > 86400000) {
          // console.log('LD : ', new Date().getTime() - (lastData.data.data.series[0].timestamp * 1000));
          this.dateParam.range = {
            start: (lastData.data.data.series[0].timestamp * 1000) - 86400000,
            end: lastData.data.data.series[0].timestamp * 1000,
          };
        } else {
          console.log('Data period not defined... using today...');
          this.dateParam = dateUtil.byInterval('today');
        }
      }
      if (!this.$route.query.value && this.devices && this.devices[0].devcMassId === '42') {
        this.isSingleEngine = true;
        this.dateParam.aggregatedUnit = 'HOUR';
      }
      this.dateParam.primaryFunctionName = 'LAST';
      // this.dateParam = dateUtil.autoMaxData(dateUtil.msToS(this.dateParam));
      /* Old Series */
      // const series = await this.getDevicesData(asset.massId).catch((err) => { console.error('Get devices data error : ', err); });
      // // console.log('Series output : ', series);
      // series.forEach((serie) => {
      //   if (serie.data.data.devices.length === 0) return;
      //   if (this.assetObject[asset.massId].devices[serie.data.data.devices[0]].devcType === 'rpm') {
      //     this.assetObject[asset.massId].devices[serie.data.data.devices[0]].data.push(serie.data.data.series);
      //     serie.data.data.series.forEach((s) => {
      //       // console.log(s.rpm);
      //       if (s.rpm > 0) {
      //         this.showRealtimeChart = true;
      //       }
      //       this.widget[s.type].rpm.push(s);
      //     });
      //   } else if (this.assetObject[asset.massId].devices[serie.data.data.devices[0]].devcType === 'flowmeter') {
      //     serie.data.data.series.forEach((s) => {
      //       this.widget[this.assetObject[asset.massId].devices[serie.data.data.devices[0]].devcLabel.split('_')[0]].fm[this.assetObject[asset.massId].devices[serie.data.data.devices[0]].devcLabel.split('_')[1]].push(s);
      //     });
      //   } else if (this.assetObject[asset.massId].devices[serie.data.data.devices[0]].devcType === 'ae') {
      //     const aes = {};
      //     serie.data.data.series.forEach((serie) => {
      //       if (!aes[serie.no]) aes[serie.no] = [];
      //       aes[serie.no].push(serie);
      //     });
      //     this.assetObject[asset.massId].devices[serie.data.data.devices[0]].data = aes;
      //   } else {
      //     this.assetObject[asset.massId].devices[serie.data.data.devices[0]].data = serie.data.data.series;
      //     serie.data.data.series.forEach((s) => {
      //       if (this.lastDataTimestamp < s.timestamp) {
      //         this.lastDataTimestamp = s.timestamp;
      //       }
      //     });
      //     // console.log('Console log : ', this.lastDataTimestamp);
      //   }
      // });
      // console.log('This is Dashboard reuqired series... :', this.assetObject[asset.massId]);
      /* Old Series Parser */

      const seriesParam = dateUtil.autoMaxData(dateUtil.msToS(this.dateParam));
      // console.log('Dodidammm... : ', this.devices, seriesParam);
      // seriesParam.typesToGet = ['gps'];
      seriesParam.typesToGet = ['flowmeter', 'gps', 'rpm', 'ae'];
      // seriesParam.typesToGet = ['ae', 'rpm'];
      const combinedData = await SeriesSvc.getCombinedData(this.devices, seriesParam);
      console.log('===> combinedData', combinedData);
      if (this.assetObject[asset.massId].datas) this.assetObject[asset.massId].datas = {};
      this.assetObject[asset.massId] = this.lodash.merge(this.assetObject[asset.massId], combinedData);
      console.log('All data combined : ', this.assetObject[asset.massId]);
      if (this.assetObject[asset.massId].lastData === undefined) {
        const lastData = await SeriesSvc.getLastData('gps', this.gpsId);
        this.lastAvailableGPSData = lastData.data.data.series[0];
        console.log('LD : ', this.lastAvailableGPSData);
      } else {
        this.lastAvailableGPSData = undefined;
      }
      console.log('halooo', this.assetObject);
      this.isLoading = false;
      this.selectedAssetObject = {
        [this.filter.devcMassId]: this.assetObject[this.filter.devcMassId],
      };
    },
    roundValue(x) {
      if (x < 1 && x > 0) {
        return x.toFixed(2);
      }

      return Math.round(x);
    },
    async download() {
      // console.log('Download report... ', this.simpleMapScreenshoter);
      const printData = {
        // type: {
        //   label: 'RPM vs Consumption',
        //   type: 'dashboardRpmCons',
        // },
        type: {
          type: 'dashboardRpmCons',
          label: 'RPM vs Consumption',
          tableId: '#tableDashboardRpmCons',
        },
        asset: this.devices[0].devcMass,
        data: this.assetObject[this.filter.devcMassId],
        period: `${this.$filters.toLocalDate(this.assetObject[this.filter.devcMassId].start)} to ${this.$filters.toLocalDate(this.assetObject[this.filter.devcMassId].end)}`,
        // period: '11 May 2023 00:00:00 to 11 May 2023 15:39:36',
      };
      console.info('bos', printData);
      // if (this.activeType === 'gps') {
      //   await this.screenShoter.takeScreen('image', {
      //     mimeType: 'image/png',
      //   }).then((image) => {
      //     printData.map = image;
      //     // this.mapShot = image;
      //     // console.log('IMAGE: ', image);
      //   }).catch((e) => {
      //     console.error(e);
      //   });
      //   // printData.map = this.mapShot;
      // }
      if (this.$refs.chartGraph) {
        printData.chart = this.$refs.chartGraph;
      }
      PdfGenerator.generate(printData)
        // .output('dataurlnewwindow', 'report.pdf');
        .save(`RpmCons_${this.assetObject[this.filter.devcMassId].start}-${this.assetObject[this.filter.devcMassId].end}`);
    },
    // getDevicesData(massId) {
    //   console.log('Get devices data using param : ', this.dateParam);
    //   const promises = [];
    //   this.sortedDeviceKeys = [];
    //   if (!this.assetObject[massId].devices) this.assetObject[massId].devices = {};
    //   for (let i = 0; i < this.devices.length; i++) {
    //     const device = this.devices[i];
    //     const freshParam = JSON.parse(JSON.stringify(dateUtil.autoMaxData(dateUtil.msToS(this.dateParam))));
    //     switch (device.devcType) {
    //       case 'gps':
    //         this.assetObject[massId].devices[device.devcUniqueId] = device;
    //         this.sortedDeviceKeys.unshift(device.devcUniqueId);
    //         promises.push(SeriesSvc.getAggregatedData(device, dateUtil.autoMaxData(dateUtil.msToS(this.dateParam))));
    //         break;
    //       case 'rpm':
    //         this.assetObject[massId].devices[device.devcUniqueId] = device;
    //         this.assetObject[massId].devices[device.devcUniqueId].data = [];
    //         this.sortedDeviceKeys.unshift(device.devcUniqueId);
    //         freshParam.type = 'PORT';
    //         promises.push(SeriesSvc.getAggregatedData(device, freshParam));
    //         freshParam.type = 'STARBOARD';
    //         promises.push(SeriesSvc.getAggregatedData(device, freshParam));
    //         break;
    //       case 'ae':
    //         this.assetObject[massId].devices[device.devcUniqueId] = device;
    //         this.sortedDeviceKeys.push(device.devcUniqueId);
    //         promises.push(SeriesSvc.getAggregatedData(device, dateUtil.autoMaxData(dateUtil.msToS(this.dateParam))));
    //         break;
    //       case 'flowmeter':
    //         this.assetObject[massId].devices[device.devcUniqueId] = device;
    //         this.sortedDeviceKeys.push(device.devcUniqueId);
    //         freshParam.functionNames = ['AVG', 'AVG', 'AVG'];
    //         freshParam.functionParams = ['@VolFlowrate', '@Density', '@Temp'];
    //         promises.push(SeriesSvc.getAggregatedData(device, freshParam));
    //         break;
    //     }
    //   }
    //   return Promise.all(promises);
    // },
    setActive(item) {
      console.log('Activate : ', item);
      this.activeAsset = item;
      this.load();
    },
    showDataLog() {
      console.log('This is to show datalog ...');
      this.$router.push(
        {
          name: 'ReportView',
          params: { id: this.filter.devcMassId, type: 'table' },
          query: dateUtil.dateParamToQuery(this.dateParam),
        },
      );
      // const params = {
      //   name: 'AssetDataLog',
      //   params: {
      //     id: this.filter.devcMassId,
      //   },
      //   query: dateUtil.dateParamToQuery(this.dateParam),
      // };
      // this.$router.push(params);
    },
    onSubmitRange(date) {
      this.$router.replace({
        name: 'Dashboard',
        query: dateUtil.dateParamToQuery(date),
      }).catch((err) => {
        console.log('Duplicate Route, Skipping... ', err);
      });
      this.load();
    },
    doLogOut() {
      this.$toast.error('Please re-login');
      this.$store.dispatch('auth/logout');
      this.$router.replace({ name: 'Login' });
    },
  },
};
</script>

<style scoped>

</style>
