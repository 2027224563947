<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <b-container fluid>
    <div class="iq-card mt-3">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="card-title">Device List of {{form.massName}}</h4>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="text-right">
              <button-options
                :access="canRead('master_asset')"
                :hideDetail="true"
                :isBackEnabled="true"
                :isRefreshEnabled="true"
                :refreshed="isLoading"
                @onRefreshed="getDevices('Refreshed')"/>
            </div>
          </div>
        </div>
      </div>
      <div class="iq-card-body">
        <div class="row" v-if="isLoading">
          <div class="col-md-12">
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </div>
        </div>
        <div class="row" v-if="devices.length > 0 && !isLoading">
          <b-col md="3" sm="6" v-for="(dev, index) in devices" v-bind:key="`Device-${index}`">
            <b-card class="text-center btn card-item-group m-1"
              :id="`assetCard${index}`"
              @click="onItemClicked(dev.devcId)">
              <h6>{{dev.devcName}}</h6>
              <CanvasDirectives
                :canvasId="`${dev.devcType}-${dev.devcId}`"
                :config="true"
                :data="dev.devcUniqueId"
                :device="dev"
                :devType="dev.devcType ? dev.devcType : undefined"
                :hidden="isLoading || isRefreshed"/>
            </b-card>
          </b-col>
        </div>
        <div class="row" v-if="devices.length <= 0 && !isLoading">
          <div class="col-md-12">
            <div class="text-center">The asset has no device</div>
          </div>
        </div>
      </div>
      <div class="iq-card-body">
        <div class="row">
          <div class="col-md-12">
            <b-pagination
              v-model="pagination.current"
              :per-page="pagination.pageSize"
              :total-rows="pagination.totalSize"
              @change="onPaginationChanged"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="iq-card" hidden>
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="card-title">Asset Analytics</h4>
        </div>
      </div>
      <div hidden class="iq-card-body">
        <div class="row">
          <div role="group" class="form-group col-md-6">
            <label for="massName" class="d-block form-label text-left">
              Name:
            </label>
            <div class="bv-no-focus-ring">
              <div role="group" class="input-group">
                <input
                  :class="errors.massName ? 'is-invalid' : ''"
                  :disabled="isSubmit"
                  autocomplete="off"
                  class="form-control"
                  id="massName"
                  placeholder="Enter name"
                  type="text"
                  v-model="form.massName">
                <b-form-invalid-feedback v-if="errors.massName">
                  {{ Object.values(errors.massName) }}
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
          <div role="group" class="form-group col-md-6">
            <label for="massLabel" class="d-block form-label text-left">
              Label:
            </label>
            <div class="bv-no-focus-ring">
              <div role="group" class="input-group">
                <!--                       v-model="form.massLabel"-->
                <input
                  :class="errors.massLabel ? 'is-invalid' : ''"
                  :disabled="isSubmit"
                  autocomplete="off"
                  class="form-control"
                  id="massLabel"
                  placeholder="Enter label"
                  type="text">
                <b-form-invalid-feedback v-if="errors.massLabel">
                  {{ Object.values(errors.massLabel) }}
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div role="group" class="form-group col-md-6">
            <label for="massName" class="d-block form-label text-left">
              Company:
            </label>
            <v-select
              :class="errors.massDataId ? 'is-invalid' : ''"
              :options="Object.values(availableCompanies)"
              :reduce="company => company.compId"
              disabled
              label="compName"
              placeholder="Please select company"
              v-model="form.massDataId">
              <template slot="no-options">
                Type to search company
              </template>
            </v-select>
            <b-form-invalid-feedback v-if="errors.massDataId">
              {{ Object.values(errors.massDataId) }}
            </b-form-invalid-feedback>
          </div>
          <div role="group" class="form-group col-md-6">
            <label class="d-block form-label text-left">
              Type:
            </label>
            <div class="bv-no-focus-ring">
              <div role="group" class="input-group">
                <select
                  id="massType"
                  class="form-control"
                  v-model="form.massType"
                  :disabled="isSubmit"
                  :class="errors.massType ? 'is-invalid' : ''">
                  <option disabled="disabled" :value="undefined"> -- Please Select Status --</option>
                  <option value="0">Not Approved</option>
                  <option value="1">Approved</option>
                </select>
                <b-form-invalid-feedback v-if="errors.massType">
                  {{ Object.values(errors.massType) }}
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div role="group" class="form-group col-md-12">
            <label for="massDescription" class="d-block form-label text-left">
              Description:
            </label>
            <div class="bv-no-focus-ring">
              <div role="group" class="input-group">
              <textarea
                id="massDescription"
                autocomplete="off"
                v-model="form.massDescription"
                placeholder="Enter description"
                type="text"
                :disabled="isSubmit"
                :class="errors.massDescription ? 'is-invalid' : ''"
                class="form-control"></textarea>
                <b-form-invalid-feedback v-if="errors.massDescription">
                  {{ Object.values(errors.massDescription) }}
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="text-right">
              <button-options
                :access="canUpdate('master_asset')"
                :hideDetail="true"
                :isBackEnabled="true"
                :isLoading="isLoading"
                :isSaveEnabled="true"
                :isSubmit="isSubmit"
                @onSubmit="onSubmit" />
            </div>
          </div>
        </div>
      </div>
      <div class="iq-card-body">
        <div class="row">
          <div class="col-md-12">
            <!-- <switcher-options
              class="mb-3"
              :defaultValue="selected.tab"
              :isLoading="isLoading"
              :options="[
              { name: 'Data Log'},
              { name: 'GPS Log'},
              { name: 'Status Log'},
              { name: 'Alarm Log'},
              { name: 'GPS Process Log'},
              { name: 'Data Analysis'},
              ]"
              @onChangeToggleOption="onActionChangeTab"
            /> -->
          </div>
        </div>
        <b-row>
          <b-col sm="4" class="mb-3">
            <!-- <switcher-options
              :defaultColor="'#28a745'"
              :defaultValue="'Week'"
              :options="[
            { name: 'Week', value: 'week'},
            { name: 'Month', value: 'month'}]"
              @onChangeToggleOption="onChangeRange"/> -->
          </b-col>
          <b-col sm="4" class="mb-3">
            <b-form-select
              v-model="selected.rangeValue"
              placeholder="Select"
              :options="[
              {value: null, text: 'Please select range', disabled: true},
              {value: 1, text: '1'},
              {value: 2, text: '2'},
              {value: 3, text: '3'},
              {value: 4, text: '4'},
              {value: 5, text: '5'},
            ]"></b-form-select>
          </b-col>
          <b-col sm="2" class="mb-3 text-left">
            <button
              class="btn btn-outline-primary"
              :disabled="isLoading"
              @click="onSubmitRange"> Submit</button>
          </b-col>
        </b-row>
        <div class="row">
          <!-- <analytics-table-helper
              :type="selected.tab"
              @fieldOption="fieldOption"/> -->
          <!-- <analytics-data-helper
              :onRangeSubmitted="selected.data"
              :tab="selected.tab"
              @generatedData="generatedAnalyticsData"
              @onLoading="onLoading"/> -->
          <div class="col-md-12" v-if="hideAnalyticTable(selected.tab)">
            <b-table
              class="table table-responsive table-responsive-sm table-responsive-md
                   table-responsive-lg table-responsive-xl fixed"
              fixed
              show-empty
              responsive
              :busy="isLoading"
              :fields="related.fields"
              :items="related.data">
              <template v-slot:table-busy="">
                <div class="text-center text-danger my-2">
                  <b-spinner variant="success" size="sm" class="align-middle"></b-spinner>
                </div>
              </template>
            </b-table>
          </div>
          <div class="col-md-12" v-if="!hideAnalyticTable(selected.tab)">
            <div class="row" v-if="selected.tab === 'GPS Process Log'">
              <!-- <b-col cols="5">
                <analytics-table-map
                  :data="data"
                  :isLoading="isLoading"/>
              </b-col> -->
              <b-col cols="7">
                <analytics-data-map
                  :data="related.data"
                  :centerMap="related.data"
                  :isLoading="isLoading"/>
              </b-col>
            </div>
            <div class="row" v-if="selected.tab === 'Data Analysis'">
              <b-col cols="12">
                <canvas-directives
                  :devType="'linestatechart'"/>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
// import AnalyticsDataHelper from '@/components/directives/analytics-data-helper';
import AnalyticsDataMap from '@/components/directives/analytics-data-map';
// import AnalyticsTableHelper from '@/components/directives/analytics-table-helper';
// import AnalyticsTableMap from '@/components/directives/analytics-table-map';
import AssetSvc from '@/services/AssetSvc';
import ButtonOptions from '@/components/directives/button-options';
import CanvasDirectives from '@/components/directives/canvas';
import DeviceSvc from '@/services/DeviceSvc';
// import SwitcherOptions from '@/components/directives/switcher-options';

import requestBuilder from '@/util/request.builder';

export default {
  name: 'AssetDetail',
  components: {
    // AnalyticsTableMap,
    AnalyticsDataMap,
    // AnalyticsDataHelper,
    // AnalyticsTableHelper,
    ButtonOptions,
    CanvasDirectives,
    // SwitcherOptions,
  },
  data() {
    return {
      data: [],
      devices: [],
      errors: {},
      filter: {
        massId: this.$route.params.id,
      },
      form: {},
      id: this.$route.params.id,
      isLoading: false,
      isRefreshed: false,
      isSubmit: false,
      pagination: {
        current: 1,
        pageSize: 10,
        totalSize: 0,
      },
      related: {
        data: [],
        fields: [],
      },
      selected: {
        data: {},
        tab: 'Data Log',
        range: 'Week',
        rangeValue: null,
      },
    };
  },
  computed: {
    availableCompanies() {
      return this.$store.getters['auth/availableCompany'];
    },
  },
  methods: {
    fieldOption(fields) {
      this.related.fields = fields;
    },
    async getData(notification) {
      this.isLoading = true;
      this.isRefreshed = true;
      try {
        const resp = await AssetSvc.getAssets({
          params: {
            ...requestBuilder.buildFilters({}, this.filter),
          },
        });
        if (notification) {
          this.$toast.success(notification);
        }
        this.form = resp.data.data.find((o) => o.massId === this.id);
      } catch (e) {
        if (e.response.data) {
          if (e.response.data.status.message === 'Authentication Error') {
            this.doLogOut();
          } else {
            this.$toast.error(e.response.data.status.message);
          }
        } else {
          this.$toast.error('Network error');
        }
      } finally {
        this.isLoading = false;
        this.isRefreshed = false;
      }
    },
    async getDevices(notification) {
      this.isLoading = true;
      this.isRefreshed = true;
      const filter = {
        devcMassId: this.id,
      };
      try {
        const resp = await DeviceSvc.getDevices({
          params: {
            ...requestBuilder.buildFilters(this.pagination, filter),
          },
        });
        if (notification) {
          this.$toast.success(notification);
        }
        this.devices = resp.data.data;
      } catch (e) {
        if (e.response.data) {
          if (e.response.data.status.message === 'Authentication Error') {
            this.doLogOut();
          } else {
            this.$toast.error(e.response.data.status.message);
          }
        } else {
          this.$toast.error('Network error');
        }
      } finally {
        this.isLoading = false;
        this.isRefreshed = false;
      }
    },
    async onSubmit() {
      this.isSubmit = true;
      const form = {
        massName: this.form.massName,
        massDataId: this.form.massDataId,
        massType: this.form.massType,
      };
      try {
        const resp = await AssetSvc.updateAsset(this.id, form);
        if (resp.data.status.error === 0) {
          this.$toast.success('Updated');
        } else {
          const errors = resp.data.status.message;
          if (typeof errors === 'object') {
            this.errors = resp.data.status.message;
            this.$toast.error('Error found');
          } else {
            this.$toast.error(resp.data.status.message);
          }
        }
      } catch (e) {
        if (e.response.data) {
          if (e.response.data.status.message === 'Authentication Error') {
            this.doLogOut();
          } else {
            this.$toast.error(e.response.data.status.message);
          }
        } else {
          this.$toast.error('Network error');
        }
      } finally {
        this.isSubmit = false;
      }
    },
    onActionChangeTab(v) {
      this.selected.rangeValue = null;
      this.selected.tab = v;
    },
    generatedAnalyticsData(data) {
      this.related.data = data;
      console.log('generated', data);
    },
    hideAnalyticTable(tab) {
      return !(tab === 'GPS Process Log' || tab === 'Data Analysis');
    },
    onItemClicked(id) {
      this.$router.push({ name: 'DeviceDetail', params: { id } });
    },
    onLoading(v) {
      this.isLoading = v;
      console.log(this.isLoading);
    },
    onPaginationChanged(page) {
      this.pagination.current = page;
      this.getDevices();
    },
    doLogOut() {
      this.$toast.error('Please re-login');
      this.$store.dispatch('auth/logout');
      this.$router.replace({ name: 'Login' });
    },
    onChangeRange(range) {
      switch (range) {
        case 'Week':
          this.selected.range = range;
          break;
        case 'Month':
          this.selected.range = range;
          break;
      }
    },
    onSubmitRange() {
      this.selected.data = {
        devices: this.devices,
        tab: this.selected.tab,
        filter: {
          type: this.selected.range.toLowerCase(),
          value: this.selected.rangeValue,
        },
      };
    },
  },
  mounted() {
    this.getData();
    this.getDevices();
  },
};
</script>

<style scoped>

</style>
