<template>
  <b-row class="mb-3">
    <b-col md="12">
      <div class="table-responsive">
        <table class="table table-striped table-bordered">
          <thead>
          <tr>
            <td style="width: 4%">Hour</td>
            <td style="width: 4%" class="text-center">0</td>
            <td style="width: 4%" class="text-center">1</td>
            <td style="width: 4%" class="text-center">2</td>
            <td style="width: 4%" class="text-center">3</td>
            <td style="width: 4%" class="text-center">4</td>
            <td style="width: 4%" class="text-center">5</td>
            <td style="width: 4%" class="text-center">6</td>
            <td style="width: 4%" class="text-center">7</td>
            <td style="width: 4%" class="text-center">8</td>
            <td style="width: 4%" class="text-center">9</td>
            <td style="width: 4%" class="text-center">10</td>
            <td style="width: 4%" class="text-center">11</td>
            <td style="width: 4%" class="text-center">12</td>
            <td style="width: 4%" class="text-center">13</td>
            <td style="width: 4%" class="text-center">14</td>
            <td style="width: 4%" class="text-center">15</td>
            <td style="width: 4%" class="text-center">16</td>
            <td style="width: 4%" class="text-center">17</td>
            <td style="width: 4%" class="text-center">18</td>
            <td style="width: 4%" class="text-center">19</td>
            <td style="width: 4%" class="text-center">20</td>
            <td style="width: 4%" class="text-center">21</td>
            <td style="width: 4%" class="text-center">22</td>
            <td style="width: 4%" class="text-center">23</td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in series" v-bind:key="index">
            <td class="text-left text-white" :style="`background-color: ${color[index]}`">{{item.name}}</td>
            <td class="text-center" v-for="(d, ind) in item.data" v-bind:key="ind">{{d}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </b-col>
    <b-col md="12" hidden>
      <div v-for="(item, index) in series" v-bind:key="index">{{item.name}}</div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'report-table',
  props: ['color', 'series'],
  watch: {
    series: {
      immediate: true,
      handler() {
        // TODO: Get Series Abbreviation
      },
    },
  },
};
</script>

<style scoped>

</style>
