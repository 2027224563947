<template>
  <b-row class="mb-3">
    <b-col md="12">
      <div class="text-center">
        <h3>VESSEL TRACKING</h3>
      </div>
      <div class="table-responsive">
        <table class="table table-striped table-bordered">
          <thead>
          <tr>
            <td style="width: 35%"></td>
            <td style="width: 35%" class="text-center">Position</td>
            <td style="width: 15%" class="text-center">Speed</td>
            <td style="width: 15%" class="text-center">Heading</td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="text-center">Start Position @ 2020-03-31 00:01:00</td>
            <td class="text-center">0.49&deg;41.16"S , 117.15'14.76"E</td>
            <td class="text-center">0 knots</td>
            <td class="text-center">0</td>
          </tr>
          <tr>
            <td class="text-center">Last Position @ 2020-04-31 00:01:00</td>
            <td class="text-center">0.49'41.16"S , 117.15'14.76"E</td>
            <td class="text-center">0 knots</td>
            <td class="text-center">0</td>
          </tr>
          <tr>
            <td class="text-center">Latest Position @ 2020-04-31 00:02:00</td>
            <td class="text-center">0.49'41.16"S , 117.15'14.76"E</td>
            <td class="text-center">0 knots</td>
            <td class="text-center">0</td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <td style="width: 35%" class="text-center">Total Distance</td>
            <td class="text-center" colspan="3">6.58 nautical miles</td>
          </tr>
          </tfoot>
        </table>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'VesselTracking',
};
</script>

<style scoped>

</style>
