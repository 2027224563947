<template>
  <div class="text-center">
    <!-- <img :src="gmailIconURL" width="80"  alt="GMail Logo"> -->
    <h3 class="mt-2 mb-0">Please Signin</h3>
    <p>Continue with your your email address and password to access admin panel.</p>
    <!-- <GoogleButton
      @onSuccess="onSuccess"
      @onFailure="onFailure" />
    <div v-if="displayLoginForm">
      <div class="divider">
        <div class="text-muted text-center">
          <small>or sign in with email
          </small>
        </div>
      </div>
      <LoginForm
        @onSuccess="onSuccess"
        @onFailure="onFailure" />
    </div> -->
    <div>
      <LoginForm
        @onSuccess="onSuccess"
        @onFailure="onFailure" />
    </div>
  </div>
</template>

<script>
import gmailIcon from '@/assets/images/gmail.png';
// import GoogleButton from '@/components/auth/GoogleButton';
import LoginForm from '@/components/auth/LoginForm';

export default {
  name: 'Login',
  components: {
    // GoogleButton,
    LoginForm,
  },
  data() {
    return {
      displayLoginForm: process.env.VUE_APP_ENABLE_FORM_LOGIN,
      gmailIconURL: gmailIcon,
    };
  },
  methods: {
    async onSuccess() {
      await this.$store.dispatch('auth/getUser');
      await this.$router.push({ name: 'CompanyActiveSelection' });
    },
    async onFailure(err) {
      console.log(err);
      this.$toast.error(err);
    },
  },
};
</script>

<style scoped>

</style>
