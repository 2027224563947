import Vue from 'vue';

function timeConverter(datetime) {
  const a = new Date(datetime);
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const date = a.getDate().toString().padStart(2, '0');
  const hour = a.getHours().toString().padStart(2, '0');
  const min = a.getMinutes().toString().padStart(2, '0');
  const sec = a.getSeconds().toString().padStart(2, '0');
  const time = `${date} ${month} ${year} ${hour}:${min}:${sec}`;
  return time;
}

const secondsToHms = (seconds) => {
  const SECONDS_PER_DAY = 86400;
  const HOURS_PER_DAY = 24;
  const days = Math.floor(seconds / SECONDS_PER_DAY);
  const remainderSeconds = seconds % SECONDS_PER_DAY;
  const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
  return hms.replace(/^(\d+)/, (h) => `${Number(h) + days * HOURS_PER_DAY}`.padStart(2, '0'));
};

Vue.filter('toLocalDate', (value) => {
  if (!value) return '';
  return timeConverter(value * 1000);
});

Vue.filter('toHHMMSS', (SECONDS) => {
  if (!SECONDS) return '00:00:00';
  return secondsToHms(SECONDS);
});

Vue.prototype.$filters = Vue.options.filters;
