<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <b-container fluid>
    <div class="iq-card mt-3">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="card-title">Report of {{activeCompany.compName}}</h4>
        </div>
      </div>
      <div class="iq-card-body">
        <b-row>
          <b-col cols="4">
            <input-selector
              :data="assets"
              :id="'massId'"
              :label="'massName'"
              :loading="isLoading"
              :placeholder="'Please select Asset'"
              @onChangeSelector="onChangeAssetSelector"/>
          </b-col>
          <b-col cols="8">
            <b-row>
              <b-col cols="12">
                <!-- <date-picker
                  :isLoading="isLoading"
                  @onSubmitRange="onSubmitRange" /> -->
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <report-data-helper
          :date="selected.date"
          :devices="devices"
          :isSubmit="isSubmit"
          @onActionFinished="onActionFinished"/>
      </div>
    </div>
    <div class="iq-card" v-if="isSubmit">
      <div class="iq-card-body">
        <div class="col-md-12">
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </div>
      </div>
    </div>
    <div class="iq-card" v-if="collected.gps">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="card-title">Vessel: {{selected.assetReport.massName}}</h4>
        </div>
        <b-row>
          <b-col md="12">
            <div class="text-right">
              Analyzed date: {{new Date(selected.date.start).toLocaleString()}} to {{new Date(selected.date.end).toLocaleString()}}
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="iq-card-body">
        <Summary
            :battery="collected.battery"
            :flowMter="collected.flowMeter"
            :gateway="collected.gateway"
            :gps="collected.gps"/>
        <utilization />
        <vessel-tracking />
        <b-row class="mb-3">
          <b-col md="6" class="mb-3">
            <img :src="logo" style="height: 400px; width: 100%"/>
          </b-col>
          <b-col md="6" class="mb-3">
            <img :src="logo" style="height: 400px; width: 100%"/>
          </b-col>
        </b-row>
        <hr />
        <b-row class="mb-3">
          <b-col md="12" class="mb-3">
            <div class="text-center">
              <h4>Distance vs Speed vs ME Total Fuel Used vs AE Total Fuel Used</h4>
            </div>
          </b-col>
          <b-col md="12" class="mb-3">
            <div id="chart1" class="iq-card" style="box-shadow: 0px 0px 40px 8px rgba(55, 73, 72, 0.06)">
              <report-chart
                :data="series.chart1"
                :yaxis="options.yaxis1"/>
            </div>
          </b-col>
          <b-col md="12">
            <div class="text-left">
              <h5 class="text-muted">{{new Date(selected.date.start).toLocaleString()}} to {{new Date(selected.date.end).toLocaleString()}}</h5>
            </div>
          </b-col>
        </b-row>
        <b-row hidden class="mb-3">
          <b-col md="12">
            <div class="table-responsive">
              <table class="table table-striped table-bordered">
                <thead>
                <tr>
                  <td style="width: 4%">Hour</td>
                  <td style="width: 4%" class="text-center">0</td>
                  <td style="width: 4%" class="text-center">1</td>
                  <td style="width: 4%" class="text-center">2</td>
                  <td style="width: 4%" class="text-center">3</td>
                  <td style="width: 4%" class="text-center">4</td>
                  <td style="width: 4%" class="text-center">5</td>
                  <td style="width: 4%" class="text-center">6</td>
                  <td style="width: 4%" class="text-center">7</td>
                  <td style="width: 4%" class="text-center">8</td>
                  <td style="width: 4%" class="text-center">9</td>
                  <td style="width: 4%" class="text-center">10</td>
                  <td style="width: 4%" class="text-center">11</td>
                  <td style="width: 4%" class="text-center">12</td>
                  <td style="width: 4%" class="text-center">13</td>
                  <td style="width: 4%" class="text-center">14</td>
                  <td style="width: 4%" class="text-center">15</td>
                  <td style="width: 4%" class="text-center">16</td>
                  <td style="width: 4%" class="text-center">17</td>
                  <td style="width: 4%" class="text-center">18</td>
                  <td style="width: 4%" class="text-center">19</td>
                  <td style="width: 4%" class="text-center">20</td>
                  <td style="width: 4%" class="text-center">21</td>
                  <td style="width: 4%" class="text-center">22</td>
                  <td style="width: 4%" class="text-center">23</td>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="text-center text-white" style="background-color: dodgerblue">V.D</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.29</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.40</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                </tr>
                <tr>
                  <td class="text-center text-white" style="background-color: black">V.S</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.29</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.40</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                </tr>
                <tr>
                  <td class="text-center text-white" style="background-color: orange">ME.F</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.29</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.40</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                </tr>
                <tr>
                  <td class="text-center text-white" style="background-color: lawngreen">AE.F</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.29</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.40</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                  <td class="text-center">0.22</td>
                </tr>
                </tbody>
              </table>
            </div>
          </b-col>
          <b-col md="12">
            <div>V.D : Distance (NM)</div>
            <div>V.S : Speed (Knots)</div>
            <div>ME.F : ME Total Fuel Used</div>
            <div>AE.F : AE Total Fuel Used</div>
          </b-col>
        </b-row>
        <!-- <report-table
           :color="colors"
           :series="series.chart1"/> -->
        <hr />
        <b-row class="mb-3">
          <b-col md="12" class="mb-3">
            <div class="text-center">
              <h4>ME Fuel Consumption</h4>
            </div>
          </b-col>
          <b-col md="12" class="mb-3">
            <div id="chart2" class="iq-card" style="box-shadow: 0px 0px 40px 8px rgba(55, 73, 72, 0.06)">
              <report-chart
                :data="series.chart2"
                :yaxis="options.yaxis2"/>
            </div>
          </b-col>
          <b-col md="12">
            <div class="text-left">
              <h5 class="text-muted">31 Mar 2020 00:00</h5>
            </div>
          </b-col>
        </b-row>
        <report-table
          :color="colors"
          :series="series.chart2"/>
        <hr />
        <b-row class="mb-3">
          <b-col md="12" class="mb-3">
            <div class="text-center">
              <h4>Port ME Total Fuel Used vs Port ME Running Hours</h4>
            </div>
          </b-col>
          <b-col md="12" class="mb-3">
            <div id="chart3" class="iq-card" style="box-shadow: 0px 0px 40px 8px rgba(55, 73, 72, 0.06)">
              <report-chart
                :data="series.chart3"
                :yaxis="options.yaxis3"/>
            </div>
          </b-col>
          <b-col md="12">
            <div class="text-left">
              <h5 class="text-muted">31 Mar 2020 00:00</h5>
            </div>
          </b-col>
        </b-row>
        <report-table
          :color="colors"
          :series="series.chart3"/>
        <hr />
        <b-row class="mb-3">
          <b-col md="12" class="mb-3">
            <div class="text-center">
              <h4>Stbd ME Total Fuel Used vs Stbd ME Running Hours</h4>
            </div>
          </b-col>
          <b-col md="12" class="mb-3">
            <div id="chart4" class="iq-card" style="box-shadow: 0px 0px 40px 8px rgba(55, 73, 72, 0.06)">
              <report-chart
                :data="series.chart4"
                :yaxis="options.yaxis4"/>
            </div>
          </b-col>
          <b-col md="12">
            <div class="text-left">
              <h5 class="text-muted">31 Mar 2020 00:00</h5>
            </div>
          </b-col>
        </b-row>
        <report-table
          :color="colors"
          :series="series.chart4"/>
        <hr />
        <b-row class="mb-3">
          <b-col md="12" class="mb-3">
            <div class="text-center">
              <h4>AE Fuel Consumption</h4>
            </div>
          </b-col>
          <b-col md="12" class="mb-3">
            <div id="chart5" class="iq-card" style="box-shadow: 0px 0px 40px 8px rgba(55, 73, 72, 0.06)">
              <report-chart
                :data="series.chart5"
                :yaxis="options.yaxis5"/>
            </div>
          </b-col>
          <b-col md="12">
            <div class="text-left">
              <h5 class="text-muted">31 Mar 2020 00:00</h5>
            </div>
          </b-col>
        </b-row>
        <report-table
          :color="colors"
          :series="series.chart5"/>
        <hr />
        <b-row class="mb-3">
          <b-col md="12" class="mb-3">
            <div class="text-center">
              <h4>Port AE Fuel Used vs Port AE Running Hours</h4>
            </div>
          </b-col>
          <b-col md="12" class="mb-3">
            <div id="chart6" class="iq-card" style="box-shadow: 0px 0px 40px 8px rgba(55, 73, 72, 0.06)">
              <report-chart
                :data="series.chart6"
                :yaxis="options.yaxis6"/>
            </div>
          </b-col>
          <b-col md="12">
            <div class="text-left">
              <h5 class="text-muted">31 Mar 2020 00:00</h5>
            </div>
          </b-col>
        </b-row>
        <report-table
          :color="colors"
          :series="series.chart6"/>
        <hr />
        <b-row class="mb-3">
          <b-col md="12" class="mb-3">
            <div class="text-center">
              <h4>Stbd AE Fuel Used vs Stbd AE Running Hours</h4>
            </div>
          </b-col>
          <b-col md="12" class="mb-3">
            <div id="chart7" class="iq-card" style="box-shadow: 0px 0px 40px 8px rgba(55, 73, 72, 0.06)">
              <report-chart
                :data="series.chart7"
                :yaxis="options.yaxis7"/>
            </div>
          </b-col>
          <b-col md="12">
            <div class="text-left">
              <h5 class="text-muted">31 Mar 2020 00:00</h5>
            </div>
          </b-col>
        </b-row>
        <report-table
          :color="colors"
          :series="series.chart7"/>
        <hr />
        <b-row class="mb-3">
          <b-col md="12" class="mb-3">
            <div class="text-center">
              <h4>Ctr AE Total Fuel Used vs Ctr AE Running Hours</h4>
            </div>
          </b-col>
          <b-col md="12" class="mb-3">
            <div id="chart8" class="iq-card" style="box-shadow: 0px 0px 40px 8px rgba(55, 73, 72, 0.06)">
              <report-chart
                :data="series.chart8"
                :yaxis="options.yaxis8"/>
            </div>
          </b-col>
          <b-col md="12">
            <div class="text-left">
              <h5 class="text-muted">31 Mar 2020 00:00</h5>
            </div>
          </b-col>
        </b-row>
        <report-table
          :color="colors"
          :series="series.chart8"/>
        <hr />
        <b-row class="mb-3">
          <b-col md="12" class="mb-3">
            <div class="text-center">
              <h4>Total Fuel Used</h4>
            </div>
          </b-col>
          <b-col md="12" class="mb-3">
            <div id="chart9" class="iq-card" style="box-shadow: 0px 0px 40px 8px rgba(55, 73, 72, 0.06)">
              <report-chart
                :data="series.chart9"
                :yaxis="options.yaxis9"/>
            </div>
          </b-col>
          <b-col md="12">
            <div class="text-left">
              <h5 class="text-muted">31 Mar 2020 00:00</h5>
            </div>
          </b-col>
        </b-row>
        <report-table
          :color="colors"
          :series="series.chart9"/>
      </div>
    </div>
  </b-container>
</template>

<script>
import AssetSvc from '@/services/AssetSvc';
// import DatePicker from '@/components/directives/date-picker';
import DeviceSvc from '@/services/DeviceSvc';
import InputSelector from '@/components/directives/input-selector';
import ReportChart from '@/components/directives/report-chart';
import ReportDataHelper from '@/components/directives/report-data-helper';
import ReportTable from '@/components/directives/report-table';
import Summary from '@/components/report/Summary';
import Utilization from '@/components/report/Utilization';
import VesselTracking from '@/components/report/VesselTracking';

import logo from '@/assets/images/map/map_canvas.jpeg';
import requestBuilder from '@/util/request.builder';

export default {
  name: 'Index',
  components: {
    ReportDataHelper,
    ReportTable,
    VesselTracking,
    Utilization,
    InputSelector,
    // DatePicker,
    Summary,
    ReportChart,
  },
  computed: {
    activeCompany() {
      return this.$store.getters['auth/activeCompany'];
    },
  },
  data() {
    return {
      assets: [],
      collected: {},
      devices: [],
      isLoading: false,
      isSubmit: false,
      logo,
      options: {
        yaxis1: [{
          title: {
            text: 'Distance (NM)',
          },

        }, {
          title: {
            text: 'AE Total Fuel Used (L)',
          },
        }],
        yaxis2: [{
          title: {
            text: 'ME Total Fuel Used',
          },
        }],
        yaxis3: [{
          title: {
            text: 'Port ME Total Fuel Used (L)',
          },
        }, {
          opposite: true,
          title: {
            text: 'Port ME Running Hours (Hrs)',
          },
        }],
        yaxis4: [{
          title: {
            text: 'Stbd ME Total Fuel Used (L)',
          },
        }, {
          opposite: true,
          title: {
            text: 'Stbd ME Running Hours (Hrs)',
          },
        }],
        yaxis5: [{
          title: {
            text: 'AE Total Fuel Used (L)',
          },
        }],
        yaxis6: [{
          title: {
            text: 'Port AE Total Fuel Used (L)',
          },
        }, {
          opposite: true,
          title: {
            text: 'Port AE Running Hours (Hrs)',
          },
        }],
        yaxis7: [{
          title: {
            text: 'Stbd AE Total Fuel Used (L)',
          },
        }, {
          opposite: true,
          title: {
            text: 'Stbd AE Running Hours (Hrs)',
          },
        }],
        yaxis8: [{
          title: {
            text: 'Ctr AE Total Fuel Used (L)',
          },
        }, {
          opposite: true,
          title: {
            text: 'Ctr AE Running Hours (Hrs)',
          },
        }],
        yaxis9: [{
          title: {
            text: 'Total Fuel Used',
          },
        }],
      },
      selected: {
        asset: undefined,
        assetReport: {},
        date: {
          end: undefined,
          start: undefined,
        },
      },
      colors: ['dodgerblue', 'black', 'lawngreen', 'orange'],
      series: {
        chart1: [{
          name: 'Distance',
          type: 'column',
          data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160, 440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160],
        }, {
          name: 'AE Total Fuel Used',
          type: 'line',
          data: [5, 5, 5, 6, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 6, 5, 5, 5, 5, 7, 5, 5, 5],
        }, {
          name: 'Speed',
          type: 'line',
          data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160, 440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160],
        }, {
          name: 'ME Total Fuel Used',
          type: 'line',
          data: [5, 5, 5, 6, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 6, 5, 5, 5, 5, 7, 5, 5, 5],
        }],
        chart2: [{
          name: 'Port ME Total Fuel Used',
          type: 'line',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        }, {
          name: 'Stbd ME Total Fuel Used',
          type: 'line',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        }, {
          name: 'Stbd ME Total Fuel Used',
          type: 'line',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2],
        }],
        chart3: [{
          name: 'Port ME Total Fuel Used (L)',
          type: 'column',
          data: [6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
        }, {
          name: 'Port ME Total Running Hours (Hrs)',
          type: 'line',
          data: [6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
        }],
        chart4: [{
          name: 'Stbd ME Total Fuel Used (L)',
          type: 'column',
          data: [6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
        }, {
          name: 'Stbd ME Total Running Hours (Hrs)',
          type: 'line',
          data: [6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
        }],
        chart5: [{
          name: 'Port AE Total Fuel Used',
          type: 'column',
          data: [5, 5, 5, 6, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 6, 5, 5, 5, 5, 7, 5, 5, 5],
        }, {
          name: 'Stbd AE Total Fuel Used',
          type: 'line',
          data: [5, 5, 5, 6, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 6, 5, 5, 5, 5, 7, 5, 5, 5],
        }, {
          name: 'Ctr AE Total Fuel Used',
          type: 'line',
          data: [5, 5, 5, 6, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 6, 5, 5, 5, 5, 7, 5, 5, 5],
        }],
        chart6: [{
          name: 'Port AE Total Fuel Used (L)',
          type: 'column',
          data: [30, 10, 10, 10, 20, 30, 20, 30, 30, 20, 10, 10, 20, 30, 30, 30, 20, 20, 10, 20, 30, 20, 10, 20],
        }, {
          name: 'Port AE Total Running Hours (Hrs)',
          type: 'line',
          data: [6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
        }],
        chart7: [{
          name: 'Stbd AE Total Fuel Used (L)',
          type: 'column',
          data: [6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
        }, {
          name: 'Stbd AE Total Running Hours (Hrs)',
          type: 'line',
          data: [30, 10, 10, 10, 20, 30, 20, 30, 30, 20, 10, 10, 20, 30, 30, 30, 20, 20, 10, 20, 30, 20, 10, 20],
        }],
        chart8: [{
          name: 'Ctr AE Total Fuel Used (L)',
          type: 'column',
          data: [10, 20, 10, 30, 20, 10, 20, 30, 10, 30, 20, 10, 30, 20, 20, 10, 30, 20, 10, 30, 30, 20, 20, 10],
        }, {
          name: 'Ctr AE Total Running Hours (Hrs)',
          type: 'line',
          data: [30, 10, 10, 10, 20, 30, 20, 30, 30, 20, 10, 10, 20, 30, 30, 30, 20, 20, 10, 20, 30, 20, 10, 20],
        }],
        chart9: [{
          name: 'Total Fuel Used',
          type: 'column',
          data: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
        }],
      },
    };
  },
  methods: {
    async getAssets() {
      const resp = await AssetSvc.getAssets();
      this.assets = resp.data.data;
    },
    getData(series) {
      this.collected.gps = series.filter((o) => o.type === 'gps');
      this.collected.battery = series.filter((o) => o.type === 'battery');
      this.collected.gateway = series.filter((o) => o.type === 'gateway');
      this.collected.flowMeter = series.filter((o) => o.type === 'flowMeter');
      this.isSubmit = false;
      console.log('collected', this.collected);
    },
    async getDevices(v) {
      try {
        this.isLoading = true;
        const filter = {
          devcMassId: v.massId,
        };
        const resp = await DeviceSvc.getDevices({
          params: {
            ...requestBuilder.buildFilters({}, filter),
          },
        });
        this.devices = resp.data.data;
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    onSubmitRange(date) {
      this.collected = {};
      this.selected.date = date;
      this.isSubmit = true;
    },
    onChangeAssetSelector(v) {
      if (typeof v !== 'undefined' || v != null) {
        this.selected.asset = v ? v.massId : null;
        this.selected.assetReport = v;
        this.$router.replace({
          name: 'ReportView',
          params: {
            id: v.massId,
            type: 'summary',
          },
        }).catch((err) => {
          console.log('Duplicate Route, Skipping... ', err);
        });
        // console.log(v.massId);
        // this.getDevices(v);
      }
    },
    onActionFinished(data) {
      this.getData(data);
    },
  },
  mounted() {
    this.getAssets();
  },
};
</script>

<style scoped>

</style>
