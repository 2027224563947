<template>
  <div class="iq-card">

    <div class="iq-card-header d-flex justify-content-between">

      <div class="iq-header-title">
        <h4 class="card-title">
          Asset Type List
        </h4>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="text-right">
            <button class="btn btn-light m-1"
                    :disabled="isSubmit"
                    @click="$router.push({name: 'Asset'})">
              <i class="ri-arrow-go-back-line"></i>
              Back
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="isSubmit"
                    @click="add">
              <i class="ri-add-line"></i>
              Add
            </button>
            <button class="btn btn-success m-1"
                    :disabled="isRefreshed || isSubmit"
                    @click="refreshData()">
              <i class="fa" :class="isRefreshed ? 'ri-loader-line' : 'ri-refresh-line'"></i>
              Refresh
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="iq-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <b-table
                class="table table-responsive table-responsive-sm table-responsive-md
                   table-responsive-lg table-responsive-xl"
                fixed
                show-empty
                responsive
                tbody-tr-class="one-line-td-table"
                :items="[]"
                :fields="['name', 'status']"
                :busy="isLoading"></b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AssetTypeList',
  data() {
    return {
      isLoading: false,
      isRefreshed: false,
      isSubmit: false,
    };
  },
  methods: {
    refreshData() {
    },
    add() {
    },
  },
};
</script>

<style scoped>

</style>
