<template>
    <div>
      <div v-if="isLoading" class="dt-loading">
        <b-spinner variant="default" size="sm" class="align-middle"></b-spinner>
        <p>Loading data...</p>
      </div>
      <div v-else>
        <RBLine v-if="columns.length > 0" ref="chartGraph" @onZoomed="chartIndexUpdated" @onScrolled="chartIndexUpdated"
        :data="assetObject[activeAssetId]" :customHeight=320 :isDailyReport=true :type="'STARBOARD'" :customXAxis="columns" />
             <b-table-simple small hover responsive class="text-center mt-2 font-weight-bold">
                <b-tbody>
                  <b-tr>
                    <b-td style="border: 1px solid black; width: 100px !important; font-size: 10px; background-color: #002a64 !important; color: white;">Hour</b-td>
                    <b-td style="border: 1px solid black; width: 40px !important; font-size: 10px; background-color: lightgray !important;"  v-for="hour in columns" v-bind:key="hour">{{hour}}</b-td>
                  </b-tr>
                  <b-tr v-if="Object.values(assetObject[activeAssetId].datas)[0].flowmeter">
                    <b-td style="border: 1px solid black; width: 150px !important; font-size: 10px; background-color: #002a64 !important; color: white;" >Stb ME Total Fuel Used</b-td>
                    <b-td style="border: 1px solid black; width: 40px !important; font-size: 10px;" v-for="x in assetObject[activeAssetId].datas" v-bind:key="x">{{roundValue(x.flowmeter.stbFuelCons)}}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td style="border: 1px solid black; width: 150px !important; font-size: 10px; background-color: #002a64 !important; color: white;" >Stb ME Running Hours</b-td>
                    <b-td style="border: 1px solid black; width: 40px !important; font-size: 10px;" v-for="x in assetObject[activeAssetId].datas" v-bind:key="x">{{roundValue((x.rpm.STARBOARD.runningTime) / 3600)}}</b-td>
                  </b-tr>
                </b-tbody>
            </b-table-simple>
      </div>
    </div>
</template>
<script>
import RBLine from '@/components/directives/report-barline';
import dateUtil, { generateHourArray } from '@/util/date';

export default {
  name: 'StbFuelRun',
  props: ['assetObject', 'isLoading', 'activeAssetId', 'rangeRaw'],
  components: {
    RBLine,
  },
  data() {
    return {
      cruiseUnit: 'km',
      flowUnit: 'lph',
      speedUnit: 'knot',
      dateParam: undefined,
      columns: [],
    };
  },
  mounted() {
    console.log('mounted summary asset>>>', this.assetObject);
    this.columns = generateHourArray(new Date(this.rangeRaw.start), new Date(this.rangeRaw.end));
  },
  methods: {
    roundValue(x) {
      if (x < 1 && x > 0) {
        return x.toFixed(2);
      }

      return Math.round(x);
    },
    chartIndexUpdated(xaxis) {
      this.dateParam = dateUtil.byInterval('custom');
      this.dateParam.range = {};
      const timeIndex = Object.keys(this.assetObject[this.activeAssetId].datas);
      let updatedStart = timeIndex[xaxis.min];
      let updatedEnd = timeIndex[xaxis.max];
      if (!timeIndex[xaxis.min]) {
        updatedStart = timeIndex[0];
      }
      if (!timeIndex[xaxis.max]) {
        updatedEnd = timeIndex[timeIndex.length - 1];
      }
      if (xaxis.min < 0) {
        const indexBefore = xaxis.min * -1;
        const interval = parseInt((parseInt(updatedEnd) - parseInt(updatedStart)) / timeIndex.length);
        updatedStart -= indexBefore * interval;
      }

      this.dateParam.range.start = parseInt(updatedStart) * 1000;
      this.dateParam.range.end = parseInt(updatedEnd) * 1000;
      this.dateParam.aggregatedUnit = 'MINUTE';
      this.onSubmitRange(this.dateParam);
      // console.log('Load data based on chart index: ', xaxis, timeIndex, timeIndex[xaxis.min], timeIndex[xaxis.max]);
    },
  },
};
</script>

<style lang="scss">
  .dt-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 9999;
    background: #DEE2E6;
    height: 100%;
    p {
      margin-top: 15px;
    }
  }
  .table-logs {
    display: block;
    font-size: 11px;
    overflow-x: auto;
    white-space: nowrap;
  }
</style>
