<template>
  <div class="d-flex justify-content-between">
    <div class="text-center">

      <h1>Unfortunately, you don't have access for this feature</h1>

    </div>
  </div>
</template>

<script>
export default {
  name: 'ForbiddenIndex',
};
</script>

<style scoped>

</style>
