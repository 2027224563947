<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="bg-white">
      <div v-if="isCurrLoading">
          <b-alert show variant="warning">Loading...</b-alert>
      </div>
      <div v-else-if="tmpMaintenanceVessel" id="maintenance">maintenance</div>
      <div v-else ref="pages">
        <div class="a4-landscape" ref="summary">
          <report-header :massName="selectedAsset[0].devcMass.massName" :start="range.start" :end="range.end"/>
          <div class="content">
            <h3 class="text-center-title" >Summary</h3>
            <Summary :data="dataSummary" :isLoading="isCurrLoading" style="height: 460px;"/>
          </div>
          <report-footer :lastPage="isFlowmeterMissing ? 4 : isSingleEngine ? 6 : 7" :page="currentPage" :currentTime="currentTime"/>
        </div>

        <div class="a4-landscape text-dark" ref="vesseltracking">
          <report-header :massName="selectedAsset[0].devcMass.massName" :start="range.start" :end="range.end"/>
          <div class="content">
            <h3 class="text-center-title" >Vessel Tracking</h3>
            <VesselTracking :assetObject="assetObject" :isLoading="isCurrLoading" :activeAssetId="filter.devcMassId"/>
          </div>
          <report-footer :lastPage="isFlowmeterMissing ? 4 : isSingleEngine ? 6 : 7" :page="currentPage + 1" :currentTime="currentTime"/>
        </div>

        <div class="a4-landscape text-dark" ref="rpmfuelcons">
          <report-header :massName="selectedAsset[0].devcMass.massName" :start="range.start" :end="range.end"/>
          <div class="content">
            <h3 class="text-center-title" >{{ isFlowmeterMissing ? 'RPM vs AE Running Time vs Speed vs Distance ' : 'RPM vs Fuel Cons vs AE Running Time vs Speed vs Distance'}}</h3>
            <RpmFuelCons :assetObject="assetObject" :isLoading="isCurrLoading" :activeAssetId="filter.devcMassId" :rangeRaw="rangeRaw"/>
          </div>
          <report-footer :lastPage="isFlowmeterMissing ? 4 : isSingleEngine ? 6 : 7" :page="3" :currentTime="currentTime"/>
        </div>

        <div :class="isFlowmeterMissing ? 'a4-landscape d-none' : 'a4-landscape'" ref="mefuelconst">
          <report-header :massName="selectedAsset[0].devcMass.massName" :start="range.start" :end="range.end"/>
          <div class="content">
            <h3 class="text-center-title" >ME Fuel Consumption</h3>
            <MEFuelCons :isSingleEngine="filter && filter.devcMassId && assetObject && assetObject[filter.devcMassId] && assetObject[filter.devcMassId].summaries.rpm.singleEngine" :assetObject="assetObject" :isLoading="isCurrLoading" :activeAssetId="filter.devcMassId" :rangeRaw="rangeRaw"/>
          </div>
          <report-footer :lastPage="isFlowmeterMissing ? 4 : isSingleEngine ? 6 : 7" :page="4" :currentTime="currentTime"/>
        </div>

        <div v-if="isSingleEngine">
          <div :class="isFlowmeterMissing ? 'a4-landscape d-none' : 'a4-landscape'" ref="portfuelrun">
            <report-header :massName="selectedAsset[0].devcMass.massName" :start="range.start" :end="range.end"/>
            <div class="content">
              <h3 class="text-center-title" >ME Total Fuel Used vs ME Running Hours</h3>
              <MeFuelRun :assetObject="assetObject" :isLoading="isCurrLoading" :activeAssetId="filter.devcMassId" :rangeRaw="rangeRaw"/>
            </div>
            <report-footer :lastPage="isFlowmeterMissing ? 4 : isSingleEngine ? 6 : 7" :page="5" :currentTime="currentTime"/>
          </div>
        </div>
        <div v-else>
          <div :class="isFlowmeterMissing ? 'a4-landscape d-none' : 'a4-landscape'" ref="portfuelrun">
            <report-header :massName="selectedAsset[0].devcMass.massName" :start="range.start" :end="range.end"/>
            <div class="content">
              <h3 class="text-center-title" >Port ME Total Fuel Used vs Port ME Running Hours</h3>
              <PortFuelRun :assetObject="assetObject" :isLoading="isCurrLoading" :activeAssetId="filter.devcMassId" :rangeRaw="rangeRaw"/>
            </div>
            <report-footer :lastPage="isFlowmeterMissing ? 4 : isSingleEngine ? 6 : 7" :page="5" :currentTime="currentTime"/>
          </div>
          <div :class="isFlowmeterMissing ? 'a4-landscape d-none' : 'a4-landscape'" ref="stbfuelrun">
            <report-header :massName="selectedAsset[0].devcMass.massName" :start="range.start" :end="range.end"/>
            <div class="content">
              <h3 class="text-center-title" >STB ME Total Fuel Used vs STB ME Running Hours</h3>
              <StbFuelRun :assetObject="assetObject" :isLoading="isCurrLoading" :activeAssetId="filter.devcMassId" :rangeRaw="rangeRaw"/>
            </div>
            <report-footer :lastPage="isFlowmeterMissing ? 4 : isSingleEngine ? 6 : 7" :page="isFlowmeterMissing ? 3 : 6" :currentTime="currentTime"/>
          </div>
        </div>

        <div class="a4-landscape text-dark mt-2" ref="aefuelcons">
          <report-header :massName="selectedAsset[0].devcMass.massName" :start="range.start" :end="range.end"/>
          <div class="content">
            <h3 class="text-center-title" >AE Fuel Consumption</h3>
            <AEFuelCons :assetObject="assetObject" :isLoading="isCurrLoading" :activeAssetId="filter.devcMassId" :rangeRaw="rangeRaw"/>
          </div>
          <report-footer :lastPage="isFlowmeterMissing ? 4 : isSingleEngine ? 6 : 7" :page="isFlowmeterMissing ? 4 : isSingleEngine ? 6 : 7" :currentTime="currentTime"/>
        </div>
      </div>
      <div v-if="gatewayId" id="gatewayId" style="display: none;">{{ gatewayId }}</div>
      <div v-if="!isCurrLoading" style="display: none;" id="finished">done</div>
    </div>
</template>

<script>

import dateUtil from '@/util/date';
// import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import dataMenus from '@/util/data-table';
import AssetSvc from '@/services/AssetSvc';
import AuthSvc from '@/services/AuthSvc';
// import AssetSummarySvc from '@/services/AssetSummarySvc';
import DeviceSvc from '@/services/DeviceSvc';
import SeriesSvc from '@/services/SeriesSvc';
import requestBuilder from '@/util/request.builder';
import moment from 'moment';
// import CryptoJS from 'crypto-js';
// import crypto from 'crypto';
// import { Random } from 'crypto-js-legacy';
import ReportHeader from './components/ReportHeader.vue';
import ReportFooter from './components/ReportFooter.vue';
import VesselTracking from './VesselTracking.vue';
import RpmFuelCons from './RpmFuelCons.vue';
import MEFuelCons from './MEFuelCons.vue';
import AEFuelCons from './AEFuelCons.vue';
import PortFuelRun from './PortFuelRun.vue';
import MeFuelRun from './MeFuelRun.vue';
import StbFuelRun from './StbFuelRun.vue';
import Summary from './Summary.vue';
import oktaConfig from '../../config/okta.js';

export default {
  name: 'Index',
  components: {
    // DatePicker,
    VesselTracking,
    Summary,
    RpmFuelCons,
    MEFuelCons,
    AEFuelCons,
    PortFuelRun,
    MeFuelRun,
    StbFuelRun,
    ReportHeader,
    ReportFooter,
  },
  data() {
    return {
      isVesselTrackingHidden: true,
      isRpmFuelConsHidden: true,
      currentPage: 1,
      currentTime: moment().format('ddd MMM DD YYYY HH:mm:ss'),
      visibility: 'click',
      masks: {
        input: 'YYYY-MM-DD h:mm',
      },
      localRange: {
        start: new Date(),
        end: new Date(),
      },
      dataIntervalOptions: dateUtil.dataIntervalOptions,
      menus: dataMenus || [],
      popover: {
        positionFixed: true,
        placement: 'top-end',
      },
      selectedAsset: undefined,
      dataSummary: undefined,
      assets: [],
      assetAvailable: false,
      filter: {
        massId: null,
        devcStatus: '1',
      },
      interval: 'HOUR',
      massId: this.assets && this.assets.length > 0 ? this.assets[0].massId : undefined,
      range: {
        start: moment().set({ hour: 0, minute: 0, second: 0 }).toString(),
        // start: moment().subtract(1, 'week').startOf('week').toString(),
        end: new Date(),
      },
      rangeRaw: {
        start: '',
        end: '',
      },
      assetObject: {},
      isCurrLoading: true,
      activeAssetData: undefined,
      selected: {
        group: {
          grcpId: 3,
        },
        company: {
          compId: 9,
        },
      },
      tmpMaintenanceVessel: false,
      dataRPMEmpty: false,
      dataGPSEmpty: false,
      dataAEEmpty: false,
      dataFlowmeterEmpty: false,
      gatewayId: undefined,
      dateStartDailyReport: '',
      dateEndDailyReport: '',
      isSingleEngine: false,
    };
  },
  async mounted() {
    this.isCurrLoading = true;
    await this.loginUser();
    await this.handleGetData();
    this.isCurrLoading = false;
    this.handleSingleEngine();
  },
  computed: {
    isFlowmeterMissing() {
      // return !this.dataSummary.hasOwnProperty('flowmeter');
      const dataAsset = this.assetObject[this.filter.devcMassId].summaries;
      // console.log('dataAsset>>>', this.assetObject[this.filter.devcMassId]);
      if (dataAsset && 'flowmeter' in dataAsset) {
        return false;
      }
      return true;
    },
  },
  methods: {
    handleSingleEngine() {
      if (this.filter && this.filter.devcMassId && this.assetObject && this.assetObject[this.filter.devcMassId] && this.assetObject[this.filter.devcMassId].summaries.rpm.singleEngine) {
        this.isSingleEngine = true;
      }
    },
    async loginUser() {
      try {
        console.log('query>>>', this.$route.query);
        // const isProd = this.$route.query.isProd;
        // if (isProd === 'true') {
        //   this.currentTime = moment().zone('+0700').format('ddd MMM DD YYYY HH:mm:ss');
        // }
        const transaction = await this.$auth.signInWithCredentials({
          username: this.$route.query.u,
          password: this.$route.query.p,
        });
        console.log('transaction', transaction);
        if (transaction.status === 'SUCCESS') {
          const response = await this.$auth.token.getWithoutPrompt({
            clientId: oktaConfig.clientId,
            responseType: ['token', 'id_token'],
            scopes: ['openid', 'email'],
            sessionToken: transaction.sessionToken,
            redirectUri: oktaConfig.redirectUri,
          });
          console.log('response', response);
          await AuthSvc.signInWithToken(response.tokens.accessToken.value);
          await this.$store.dispatch('auth/changeActiveCompany', {
            group: {
              grcpId: this.$route.query.grcpId,
            },
            company: {
              compId: this.$route.query.compId,
            },
          });
        }
      } catch (error) {
        console.error('error', error);
      }
    },
    async getAssets() {
      try {
        const resp = await AssetSvc.getAssets();
        if (resp.data.data.length > 0) {
          this.assetAvailable = true;
          this.assets = resp.data.data;
          console.log('assetts >>', this.assets);
        } else {
          this.assetAvailable = false;
        }
      } catch (err) {
        console.log('Handle error nih... ', err.response);
        // this.doLogOut();
      }
    },
    async handleGetData() {
      try {
        this.filter.devcMassId = this.$route.query.devcMassId;
        this.rangeRaw.start = parseInt(this.$route.query.start);
        this.rangeRaw.end = parseInt(this.$route.query.end);
        const devices = await DeviceSvc.getDevices({
          params: {
            ...requestBuilder.buildFilters({}, this.filter),
          },
        });
        console.log('===> 1. devices: ', devices);
        this.selectedAsset = devices.data.data;
        const currAssetDevices = devices.data.data.filter((item) => item.devcMassId === this.$route.query.devcMassId);
        this.activeAssetData = currAssetDevices[0].devcMass;
        console.log('===> 2. devices: ', currAssetDevices);
        const foundGateway = currAssetDevices.find((item) => item.devcType === 'gateway');
        this.gatewayId = foundGateway.devcUniqueId;
        const dateParam = {
          aggregatedUnit: this.interval,
          // aggregatedUnit: this.$route.aggregatedUnit, *from params route
          mode: 'period', // period, last
          // value: 'custom',
          range: {
            start: parseInt(this.$route.query.start),
            end: parseInt(this.$route.query.end),
          },
        // duration: 1,
        };
        this.range.start = this.dateConvert(dateParam.range.start);
        this.range.end = this.dateConvert(dateParam.range.end);
        const seriesParam = dateUtil.autoMaxData(dateUtil.msToS(dateParam));
        seriesParam.typesToGet = ['gps', 'ae', 'flowmeter', 'rpm'];
        console.log('===> 3. seriesParam: ', seriesParam);
        const combinedData = await SeriesSvc.getCombinedData(
          currAssetDevices,
          seriesParam,
        );
        this.dataSummary = combinedData;
        this.assetObject[this.filter.devcMassId] = combinedData;
        console.log('===> 4. combinedData: ', combinedData);
        console.log('===> 4. this.assetObject: ', this.assetObject);
        console.log('===> 4. this.dataSummary: ', this.dataSummary);
        if (combinedData.summaries) {
          if (!combinedData.summaries.rpm || (combinedData.summaries.rpm.totalRPM.PORT === null || combinedData.summaries.rpm.totalRPM.PORT === undefined || combinedData.summaries.rpm.totalRPM.STARBOARD === null || combinedData.summaries.rpm.totalRPM.STARBOARD === undefined)) {
            console.log('===> 4.1 data rpm empty');
            this.dataRPMEmpty = true;
          }
          if (!combinedData.summaries.ae || ((combinedData.summaries.ae.runningTime.AE1 === null || combinedData.summaries.ae.runningTime.AE1 === undefined) && (combinedData.summaries.ae.runningTime.AE2 === null || combinedData.summaries.ae.runningTime.AE2 === undefined) && (combinedData.summaries.ae.runningTime.AE3 === null || combinedData.summaries.ae.runningTime.AE3 === undefined))) {
            console.log('===> 4.2 data ae empty');
            this.dataAEEmpty = true;
          }
          if (!combinedData.summaries.flowmeter || ((combinedData.summaries.flowmeter.portAverageFlow === null || combinedData.summaries.flowmeter.portAverageFlow === undefined) && (combinedData.summaries.flowmeter.portEngineCons === null || combinedData.summaries.flowmeter.portEngineCons === undefined) && (combinedData.summaries.flowmeter.portTotalAverageFlow === null || combinedData.summaries.flowmeter.portTotalAverageFlow === undefined) && (combinedData.summaries.flowmeter.starboardAverageFlow === null || combinedData.summaries.flowmeter.starboardAverageFlow === undefined) && (combinedData.summaries.flowmeter.starboardTotalAverageFlow === null || combinedData.summaries.flowmeter.starboardTotalAverageFlow === undefined) && (combinedData.summaries.flowmeter.stbEngineCons === null || combinedData.summaries.flowmeter.stbEngineCons === undefined))) {
            console.log('===> 4.2 data flowmeter empty');
            this.dataFlowmeterEmpty = true;
          }
        }
        if (this.dataRPMEmpty && this.dataAEEmpty && this.dataFlowmeterEmpty) {
          this.tmpMaintenanceVessel = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    dateConvert(x) {
      // const isProd = this.$route.query.isProd;
      const date = moment(x);
      // if (isProd === 'true') {
      //   date = moment(x).zone('+0700');
      // }
      return date.format('DD MMM YYYY HH:mm');
    },
  },
};

</script>

<style scoped>
  body {
    background-color: white;
  }
  .a4-landscape {
    position: relative;
    background-color: white;
    width: 286mm;
    height: 209mm;
    margin: 0 auto;
    /* border: thin solid black; */
  }
  body {
    -webkit-print-color-adjust: exact;
    background-color: white !important;
  }
  @page {
    /* size: 287mm 209mm; */
    size: 287mm 210mm landscape;
    /* margin: 1cm 1cm; */
  }
  /* page[size="A4"] {
    background: white;
    width: 21cm;
    height: 27cm;
    display: block;
    margin: 5mm auto;
    margin-bottom: 0.5cm;
    box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
  } */
  /* @media print {
    @page {
      size: 287mm 210mm landscape;
      margin: 1cm 1cm;
    }
  } */
  .content {
    height: 16cm;
    background-color: whitered;
  }
  .text-center-title {
    font-size: 22px;
    text-align: center;
    font-weight: bold;
    color: black;
    margin-bottom: 10px;
  }
</style>
<!-- <img src="https://quickchart.io/chart?c={type:'bar',data:{labels:['January','February','March','April','May'],datasets:[{label:'Dogs',data:[50,60,70,180,190]},{label:'Cats',data:[100,200,300,400,500]},{type:'line',fill:false,label:'Potatoes',data:[100,400,200,400,700]}]}}"></img> -->
