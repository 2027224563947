<template>
  <section class="sign-in-page bg-white">
    <b-container fluid class="h-100">
      <b-row class="h-25" align-v="center">
        <b-col md="6" offset-md="3">
          <hr>
          <b-img :src="ramusLogoURL" fluid alt="RAMUS Logo"></b-img>
          <hr>
        </b-col>
      </b-row>
      <b-row class="h-75" align-v="center">
        <b-col md="6" offset-md="3">
          <router-view></router-view>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import ramusLogo from '@/assets/images/ramus_logo.png';

// import { sofbox } from '@/config/pluginInit';

export default {
  name: 'UnauthorizedLayout',
  // mounted() {
  //   sofbox.index();
  // },
  data() {
    return {
      ramusLogoURL: ramusLogo,
    };
  },
};
</script>
