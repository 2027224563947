<template>
  <b-container fluid>
    <div class="iq-card mt-3">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="card-title">Group Company List</h4>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="text-right">
              <button-options
                :access="canRead('master_group_company')"
                :hideDetail="true"
                :isBackEnabled="true"
                :isRefreshEnabled="true"
                :refreshed="isLoading"
                @onRefreshed="getData('Refreshed')"/>
            </div>
          </div>
        </div>
      </div>
      <div class="iq-card-body">
        <div class="row" v-if="isLoading">
          <div class="col-md-12">
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </div>
        </div>
        <div class="row" v-if="companies.length > 0 && !isLoading">
          <b-col cols="3" v-for="(data, index) in companies" v-bind:key="`D-${index}`">
            <b-card class="text-center btn card-item-group m-1"
                    :id="`groupCard${index}`"
                    @click="onItemClicked(data.compId)">
              <b-card-text>
                <i class="ri-building-line"></i>
                {{data.compName}}
              </b-card-text>
            </b-card>
          </b-col>
        </div>
        <div class="row" v-if="companies.length <= 0 && !isLoading">
          <b-col cols="12" class="text-center">
            No data to be displayed
          </b-col>
        </div>
      </div>
    </div>

  </b-container>
</template>

<script>

import ButtonOptions from '@/components/directives/button-options';
import CompanySvc from '@/services/CompanySvc';

import requestBuilder from '@/util/request.builder';

export default {
  name: 'GroupDetail',
  components: { ButtonOptions },
  data() {
    return {
      companies: [],
      filter: {
        compGrcpId: this.$route.params.id,
      },
      id: this.$route.params.id,
      isLoading: false,
      isRefreshed: false,
      isSubmit: false,
      pagination: {
        current: 1,
        pageSize: 10,
        totalSize: 0,
      },
    };
  },
  methods: {
    async getData(notification) {
      this.isLoading = true;
      this.isRefreshed = true;
      try {
        const resp = await CompanySvc.getCompanies({
          params: {
            ...requestBuilder.buildFilters(this.pagination, this.filter),
          },
        });
        if (notification) {
          this.$toast.success(notification);
        }
        this.companies = resp.data.data;
      } catch (e) {
        if (e.response.data) {
          if (e.response.data.status.message === 'Authentication Error') {
            this.doLogOut();
          } else {
            this.$toast.error(e.response.data.status.message);
          }
        } else {
          this.$toast.error('Network error');
        }
      } finally {
        this.isLoading = false;
        this.isRefreshed = false;
      }
    },
    onItemClicked(id) {
      this.$router.push({ name: 'CompanyDetail', params: { id } });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>

</style>
