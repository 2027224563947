<template>
  <b-collapse tag="ul" :class="className" :visible="open" :id="idName" :accordion="accordianName">
    <li
      v-for="(item,index) in sidebarItems"
      :key="index"
      :class="item.is_heading ? 'iq-menu-title' :activeLink(item) && item.children ? 'active' : activeLink(item) ? 'active' : ''">
      <i v-if="item.is_heading" class="ri-subtract-line"/>
      <span v-if="item.is_heading">{{ item.title }}</span>
      <router-link
        v-if="!item.is_heading"
        :to="item.link"
        :class="`iq-waves-effect ${activeLink(item) && item.children ? 'active' : activeLink(item) ? 'active' : ''}`"
        v-b-toggle="item.name">
        <i :class="item.icon" v-if="item.is_icon_class"/>
        <template v-else v-html="item.icon"></template>
        <span v-if="!small">{{ item.title }}</span>
        <i v-if="item.children" class="ri-arrow-right-s-line iq-arrow-right"/>
        <!-- <pre>{{item}}</pre> -->
        <small v-html="item.append" :class="item.append_class"/>
      </router-link>
      <SideBarList v-if="item.children" :items="item.children"
                   :open="item.link.name !== '' && activeLink(item) && item.children ? true : !!(item.link.name !== '' && activeLink(item))"
                   :idName="item.name" :accordianName="`sidebar-accordion ${item.class_name}`"
                   :className="`iq-submenu ${item.class_name}`"/>
    </li>
  </b-collapse>
</template>
<script>
import { sofbox } from '@/config/pluginInit';

export default {
  name: 'SideBarList',
  props: {
    items: Array,
    className: { type: String, default: 'iq-menu' },
    horizontal: Boolean,
    open: { type: Boolean, default: false },
    idName: { type: String, default: 'sidebar' },
    accordianName: { type: String, default: 'sidebar' },
    small: {
      type: Boolean, default: false,
    },
  },
  computed: {
    userAccess() {
      return this.$store.getters['auth/userAccess'];
    },
    sidebarItems() {
      return this.items.filter((item) => {
        if (!item.module) {
          if (item.name && item.name === 'User.Role') {
            if (this.userAccess && this.userAccess.master_user) {
              return true;
            }
            return false;
          }
          // return this.isVisible(v);
        }
        return this.canRead(item.module);
      });
    },
  },
  methods: {
    activeLink(item) {
      return sofbox.getActiveLink(item, this.$route.name);
    },
  },
};
</script>
