<template>
  <b-container fluid>
    <b-row class="row-eq-height">
      <b-col md="12" class="mt-3">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Analytics</h4>
          </template>
          <template v-slot:headerAction>
            <ul class="nav nav-pills">
              <!-- <li class="nav-item mr-3"> -->
              <!-- <li class="nav-item mr-3">
                <a href="#" class="nav-link" @click.prevent="showSummary">Show Summary</a>
              </li> -->
              <li class="nav-item">
                <b-dropdown  id="dropdownMenuButton4" right variant="none" data-toggle="dropdown">
                  <template v-slot:button-content>
                    <span><i class="ri-more-2-fill"></i></span>
                  </template>
                  <b-dropdown-item href="#"><i class="ri-file-download-fill mr-2"></i>Download</b-dropdown-item>
                </b-dropdown>
              </li>
            </ul>
          </template>
          <template v-slot:body>
            <div class="mb-3" v-if="dateParam">
              <date-picker
                :dateParam="dateParam"
                :isInline="true"
                :isLoading="isLoading"
                :enableInterval="true"
                @onSubmitRange="onSubmitRange"/>
            </div>
            <div class="mb-3">
              <template v-for="(item,index) in deviceTypes">
                <b-form-checkbox @change="onItemChange" v-model="item.checked" :disabled="item.disabled" name="check-button" switch inline :key="index">
                  {{ item.label }}
                </b-form-checkbox>
              </template>
            </div>
            <div v-if="isLoading">
              <b-alert show variant="warning">Loading...</b-alert>
            </div>
            <div v-else>
              <template v-if="!isLoading">
                <AnalyticTable
                  :data="assetObject[activeAssetId]"
                  class="overlay-dataview"/>
              </template>
            </div>
            <b-modal id="summary-modal">
              <!-- <pre>{{series}}</pre> -->
              <h1>Summary yay...</h1>
            </b-modal>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import requestBuilder from '@/util/request.builder';
import DeviceSvc from '@/services/DeviceSvc';

import DatePicker from '@/components/directives/date-picker';
import dateUtil from '@/util/date';

import SeriesSvc from '@/services/SeriesSvc';
import AnalyticTable from './AnalyticTable';

export default {
  name: 'AssetAnalytics',
  components: {
    DatePicker,
    AnalyticTable,
  },
  data() {
    return {
      deviceTypes: [
        {
          label: 'GPS',
          value: 'gps',
          checked: true,
          disabled: false,
        },
        {
          label: 'Flowmeter',
          value: 'flowmeter',
          checked: false,
          disabled: false,
        },
        {
          label: 'RPM',
          value: 'rpm',
          checked: false,
          disabled: false,
        },
        {
          label: 'AE',
          value: 'ae',
          checked: false,
          disabled: false,
        },
      ],
      activeAssetId: undefined,
      isLoading: false,
      asset: undefined,
      assetObject: {},
      query: {},
      series: undefined,
      /* Default Date Parameter */
      dateParam: undefined,
    };
  },
  async mounted() {
    console.log('Analytics mounted...');
    this.activeAssetId = this.$route.params.id;
    if (!this.assetObject[this.activeAssetId]) this.assetObject[this.activeAssetId] = {};
    await this.load();
  },
  methods: {
    async load() {
      this.isLoading = true;
      if (this.$route.query.value) {
        console.log('Data period defined... using query...');
        this.dateParam = dateUtil.queryToDateParam(this.$route.query);
      } else {
        console.log('Data period not defined... using today...');
        this.dateParam = dateUtil.byInterval('today');
      }

      await this.getDevices();
      this.series = await this.getDevicesData();
      this.series.forEach((serie) => {
        this.assetObject[this.activeAssetId].devices[serie.data.data.devices[0]].data = serie.data.data.series;
        this.assetObject[this.activeAssetId].devices[serie.data.data.devices[0]].interval = serie.data.data.interval;
      });
      this.isLoading = false;
    },
    async getDevices() {
      console.log('Getting gateway device id on asset no : ', this.activeAssetId);
      const params = requestBuilder.buildFilters({
        current: 1,
        pageSize: 50,
      }, {
        devcMassId: this.activeAssetId,
        devcType: this.getEnabledDeviceTypes(),
      });
      const resp = await DeviceSvc.getDevices({ params });
      this.devices = resp.data.data;
      console.log('Got devices : ', this.devices);
    },
    getDevicesData() {
      console.log('Get devices data using param : ', this.dateParam);
      const promises = [];
      this.sortedDeviceKeys = [];
      if (!this.assetObject[this.activeAssetId].devices) this.assetObject[this.activeAssetId].devices = {};
      for (let i = 0; i < this.devices.length; i++) {
        const device = this.devices[i];
        const freshParam = JSON.parse(JSON.stringify(dateUtil.msToS(this.dateParam)));
        switch (device.devcType) {
          case 'gps':
            this.assetObject[this.activeAssetId].devices[device.devcUniqueId] = device;
            this.sortedDeviceKeys.unshift(device.devcUniqueId);
            // freshParam.primaryFunctionName = 'LAST';
            freshParam.functionNames = ['AVG', 'AVG', 'AVG'];
            freshParam.functionParams = ['@Speed', '@Track', '@Altitude'];
            promises.push(SeriesSvc.getAggregatedData(device, freshParam));
            break;
          case 'rpm':
            this.assetObject[this.activeAssetId].devices[device.devcUniqueId] = device;
            this.assetObject[this.activeAssetId].devices[device.devcUniqueId].data = [];
            this.sortedDeviceKeys.unshift(device.devcUniqueId);
            freshParam.functionNames = ['AVG'];
            freshParam.functionParams = ['@Rpm'];
            // freshParam.type = 'PORT';
            promises.push(SeriesSvc.getAggregatedData(device, freshParam));
            // freshParam.type = 'STARBOARD';
            // promises.push(SeriesSvc.getAggregatedData(device, freshParam));
            break;
          case 'ae':
            this.assetObject[this.activeAssetId].devices[device.devcUniqueId] = device;
            this.sortedDeviceKeys.push(device.devcUniqueId);
            freshParam.functionNames = ['AVG'];
            freshParam.functionParams = ['@Rpm'];
            promises.push(SeriesSvc.getAggregatedData(device, freshParam));
            break;
          case 'flowmeter':
            this.assetObject[this.activeAssetId].devices[device.devcUniqueId] = device;
            this.sortedDeviceKeys.push(device.devcUniqueId);
            freshParam.functionNames = ['AVG', 'AVG', 'AVG'];
            freshParam.functionParams = ['@VolFlowrate', '@Density', '@Temp'];
            promises.push(SeriesSvc.getAggregatedData(device, freshParam));
            break;
        }
      }
      return Promise.all(promises);
    },
    getEnabledDeviceTypes() {
      const enabled = [];
      this.deviceTypes.forEach((type) => {
        if (type.checked) {
          enabled.push(type.value);
        }
      });
      return enabled;
    },
    onSubmitRange(date) {
      console.log('Range submit : ', date);
      this.$router.replace({
        name: 'AssetAnalytics',
        query: dateUtil.dateParamToQuery(date),
      }).catch((err) => {
        console.log('Duplicate Route, Skipping... ', err);
      });
      this.load();
    },
    onItemChange() {
      console.log('Something changed... ', this.deviceTypes);
      this.load();
    },
    showSummary() {
      // console.log('Show summary for : ', this.series);
      this.$bvModal.show('summary-modal');
    },
  },
};
</script>
