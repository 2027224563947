<template>
  <b-container fluid>
    <div hidden class="iq-card mt-3">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="card-title">Detail</h4>
        </div>
      </div>
      <div class="iq-card-body">
        <div class="row">
          <div role="group" class="form-group col-md-6">
            <label for="companyName" class="d-block form-label text-left">
              Name:
            </label>
            <div class="bv-no-focus-ring">
              <div role="group" class="input-group">
                <input id="companyName"
                       autocomplete="off"
                       v-model="form.compName"
                       placeholder="Enter name"
                       type="text"
                       :disabled="isSubmit"
                       :class="errors.compName ? 'is-invalid' : ''"
                       class="form-control">
                <b-form-invalid-feedback v-if="errors.compName">
                  {{ Object.values(errors.compName) }}
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
          <div role="group" class="form-group col-md-6">
            <label for="grcpName" class="d-block form-label text-left">
              Group:
            </label>
            <v-select id="grcpName"
                      label="grcpName"
                      :options="groups"
                      v-model="form.compGrcpId"
                      :reduce="group => group.grcpId"
                      placeholder="Select Group"
                      :disabled="isSubmit"
                      :class="errors.compGrcpId ? 'is-invalid' : ''">
              <template slot="no-options">
                Type to search group
              </template>
            </v-select>
            <b-form-invalid-feedback v-if="errors.compGrcpId">
              {{ Object.values(errors.compGrcpId) }}
            </b-form-invalid-feedback>
            <div class="bv-no-focus-ring">
              <div role="group" class="input-group">
                <b-form-invalid-feedback v-if="errors.compGrcpId">
                  {{ Object.values(errors.compGrcpId) }}
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div role="group" class="form-group col-md-12">
            <label for="companyAddress" class="d-block form-label text-left">
              Address:
            </label>
            <div class="bv-no-focus-ring">
              <div role="group" class="input-group">
                  <textarea id="companyAddress"
                            autocomplete="off"
                            v-model="form.compAddress"
                            placeholder="Enter company address"
                            type="text"
                            :disabled="isSubmit"
                            :class="errors.compAddress ? 'is-invalid' : ''"
                            class="form-control"></textarea>
                <b-form-invalid-feedback v-if="errors.compAddress">
                  {{ Object.values(errors.compAddress) }}
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div role="group" class="form-group col-md-6">
            <label for="companyPIC" class="d-block form-label text-left">
              PIC:
            </label>
            <div class="bv-no-focus-ring">
              <div role="group" class="input-group">
                <input id="companyPIC"
                       autocomplete="off"
                       v-model="form.compPIC"
                       placeholder="PIC name"
                       type="text"
                       :disabled="isSubmit"
                       :class="errors.compPIC ? 'is-invalid' : ''"
                       class="form-control">
                <b-form-invalid-feedback v-if="errors.compPIC">
                  {{ Object.values(errors.compPIC) }}
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
          <div role="group" class="form-group col-md-6">
            <label for="companyPICPhone" class="d-block form-label text-left">
              PIC Phone:
            </label>
            <div class="bv-no-focus-ring">
              <div role="group" class="input-group">
                <input id="companyPICPhone"
                       autocomplete="off"
                       v-model="form.compPICPhone"
                       placeholder="085255XXXXXX"
                       type="text"
                       :disabled="isSubmit"
                       :class="errors.compPICPhone ? 'is-invalid' : ''"
                       class="form-control">
                <b-form-invalid-feedback v-if="errors.compPICPhone">
                  {{ Object.values(errors.compPICPhone) }}
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="text-right">
              <button-options
                :access="canUpdate('master_company')"
                :hideDetail="true"
                :isBackEnabled="true"
                :isLoading="isLoading"
                :isSaveEnabled="true"
                :isSubmit="isSubmit"
                @onSubmit="onSubmit" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="iq-card mt-3">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="card-title">Company Asset List</h4>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="text-right">
              <button-options
                :access="canRead('master_asset')"
                :hideDetail="true"
                :isBackEnabled="true"
                :isRefreshEnabled="true"
                :refreshed="isLoading"
                @onRefreshed="getData('Refreshed')"/>
            </div>
          </div>
        </div>
      </div>
      <div class="iq-card-body">
        <div class="row" v-if="isLoading">
          <div class="col-md-12">
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </div>
        </div>
        <div class="row" v-if="assets.length > 0 && !isLoading">
          <b-col cols="3" v-for="(data, index) in assets" v-bind:key="`E-${index}`">
            <b-card class="text-center btn card-item-group m-1"
                    :id="`companyCard${index}`"
                    @click="onItemClicked(data)">
              <b-card-text>
                <i class="ri-database-line"></i>
                {{data.massName}}
              </b-card-text>
            </b-card>
          </b-col>
        </div>
        <div class="row" v-if="assets.length <= 0 && !isLoading">
          <b-col cols="12" class="text-center">
            No data to be displayed
          </b-col>
        </div>
      </div>
      <div class="iq-card-body">
        <div class="row">
          <div class="col-md-12">
            <b-pagination
              v-model="pagination.current"
              :per-page="pagination.pageSize"
              :total-rows="pagination.totalSize"
              @change="onPaginationChanged"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>

import AssetSvc from '@/services/AssetSvc';
import ButtonOptions from '@/components/directives/button-options';
import CompanySvc from '@/services/CompanySvc';

import requestBuilder from '@/util/request.builder';

export default {
  name: 'CompanyDetail',
  components: { ButtonOptions },
  computed: {
    groups() {
      return this.$store.getters['auth/availableGroup'];
    },
  },
  data() {
    return {
      assets: [],
      errors: {},
      filter: {
        asset: {
          massCompId: this.$route.params.id,
        },
        company: {
          compId: this.$route.params.id,
        },
      },
      form: {},
      id: this.$route.params.id,
      isLoading: false,
      isSubmit: false,
      pagination: {
        current: 1,
        pageSize: 10,
        totalSize: 0,
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(notification) {
      this.isLoading = true;
      try {
        const assets = await AssetSvc.getAsset({
          params: {
            ...requestBuilder.buildFilters(this.pagination, this.filter.asset),
          },
        });
        const companies = await CompanySvc.getCompanyById(this.id, {
          params: {
            ...requestBuilder.buildFilters({}, this.filter.company),
          },
        });
        if (notification) {
          this.$toast.success(notification);
        }
        this.assets = assets.data.data;
        this.pagination.totalSize = assets.data.pagination.totalData;
        this.form = companies.data.data.find((o) => o.compId === this.id);
      } catch (e) {
        if (e.response.data) {
          if (e.response.data.status.message === 'Authentication Error') {
            this.doLogOut();
          } else {
            this.$toast.error(e.response.data.status.message);
          }
        } else {
          this.$toast.error('Network error');
        }
      } finally {
        this.isLoading = false;
      }
    },
    onItemClicked(item) {
      this.$router.push({ name: 'AssetDetail', params: { id: item.massId } });
    },
    onPaginationChanged(page) {
      this.pagination.current = page;
      this.getData();
    },
    async onSubmit() {
      this.isSubmit = true;
      const form = {
        compName: this.form.compName,
        compGrcpId: this.form.compGrcpId,
        compAddress: this.form.compAddress,
        compPIC: this.form.compPIC,
        compPICPhone: this.form.compPICPhone,
      };
      try {
        const resp = await CompanySvc.updateCompany(this.id, form);
        if (resp.data.status.error === 0) {
          this.$toast.success('Updated');
        } else {
          const errors = resp.data.status.message;
          if (typeof errors === 'object') {
            this.errors = resp.data.status.message;
            this.$toast.error('Error found');
          } else {
            this.$toast.error(resp.data.status.message);
          }
        }
      } catch (e) {
        if (e.response.data) {
          if (e.response.data.status.message === 'Authentication Error') {
            this.doLogOut();
          } else {
            this.$toast.error(e.response.data.status.message);
          }
        } else {
          this.$toast.error('Network error');
        }
      } finally {
        this.isSubmit = false;
      }
    },
    doLogOut() {
      this.$toast.error('Please re-login');
      this.$store.dispatch('auth/logout');
      this.$router.replace({ name: 'Login' });
    },
  },
};
</script>

<style scoped>

</style>
