import axios from 'axios';

export default {
  getCompanies(config) {
    return axios.get('/master/company', config);
  },
  getCompanyById(id, config) {
    return axios.get(`/master/company/${id}`, config);
  },
  getCompanyByName(param) {
    return axios.get('/master/company', { params: param });
  },
  getCompany(params) {
    return axios.get('/master/company', { params });
  },
  getCompanyByGroupId(param) {
    return axios.get(`/master/company?findField=${param.findField}&findValue=${param.findValue}`);
  },
  addCompany(data) {
    return axios.post('/master/company', data);
  },
  updateCompany(id, data) {
    return axios.put(`/master/company/${id}`, data);
  },
  deleteCompany(id) {
    return axios.delete(`/master/company/${id}`);
  },

};
