<template>
  <div class="text-center">
    <p class="text-center card-text">{{dev.devcName}}</p>
    <canvas :id="canId"></canvas>
    <p class="text-right card-text">{{device.recentSeries ? recentSeries.datetime : ''}}</p>
  </div>
</template>

<script>
import { LinearGauge } from 'canvas-gauges';
import SeriesSvc from '@/services/SeriesSvc';

export default {
  name: 'Temperature',
  props: ['type', 'canId', 'data', 'dev'],
  data() {
    return {
      device: {},
      recentSeries: {},
      temperature: null,
    };
  },
  methods: {
    dateRange(date) {
      this.$emit('dateRange', date);
    },
    async getData(devcUniqueId) {
      if (devcUniqueId) {
        const filter = {
          start: 1593561600,
          end: parseInt((new Date().getTime() / 1000).toFixed(0)),
        };
        const resp = await SeriesSvc.getWeeklySeries(filter, devcUniqueId);
        const range = {
          from: resp.data.data.fromString,
          to: resp.data.data.toString,
        };
        this.dateRange(range);
        this.onFinishedGetData(resp.data.data);
        let log = resp.data.data.devices.map((v) => {
          const data = {};
          data.device = this.dev;
          data.devcType = v;
          data.series = resp.data.data.series.filter((o) => o.deviceId === v);
          data.series = data.series.map((s) => JSON.parse(s.data));
          data.recentSeries = data.series[0];
          return data;
        });
        log = log.find((o) => o.devcType === devcUniqueId);
        this.device = log;
        this.recentSeries = resp.data.data.series[0];
        this.generateWidget(log);
      }
    },
    generateWidget(log) {
      this.temperature = new LinearGauge({
        renderTo: this.canId,
        width: 120,
        height: 194,
        units: '°C',
        minValue: 0,
        maxValue: 220,
        majorTicks: [
          '0',
          '20',
          '40',
          '60',
          '80',
          '100',
          '120',
          '140',
        ],
        minorTicks: 2,
        strokeTicks: true,
        highlights: [
          {
            from: 100,
            to: 220,
            color: 'rgba(200, 50, 50, .75)',
          },
        ],
        colorPlate: '#fff',
        borderShadowWidth: 0,
        borders: false,
        needleType: 'arrow',
        needleWidth: 2,
        animationDuration: 1500,
        animationRule: 'linear',
        tickSide: 'left',
        numberSide: 'left',
        needleSide: 'left',
        barStrokeWidth: 7,
        barBeginCircle: false,
        value: log.recentSeries.temperature,
      }).draw();
    },
    onFinishedGetData(series) {
      const fields = [
        { key: 'datetime', label: 'Date', class: 'text-center' },
        { key: 'data.massFlowRate', label: 'Flow Rate', class: 'text-center' },
        { key: 'data.volumeFlowRate', label: 'Volume Rate', class: 'text-center' },
        { key: 'data.density', label: 'Density', class: 'text-center' },
        { key: 'data.temperature', label: 'Temperature', class: 'text-center' },
        { key: 'data.pressure', label: 'Pressure', class: 'text-center' },
        { key: 'data.massTotal', label: 'Mass Total', class: 'text-center' },
        { key: 'data.volumeTotal', label: 'Volume Total', class: 'text-center' },
        { key: 'data.massInventory', label: 'Mass Inventory', class: 'text-center' },
        { key: 'data.volumeInventory', label: 'Volume Inventory', class: 'text-center' },
      ];
      const logs = series.series.map((s) => {
        const log = {};
        log.datetime = s.datetime;
        log.id = s.id;
        log.data = JSON.parse(s.data);
        return log;
      });
      this.$emit('collectedData', logs);
      this.$emit('fieldsData', fields);
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(devcUniqueId) {
        this.getData(devcUniqueId);
      },
    },
  },
};
</script>

<style scoped>

</style>
