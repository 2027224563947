import axios from 'axios';

export default {

  getRoles(config) {
    return axios.get('/master/roles', config);
  },
  create(data) {
    return axios.post('/master/roles', data);
  },
  delete(id) {
    return axios.delete(`/master/roles/${id}`);
  },
  update(id, data) {
    return axios.put(`/master/roles/${id}`, data);
  },
  getRolePermissions(config) {
    return axios.get('/master/role_permission', config);
  },
  getUserRoles(config) {
    return axios.get('/master/user_role', config);
  },
  addUserRole(data) {
    return axios.post('/master/user_role', data);
  },

};
