<template>
  <div>
    <div class="wrapper">
      <SideBar :items="sidebarItems" :logo="logo" :small="isSmallSidebar" @onMaxMinSidebar="onMaxMinSidebar"
        :width="width" />
      <!-- <NavBar :logo="logo">
        <template slot="responsiveRight">
          <ul class="navbar-nav ml-auto navbar-list">
            <li class="nav-item" v-if="activeCompany">
              <a @click="onCompanyActiveClicked" class="iq-waves-effect">
                {{ activeCompany.compName }}
              </a>
            </li>
          </ul>
        </template>
        <template slot="right">
          <user-dropdown />
        </template>
      </NavBar> -->
      <div id="content-page" class="content-page p-0" :style="isSmallSidebar ? 'margin-left: 80px' : ''">
        <transition name="router-anim">
          <router-view />
        </transition>
      </div>
    </div>
    <footer class="bg-white iq-footer" :style="isSmallSidebar ? 'margin-left: 80px' : ''" v-if="isShowFooter">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6">
            <ul class="list-inline mb-0">
              <li class="list-inline-item"><a href="#">Terms of Use</a></li>
            </ul>
          </div>
          <div class="col-lg-6 text-right">
            Copyright 2020 <a href="#">RAMUS</a> All Rights Reserved.
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
// import NavBar from '@/components/navigations/NavBar';
import SideBar from '@/components/navigations/SideBar';
// import UserDropdown from '@/components/navigations/UserDropdown';

import SideBarItems from '@/data/sidebar/list';
import logo from '@/assets/images/logo-transparent.png';
import { sofbox } from '@/config/pluginInit';

export default {
  name: 'AuthorizedLayout',
  components: {
    // NavBar,
    SideBar,
    // UserDropdown,
  },
  computed: {
    activeCompany() {
      return this.$store.getters['auth/activeCompany'];
    },
  },
  data() {
    return {
      logo,
      sidebarItems: SideBarItems,
      isSmallSidebar: false,
      isShowFooter: true,
      width: document.documentElement.clientWidth,
    };
  },
  mounted() {
    sofbox.mainIndex();
    this.isShowFooter = this.$router.currentRoute.name !== 'Home';
  },
  created() {
    window.addEventListener('resize', this.getDimensions);
    this.getDimensions();
  },
  unmounted() {
    window.removeEventListener('resize', this.getDimensions);
  },
  methods: {
    onCompanyActiveClicked() {
      this.$router.push({ name: 'CompanyActiveSelection' });
    },
    onMaxMinSidebar() {
      this.isSmallSidebar = !this.isSmallSidebar;
    },
    getDimensions() {
      console.info('triggered width screen', document.documentElement.clientWidth);
      this.width = document.documentElement.clientWidth;
      if (document.documentElement.clientWidth < 1300) {
        this.isSmallSidebar = true;
        console.info('isSmallSidebar', this.isSmallSidebar);
      }
    },
  },
  watch: {
    $route() {
      this.isShowFooter = this.$router.currentRoute.name !== 'Home';
    },
    // width(newVal) {
    //   console.info('isSmallSidebar', newVal);
    //   if (newVal < 1300) {
    //     this.isSmallSidebar = true;
    //     console.info('isSmallSidebar', this.isSmallSidebar);
    //   }
    // },
  },
};
</script>
