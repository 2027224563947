<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <div class="row mb-3 mt-3">
            <div class="col-md-4">
                <h6 class="card-title text-uppercase text-secondary mb-0">Mass Total</h6>
                <span class="h2 text-dark mb-0 counter d-inline-block w-100">
                    <span v-if="summaries.massTotal !== undefined">{{(summaries.massTotal).toFixed(2)}} MT</span>
                </span>
            </div>
            <div class="col-md-4">
                <h6 class="card-title text-uppercase text-secondary mb-0">Mass Flow Rate</h6>
                <span class="h2 text-dark mb-0 counter d-inline-block w-100">
                    <span v-if="summaries.avgMassFlowrate !== undefined">{{(summaries.avgMassFlowrate).toFixed(2)}} MT/HR</span>
                </span>
            </div>
            <div class="col-md-4">
                <h6 class="card-title text-uppercase text-secondary mb-0">Density</h6>
                <span class="h2 text-dark mb-0 counter d-inline-block w-100">
                    <span v-if="summaries.avgDensity !== undefined">{{(summaries.avgDensity).toFixed(2)}} Kg/m³</span>
                </span>
            </div>
        </div>
        <div class="row mb-3 mt-3">
            <div class="col-md-4">
                <h6 class="card-title text-uppercase text-secondary mb-0">Volume Total</h6>
                <span class="h2 text-dark mb-0 counter d-inline-block w-100">
                    <span v-if="summaries.volumeTotal !== undefined">{{(summaries.volumeTotal).toFixed(2)}} M³</span>
                </span>
            </div>
            <div class="col-md-4">
                <h6 class="card-title text-uppercase text-secondary mb-0">Temperature</h6>
                <span class="h2 text-dark mb-0 counter d-inline-block w-100">
                    <span v-if="summaries.avgTemp !== undefined">{{(summaries.avgTemp).toFixed(2)}} °C</span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'ReportBunker',
  props: {
    data: { type: Object },
  },
  data() {
    return {
      summaries: {},
    };
  },
  async mounted() {
    if (this.data) {
      this.summaries = { ...this.summariesData(this.data) };
    }
  },
  methods: {
    summariesData(data) {
      const summaryData = {};
      let maxMassTotal = 0;
      let minMassTotal = 0;
      let maxVolumeTotal = 0;
      let minVolumeTotal = 0;
      let avgMassFlowrate = 0;
      let avgTemp = 0;
      let avgDensity = 0;
      Object.keys(data.datas).forEach((timeStamp, timestampIndex) => {
        if (timestampIndex === 0) {
          maxMassTotal = data.datas[timeStamp].flowmeter.BUNKER_IN.massTotal;
          minMassTotal = data.datas[timeStamp].flowmeter.BUNKER_IN.massTotal;
          maxVolumeTotal = data.datas[timeStamp].flowmeter.BUNKER_IN.volumeTotal;
          minVolumeTotal = data.datas[timeStamp].flowmeter.BUNKER_IN.volumeTotal;
        }
        if (data.datas[timeStamp].flowmeter.BUNKER_IN) {
          Object.keys(data.datas[timeStamp].flowmeter.BUNKER_IN).forEach((params) => {
            switch (params) {
              case 'massTotal':
                if (data.datas[timeStamp].flowmeter.BUNKER_IN.massTotal > maxMassTotal) {
                  maxMassTotal = data.datas[timeStamp].flowmeter.BUNKER_IN.massTotal;
                } else if (data.datas[timeStamp].flowmeter.BUNKER_IN.massTotal < minMassTotal) {
                  minMassTotal = data.datas[timeStamp].flowmeter.BUNKER_IN.massTotal;
                }
                break;
              case 'volumeTotal':
                if (data.datas[timeStamp].flowmeter.BUNKER_IN.volumeTotal > maxVolumeTotal) {
                  maxVolumeTotal = data.datas[timeStamp].flowmeter.BUNKER_IN.volumeTotal;
                } else if (data.datas[timeStamp].flowmeter.BUNKER_IN.volumeTotal < minVolumeTotal) {
                  minVolumeTotal = data.datas[timeStamp].flowmeter.BUNKER_IN.volumeTotal;
                }
                break;
              case 'additional':
                avgMassFlowrate = data.datas[timeStamp].flowmeter.BUNKER_IN.additional.AVG_dlfmVolFlowrate_2;
                avgTemp = data.datas[timeStamp].flowmeter.BUNKER_IN.additional.AVG_dlfmTemp_4;
                avgDensity = data.datas[timeStamp].flowmeter.BUNKER_IN.additional.AVG_dlfmDensity_3;
                break;
            }
          });
        }
      });
      summaryData.massTotal = maxMassTotal - minMassTotal;
      summaryData.volumeTotal = maxVolumeTotal - minVolumeTotal;
      summaryData.avgMassFlowrate = avgMassFlowrate;
      summaryData.avgTemp = avgTemp;
      summaryData.avgDensity = avgDensity;
      return summaryData;
    },
  },
};

</script>

<style scoped lang="scss">
.table-logs {
  display: block;
  font-size: 11px;
  overflow-x: auto;
  white-space: nowrap;
}
</style>
