<template>
  <!-- <ApexChart element="report-graph" :chartOption="chartOption"/> -->
  <div>
    <!-- <pre>{{data}}</pre> -->
    <apexchart ref="chartRef" v-if="ready" type="line" :options="chartOptions" :series="series" :height="customHeight"></apexchart>
  </div>
</template>
<script>

import dateUtil from '@/util/date';

function genArrs(value, length) {
  const a = [];
  for (let i = 0; i < length; i++) {
    a.push(value);
  }
  return a;
}

// console.log(genArrs(5, 2));

export default {
  name: 'report-stacked',
  props: {
    data: { type: Object },
    customHeight: { type: Number },
    isDailyReport: {
      type: Boolean,
      default: false,
    },
    customXAxis: {
      type: Array,
    },
  },
  mounted() {
    if (this.data) {
      const chartOptions = this.constructChartData(this.data);
      console.log('stacked data', this.data);
      console.log('series data', this.series);
      console.log('chartOptions data', this.chartOptions);
      console.log('Mounted... ', chartOptions);
      // if (this.data) {
      //   this.chartOptions.labels = Object.keys(this.data.datas);
      // }
      this.ready = true;
      // console.log('Mounted... ', this.$refs.chartRef);
      // this.$refs.chartRef.chart.dataURI().then((uri) => {
      //   console.log('Test urine... ', uri);
      // });
    }
    // const portRpmSeries = [1, 2, 3];
    // const starboardRpmSeries = [1, 2, 3];
    // const portUsageSeries = [1, 2, 3];
    // const starboardUsageSeries = [1, 2, 3];
    // // this.data.PORT.rpm.forEach((d, idx) => {
    // //   portRpmSeries.push(d.rpm);
    // //   if (this.data.STARBOARD.rpm[idx] !== undefined) {
    // //     starboardRpmSeries.push(this.data.STARBOARD.rpm[idx].rpm);
    // //   }
    // //   if (this.data.PORT.fm.IN[idx].volumeFlowrate !== undefined && this.data.PORT.fm.OUT[idx].volumeFlowrate !== undefined) {
    // //     portUsageSeries.push(this.data.PORT.fm.IN[idx].volumeFlowrate - this.data.PORT.fm.OUT[idx].volumeFlowrate);
    // //   }
    // //   if (this.data.STARBOARD.fm.IN[idx].volumeFlowrate !== undefined && this.data.STARBOARD.fm.OUT[idx].volumeFlowrate !== undefined) {
    // //     starboardUsageSeries.push(this.data.STARBOARD.fm.IN[idx].volumeFlowrate - this.data.STARBOARD.fm.OUT[idx].volumeFlowrate);
    // //   }
    // //   this.chartOption.labels.push(`${new Date(d.timestamp * 1000).getHours().toString().padStart(2, '0')}:${new Date(d.timestamp * 1000).getMinutes().toString().padStart(2, '0')}`);
    // // });
    // if (portRpmSeries.length === 0 && starboardRpmSeries.length === 0 && portUsageSeries.length === 0 && starboardUsageSeries.length === 0) {
    //   this.noData = true;
    // }
    // this.chartOption.series.push({
    //   name: 'Port',
    //   type: 'column',
    //   data: portRpmSeries,
    // });
    // this.chartOption.series.push({
    //   name: 'Starboard',
    //   type: 'column',
    //   data: starboardRpmSeries,
    // });
    // this.chartOption.series.push({
    //   name: 'Port Flow',
    //   type: 'line',
    //   data: portUsageSeries,
    // });
    // this.chartOption.series.push({
    //   name: 'Starboard Flow',
    //   type: 'line',
    //   data: starboardUsageSeries,
    // });
  },
  // computed: {
  //   series() {
  //     const series = [];
  //     if (this.data) {
  //     //   Object.keys(this.data.devices).forEach((device) => {
  //     //     // console.log('AAAAAAAA : ', this.data.devices[device]);
  //     //     const speedSeries = {
  //     //       name: 'Speed',
  //     //       type: 'line',
  //     //       data: [],
  //     //     };
  //     //     const portRPMSeries = {
  //     //       name: 'Port RPM',
  //     //       type: 'column',
  //     //       data: [],
  //     //     };
  //     //     const stbRPMSeries = {
  //     //       name: 'Stb RPM',
  //     //       type: 'column',
  //     //       data: [],
  //     //     };
  //     //     const consumptionSeries = {
  //     //       name: 'Consumption',
  //     //       type: 'area',
  //     //       data: [],
  //     //     };
  //     //     switch (this.data.devices[device].devcType) {
  //     //       case 'gps':
  //     //         console.log('Draw GPS Data : ', this.data.devices[device].data);
  //     //         this.data.devices[device].data.forEach((d) => {
  //     //           speedSeries.data.push(d.speed);
  //     //         });
  //     //         series[2] = speedSeries;
  //     //         break;
  //     //       case 'rpm':
  //     //         console.log('Draw RPM Data :', this.data.devices[device].data);
  //     //         this.data.devices[device].data.forEach((d) => {
  //     //           if (d.type === 'PORT') {
  //     //             portRPMSeries.data.push(d.rpm);
  //     //           } else {
  //     //             stbRPMSeries.data.push(d.rpm);
  //     //           }
  //     //         });
  //     //         series[0] = portRPMSeries;
  //     //         series[1] = stbRPMSeries;
  //     //         break;
  //     //       case 'ae':
  //     //         break;
  //     //       case 'flowmeter':
  //     //         console.log('Draw flowmeter Data :', this.data.devices[device]);
  //     //         if (this.data.devices[device].devcLabel === 'STARBOARD_OUT') {
  //     //           this.data.devices[device].data.forEach((d) => {
  //     //             consumptionSeries.data.push(d.massFlowrate);
  //     //           });
  //     //         }
  //     //         series.push(consumptionSeries);
  //     //         break;
  //     //     }
  //     //   });
  //       // console.log(this.data);
  //     }
  //     return series;
  //     // return [
  //     //   {
  //     //     name: 'PORT RPM',
  //     //     type: 'column',
  //     //     data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
  //     //   },
  //     //   {
  //     //     name: 'STB RPM',
  //     //     type: 'column',
  //     //     data: [12, 11, 22, 12, 13, 22, 12, 21, 12, 22, 30],
  //     //   },
  //     //   {
  //     //     name: 'Fuel Cons',
  //     //     type: 'area',
  //     //     data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
  //     //   },
  //     //   {
  //     //     name: 'Speed',
  //     //     type: 'line',
  //     //     data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
  //     //   },
  //     // ];
  //   },
  // },
  methods: {
    appendChart(chart) {
      // console.log(chart);
      switch (chart.chartType) {
        case 'column':
          if (this.chartOptions.chart.stacked && this.chartOptions.yaxis.length === 0) {
            this.chartOptions.yaxis.push({
              labels: {
                show: true,
                formatter: (value) => value.toFixed(),
              },
              title: {
                text: 'Liters',
              },
            });
          }
          // console.log('TS : ', data.datas[timeStamp][deviceType]);
          // this.series[3].data.push(data.datas[timeStamp][deviceType].speed);

          break;
        default:
          this.chartOptions.yaxis.push({
            labels: {
              show: false,
            },
          });
      }
      this.series.push({
        name: chart.title,
        type: chart.chartType,
        data: chart.data,
      });
      // this.series.push({
      //   name: 'Line',
      //   type: 'line',
      //   data: [{
      //     x: 1,
      //     y: 100,
      //   }, {
      //     x: 2,
      //     y: 100,
      //   }],
      // });
    },
    constructChartData(data) {
      console.log('Data to construct : ', data);
      let dataLength = 0;
      let dataWithValue = 0;
      let totalConsumption = 0;
      const chartsToAppend = {};
      const summary = {
        max: 0,
        min: 100000000,
        avg: 0,
      };
      Object.keys(data.datas).forEach((timeStamp) => {
        dataLength++;
        if (this.isDailyReport) {
          this.chartOptions.labels.push(++this.count);
        } else {
          this.chartOptions.labels.push(timeStamp);
        }
        Object.keys(data.datas[timeStamp]).forEach((deviceType) => {
          // let totalAERunningSeconds = 0;
          switch (deviceType) {
            case 'gps':
              // console.log('TS : ', data.datas[timeStamp][deviceType]);

              // this.series[3].data.push(data.datas[timeStamp][deviceType].speed);

              break;
            case 'rpm':
              // console.log('TS : ', data.datas[timeStamp][deviceType]);

              // if (data.datas[timeStamp][deviceType].PORT) {
              //   this.series[0].data.push(data.datas[timeStamp][deviceType].PORT.rpm);
              // }
              // if (data.datas[timeStamp][deviceType].STARBOARD) {
              //   this.series[1].data.push(data.datas[timeStamp][deviceType].STARBOARD.rpm);
              // }

              break;
            case 'flowmeter':
              // console.log('AAAAAAAAA --------> ', chartsToAppend[deviceType]);
              // if (!summary.min) summary.min = data.datas[timeStamp][deviceType].portFuelCons;
              if (data.datas[timeStamp][deviceType].portFuelCons > summary.max) {
                summary.max = data.datas[timeStamp][deviceType].portFuelCons;
              }
              if (data.datas[timeStamp][deviceType].stbFuelCons > summary.max) {
                summary.max = data.datas[timeStamp][deviceType].stbFuelCons;
              }
              if (data.datas[timeStamp][deviceType].portFuelCons < summary.min) {
                summary.min = data.datas[timeStamp][deviceType].portFuelCons;
              }
              if (data.datas[timeStamp][deviceType].stbFuelCons < summary.min) {
                summary.min = data.datas[timeStamp][deviceType].stbFuelCons;
              }
              if (!chartsToAppend[`PORT_${deviceType}`]) {
                chartsToAppend[`PORT_${deviceType}`] = {
                  chartType: 'column',
                  title: 'Port Fuel Consumption',
                  data: [],
                };
              }
              chartsToAppend[`PORT_${deviceType}`].data.push(data.datas[timeStamp][deviceType].portFuelCons);
              if (data.datas[timeStamp][deviceType].portFuelCons > 0) {
                dataWithValue++;
                totalConsumption += data.datas[timeStamp][deviceType].portFuelCons;
              }

              if (!chartsToAppend[`STB_${deviceType}`]) {
                chartsToAppend[`STB_${deviceType}`] = {
                  chartType: 'column',
                  title: 'Starboard Fuel Consumption',
                  data: [],
                };
              }
              chartsToAppend[`STB_${deviceType}`].data.push(data.datas[timeStamp][deviceType].stbFuelCons);
              if (data.datas[timeStamp][deviceType].stbFuelCons > 0) {
                dataWithValue++;
                totalConsumption += data.datas[timeStamp][deviceType].stbFuelCons;
              }
              // this.series[2].data.push(data.datas[timeStamp][deviceType].meFuelCons);

              // console.log('TS : ', data.datas[timeStamp][deviceType]);
              console.log(data.datas[timeStamp][deviceType].portFuelCons, data.datas[timeStamp][deviceType].stbFuelCons, data.datas[timeStamp][deviceType]);
              break;
            case 'ae':

              // Object.keys(data.datas[timeStamp][deviceType]).forEach((ae) => {
              //   totalAERunningSeconds += data.datas[timeStamp][deviceType][ae].runningTime;
              //   // console.log(data.datas[timeStamp][deviceType][ae]);
              // });
              // this.series[4].data.push(totalAERunningSeconds / 3600);

              // console.log('TS : ', data.datas[timeStamp][deviceType], totalAERunningSeconds);
              break;
          }
        });
        summary.avg = totalConsumption / dataWithValue;
      });
      Object.keys(chartsToAppend).forEach((chart) => {
        this.appendChart(chartsToAppend[chart]);
      });
      this.series.push({
        name: 'Average',
        type: 'line',
        data: genArrs(summary.avg, dataLength),
      });
      this.series.push({
        name: 'Max',
        type: 'line',
        data: genArrs(summary.max, dataLength),
      });
      if (summary.min > 0) {
        this.series.push({
          name: 'Min',
          type: 'line',
          data: genArrs(summary.min, dataLength),
        });
      }
      // console.log('Final chart to append : ', genArrs, dataLength, summary);
      return {};
    },
  },
  data() {
    return {
      count: 0,
      ready: false,
      svg: undefined,
      png: undefined,
      series: [
        // {
        //   name: 'PORT RPM',
        //   type: 'column',
        //   data: [],
        // },
        // {
        //   name: 'STB RPM',
        //   type: 'column',
        //   data: [],
        // },
        // {
        //   name: 'Fuel Cons',
        //   type: 'area',
        //   data: [],
        // },
        // {
        //   name: 'Speed',
        //   type: 'line',
        //   data: [],
        // },
        // {
        //   name: 'AE Running Time',
        //   type: 'line',
        //   data: [],
        // },
      ],

      chartOptions: {
        // colors: ['#0084ff', '#00ca00', '#ffca00', '#FF8C00'],
        chart: {
          animations: {
            enabled: !this.isDailyReport,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: !this.isDailyReport,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: !this.isDailyReport,
              speed: 350,
            },
          },
          // height: 350,
          type: 'line',
          // stacked: true,
          events: {
            zoomed: (chartContext, { xaxis }) => {
              console.log('Chart zoomed... ', xaxis);
              this.$emit('onZoomed', xaxis);
            },
            scrolled: (chartContext, { xaxis }) => {
              console.log('Chart scrolled... ', xaxis);
              this.$emit('onScrolled', xaxis);
            },
            // mounted: (chartContext) => {
            //   this.svg = chartContext.paper().svg();
            //   // console.log('This is SVG : ', this.svg);
            //   // console.log('Mantedd... ', this.$refs.chartRef.chart);
            //   chartContext.dataURI().then(({ imgURI }) => {
            //     this.png = imgURI;
            //     // console.log('This is image URI : ', imgURI, blob);
            //     // pdf.addImage(imgURI, 'PNG', 0, 0);
            //   });
            // },
            animationEnd: (chartContext) => {
              this.svg = chartContext.paper().svg();
              // console.log('This is SVG : ', this.svg);
              // console.log('Mantedd... ', this.$refs.chartRef.chart);
              chartContext.dataURI().then(({ imgURI }) => {
                this.png = imgURI;
                // console.log('This is image URI : ', imgURI, blob);
                // pdf.addImage(imgURI, 'PNG', 0, 0);
              });
            },
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: true,
              reset: false,
              customIcons: [],
            },
            // export: {
            //   csv: {
            //     filename: undefined,
            //     columnDelimiter: ',',
            //     headerCategory: 'category',
            //     headerValue: 'value',
            //     dateFormatter(timestamp) {
            //       return new Date(timestamp).toDateString()
            //     }
            //   }
            // },
            // autoSelected: 'zoom'
          },
        },
        stroke: {
          width: [1, 1, 3, 3, 3],
          curve: 'smooth',
        },
        // plotOptions: {
        //   bar: {
        //     columnWidth: '80%',
        //   },
        // },
        fill: {
          opacity: [0.75, 0.75, 1, 0.85, 1],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: 'vertical',
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        labels: [], // dynamic
        markers: {
          size: 0,
        },
        xaxis: {
          categories: this.customXAxis || [],
          labels: {
            show: true,
            formatter: (value) => {
              if (value) {
                if (this.isDailyReport) {
                  return value;
                }
                return dateUtil.toLocalDate(value);
              }
            },
          },
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#008FFB',
            },
            labels: {
              style: {
                colors: '#008FFB',
              },
              formatter: (value) => value.toFixed(),
            },
            title: {
              text: 'L/h',
              style: {
                color: '#008FFB',
              },
            },
            // max: 2000,
            min: 0,
            // forceNiceScale: false,
            tooltip: {
              enabled: true,
            },
          },

          // {
          //   labels: {
          //     show: false,
          //   },
          // },
          // {
          //   labels: {
          //     show: true,
          //     formatter: (value) => value.toFixed(),
          //   },
          //   title: {
          //     text: 'Liters',
          //   },
          // },

          // {
          //   opposite: true,
          //   labels: {
          //     show: true,
          //     formatter: (value) => value.toFixed(),
          //   },
          //   title: {
          //     text: 'Knot',
          //   },
          // },
        ],
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: (y) => {
              if (typeof y !== 'undefined') {
                return y.toFixed(0);
              }
              return y;
            },
          },
        },
      },
    };
  },
};
</script>
