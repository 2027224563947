<script>
import { Radar } from 'vue-chartjs';

export default {
  name: 'Radar',
  extends: Radar,
  props: ['type', 'canId', 'data'],
  data: () => ({
    chartdata: {
      labels: ['First', 'Second', 'Third', 'Fourth'],
      datasets: [{
        label: 'January',
        borderColor: '#4e73df',
        pointBackgroundColor: '#4e73df',
        data: [60, 30, 40, 50],
      }, {
        label: 'February',
        borderColor: '#4e7',
        pointBackgroundColor: '#4e7',
        data: [30, 50, 50, 80],
      }],
    },
    options: {
      scale: {
        angleLines: {
          display: false,
        },
        ticks: {
          suggestedMin: 20,
          suggestedMax: 40,
        },
      },
    },
  }),
  mounted() {
    this.renderChart(this.chartdata, this.options);
  },
};
</script>

<style scoped>

</style>
