<template>
  <div class='map-control'>
    <div>
      <div v-if="!hideMapSetting" class='border-b border-color-bright-gray'>
        <button class='map-control-item' @click.stop='openMapSettings'>
          <i class='ri-settings-5-line fs-3 text-choarcoal-800' style="font-size: 20px;" />
        </button>
      </div>
      <div class='border-b border-color-bright-gray'>
        <button class='map-control-item' @click.stop='openSummary'>
          <i class='ri-table-line fs-3 text-choarcoal-800' style="font-size: 20px;" />
        </button>
      </div>
      <div class='border-b-5 border-color-bright-gray'>
        <button class='map-control-item' @click.stop='openMapStyles'>
          <i class='ri-stack-line fs-3 text-choarcoal-800' style="font-size: 20px;" />
        </button>
      </div>
    </div>
    <div>
      <div class='border-b border-color-bright-gray'>
        <button class='map-control-item' @click.stop='mapZoomIn'>
          <i class='ri-add-line fs-3 text-choarcoal-800' style="font-size: 20px;" />
        </button>
      </div>
      <div>
        <button class='map-control-item' @click.stop='mapZoomOut'>
          <i class='ri-subtract-line fs-3 text-choarcoal-800' style="font-size: 20px;" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'map-control',
  props: {
    hideMapSetting: { type: Boolean, default: false },
  },
  methods: {
    openMapSettings() {
      this.$emit('openMapSettings');
    },
    openSummary() {
      this.$emit('openSummary');
    },
    openMapStyles() {
      this.$emit('openMapStyles');
    },
    mapZoomIn() {
      this.$emit('zoomIn');
    },
    mapZoomOut() {
      this.$emit('zoomOut');
    },
  },
};
</script>

<style scoped>
.map-control {
  position: absolute;
  top: 20px;
  right: 15px;
  z-index: 10000;
  background-color: white;
  border-radius: 8px;
}

.map-control-item {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
}
</style>
