<template>
  <b-container fluid>
    <div class="iq-card mt-3">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="card-title">Asset List</h4>
        </div>
        <b-row>
          <b-col md="12">
            <div class="text-right">
              <button-options
                :access="'master_asset'"
                :hideAdd="false"
                :hideDetail="true"
                :isRefreshEnabled="true"
                :isRefreshed="isLoading"
                @onAddItemClicked="openAddForm"
                @onRefreshed="getData('Refreshed')"/>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="iq-card-body">
        <b-row class="mb-3">
          <b-col md="3">
            <input-selector
              :data="[
                {value: '0', text: 'Kapal'},
                {value: '1', text: 'Mobil'},
                ]"
              :id="'value'"
              :label="'text'"
              :loading="isLoading"
              :placeholder="'Please select asset type'"
              @onChangeSelector="onChangeAssetSelector"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <asset-list
              @onActionDelete="openDelete"
              @onActionEdit="openEditForm"
              @onActionView="openDetail"
              @onActionAnalytics="openAnalytics"
              @onPaginationChange="onPaginationChange"
              :access="'master_asset'"
              :data="data"
              :isLoading="isLoading"
              :pagination="pagination" />
          </b-col>
        </b-row>
      </div>
      <b-modal
        size="lg"
        hide-footer
        no-close-on-esc
        no-close-on-backdrop
        v-model="displayForm">
        <asset-form
          :data="currentAsset"
          @onSuccess="onAddOrUpdateSuccess"
          @onCancel="onCancel" />
      </b-modal>
      <b-modal
        size="lg"
        hide-footer
        no-close-on-esc
        no-close-on-backdrop
        v-model="displayDeletion">
        <DeleteItem
          :isSubmit="isSubmit"
          @onCancelDeletion="displayDeletion = false"
          @onConfirmDeletion="onConfirmDeletion"/>
      </b-modal>
    </div>
  </b-container>
</template>

<script>
import AssetForm from '@/components/asset/Form.vue';
import AssetList from '@/components/asset/List.vue';
import AssetSvc from '@/services/AssetSvc';
import ButtonOptions from '@/components/directives/button-options';
import DeleteItem from '@/components/directives/delete-item';
import InputSelector from '@/components/directives/input-selector';

import requestBuilder from '@/util/request.builder';

export default {
  name: 'Asset',
  data() {
    return {
      currentAsset: {},
      data: [],
      displayForm: false,
      displayDeletion: false,
      filter: {
        massType: null,
      },
      isLoading: false,
      isSubmit: false,
      pagination: {
        current: 1,
        pageSize: 10,
        totalSize: 0,
      },
    };
  },
  components: {
    DeleteItem,
    InputSelector,
    ButtonOptions,
    AssetForm,
    AssetList,
  },
  methods: {
    async deleteData(notification, massId) {
      this.isSubmit = true;
      try {
        const resp = await AssetSvc.deleteAsset(massId);
        if (resp.data.status.error === 0) {
          await this.$toast.success(notification);
          this.displayDeletion = await false;
          this.pagination.current = 1;
          this.getData();
        } else {
          this.$toast.error(resp.data.status.message);
        }
      } catch (e) {
        this.$toast.error(e.message);
      } finally {
        this.isSubmit = false;
      }
    },
    async getData() {
      this.isLoading = true;
      try {
        const resp = await AssetSvc.getAssets({
          params: {
            ...requestBuilder.buildFilters(this.pagination, this.filter),
          },
        });
        this.data = resp.data.data;
        this.pagination.totalSize = resp.data.pagination.totalData;
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    onAddOrUpdateSuccess() {
      this.getData();
      this.displayForm = false;
    },
    onCancel() {
      this.currentAsset = {};
      this.displayForm = false;
    },
    onConfirmDeletion() {
      this.deleteData('Deleted complete', this.selected);
    },
    onPaginationChange(page) {
      this.pagination.current = page;
      this.getData();
    },
    openAddForm() {
      this.currentAsset = {};
      this.displayForm = true;
    },
    openAnalytics(massId) {
      this.$router.push({ name: 'AssetAnalytics', params: { id: massId } });
    },
    openDelete(data) {
      this.displayDeletion = true;
      this.selected = data;
    },
    openDetail(massId) {
      this.$router.push({ name: 'AssetDetail', params: { id: massId } });
    },
    openEditForm(massId) {
      this.currentAsset = { ...this._.find(this.data, (v) => v.massId === massId) };
      this.displayForm = true;
      console.log(this.currentAsset);
    },
    onChangeAssetSelector(v) {
      this.filter.massType = v ? v.value : null;
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
