<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="mt-3">
    <!-- <pre>{{data.summaries}}</pre> -->
    <b-table-simple id="summaryTable" hover small responsive>
      <b-thead head-variant="dark">
        <b-tr>
          <b-th>Description</b-th>
          <b-th>Value</b-th>
          <b-th>Unit</b-th>
          <b-th>Description</b-th>
          <b-th>Value</b-th>
          <b-th>Unit</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <template v-if="data.summaries.rpm">
            <b-th>Port ME Engine Speed</b-th>
            <b-td>{{(data.summaries.rpm.average.PORT).toFixed(2)}}</b-td>
            <b-td>RPM</b-td>
          </template>
          <template v-if="data.summaries.flowmeter">
            <b-th>Port ME Total Fuel Used</b-th>
            <b-td v-if="data.summaries.flowmeter.portEngineCons">{{(data.summaries.flowmeter.portEngineCons).toFixed(2)}}</b-td>
            <b-td v-else>0</b-td>
            <b-td>Liter</b-td>
          </template>
        </b-tr>
        <b-tr>
          <template v-if="data.summaries.rpm">
            <b-th>Stb ME Engine Speed</b-th>
            <b-td>{{(data.summaries.rpm.average.STARBOARD).toFixed(2)}}</b-td>
            <b-td>RPM</b-td>
          </template>
          <template v-if="data.summaries.flowmeter">
            <b-th>Stb ME Total Fuel Used</b-th>
            <b-td v-if="data.summaries.flowmeter.stbEngineCons">{{(data.summaries.flowmeter.stbEngineCons).toFixed(2)}}</b-td>
            <b-td v-else>0</b-td>
            <b-td>Liter</b-td>
          </template>
        </b-tr>
        <b-tr>
          <template v-if="data.summaries.rpm">
            <b-th>Port ME Running Hour</b-th>
            <b-td>{{ data.summaries.rpm.runningTime.PORT | toHHMMSS }}</b-td>
            <b-td>Hours</b-td>
          </template>
          <template v-if="data.summaries.flowmeter">
            <b-td colspan="3"></b-td>
          </template>
        </b-tr>
        <b-tr>
          <template v-if="data.summaries.rpm">
            <b-th>Stb ME Running Hour</b-th>
            <b-td>{{data.summaries.rpm.runningTime.STARBOARD | toHHMMSS }}</b-td>
            <b-td>Hours</b-td>
          </template>
          <template v-if="data.summaries.flowmeter">
            <b-th variant="secondary">ME Total Fuel Used</b-th>
            <b-td variant="secondary">{{sumArray([data.summaries.flowmeter.portEngineCons, data.summaries.flowmeter.stbEngineCons]).toFixed(2)}}</b-td>
            <b-td variant="secondary">Liter</b-td>
          </template>
        </b-tr>
        <template v-if="data.summaries.ae && data.summaries.flowmeter && (data.summaries.flowmeter.AE_IN || data.summaries.flowmeter.AE_OUT)">
          <b-tr v-if="data.summaries.ae.fuelConsumption.AE1 || typeof data.summaries.ae.fuelConsumption.AE1 === 'number'">
            <b-th>Port AE Running Hour</b-th>
            <b-td>{{data.summaries.ae.runningSeconds.AE1 | toHHMMSS}}</b-td>
            <b-td>Hours</b-td>
          </b-tr>
          <b-tr v-if="data.summaries.ae.fuelConsumption.AE2 || typeof data.summaries.ae.fuelConsumption.AE2 === 'number'">
            <b-th>Ctr AE Running Hour</b-th>
            <b-td>{{data.summaries.ae.runningSeconds.AE2 | toHHMMSS}}</b-td>
            <b-td>Hours</b-td>
          </b-tr>
          <b-tr v-if="data.summaries.ae.fuelConsumption.AE3 || typeof data.summaries.ae.fuelConsumption.AE3 === 'number'">
            <b-th>Stb AE Running Hour</b-th>
            <b-td>{{data.summaries.ae.runningSeconds.AE3 | toHHMMSS}}</b-td>
            <b-td>Hours</b-td>
          </b-tr>
          <b-tr v-if="(data.summaries.ae.fuelConsumption.AE1||typeof data.summaries.ae.fuelConsumption.AE1 === 'number')&&(data.summaries.ae.fuelConsumption.AE2||typeof data.summaries.ae.fuelConsumption.AE2 === 'number')&&(data.summaries.ae.fuelConsumption.AE3||typeof data.summaries.ae.fuelConsumption.AE3 === 'number')">
            <b-th colspan="3"></b-th>
            <b-th variant="secondary">AE Total Fuel Used</b-th>
            <b-td variant="secondary">{{(data.summaries.flowmeter.aeEngineCons).toFixed(2)}}</b-td>
            <b-td variant="secondary">Liter</b-td>
          </b-tr>
        </template>
        <template v-if="(data.summaries.ae && !data.summaries.flowmeter) || (data.summaries.ae && data.summaries.flowmeter && (!data.summaries.flowmeter.AE_IN || !data.summaries.flowmeter.AE_OUT))">
          <b-tr v-if="data.summaries.ae.fuelConsumption.AE1 || typeof data.summaries.ae.fuelConsumption.AE1 === 'number'">
            <b-th>Port AE Running Hour</b-th>
            <b-td>{{data.summaries.ae.runningSeconds.AE1 | toHHMMSS}}</b-td>
            <b-td>Hours</b-td>
            <b-th>Port AE Total Fuel Used</b-th>
            <b-td>{{(data.summaries.ae.fuelConsumption.AE1).toFixed(2)}}</b-td>
            <b-td>Liter</b-td>
          </b-tr>
          <b-tr v-if="data.summaries.ae.fuelConsumption.AE2 || typeof data.summaries.ae.fuelConsumption.AE2 === 'number'">
            <b-th>Ctr AE Running Hour</b-th>
            <b-td>{{data.summaries.ae.runningSeconds.AE2 | toHHMMSS}}</b-td>
            <b-td>Hours</b-td>
            <b-th>Ctr AE Total Fuel Used</b-th>
            <b-td>{{(data.summaries.ae.fuelConsumption.AE2).toFixed(2)}}</b-td>
            <b-td>Liter</b-td>
          </b-tr>
          <b-tr v-if="data.summaries.ae.fuelConsumption.AE3 || typeof data.summaries.ae.fuelConsumption.AE3 === 'number'">
            <b-th>Stb AE Running Hour</b-th>
            <b-td>{{data.summaries.ae.runningSeconds.AE3 | toHHMMSS}}</b-td>
            <b-td>Hours</b-td>
            <b-th>Stb AE Total Fuel Used</b-th>
            <b-td>{{(data.summaries.ae.fuelConsumption.AE3).toFixed(2)}}</b-td>
            <b-td>Liter</b-td>
          </b-tr>
          <b-tr v-if="(data.summaries.ae.fuelConsumption.AE1||typeof data.summaries.ae.fuelConsumption.AE1 === 'number')&&(data.summaries.ae.fuelConsumption.AE2||typeof data.summaries.ae.fuelConsumption.AE2 === 'number')&&(data.summaries.ae.fuelConsumption.AE3||typeof data.summaries.ae.fuelConsumption.AE3 === 'number')">
            <b-th colspan="3"></b-th>
            <b-th variant="secondary">AE Total Fuel Used</b-th>
            <b-td variant="secondary">{{(data.summaries.ae.fuelConsumption.AE1 + data.summaries.ae.fuelConsumption.AE2 + data.summaries.ae.fuelConsumption.AE3).toFixed(2)}}</b-td>
            <b-td variant="secondary">Liter</b-td>
          </b-tr>
        </template>
      </b-tbody>
      <b-tfoot v-if="data.summaries.rpm||data.summaries.flowmeter||data.summaries.ae">
        <b-tr>
          <b-th colspan="3" variant="primary"></b-th>
          <b-th variant="primary">Total Fuel Used</b-th>
          <b-th variant="primary" v-if="data.summaries.flowmeter&&data.summaries.ae&&(!data.summaries.flowmeter.AE_IN || !data.summaries.flowmeter.AE_OUT)">{{sumArray([data.summaries.flowmeter.portEngineCons, data.summaries.flowmeter.stbEngineCons, data.summaries.ae.fuelConsumption.AE1, data.summaries.ae.fuelConsumption.AE2, data.summaries.ae.fuelConsumption.AE3]).toFixed(2)}}</b-th>
          <b-th variant="primary" v-else-if="data.summaries.flowmeter&&!data.summaries.ae&&(!data.summaries.flowmeter.AE_IN || !data.summaries.flowmeter.AE_OUT)">{{sumArray([data.summaries.flowmeter.portEngineCons, data.summaries.flowmeter.stbEngineCons]).toFixed(2)}}</b-th>
          <b-th variant="primary" v-else-if="data.summaries.ae&&!data.summaries.flowmeter">{{sumArray([data.summaries.ae.fuelConsumption.AE1, data.summaries.ae.fuelConsumption.AE2, data.summaries.ae.fuelConsumption.AE3]).toFixed(2)}}</b-th>
          <b-th variant="primary" v-else-if="data.summaries.flowmeter&&(data.summaries.flowmeter.AE_IN || data.summaries.flowmeter.AE_OUT)">{{sumArray([data.summaries.flowmeter.portEngineCons, data.summaries.flowmeter.stbEngineCons, data.summaries.flowmeter.aeEngineCons]).toFixed(2)}}</b-th>
          <b-th variant="primary">Liter</b-th>
        </b-tr>
      </b-tfoot>
      <b-tfoot v-else>
        <b-th colspan="6" variant="primary" class="text-center">No Data</b-th>
      </b-tfoot>
    </b-table-simple>
    <div class="row mb-3" v-if="data.summaries.gps">
      <div class="col-md-4">
        <h6 class="card-title text-uppercase text-secondary mb-0">Total Cruise</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <b-form inline>
            <span v-if="cruiseUnit === 'km'">{{data.summaries.gps.totalDistance | toKM}}</span>
            <span v-if="cruiseUnit === 'mile'">{{data.summaries.gps.totalDistance | toMiles}}</span>
            <span v-if="cruiseUnit === 'nmi'">{{data.summaries.gps.totalDistance | toNM}}</span>
            <b-form-select v-model="cruiseUnit" class="ml-2">
              <b-form-select-option value="km">KM</b-form-select-option>
              <b-form-select-option value="nmi">NM</b-form-select-option>
              <b-form-select-option value="mile">Miles</b-form-select-option>
            </b-form-select>
          </b-form>
        </span>
      </div>
      <div class="col-md-4">
        <h6 class="card-title text-uppercase text-secondary mb-0">Average Speed</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <b-form inline>
            <span v-if="speedUnit === 'knot'">{{data.summaries.gps.averageSpeed.toFixed(2) | toKnot}}</span>
            <span v-else>{{(data.summaries.gps.averageSpeed).toFixed(2)}}</span>
            <b-form-select v-model="speedUnit" class="ml-2">
              <b-form-select-option value="knot">Knot</b-form-select-option>
              <b-form-select-option value="kmh">KM/h</b-form-select-option>
            </b-form-select>
          </b-form>
        </span>
      </div>
      <div class="col-md-4">
        <h6 class="card-title text-uppercase text-secondary mb-0">Distance</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <span v-if="cruiseUnit === 'km'">{{data.summaries.gps.distance | toKM}} KM</span>
          <span v-if="cruiseUnit === 'nmi'">{{data.summaries.gps.distance | toNM}} NM</span>
          <span v-if="cruiseUnit === 'mile'">{{data.summaries.gps.distance | toMiles}} Miles</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ReportSummaryView',
  props: ['data'],
  data() {
    return {
      cruiseUnit: 'km',
      flowUnit: 'lph',
      speedUnit: 'knot',
    };
  },
  mounted() {
    console.log('this.data>>>>', this.data);
    if (this.data.summaries.ae.fuelConsumption.AE1 || typeof this.data.summaries.ae.fuelConsumption.AE1 === 'number') {
      console.log('jakuz');
    } else {
      console.log('nokuz');
    }
  },
  methods: {
    sumArray(array) {
      // console.log('Ayeee....', array);
      let out = 0;
      array.forEach((a) => {
        if (a !== undefined) {
          out += a;
        }
      });
      return out;
    },
  },
};
</script>

<style scoped lang="scss">
.table-logs {
  display: block;
  font-size: 11px;
  overflow-x: auto;
  white-space: nowrap;
}
</style>
