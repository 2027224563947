<template>
  <div class="content-body">
    <label class="text-left">Select group</label>
    <div class="group-list-item">

      <div v-if="!groups || groups.length === 0" class="company-list-item">
        <div class="d-flex w-100 justify-content-between">
          <h5>
            No Group available.
          </h5>
        </div>
      </div>

      <b-list-group
        v-else
        v-for="(group, i) in groups"
        :disabled="loading"
        :key="i">
        <b-list-group-item
          href="#"
          class="flex-column align-items-start mb-1"
          :active="selected && selected.grcpId === group.grcpId"
          @click="setSelectedGroup(group)">
          <div
            :name="group.grcpId"
            class="d-flex w-100 justify-content-between">
            <h5 :class="selected && selected.grcpId === group.grcpId ? 'selected-preference-item' : ''">
              {{group.grcpName}}
            </h5>
            <i class="ri-arrow-right-s-line"></i>
          </div>
        </b-list-group-item>
      </b-list-group>

    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupSelection',
  props: {
    groups: Array,
    loading: Boolean,
    selected: Object,
  },
  methods: {
    setSelectedGroup(v) {
      this.$emit('onSelected', v);
    },
  },
};
</script>

<style scoped>
  .group-list-item {
    height: 250px;
    overflow-y: auto;
  }
</style>
