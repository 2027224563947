<template>
  <b-container fluid>
    <SpeedMeter :dataEngine="data"/>
  </b-container>
</template>

<script>
import SpeedMeter from './analog_gauge/SpeedMeter';

export default {
  name: 'WidgetsIndex',
  components: { SpeedMeter },
  props: ['data'],
};
</script>

<style scoped>

</style>
