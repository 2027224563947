import axios from 'axios';

export default {

  getUsers(config) {
    return axios.get('/master/user', config);
  },
  create(data) {
    return axios.post('/master/user', data);
  },
  update(id, data) {
    return axios.put(`/master/user/${id}`, data);
  },
  delete(id) {
    return axios.delete(`/master/user/${id}`);
  },
  addUserCompany(data) {
    return axios.post('/master/user_company', data);
  },
  getUserCompany(config) {
    return axios.get('/master/user_company', config);
  },
  deleteUserCompany(id) {
    return axios.delete(`/master/user_company/${id}`);
  },
  getUserRoles(param) {
    return axios.get('/master/user_role', { params: { page: param } });
  },
  getUserRoleById(param) {
    return axios.get(`/master/user_role?findField=${param.findField}&findValue=${param.findValue}`);
  },
  deleteUserRoleById(id) {
    return axios.delete(`/master/user_role/${id}`);
  },
  addUserRole(data) {
    return axios.post('/master/user_role', data);
  },
  updateUserRole(data, id) {
    return axios.put(`/master/user_role/${id}`, data);
  },
  deleteUserRole(id) {
    return axios.delete(`/master/user_role/${id}`);
  },
  getRoles() {
    return axios.get('/master/roles');
  },
  getRoleByName(data) {
    return axios.get('/master/roles', { params: data });
  },
  addRole(data) {
    return axios.post('/master/roles', data);
  },
  updateRole(data, id) {
    return axios.put(`/master/roles/${id}`, data);
  },
  deleteRole(id) {
    return axios.delete(`/master/roles/${id}`);
  },
  getRolePermissions(param) {
    return axios.get('/master/role_permission', { params: { page: param } });
  },
  getRolePermissionByRoleId(param) {
    return axios.get('/master/role_permission', { params: param });
  },
  addRolePermission(data) {
    return axios.post('/master/role_permission', data);
  },
  updateRolePermission(data, id) {
    return axios.put(`/master/role_permission/${id}`, data);
  },
  deleteRolePermission(id) {
    return axios.delete(`/master/role_permission/${id}`);
  },
  getPermissions(param) {
    return axios.get('/master/permission', { params: { page: param, max: 100 } });
  },
  getPermissionsByName(param) {
    return axios.get(`/master/permission?findField=${param.findField}&findValue=${param.findValue}`);
  },
  addPermission(data) {
    return axios.post('/master/permission', data);
  },
  updatePermission(data, id) {
    return axios.put(`/master/permission/${id}`, data);
  },
  deletePermission(id) {
    return axios.delete(`/master/permission/${id}`);
  },
  getUserRolePermissions(param) {
    return axios.get('/master/role_permission', { params: param });
  },

};
