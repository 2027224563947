<script>
import { Bar } from 'vue-chartjs';

export default {
  name: 'Bar',
  extends: Bar,
  props: ['type', 'canId', 'data'],
  data() {
    return {
      chartdata: {
        labels: ['January'],
        datasets: [
          {
            label: 'First',
            backgroundColor: '#4e73df',
            data: [30, 20],
          },
          {
            label: 'Second',
            backgroundColor: '#4e7',
            data: [50, 20],
          },
          {
            label: 'Third',
            backgroundColor: '#f87979',
            data: [50, 20],
          },
          {
            label: 'Fourth',
            backgroundColor: 'rgba(238, 238, 0, 1)',
            data: [40, 20],
          },
        ],
      },
      options: {},
    };
  },
  mounted() {
    this.renderChart(this.chartdata, this.options);
  },
};
</script>

<style scoped>

</style>
