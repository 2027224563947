<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <b-container fluid>
    <div class="iq-card mt-3">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="card-title">Widget</h4>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="text-right">
              <button-options
                :access="canRead('master_device')"
                :hideDetail="true"
                :isBackEnabled="true"
                :isRefreshEnabled="true"
                :refreshed="isLoading"
                @onRefreshed="load('Refreshed')"/>
            </div>
          </div>
        </div>
      </div>
      <div class="iq-card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="mb-3" v-if="dateParam">
              <date-picker
                :dateParam="dateParam"
                :isInline="true"
                :isLoading="isLoading"
                :enableInterval="true"
                @onSubmitRange="onSubmitRange"/>
            </div>
          </div>
        </div>
        <div class="row" v-if="isLoading">
          <div class="col-md-12">
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle mr-3"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- <pre>{{related}}</pre> -->
          <div
            class="col-md-12"
            v-if="related.devcType !== 'gps'">
            <CanvasDirectives
              :canvasId="`${form.devcType}-${form.devcId}`"
              :config="true"
              :data="form.devcUniqueId"
              :device="form"
              :devType="related.devcType"
              :hidden="isLoading || isRefreshed"
              @collectedData="onCollectData"
              @dateRange="dateRange"
              @fieldsData="fieldsData"/>
          </div>
          <div
            class="col-md-12"
            v-if="related.devcType === 'gps'">
            <Map
              v-if="!isLoading"
              :center="mapCenter"
              :data="assetObject"
              :hidden="isRefreshed"
              style="position: sticky"/>
          </div>
          <div class="col-md-12" v-if="related.devcType === null || typeof related.devcType === 'undefined'">
            <div v-if="related.devcType === null" class="text-center">No widget available</div>
          </div>
        </div>
        <div class="row mt-3" v-if="related.devcType === 'gps'">
          <div class="col-md-4">
            <h6 class="card-title text-uppercase text-secondary mb-0">Total Cruise</h6>
            <span class="h2 text-dark mb-0 counter d-inline-block w-100">
              <span>{{gpsSummary.totalCruise | toKM}} KM</span>
            </span>
          </div>
          <div class="col-md-4">
            <h6 class="card-title text-uppercase text-secondary mb-0">Average Speed</h6>
            <span class="h2 text-dark mb-0 counter d-inline-block w-100">
              <span>{{gpsSummary.averageSpeed | toKnot}} Knot</span>
            </span>
          </div>
          <div class="col-md-4">
            <h6 class="card-title text-uppercase text-secondary mb-0">Distance</h6>
            <span class="h2 text-dark mb-0 counter d-inline-block w-100">
              <span>{{gpsSummary.distance | toKM}} KM</span>
            </span>
          </div>
        </div>
        <div class="row mt-3" v-if="related.devcType === 'flowmeter'">
          <div class="col-md-4">
            <h6 class="card-title text-uppercase text-secondary mb-0">Flowing Time</h6>
            <span class="h2 text-dark mb-0 counter d-inline-block w-100">
              <span>{{flowmeterSummary.flowingTime | toHHMMSS}}</span>
            </span>
          </div>
          <div class="col-md-4">
            <h6 class="card-title text-uppercase text-secondary mb-0">Average Flow Rate</h6>
            <span class="h2 text-dark mb-0 counter d-inline-block w-100">
              <span>{{(flowmeterSummary.averageFlow).toFixed(2)}} L/h</span>
            </span>
          </div>
          <div class="col-md-4">
            <h6 class="card-title text-uppercase text-secondary mb-0">Total Flow</h6>
            <span class="h2 text-dark mb-0 counter d-inline-block w-100">
              <span>{{(flowmeterSummary.totalFlow).toFixed(2)}} Liters</span>
            </span>
          </div>
        </div>
        <div class="row mt-3" v-if="related.devcType === 'ae'">
          <div class="col-md-4" v-for="(ae, index) in aesTotal" :key="index">
            <h6 class="card-title text-uppercase text-secondary mb-0">AE {{index+1}} Running Hours</h6>
            <span class="h2 text-dark mb-0 counter d-inline-block w-100">
              <span>{{ae | toHHMMSS}}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="iq-card">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="card-title" v-if="series">
            Device Logs from {{series.data.data.from | toLocalDate}} - {{series.data.data.to | toLocalDate}}
          </h4>
        </div>
      </div>
      <div hidden class="iq-card-body">
        <div class="row">
          <div role="group" class="form-group col-md-6">
            <label for="devcName" class="d-block form-label text-left">
              Device:
            </label>
            <div class="bv-no-focus-ring">
              <div role="group" class="input-group">
                <input id="devcName"
                       autocomplete="off"
                       v-model="form.devcName"
                       placeholder="Enter name"
                       type="text"
                       :class="errors.devcName ? 'is-invalid' : ''"
                       class="form-control">
                <b-form-invalid-feedback v-if="errors.devcName">
                  {{ Object.values(errors.devcName) }}
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
          <div role="group" class="form-group col-md-6">
            <label for="devcLabel" class="d-block form-label text-left">
              Label:
            </label>
            <div class="bv-no-focus-ring">
              <div role="group" class="input-group">
                <input id="devcLabel"
                       autocomplete="off"
                       v-model="form.devcLabel"
                       placeholder="Enter label"
                       type="text"
                       :class="errors.devcLabel ? 'is-invalid' : ''"
                       class="form-control">
                <b-form-invalid-feedback v-if="errors.devcLabel">
                  {{ Object.values(errors.devcLabel) }}
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div role="group" class="form-group col-md-6">
            <label class="d-block form-label text-left">
              Device Type:
            </label>
            <div class="bv-no-focus-ring">
              <div role="group" class="input-group">
                <b-form-select v-model="form.devcType"
                               text-field="name"
                               value-field="value"
                               class="form-control"
                               style="border-top-right-radius: 4px; border-bottom-right-radius: 4px;"
                               :options="related.devcTypes">
                  <template v-slot:first="">
                    <b-form-select-option value=""
                                          disabled>
                      -- Please Select Type --
                    </b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback v-if="errors.devcType">
                  {{ Object.values(errors.devcType) }}
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
          <div role="group" class="form-group col-md-6">
            <label class="d-block form-label text-left">
              Status:
            </label>
            <div class="bv-no-focus-ring">
              <div role="group" class="input-group">
                <select class="form-control"
                        v-model="form.devcStatus"
                        :disabled="isSubmit"
                        :class="errors.devcStatus ? 'is-invalid' : ''">
                  <option disabled="disabled" value="null"> -- Please Select Status --</option>
                  <option value="0">Not Approved</option>
                  <option value="1">Approved</option>
                </select>
                <b-form-invalid-feedback v-if="errors.devcStatus">
                  {{ Object.values(errors.devcStatus) }}
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div role="group" class="form-group col-md-6">
            <label class="d-block form-label text-left">
              Gateway:
            </label>
            <div class="bv-no-focus-ring">
              <div role="group" class="input-group">
                <input id="devcGateway"
                       autocomplete="off"
                       placeholder="Enter gateway"
                       type="text"
                       :disabled="isSubmit"
                       :class="errors.devcGateway ? 'is-invalid' : ''"
                       class="form-control">
                <b-form-invalid-feedback v-if="errors.devcGateway">
                  {{ Object.values(errors.devcGateway) }}
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
          <div role="group" class="form-group col-md-6">
            <label for="massId" class="d-block form-label text-left">
              Link to Asset:
            </label>
            <v-select id="massId"
                      label="massName"
                      :options="related.assets"
                      v-model="form.devcMassId"
                      placeholder="Select Asset"
                      :disabled="isSubmit"
                      :class="errors.devcMassId ? 'is-invalid' : ''"
                      :reduce="asset => asset.massId"
                      @search="getAsset">
              <template slot="no-options">
                Type to search asset
              </template>
            </v-select>
            <b-form-invalid-feedback v-if="errors.devcMassId">
              {{ Object.values(errors.devcMassId) }}
            </b-form-invalid-feedback>
          </div>
        </div>
        <div class="row">
          <div role="group" class="form-group col-md-12">
            <label class="d-block form-label text-left">
              Description:
            </label>
            <div class="bv-no-focus-ring">
              <div role="group" class="input-group">
              <textarea id="devcDescription"
                        autocomplete="off"
                        v-model="form.devcDescription"
                        placeholder="Enter description"
                        type="text"
                        :class="errors.devcDescription ? 'is-invalid' : ''"
                        class="form-control"></textarea>
                <b-form-invalid-feedback v-if="errors.devcDescription">
                  {{ Object.values(errors.devcDescription) }}
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="text-right">
              <button-options
                :access="canUpdate('master_device')"
                :hideDetail="true"
                :isBackEnabled="true"
                :isLoading="isLoading"
                :isSaveEnabled="true"
                :isSubmit="isSubmit"
                @onSubmit="onSubmit" />
            </div>
          </div>
        </div>
      </div>
      <div class="iq-card-body">
        <b-table
          striped hover fixed small
          :busy="isLoading"
          :fields="related.fields"
          :items="related.logs">
          <template v-slot:table-busy="">
            <div class="text-center text-danger my-2">
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle mr-3"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </b-container>
</template>

<script>

import AssetSvc from '@/services/AssetSvc';
import ButtonOptions from '@/components/directives/button-options';
import CanvasDirectives from '@/components/directives/canvas';
import DeviceSvc from '@/services/DeviceSvc';
import Map from '@/components/directives/iq-map';
import SeriesSvc from '@/services/SeriesSvc';

import DatePicker from '@/components/directives/date-picker';
import dateUtil from '@/util/date';

import requestBuilder from '@/util/request.builder';
import LatLon from 'geodesy/latlon-spherical.js';

export default {
  name: 'DeviceDetail',
  components: {
    DatePicker,
    CanvasDirectives,
    ButtonOptions,
    Map,
  },
  data() {
    return {
      gpsSummary: {
        totalCruise: 0,
        averageSpeed: 0,
        distance: 0,
      },
      flowmeterSummary: {
        averageFlow: 0,
        flowingTime: 0,
        totalFlow: 0,
      },
      aesTotal: [],
      dateParam: undefined,
      deviceData: undefined,
      mapCenter: [-0.831, 117.25343666666667],
      assetObject: {},
      data: [],
      device: [],
      series: undefined,
      errors: {},
      form: {},
      filter: {
        devcId: this.$route.params.id,
      },
      id: this.$route.params.id,
      isLoading: false,
      isRefreshed: false,
      isSubmit: false,
      pagination: {
        current: 1,
        pageSize: 10,
        totalSize: 0,
      },
      related: {
        assets: [],
        devcType: undefined,
        devcTypes: [
          { name: 'Memory', value: 'memory' },
          { name: 'Battery', value: 'battery' },
          { name: 'Flow Meter', value: 'flowMeter' },
          { name: 'GPS', value: 'gps' },
          { name: 'Engines', value: 'engines' },
          { name: 'CPU Usage', value: 'cpuUsage' },
          { name: 'Disk Space', value: 'diskspace' },
        ],
        fields: [],
        logs: [],
        range: {},
      },
    };
  },
  async mounted() {
    await this.getAsset();
    // this.getData();
    await this.getDevices();
    await this.load();
  },
  methods: {
    async load() {
      this.isLoading = true;
      // console.log('QQQQQQQ : ', this.$route.query.value);
      // if (this.$route.query && this.$route.query.start) {
      if (this.$route.query.value) {
        console.log('Data period defined... using query...');
        this.dateParam = dateUtil.queryToDateParam(this.$route.query);
      } else {
        console.log('Data period not defined... using today...');
        this.dateParam = dateUtil.byInterval('today');
      }
      if (!this.assetObject[this.deviceData.devcMassId].devices) this.assetObject[this.deviceData.devcMassId].devices = {};
      this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId] = this.deviceData;
      // this.dateParam.functionNames = ['CONCAT'];
      // this.dateParam.functionParams = ['@Polyline'];
      if (this.deviceData.devcType === 'flowmeter' && this.dateParam.aggregatedUnit !== 'MINUTE') {
        this.dateParam.functionNames = ['MIN', 'MAX', 'MIN', 'MAX', 'AVG', 'AVG', 'AVG', 'AVG'];
        this.dateParam.functionParams = ['@VolTotal', '@VolTotal', '@MassTotal', '@MassTotal', '@MassFlowrate', '@VolFlowrate', '@Density', '@Temp'];
      }

      if (this.deviceData.devcType === 'gps') {
        this.dateParam.functionNames = ['SUM', 'AVG'];
        this.dateParam.functionParams = ['@Distance', '@Speed'];
      }
      this.series = await SeriesSvc.getAggregatedData(this.deviceData, dateUtil.msToS(this.dateParam));
      this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data = this.series.data.data.series;
      console.log('Loaded : ', this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId]);
      if (this.series.data.data.count > 0) {
        this.mapCenter = [
          this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data[this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data.length - 1].latitude,
          this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data[this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data.length - 1].longitude,
        ];
      } else {
        this.isLoading = false;
        return false;
      }
      // console.log('Load : ', this.series.data.data.count);
      // if (this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].length === 0) {
      //   return;
      // }
      switch (this.related.devcType) {
        case 'gps':
          this.gpsSummary.distance = new LatLon(
            this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data[0].latitude,
            this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data[0].longitude,
          ).distanceTo(new LatLon(
            this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data[this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data.length - 1].latitude,
            this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data[this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data.length - 1].longitude,
          ));
          console.log('first lat', this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data[0].latitude);
          console.log('first long', this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data[0].longitude);
          console.log('last lat', this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data[this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data.length - 1].latitude);
          console.log('last long', this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data[this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data.length - 1].longitude);
          this.related.fields = [
            { key: 'datetime', label: 'Date Time', class: 'text-center' },
            { key: 'latitude', label: 'Latitude', class: 'text-center' },
            { key: 'longitude', label: 'Longitude', class: 'text-center' },
            { key: 'altitude', label: 'Altitude', class: 'text-center' },
            { key: 'track', label: 'Heading', class: 'text-center' },
            { key: 'additional.AVG_dlgpSpeed_1', label: 'Speed (Knot)', class: 'text-center' },
            { key: 'additional.SUM_dlgpDistance_0', label: 'Distance (m)', class: 'text-center' },
          ];
          break;
        case 'rpm':
          this.related.fields = [
            { key: 'datetime', label: 'Date Time', class: 'text-center' },
            { key: 'type', label: 'Engine Type', class: 'text-center' },
            { key: 'rpm', label: 'RPM', class: 'text-center' },
          ];
          break;
        case 'ae':
          this.related.fields = [
            { key: 'datetime', label: 'Date Time', class: 'text-center' },
            { key: 'no', label: 'AE No', class: 'text-center' },
            { key: 'rpm', label: 'Running Second', class: 'text-center' },
          ];
          break;
        case 'flowmeter':
          // console.log(this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data[0].volumeFlowrate);
          if (this.dateParam.aggregatedUnit === 'MINUTE') {
            this.flowmeterSummary.totalFlow = (this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data[this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data.length - 1].volumeInventory - this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data[0].volumeInventory);
          } else {
            this.flowmeterSummary.totalFlow = (this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data[this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data.length - 1].additional.MAX_dlfmVolTotal_1 - this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data[0].additional.MIN_dlfmVolTotal_0);
          }
          if (this.dateParam.aggregatedUnit === 'MINUTE') {
            this.related.fields = [
              { key: 'datetime', label: 'Date Time', class: 'text-center' },
              { key: 'volumeFlowrate', label: 'Volume Flow Rate', class: 'text-center' },
              { key: 'volumeTotal', label: 'Volume Total', class: 'text-center' },
              { key: 'density', label: 'Density', class: 'text-center' },
              { key: 'massFlowrate', label: 'Mass Flow Rate', class: 'text-center' },
              { key: 'massTotal', label: 'Mass Total', class: 'text-center' },
              { key: 'temperature', label: 'Temperature', class: 'text-center' },
            ];
          } else {
            this.related.fields = [
              { key: 'datetime', label: 'Date Time', class: 'text-center' },
              { key: 'additional.AVG_dlfmVolFlowrate_5', label: 'Volume Flow Rate', class: 'text-center' },
              { key: 'volumeTotal', label: 'Volume Total', class: 'text-center' },
              { key: 'additional.AVG_dlfmDensity_6', label: 'Density', class: 'text-center' },
              { key: 'additional.AVG_dlfmMassFlowrate_4', label: 'Mass Flow Rate', class: 'text-center' },
              { key: 'massTotal', label: 'Mass Total', class: 'text-center' },
              { key: 'additional.AVG_dlfmTemp_7', label: 'Temperature', class: 'text-center' },
            ];
          }
          break;
      }
      this.related.logs = [];
      // let firstFlowingTime;
      // let test = 0;
      let totalSpeed = 0;
      let totalDataSpeed = 0;
      this.gpsSummary.totalCruise = 0;
      this.flowmeterSummary.flowingTime = 0;
      this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data.forEach((serie, index) => {
        if (this.related.devcType === 'gps') {
          // if (index !== 0) {
          //   const p1 = new LatLon(serie.latitude, serie.longitude);
          //   const p2 = new LatLon(this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data[index - 1].latitude, this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data[index - 1].longitude);
          //   const distance = p1.distanceTo(p2);
          //   serie.distance = distance.toFixed(2);
          //   // console.log(p1.distanceTo(p2));
          // }
          totalSpeed += serie.additional.AVG_dlgpSpeed_1;
          totalDataSpeed++;
          this.gpsSummary.totalCruise = this.gpsSummary.totalCruise + serie.additional.SUM_dlgpDistance_0;
          serie.additional.SUM_dlgpDistance_0 = serie.additional.SUM_dlgpDistance_0.toFixed(2);
          serie.additional.AVG_dlgpSpeed_1 = serie.additional.AVG_dlgpSpeed_1.toFixed(2);
        }
        if (this.related.devcType === 'flowmeter') {
          // this.flowmeterSummary.flowingTime = 0;
          if (serie.volumeFlowrate !== 0 && index !== 0) {
            this.flowmeterSummary.flowingTime += serie.timestamp - this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data[index - 1].timestamp;
            // test += 60;
            // console.log(test);
            // if (!firstFlowingTime) firstFlowingTime = serie.timestamp;
            // console.log(firstFlowingTime, serie.timestamp);
          }// else if (firstFlowingTime) {
          //   const totalFlowInSeconds = serie.timestamp - firstFlowingTime;
          //   this.flowmeterSummary.flowingTime = this.flowmeterSummary.flowingTime + totalFlowInSeconds;
          //   console.log('Flow : ', this.flowmeterSummary.totalFlow, this.flowmeterSummary.flowingTime);
          if (this.flowmeterSummary.totalFlow && this.flowmeterSummary.flowingTime) {
            this.flowmeterSummary.averageFlow = this.flowmeterSummary.totalFlow / (this.flowmeterSummary.flowingTime / 3600);
          }
          //   firstFlowingTime = false;
          // }
        }
        if (this.related.devcType === 'ae') {
          if (this.aesTotal[serie.no] === undefined) this.aesTotal[serie.no] = 0;
          this.aesTotal[serie.no] += serie.rpm;
          // console.log(serie);
        }
        if (this.related.devcType === 'gateway') {
          // if (this.aesTotal[serie.no] === undefined) this.aesTotal[serie.no] = 0;
          // this.aesTotal[serie.no] += serie.rpm;
          // console.log(serie);
          serie.cpuUsage = (serie.cpuUsage * 100).toFixed(2);
          serie.memoryFree = (serie.memoryFree * 100).toFixed(2);
          serie.spaceFree = (serie.spaceFree * 100).toFixed(2);
        }
        this.related.logs.push(serie);
      });
      // this.gpsSummary.averageSpeed = (this.gpsSummary.totalCruise / 1000) / ((this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data[this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data.length - 1].timestamp - this.assetObject[this.deviceData.devcMassId].devices[this.deviceData.devcUniqueId].data[0].timestamp) / 3600);
      this.gpsSummary.averageSpeed = totalDataSpeed !== 0 ? totalSpeed / totalDataSpeed : 0;
      this.isLoading = false;
    },
    onSubmitRange(date) {
      console.log('Range submit : ', date);
      this.$router.replace({
        name: 'DeviceDetail',
        query: dateUtil.dateParamToQuery(date),
      }).catch((err) => {
        console.log('Duplicate Route, Skipping... ', err);
      });
      this.load();
    },
    dateRange(date) {
      this.related.range = date;
    },
    fieldsData(fields) {
      this.related.fields = fields;
    },
    async getAsset() {
      this.isLoading = true;
      try {
        const resp = await AssetSvc.getAsset();
        this.related.assets = resp.data.data;
        this.related.assets.forEach((asset) => {
          this.assetObject[asset.massId] = asset;
        });
        console.log('Got assets : ', this.related.assets);
      } catch (e) {
        if (e.response.data) {
          if (e.response.data.status.message === 'Authentication Error') {
            this.doLogOut();
          } else {
            this.$toast.error(e.response.data.status.message);
          }
        } else {
          this.$toast.error('Network error');
        }
      } finally {
        this.isLoading = false;
      }
    },
    async getCoordinates(notification) {
      this.isLoading = true;
      this.isRefreshed = true;
      try {
        const resp = await SeriesSvc
          .getSeriesByInterval('day', 1, this.form.devcUniqueId);
        if (notification) {
          this.$toast.success(notification);
        }
        this.data = resp.data.data.devices.map((v) => {
          const data = {};
          data.device = this.device.find((d) => d.devcUniqueId === v);
          data.devcType = v;
          data.series = resp.data.data.series.filter((o) => o.deviceId === v);
          data.series = data.series.map((s) => JSON.parse(s.data));
          data.logs = data.series.map((l) => [l.latitude, l.longitude]);
          data.logs = data.logs.reverse();
          data.detail = data.series[0];
          this.related.centerMap = data.logs[0];
          return data;
        });
        this.related.range = {
          from: resp.data.data.fromString,
          to: resp.data.data.toString,
        };
        this.related.logs = resp.data.data.series.map((s) => {
          const log = {};
          log.datetime = s.datetime;
          log.id = s.id;
          log.data = JSON.parse(s.data);
          return log;
        });
        this.related.fields = [
          { key: 'datetime', label: 'Date Time', class: 'text-center' },
          { key: 'data.latitude', label: 'Latitude', class: 'text-center' },
          { key: 'data.longitude', label: 'Longitude', class: 'text-center' },
          { key: 'data.altitude', label: 'Altitude', class: 'text-center' },
          { key: 'data.heading', label: 'Heading', class: 'text-center' },
          { key: 'data.speed', label: 'Speed (Knot)', class: 'text-center' },
        ];
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
        this.isRefreshed = false;
      }
    },
    async getDevices(notification) {
      this.isLoading = true;
      this.isRefreshed = true;
      try {
        const resp = await DeviceSvc.getDevices({
          params: {
            ...requestBuilder.buildFilters(this.pagination, this.filter),
          },
        });
        if (notification) {
          this.$toast.success(notification);
        }
        const data = resp.data.data.find((o) => o.devcId === this.id);
        this.device = resp.data.data.filter((o) => o.devcId === this.id);
        this.deviceData = data;
        this.related.devcType = data.devcType;
        console.log('Got device : ', this.related);
        // if (this.related.devcType === 'gps') {
        //   this.getCoordinates();
        // }
      } catch (e) {
        if (e.response.data) {
          if (e.response.data.status.message === 'Authentication Error') {
            this.doLogOut();
          } else {
            this.$toast.error(e.response.data.status.message);
          }
        } else {
          this.$toast.error('Network error');
        }
      } finally {
        this.isLoading = false;
        this.isRefreshed = false;
      }
    },
    // async getData(notification) {
    //   this.isLoading = true;
    //   this.isRefreshed = true;
    //   try {
    //     const resp = await DeviceSvc.getDevices({
    //       params: {
    //         ...requestBuilder.buildFilters(this.pagination, this.filter),
    //       },
    //     });
    //     if (notification) {
    //       this.$toast.success(notification);
    //     }
    //     const data = resp.data.data.find((o) => o.devcId === this.id);
    //     this.device = resp.data.data.filter((o) => o.devcId === this.id);
    //     this.form = data;
    //     this.related.devcType = data.devcType;
    //     console.log('Got device : ', this.related);
    //     if (this.related.devcType === 'gps') {
    //       this.getCoordinates();
    //     }
    //   } catch (e) {
    //     if (e.response.data) {
    //       if (e.response.data.status.message === 'Authentication Error') {
    //         this.doLogOut();
    //       } else {
    //         this.$toast.error(e.response.data.status.message);
    //       }
    //     } else {
    //       this.$toast.error('Network error');
    //     }
    //   } finally {
    //     this.isLoading = false;
    //     this.isRefreshed = false;
    //   }
    // },
    onCollectData(data) {
      this.related.logs = data;
      console.log('collected', data);
    },
    async onSubmit() {
      this.isSubmit = true;
      const form = {
        devcName: this.form.devcName,
        devcMassId: this.form.devcMassId,
        devcStatus: this.form.devcStatus,
        devcLabel: this.form.devcLabel,
        devcType: this.form.devcType,
        devcDescription: this.form.devcDescription,
      };
      try {
        const resp = await DeviceSvc.updateDevice(form, this.id);
        if (resp.data.status.error === 0) {
          this.$toast.success('Updated');
        } else {
          const errors = resp.data.status.message;
          if (typeof errors === 'object') {
            this.errors = resp.data.status.message;
            this.$toast.error('Error found');
          } else {
            this.$toast.error(resp.data.status.message);
          }
        }
      } catch (e) {
        if (e.response.data) {
          if (e.response.data.status.message === 'Authentication Error') {
            this.doLogOut();
          } else {
            this.$toast.error(e.response.data.status.message);
          }
        } else {
          this.$toast.error('Network error');
        }
      } finally {
        this.isSubmit = false;
      }
    },
    doLogOut() {
      this.$toast.error('Please re-login');
      this.$store.dispatch('auth/logout');
      this.$router.replace({ name: 'Login' });
    },
  },
};
</script>

<style scoped>

</style>
