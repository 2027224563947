<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="table-responsive">
    <b-table
        fixed
        show-empty
        responsive="xl"
        :busy="isLoading"
        :fields="fields"
        :items="data">
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </div>
      </template>
      <template v-slot:cell(massType)="data">
        <span v-if="data.value === '0'">Kapal</span>
        <span v-if="data.value === '1'">Mobil</span>
      </template>
      <template v-slot:cell(action)="data">
        <div class="text-center">
          <AssetEdit
            :access="'master_device'"
            :asset="data.item"
            @onActionAnalytics="onActionAnalytics"
            @onDeleteClicked="onActionDelete"
            @onEditClicked="onActionEdit"
            @onViewClicked="onActionView"/>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="pagination.totalSize"
      :per-page="pagination.pageSize"
    ></b-pagination>
  </div>
</template>

<script>
import AssetEdit from './Edit';

export default {
  name: 'AssetList',
  components: { AssetEdit },
  props: ['data', 'isLoading', 'pagination'],
  data() {
    return {
      currentPage: 1,
      fields: [
        {
          key: 'massName',
          label: 'Asset',
        },
        {
          key: 'massType',
          label: 'Asset Type',
        },
        {
          key: 'massCreatedTime',
          label: 'Created Time',
        },
        {
          key: 'action',
          label: '',
        },
      ],
    };
  },
  methods: {
    onActionAnalytics(data) {
      this.$emit('onActionAnalytics', data.massId);
    },
    onActionDelete(data) {
      this.$emit('onActionDelete', data.massId);
    },
    onActionEdit(data) {
      this.$emit('onActionEdit', data.massId);
    },
    onActionView(data) {
      this.$emit('onActionView', data.massId);
    },
  },
  watch: {
    currentPage(nv) {
      this.$emit('onPaginationChange', nv);
    },
  },
};
</script>

<style scoped>

</style>
