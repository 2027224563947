<template>
  <iq-card class="">
    <template v-slot:headerTitle>
      <h4 class="card-title">{{data.type}}</h4>
    </template>
    <!-- <pre>{{data.deviceId}}</pre> -->
    <!-- <template v-slot:headerAction v-if="false">
      <b-dropdown id="dropdownMenuButton4" right variant="none" data-toggle="dropdown">
        <template v-slot:button-content>
          <span><i class="ri-more-2-fill"></i></span>
        </template>
        <b-dropdown-item href="#"><i class="ri-eye-fill mr-2"></i>View</b-dropdown-item>
        <b-dropdown-item href="#"><i class="ri-file-download-fill mr-2"></i>Download</b-dropdown-item>
      </b-dropdown>
    </template> -->
    <template v-slot:body>
      <b-row>
        <b-col sm="10" offset-sm="1">
          <b-aspect :aspect="1.6">
            <vue-speedometer
              class="speedometer"
              :segments="3"
              :maxValue="2000"
              :customSegmentStops="[0, 900, 1200, 2000]"
              :segmentColors='["limegreen", "gold", "#cc0000"]'
              :value="data.rpm"
              :currentValueText="`${data.rpm.toFixed()} ${summaries.rpm.singleEngine ? '' : '/' + summaries.rpm.average[data.type].toFixed()} RPM`"
              :fluidWidth="true"
              valueTextFontSize="24"
              :paddingVertical="10"
              valueFormat="d"
              v-if="data.deviceId !== 'rpm-mdm_medan1'"
            />
            <vue-speedometer
              class="speedometer"
              :segments="3"
              :maxValue="180"
              :segmentColors='["limegreen", "gold", "#cc0000"]'
              :value="data.rpm"
              :currentValueText="`${data.rpm.toFixed()} RPM`"
              :fluidWidth="true"
              valueTextFontSize="24"
              :paddingVertical="10"
              valueFormat="d"
              v-else
            />
          </b-aspect>
        </b-col>
        <!-- <pre>{{summaries.flowmeter}}</pre> -->
        <!-- <b-col md="12 mb-3 mt-2" class="text-center">
          <b-row>
            <b-col md="12">
              <h5 class="text-dark mb-0">Fuel Consumption</h5>
                <b-progress class="mt-2" :max="bar.max" show-value>
                <b-progress-bar :value="bar.value" variant="success" :label="`${(bar.value).toFixed(1)} L/h`"></b-progress-bar>
                <b-progress-bar v-if="bar.average - bar.value > 0" :value="bar.average - bar.value" variant="warning" :label="`${(bar.average).toFixed()}`"></b-progress-bar>
                <b-progress-bar :value="value * (1.5 / 10)" variant="danger"></b-progress-bar>
              </b-progress>
              <h2 class="text-primary">{{(data.fm.IN[data.fm.IN.length-1].volumeFlowrate-data.fm.OUT[data.fm.OUT.length-1].volumeFlowrate).toFixed(2)}}<small class="ml-3">Liter/Hour</small></h2>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col md="12">
              <h6 class="d-inline-block w-100 mb-2">Current Flow Rate</h6>
              <h5>IN<span class="ml-3 font-size-22 text-success">{{(data.fm.IN[data.fm.IN.length-1].volumeFlowrate).toFixed(2)}} Liter/Hour</span></h5>
              <h5>OUT<span class="ml-3 font-size-22 text-danger">{{(data.fm.OUT[data.fm.OUT.length-1].volumeFlowrate).toFixed(2)}} Liter/Hour</span></h5>
              <h5>Totalizer<span class="ml-3 font-size-22 text-warning">{{(data.fm.IN[data.fm.IN.length-1].volumeTotal-data.fm.OUT[data.fm.OUT.length-1].volumeTotal).toFixed(2)}} Liter</span></h5>
            </b-col>
          </b-row>
        </b-col> -->
      </b-row>
      <!-- <b-row>
        <b-col md="12">
          <b-table-simple responsive class="table-sm">
            <b-thead>
              <b-th>#</b-th>
              <b-th>IN</b-th>
              <b-th>OUT</b-th>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-th>Volume Flowrate</b-th>
                <b-td>{{(data.fm.IN[data.fm.IN.length-1].volumeFlowrate).toFixed(2)}}</b-td>
                <b-td>{{(data.fm.OUT[data.fm.OUT.length-1].volumeFlowrate).toFixed(2)}}</b-td>
              </b-tr>
              <b-tr>
                <b-th>Volume Total</b-th>
                <b-td>{{(data.fm.IN[data.fm.IN.length-1].volumeTotal).toFixed(2)}}</b-td>
                <b-td>{{(data.fm.OUT[data.fm.OUT.length-1].volumeTotal).toFixed(2)}}</b-td>
              </b-tr>
              <b-tr>
                <b-th>Density</b-th>
                <b-td>{{(data.fm.IN[data.fm.IN.length-1].density).toFixed(2)}}</b-td>
                <b-td>{{(data.fm.OUT[data.fm.OUT.length-1].density).toFixed(2)}}</b-td>
              </b-tr>
              <b-tr>
                <b-th>Temperature</b-th>
                <b-td>{{(data.fm.IN[data.fm.IN.length-1].temperature).toFixed(2)}}</b-td>
                <b-td>{{(data.fm.OUT[data.fm.OUT.length-1].temperature).toFixed(2)}}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row> -->
    </template>
    <template v-slot:footer>
      <small>Updated at {{data.timestamp | toLocalDate}}</small>
      <!-- <small>RPM Updated at : {{data.rpm[data.rpm.length-1].timestamp | toLocalDate}} / FM Updated at : {{data.fm.IN[data.fm.IN.length-1].timestamp | toLocalDate}}</small> -->
    </template>
  </iq-card>
</template>
<script>

/*
  Speedometer chart docs https://github.com/palerdot/vue-speedometer
*/

export default {
  name: 'tachometer',
  props: {
    data: { type: Object },
    summaries: { type: Object },
  },
  mounted() {
  },
  methods: {
    getColor(value) {
      function perc2color(perc) {
        let r = 0;
        let g = 0;
        const b = 0;
        if (perc < 50) {
          r = 255;
          g = Math.round(5.1 * perc);
        } else {
          g = 255;
          r = Math.round(510 - 5.10 * perc);
        }
        const h = r * 0x10000 + g * 0x100 + b * 0x1;
        return `#${h.toString(16).padStart(6, '0')}`;
      }
      return perc2color(100 - value);
    },
  },
  computed: {
    bar() {
      // console.log(this.data.fm.IN[this.data.fm.IN.length - 1].volumeFlowrate - this.data.fm.OUT[this.data.fm.OUT.length - 1].volumeFlowrate);
      return {
        max: 20,
        average: 0,
        value: this.data.fm.IN[this.data.fm.IN.length - 1].volumeFlowrate - this.data.fm.OUT[this.data.fm.OUT.length - 1].volumeFlowrate,
      };
    },
    chartOption() {
      return {
        chart: {
          height: 350,
          type: 'radialBar',
        },
        plotOptions: {
          hollow: {
            margin: 10,
            size: '30%',
            background: 'transparent',
            image: undefined,
            imageWidth: 64,
            imageHeight: 64,
          },
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: '16px',
                color: undefined,
                offsetY: 120,
              },
              value: {
                offsetY: 76,
                fontSize: '22px',
                color: undefined,
                formatter: (val) => (val * 20).toFixed(2),
              },
            },
          },
        },
        fill: {},
        stroke: {
          dashArray: 5,
        },
        series: [this.data.rpm[this.data.rpm.length - 1].rpm / 20],
        labels: ['RPM'],
        colors: [this.getColor(this.data.rpm[this.data.rpm.length - 1].rpm / 20)],
      };
    },
  },
};
</script>
