<template>
  <div class="iq-card mb-0" style="min-width: 300px;">
    <div class="iq-card-header d-flex justify-content-between">
      <div class="iq-header-title">
        <h4 class="card-title">Add New Asset for {{ activeCompany.compName }}</h4>
      </div>
    </div>
    <div class="iq-card-body">
      <div class="row">
        <div role="group" class="form-group col-md-6">
          <label for="massName" class="d-block form-label text-left">
            Name:
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <input
              autocomplete="off"
              class="form-control"
              id="massName"
              placeholder="Enter name"
              type="text"
              v-model="data.massName"
              :class="errors.massName ? 'is-invalid' : ''"
              :disabled="isSubmit">
              <b-form-invalid-feedback v-if="errors.massName">
                {{ Object.values(errors.massName) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <div role="group" class="form-group col-md-6">
          <label class="d-block form-label text-left">
            Type:
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <b-form-select
                class="form-control"
                v-model="data.massType"
                placeholder="Select Type"
                :disabled="isSubmit"
                :options="[
                  {value: null, text: 'Please select type', disabled: true},
                  {value: '0', text: 'Kapal'},
                  {value: '1', text: 'Mobil'},
                ]" />
              <b-form-invalid-feedback v-if="errors.massType">
                {{ Object.values(errors.massType) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
      </div>
      <div class="row" hidden>
        <div role="group" class="form-group col-md-6">
          <label for="massName" class="d-block form-label text-left">
            Company:
          </label>
          <input
            class="form-control"
            :disabled="true"
            :value="activeCompany.compName" />
        </div>
      </div>
      <div class="form-group">
        <b-button @click="cancel" class="mr-1" size="sm" variant="outline-danger">
          <div class="mx-2">Cancel</div>
        </b-button>
        <b-button
          class="ml-1"
          size="sm"
          type="submit"
          variant="primary"
          @click="submit"
          :disabled="isSubmit">
          <div class="mx-2">
            <i v-if="isSubmit" class="ri-loader-line"></i>
            Submit
          </div>
        </b-button>
      </div>

    </div>
  </div>
</template>

<script>
import AssetSvc from '@/services/AssetSvc';

export default {
  name: 'AssetForm',
  computed: {
    activeCompany() {
      return this.$store.getters['auth/activeCompany'];
    },
  },
  props: ['data'],
  data() {
    return {
      errors: {},
      isSubmit: false,
    };
  },
  methods: {
    async submit() {
      const compId = this.activeCompany.compId;
      const data = { ...this.data };
      data.massCompId = compId;
      this.isSubmit = true;
      console.log(data);
      try {
        const res = await AssetSvc.addOrUpdate(data.massId, data);
        if (res.data.status.error === 0) {
          this.$toast.success('new asset created');
          this.$emit('onSuccess');
        } else if (res.data.status.errorCode === 401) {
          this.errors = res.data.status.message;
          this.$toast.error('invalid data');
        }
      } catch (e) {
        this.$toast.error(e.response.data.status.message);
      } finally {
        this.isSubmit = false;
      }
    },
    cancel() {
      this.$emit('onCancel');
    },
  },
};
</script>

<style scoped>
  .vs__dropdown-toggle {
    border: 0;
  }
  .vs__selected {
    color: #8c91b6;
  }
</style>
