module.exports = {
  // RAMUS Issuer
  // issuer: 'https://auth.ramus.id/oauth2/auskqvezvAog47m1i5d6',
  issuer: 'https://auth.ramus.id/oauth2/ausce9n05nF7OSw3r5d7',
  //  RAMUS Client Id
  // clientId: '0oakqezd9pEr65TM25d6',
  clientId: '0oa8bazwvp9webi8M5d7',
  // redirectUri: 'https://dev.local.ramus.id/api/security/authentication/login',
  redirectUri: 'https://v1.ramus.id/login/callback',
  scope: 'openid profile email',
  // Use authorization_code flow
  responseType: 'code',
  pkce: false,
};
