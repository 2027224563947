<template>
  <div></div>
</template>

<script>
import SeriesSvc from '@/services/SeriesSvc';

export default {
  name: 'report-data-helper',
  props: ['date', 'devices', 'isSubmit', 'type'],
  methods: {
    async getData() {
      const devcUniqueId = this.devices.map((o) => o.devcUniqueId);
      const resp = await SeriesSvc.getWeeklySeries(this.date, devcUniqueId);
      let data = [];
      data = this.devices.map((v) => {
        const data = {};
        data.device = this.devices.find((d) => d.devcUniqueId === v.devcUniqueId);
        data.type = data.device.devcType;
        data.series = resp.data.data.series.filter((o) => o.deviceId === v.devcUniqueId);
        data.series = data.series.map((s) => JSON.parse(s.data));
        data.data = {};
        if (data.type === 'gps') {
          data.data = data.series.map((d) => d.speed);
        }
        if (data.type === 'battery') {
          data.data = data.series.map((d) => d.uptime);
        }
        return data;
      });
      this.$emit('onActionFinished', data);
    },
  },
  watch: {
    isSubmit: {
      immediate: true,
      handler(v) {
        if (v === true) {
          this.getData();
        }
      },
    },
  },
};
</script>

<style scoped>

</style>
