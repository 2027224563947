import Vue from 'vue';
import VueRouter from 'vue-router';
import { OktaAuth } from '@okta/okta-auth-js';
import OktaVue, { LoginCallback } from '@okta/okta-vue';

import AuthorizedLayout from '@/layouts/AuthorizedLayout';
import BlankLayout from '@/layouts/BlankLayout';
import UnauthorizedLayout from '@/layouts/UnauthorizedLayout';
import ExportDailyReportLayout from '@/layouts/ExportDailyReportLayout';

import CompanyActiveSelection from '@/views/auth/CompanyActiveSelection';
import Login from '@/views/auth/login/Index';

import Asset from '@/views/asset/Index.vue';
import AssetDetail from '@/views/asset/Detail.vue';
import AssetAnalytics from '@/views/asset/Analytics.vue';
import AssetDataLog from '@/views/asset/DataLog.vue';
import Company from '@/views/company/Index.vue';
import CompanyDetail from '@/views/company/Detail.vue';
import Home from '@/views/home/Index.vue';
import Dashboard from '@/views/dashboard/Index.vue';
import Device from '@/views/device/Index.vue';
import DeviceDetail from '@/views/device/Detail.vue';
import Group from '@/views/group/Index';
import GroupDetail from '@/views/group/Detail';
import Report from '@/views/report/Index';
import ReportView from '@/views/report/View.vue';
import Role from '@/views/role/Index';
import RoleDetail from '@/views/role/Detail';
import User from '@/views/user/Index';
import UserDetail from '@/views/user/Detail';
import Export from '@/views/export/Index.vue';

import Widgets from '../views/Widgets';
import Forbidden from '../views/Forbidden';

import AssetType from '../views/AssetType';

import store from '../store';

import middleware from './middleware';

import oktaConfig from '../config/okta.js';

const oktaAuth = new OktaAuth(oktaConfig);

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Ramus',
    component: AuthorizedLayout,
    redirect: '/home',
    beforeEnter: middleware.isAuthenticated(store),
    children: [
      {
        path: 'home',
        name: 'Home',
        component: Home,
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
      },
      {
        path: 'company',
        name: 'Company',
        component: Company,
      },
      {
        path: 'group',
        name: 'Group',
        component: Group,
      },
      {
        path: 'widgets',
        name: 'Widgets',
        component: Widgets,
      },
      {
        path: 'user',
        name: 'User',
        component: User,
      },
      {
        path: 'user/:id',
        name: 'UserDetail',
        component: UserDetail,
      },
      {
        path: 'role',
        name: 'Role',
        component: Role,
      },
      {
        path: 'role/:id',
        name: 'RoleDetail',
        component: RoleDetail,
      },
      {
        path: 'asset',
        name: 'Asset',
        component: Asset,
      },
      {
        path: 'asset/detail/:id',
        name: 'AssetDetail',
        component: AssetDetail,
      },
      {
        path: 'asset/analytics/:id',
        name: 'AssetAnalytics',
        component: AssetAnalytics,
      },
      {
        path: 'asset/datalog/:id',
        name: 'AssetDataLog',
        component: AssetDataLog,
      },
      {
        path: 'asset/type/',
        name: 'AssetType',
        component: AssetType,
      },
      {
        path: 'device',
        name: 'Device',
        component: Device,
      },
      {
        path: 'device/detail/:id',
        name: 'DeviceDetail',
        component: DeviceDetail,
      },
      {
        path: 'group/detail/:id',
        name: 'GroupDetail',
        component: GroupDetail,
      },
      {
        path: 'company/detail/:id',
        name: 'CompanyDetail',
        component: CompanyDetail,
      },
      {
        path: '401',
        name: 'Forbidden',
        component: Forbidden,
      },
      {
        path: 'report',
        name: 'Report',
        component: Report,
      },
      {
        path: 'report/:id/:type',
        name: 'ReportView',
        component: ReportView,
      },
    ],
  },
  {
    path: '/preference',
    name: 'Preference',
    component: BlankLayout,
    beforeEnter: middleware.isAuthenticated(store),
    children: [
      {
        path: 'active-company',
        name: 'CompanyActiveSelection',
        component: CompanyActiveSelection,
      },
    ],
  },
  {
    path: '/auth',
    name: 'UnAuthorized',
    component: UnauthorizedLayout,
    // redirect: '/auth/login',
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login,
      },
      {
        path: 'export',
        name: 'Export',
        component: Export,
      },
    ],
  },
  {
    path: '/dailyreport',
    name: 'UnAuthorized',
    component: ExportDailyReportLayout,
    redirect: '/dailyreport/export',
    children: [
      {
        path: 'export',
        name: 'Export',
        component: Export,
      },
    ],
  },
  {
    path: '/login/callback',
    component: LoginCallback,
  },
];

Vue.use(OktaVue, { oktaAuth });

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
