<template>
  <div class="iq-card mb-0" style="min-width: 300px;">
    <div class="iq-card-header d-flex justify-content-between">
      <div class="iq-header-title">
        <h4 class="card-title">Add New Group</h4>
      </div>
    </div>
    <div class="iq-card-body">
      <div class="row">
        <div role="group" class="form-group col-md-6">
          <label for="grcpNickName" class="d-block form-label text-left">
            Nick Name:
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <input id="grcpNickName"
                     autocomplete="off"
                     v-model="data.grcpNickName"
                     placeholder="Enter nick name"
                     type="text"
                     :class="errors.grcpNickName ? 'is-invalid' : ''"
                     class="form-control">
              <b-form-invalid-feedback v-if="errors.grcpNickName">
                {{ Object.values(errors.grcpNickName) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <div role="group" class="form-group col-md-6">
          <label for="grcpName" class="d-block form-label text-left">
            Name:
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <input id="grcpName"
                     autocomplete="off"
                     v-model="data.grcpName"
                     placeholder="Enter group name"
                     type="text"
                     :class="errors.grcpName ? 'is-invalid' : ''"
                     class="form-control">
              <b-form-invalid-feedback v-if="errors.grcpName">
                {{ Object.values(errors.grcpName) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div role="group" class="form-group col-md-12">
          <label for="grcpAddress" class="d-block form-label text-left">
            Address:
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
                  <textarea id="grcpAddress"
                            autocomplete="off"
                            v-model="data.grcpAddress"
                            placeholder="Enter group address"
                            type="text"
                            :class="errors.grcpAddress ? 'is-invalid' : ''"
                            class="form-control"></textarea>
              <b-form-invalid-feedback v-if="errors.grcpAddress">
                {{ Object.values(errors.grcpAddress) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div role="group" class="form-group col-md-12">
          <div role="group" class="form-group">
            <label for="grcpCity" class="d-block form-label text-left">
              City:
            </label>
            <div class="bv-no-focus-ring">
              <div role="group" class="input-group">
                <input id="grcpCity"
                       autocomplete="off"
                       v-model="data.grcpCity"
                       placeholder="Group city"
                       type="text"
                       :class="errors.grcpCity ? 'is-invalid' : ''"
                       class="form-control">
                <b-form-invalid-feedback v-if="errors.grcpCity">
                  {{ Object.values(errors.grcpCity) }}
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div role="group" class="form-group col-md-6">
          <label for="grcpFax" class="d-block form-label text-left">
            Fax:
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <input id="grcpFax"
                     autocomplete="off"
                     v-model="data.grcpFax"
                     placeholder="085255XXXXXX"
                     type="text"
                     :class="errors.grcpFax ? 'is-invalid' : ''"
                     class="form-control">
              <b-form-invalid-feedback v-if="errors.grcpFax">
                {{ Object.values(errors.grcpFax) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <div role="group" class="form-group col-md-6">
          <label for="grcpTelp" class="d-block form-label text-left">
            PIC Phone:
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <input id="grcpTelp"
                     autocomplete="off"
                     v-model="data.grcpTelp"
                     placeholder="085255XXXXXX"
                     type="text"
                     :class="errors.grcpTelp ? 'is-invalid' : ''"
                     class="form-control">
              <b-form-invalid-feedback v-if="errors.grcpTelp">
                {{ Object.values(errors.grcpTelp) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <b-button @click="onCancelAddClicked"
                  class="mr-1"
                  size="sm"
                  :disabled="isSubmit"
                  variant="outline-danger">
          <div class="mx-2">Cancel</div>
        </b-button>
        <b-button type="submit"
                  @click="onSubmitClicked"
                  :disabled="isSubmit"
                  variant="primary"
                  size="sm"
                  class="ml-1">
          <div class="mx-2">
            <i v-if="isSubmit" class="ri-loader-line"></i>
            Submit
          </div>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GroupForm',
  props: ['data', 'errors', 'isSubmit'],
  data() {
    return {
    };
  },
  methods: {
    onCancelAddClicked() {
      this.$emit('onCancelAddClicked');
    },
    onSubmitClicked() {
      const data = { ...this.data };
      this.$emit('onSubmitClicked', data);
    },
  },
};
</script>

<style scoped>

</style>
