const SET_LAYOUT_STATE = 'set_layout_state';

// noinspection JSUnusedGlobalSymbols
const layout = {
  namespaced: true,
  state: {
    layout: undefined,
  },
  getters: {
    state(state) {
      return state.layout;
    },
  },
  mutations: {
    [SET_LAYOUT_STATE](state, v) {
      state.layout = v;
    },
  },
  actions: {
    setLayoutState({ commit }, v) {
      commit(SET_LAYOUT_STATE, v);
    },
  },
};

export default layout;
