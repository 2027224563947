<template>
  <b-container fluid>
    <div class="iq-card mt-3">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="card-title">Company List</h4>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="text-right">
              <button-options
                :access="'master_company'"
                :hideDetail="true"
                :hideAdd="false"
                :isRefreshEnabled="true"
                :isRefreshed="isRefreshed"
                @onAddItemClicked="openAddForm"
                @onRefreshed="getData('Refreshed')"/>
            </div>
          </div>
        </div>
      </div>
      <div class="iq-card-body">
        <b-row class="mb-3">
          <b-col md="3" sm="6">
            <input-selector
              :data="availableGroup"
              :id="'grcpId'"
              :label="'grcpName'"
              :loading="isLoading"
              :placeholder="'Please select group'"
              @onChangeSelector="onChangeGroupSelector"/>
          </b-col>
          <b-col md="3" sm="6">
            <input-selector
              :data="[
                {value: '0', text: 'Not Approved'},
                {value: '1', text: 'Approved'},
              ]"
              :id="'value'"
              :label="'text'"
              :loading="isLoading"
              :placeholder="'Please select status'"
              @onChangeSelector="onChangeCompanyTypeSelector"/>
          </b-col>
        </b-row>
        <div class="row">
          <div class="col-md-12">
            <CompanyList
              :access="'master_company'"
              :data="data"
              :group="availableGroup"
              :loading="isLoading"
              :pagination="pagination"
              @onActionDelete="onActionDelete"
              @onActionEdit="onActionEdit"
              @onActionView="onActionView"
              @onPageChanged="onPageChanged"
              @update="onUpdated"/>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      size="lg"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      v-model="displayForm">
      <CompanyForm
        :data="currentCompany"
        :errors="errors"
        :isSubmit="isSubmit"
        @onSubmitClicked="onSubmitClicked"
        @onCancelAddClicked="onCancel"/>
    </b-modal>
    <b-modal
      size="lg"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      v-model="displayDeletion">
      <DeleteItem
        :isSubmit="isSubmit"
        @onCancelDeletion="displayDeletion = false"
        @onConfirmDeletion="onConfirmDeletion"/>
    </b-modal>

  </b-container>
</template>

<script>

import ButtonOptions from '@/components/directives/button-options';
import CompanyForm from '@/components/company/Form';
import CompanyList from '@/components/company/List';
import CompanySvc from '@/services/CompanySvc';
import DeleteItem from '@/components/directives/delete-item';
import InputSelector from '@/components/directives/input-selector';

import requestBuilder from '@/util/request.builder';

export default {
  name: 'CompanyIndex',
  components: {
    CompanyForm,
    CompanyList,
    ButtonOptions,
    DeleteItem,
    InputSelector,
  },
  computed: {
    availableGroup() {
      return this.$store.getters['auth/availableGroup'];
    },
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      currentCompany: {},
      data: [],
      displayForm: false,
      displayDeletion: false,
      errors: {},
      filter: {
        compGrcp: null,
        compStatus: null,
      },
      isLoading: false,
      isRefreshed: false,
      isSubmit: false,
      pagination: {
        current: 1,
        pageSize: 10,
        totalSize: 0,
      },
      selected: {},
    };
  },
  methods: {
    async getData(notification) {
      this.isLoading = true;
      try {
        const resp = await CompanySvc.getCompanies({
          params: {
            ...requestBuilder.buildFilters(this.pagination, this.filter),
          },
        });
        this.data = resp.data.data;
        if (notification) {
          this.$toast.success(notification);
        }
        this.pagination.totalSize = resp.data.pagination.totalData;
      } catch (e) {
        if (e.response.data) {
          if (e.response.data.status.message === 'Authentication Error') {
            this.doLogOut();
          } else {
            this.$toast.error(e.response.data.status.message);
          }
        } else {
          this.$toast.error('Network error');
        }
      } finally {
        this.isLoading = false;
      }
    },
    async onSubmitClicked(form) {
      this.errors = {};
      this.isSubmit = true;
      const data = {
        compName: form.compName,
        compGrcpId: form.compGrcpId,
        compAddress: form.compAddress,
        compPIC: form.compPIC,
        compPICPhone: form.compPICPhone,
        compPhone: form.compPhone,
        compStatus: form.compStatus,
      };
      try {
        let resp = {};
        if (form.compId) {
          resp = await CompanySvc.updateCompany(form.compId, data);
        } else {
          resp = await CompanySvc.addCompany(form);
        }
        if (resp.data.status.error === 0) {
          await this.getData('Saved');
          this.displayForm = false;
        } else {
          const errors = resp.data.status.message;
          if (typeof errors === 'object') {
            this.errors = resp.data.status.message;
            this.$toast.error('Error found');
          } else {
            this.$toast.error(resp.data.status.message);
          }
        }
        this.isSubmit = false;
      } catch (e) {
        this.$toast.error(e.response.data.status.message);
        this.isSubmit = false;
      }
    },
    async deleteData(notification, data) {
      this.isSubmit = true;
      try {
        const resp = await CompanySvc.deleteCompany(data.compId);
        if (resp.data.status.error === 0) {
          await this.$toast.success(notification);
          this.displayDeletion = await false;
          this.pagination.current = 1;
          this.getData();
        } else {
          this.$toast.error(resp.data.status.message);
        }
      } catch (e) {
        this.$toast.error(e.message);
      } finally {
        this.isSubmit = false;
      }
    },
    onPageChanged(page) {
      this.pagination.current = page;
      this.getData();
    },
    onUpdated() {
      this.getData('Updated');
    },
    onCancel() {
      this.currentCompany = {};
      this.displayForm = false;
    },
    onConfirmDeletion() {
      this.deleteData('Deleted complete', this.selected);
    },
    onChangeGroupSelector(v) {
      this.filter.compGrcpId = v ? v.grcpId : null;
      this.getData();
    },
    onChangeCompanyTypeSelector(v) {
      this.filter.compStatus = v ? v.value : null;
      this.getData();
    },
    onActionDelete(data) {
      this.displayDeletion = true;
      this.selected = data;
    },
    onActionEdit(data) {
      this.displayForm = true;
      this.currentCompany = { ...this._.find(this.data, (v) => v.compId === data.compId) };
    },
    onActionView(data) {
      this.$router.push({ name: 'CompanyDetail', params: { id: data.compId } });
    },
    openAddForm() {
      this.currentCompany = {};
      this.displayForm = true;
    },
    doLogOut() {
      this.$toast.error('Please re-login');
      this.$store.dispatch('auth/logout');
      this.$router.replace({ name: 'Login' });
    },
  },
};
</script>

<style scoped>

</style>
