<template>
  <div class="iq-card mb-0" style="min-width: 300px;">
    <div class="iq-card-body text-center">
      <div class="row">
        <div class="col-md-12">
          <p class="text-center">Delete this item ?</p>
        </div>
      </div>

      <div class="form-group">
        <b-button @click="onCancelDeletion"
                  class="mr-1"
                  size="sm"
                  :disabled="isSubmit"
                  variant="outline-danger">
          <div class="mx-2">Cancel</div>
        </b-button>
        <b-button type="submit"
                  @click="onConfirmDeletion"
                  variant="primary"
                  size="sm"
                  :disabled="isSubmit"
                  class="ml-1">
          <div class="mx-2">
            <i v-if="isSubmit" class="ri-loader-line"></i>
            Confirm
          </div>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeleteItem',
  props: ['isSubmit'],
  methods: {
    onCancelDeletion() {
      this.$emit('onCancelDeletion');
    },
    onConfirmDeletion() {
      this.$emit('onConfirmDeletion');
    },
  },
};
</script>

<style scoped>

</style>
