<template>
  <!-- <ApexChart element="chart-19" :chartOption="chartOption"/> -->
  <div>
    <ApexChart
      element="report-chart"
      :chartOption="chartOptions"
      :series="series">
    </ApexChart>
  </div>
</template>

<script>
export default {
  name: 'report-chart',
  props: ['data', 'yaxis'],
  data() {
    return {
      chartOptions: {
        chart: {
          stacked: false,
        },
        stroke: {
          width: [0, 2, 5],
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
          },
        },
        colors: ['#2E93fA', '#546E7A', '#66DA26', '#F4A540'],
        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: 'vertical',
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 8,
        },
        xaxis: {
          categories: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
        },
        yaxis: [],
        tooltip: {
          shared: true,
          intersect: false,
          // y: {
          //   formatter(y) {
          //     if (typeof y !== 'undefined') {
          //       return `${y.toFixed(0)} points`;
          //     }
          //     return y;
          //   },
          // },
        },
      },
      series: [],
    };
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(data) {
        this.series = data;
      },
    },
    yaxis: {
      immediate: true,
      deep: true,
      handler(data) {
        this.chartOptions.yaxis = data;
      },
    },
  },
};
</script>

<style scoped>

</style>
