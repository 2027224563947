<script>
export default {
  computed: {
    userAccess() {
      return this.$store.getters['auth/userAccess'];
    },
  },
  methods: {
    hasAccess(module, action) {
      if (!this.userAccess[module]) {
        return false;
      }
      if (Array.isArray(this.userAccess[module])) {
        return this.userAccess[module].includes(action);
      }
      return false;
    },
    // isVisible(item) {
    //   console.log('IV : ', item, this.userAccess);
    //   if (item.name && item.name === 'User.Role') {
    //     if (this.userAccess && this.userAccess.master_user) {
    //       return true;
    //     }
    //     return false;
    //   }
    //   // if (!this.userAccess[module]) {
    //   //   return false;
    //   // }
    //   // if (Array.isArray(this.userAccess[module])) {
    //   //   return this.userAccess[module].includes(action);
    //   // }
    //   return true;
    // },
    canCreate(module) {
      return this.hasAccess(module, 'create');
    },
    canRead(module) {
      return this.hasAccess(module, 'read');
    },
    canUpdate(module) {
      return this.hasAccess(module, 'update');
    },
    canDelete(module) {
      return this.hasAccess(module, 'delete');
    },
  },
};
</script>
