<template>
  <!-- <ApexChart element="report-graph" :chartOption="chartOption"/> -->
  <div>
    <!-- <pre>{{data}}</pre> -->
    <apexchart v-if="ready" type="line" :options="chartOptions" :series="series" :height="customHeight"></apexchart>
  </div>
</template>
<script>

import dateUtil from '@/util/date';

export default {
  name: 'report-barline',
  props: {
    data: { type: Object },
    type: { type: String },
    customHeight: { type: Number },
    isDailyReport: {
      type: Boolean,
      default: false,
    },
    customXAxis: {
      type: Array,
    },
  },
  mounted() {
    if (this.data) {
      const chartOptions = this.constructChartData(this.data);
      console.log('Mounted... ', chartOptions, this.type);
      console.log('series>>', this.series);
      // if (this.data) {
      //   this.chartOptions.labels = Object.keys(this.data.datas);
      // }
      this.ready = true;
    }
    // const portRpmSeries = [1, 2, 3];
    // const starboardRpmSeries = [1, 2, 3];
    // const portUsageSeries = [1, 2, 3];
    // const starboardUsageSeries = [1, 2, 3];
    // // this.data.PORT.rpm.forEach((d, idx) => {
    // //   portRpmSeries.push(d.rpm);
    // //   if (this.data.STARBOARD.rpm[idx] !== undefined) {
    // //     starboardRpmSeries.push(this.data.STARBOARD.rpm[idx].rpm);
    // //   }
    // //   if (this.data.PORT.fm.IN[idx].volumeFlowrate !== undefined && this.data.PORT.fm.OUT[idx].volumeFlowrate !== undefined) {
    // //     portUsageSeries.push(this.data.PORT.fm.IN[idx].volumeFlowrate - this.data.PORT.fm.OUT[idx].volumeFlowrate);
    // //   }
    // //   if (this.data.STARBOARD.fm.IN[idx].volumeFlowrate !== undefined && this.data.STARBOARD.fm.OUT[idx].volumeFlowrate !== undefined) {
    // //     starboardUsageSeries.push(this.data.STARBOARD.fm.IN[idx].volumeFlowrate - this.data.STARBOARD.fm.OUT[idx].volumeFlowrate);
    // //   }
    // //   this.chartOption.labels.push(`${new Date(d.timestamp * 1000).getHours().toString().padStart(2, '0')}:${new Date(d.timestamp * 1000).getMinutes().toString().padStart(2, '0')}`);
    // // });
    // if (portRpmSeries.length === 0 && starboardRpmSeries.length === 0 && portUsageSeries.length === 0 && starboardUsageSeries.length === 0) {
    //   this.noData = true;
    // }
    // this.chartOption.series.push({
    //   name: 'Port',
    //   type: 'column',
    //   data: portRpmSeries,
    // });
    // this.chartOption.series.push({
    //   name: 'Starboard',
    //   type: 'column',
    //   data: starboardRpmSeries,
    // });
    // this.chartOption.series.push({
    //   name: 'Port Flow',
    //   type: 'line',
    //   data: portUsageSeries,
    // });
    // this.chartOption.series.push({
    //   name: 'Starboard Flow',
    //   type: 'line',
    //   data: starboardUsageSeries,
    // });
  },
  // computed: {
  //   series() {
  //     const series = [];
  //     if (this.data) {
  //     //   Object.keys(this.data.devices).forEach((device) => {
  //     //     // console.log('AAAAAAAA : ', this.data.devices[device]);
  //     //     const speedSeries = {
  //     //       name: 'Speed',
  //     //       type: 'line',
  //     //       data: [],
  //     //     };
  //     //     const portRPMSeries = {
  //     //       name: 'Port RPM',
  //     //       type: 'column',
  //     //       data: [],
  //     //     };
  //     //     const stbRPMSeries = {
  //     //       name: 'Stb RPM',
  //     //       type: 'column',
  //     //       data: [],
  //     //     };
  //     //     const consumptionSeries = {
  //     //       name: 'Consumption',
  //     //       type: 'area',
  //     //       data: [],
  //     //     };
  //     //     switch (this.data.devices[device].devcType) {
  //     //       case 'gps':
  //     //         console.log('Draw GPS Data : ', this.data.devices[device].data);
  //     //         this.data.devices[device].data.forEach((d) => {
  //     //           speedSeries.data.push(d.speed);
  //     //         });
  //     //         series[2] = speedSeries;
  //     //         break;
  //     //       case 'rpm':
  //     //         console.log('Draw RPM Data :', this.data.devices[device].data);
  //     //         this.data.devices[device].data.forEach((d) => {
  //     //           if (d.type === 'PORT') {
  //     //             portRPMSeries.data.push(d.rpm);
  //     //           } else {
  //     //             stbRPMSeries.data.push(d.rpm);
  //     //           }
  //     //         });
  //     //         series[0] = portRPMSeries;
  //     //         series[1] = stbRPMSeries;
  //     //         break;
  //     //       case 'ae':
  //     //         break;
  //     //       case 'flowmeter':
  //     //         console.log('Draw flowmeter Data :', this.data.devices[device]);
  //     //         if (this.data.devices[device].devcLabel === 'STARBOARD_OUT') {
  //     //           this.data.devices[device].data.forEach((d) => {
  //     //             consumptionSeries.data.push(d.massFlowrate);
  //     //           });
  //     //         }
  //     //         series.push(consumptionSeries);
  //     //         break;
  //     //     }
  //     //   });
  //       // console.log(this.data);
  //     }
  //     return series;
  //     // return [
  //     //   {
  //     //     name: 'PORT RPM',
  //     //     type: 'column',
  //     //     data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
  //     //   },
  //     //   {
  //     //     name: 'STB RPM',
  //     //     type: 'column',
  //     //     data: [12, 11, 22, 12, 13, 22, 12, 21, 12, 22, 30],
  //     //   },
  //     //   {
  //     //     name: 'Fuel Cons',
  //     //     type: 'area',
  //     //     data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
  //     //   },
  //     //   {
  //     //     name: 'Speed',
  //     //     type: 'line',
  //     //     data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
  //     //   },
  //     // ];
  //   },
  // },
  methods: {
    constructChartData(data) {
      // console.log('Data to construct : ', data);
      Object.keys(data.datas).forEach((timeStamp, timestampIndex) => {
        if (this.isDailyReport) {
          this.chartOptions.labels.push(++this.count);
        } else {
          this.chartOptions.labels.push(timeStamp);
        }
        // this.chartOptions.labels.push(timeStamp);
        // this.series[0].data[timestampIndex] = 0;
        // this.series[1].data[timestampIndex] = 0;
        this.series[2].data[timestampIndex] = 0;
        // this.series[3].data[timestampIndex] = 0;
        this.series[4].data[timestampIndex] = 0;
        // this.series[5].data[timestampIndex] = 0;
        Object.keys(data.datas[timeStamp]).forEach((deviceType) => {
          // console.log(timestampIndex);
          // let totalAERunningSeconds = 0;
          switch (deviceType) {
            // case 'gps':
            //   // console.log('TS : ', data.datas[timeStamp][deviceType].polyLength);
            //   if (data.datas[timeStamp][deviceType]) {
            //     this.series[3].data[timestampIndex] = data.datas[timeStamp][deviceType].speed;
            //     this.series[5].data[timestampIndex] = data.datas[timeStamp][deviceType].polyLength;
            //   }
            //   break;
            case 'rpm':
              // console.log('TS : ', data.datas[timeStamp][deviceType]);
              // if (data.datas[timeStamp][deviceType].PORT) {
              //   this.series[0].data[timestampIndex] = data.datas[timeStamp][deviceType].PORT.rpm;
              // }
              // if (data.datas[timeStamp][deviceType].STARBOARD) {
              //   this.series[1].data[timestampIndex] = data.datas[timeStamp][deviceType].STARBOARD.rpm;
              // }
              if (data.datas[timeStamp][deviceType][this.type] && data.datas[timeStamp][deviceType][this.type].runningTime) {
                this.series[4].data[timestampIndex] = data.datas[timeStamp][deviceType][this.type].runningTime / 3600;
              }
              // console.log('RPM : ', data.datas[timeStamp][deviceType]);
              break;
            case 'flowmeter':
              if (data.datas[timeStamp][deviceType].stbFuelCons > 0) {
                this.series[2].data[timestampIndex] = data.datas[timeStamp][deviceType].stbFuelCons;
              } else {
                this.series[2].data[timestampIndex] = 0;
              }
              if (this.type === 'PORT') {
                if (data.datas[timeStamp][deviceType].portFuelCons > 0) {
                  this.series[2].data[timestampIndex] = data.datas[timeStamp][deviceType].portFuelCons;
                } else {
                  this.series[2].data[timestampIndex] = 0;
                }
              }
              // console.log('TS : ', data.datas[timeStamp][deviceType]);
              break;
            // case 'ae':
            //   Object.keys(data.datas[timeStamp][deviceType]).forEach((ae) => {
            //     totalAERunningSeconds += data.datas[timeStamp][deviceType][ae].runningTime;
            //     // console.log(data.datas[timeStamp][deviceType][ae]);
            //   });
            //   this.series[4].data[timestampIndex] = totalAERunningSeconds / 3600;
            //   // console.log('TS : ', data.datas[timeStamp][deviceType], totalAERunningSeconds);
            //   break;
          }
        });
      });
      return {};
    },
  },
  data() {
    return {
      count: 0,
      ready: false,
      series: [
        {
          name: 'PORT RPM',
          type: 'column',
          data: [],
        },
        {
          name: 'STB RPM',
          type: 'column',
          data: [],
        },
        {
          name: 'Fuel Used',
          type: 'column',
          data: [],
        },
        {
          name: 'Speed',
          type: 'line',
          data: [],
        },
        {
          name: 'Running Time',
          type: 'line',
          data: [],
        },
        {
          name: 'Distance',
          type: 'line',
          data: [],
        },
      ],
      chartOptions: {
        // colors: ['#0084ff', '#00ca00', '#ffca00', '#FF8C00'],
        // colors: ['#ff0000', '#ffa500', '#ffff00', '#008000', '#0000ff', '#4b0082', '#ee82ee'],
        chart: {
          animations: {
            enabled: !this.isDailyReport,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: !this.isDailyReport,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: !this.isDailyReport,
              speed: 350,
            },
          },
          height: 'auto',
          type: 'line',
          stacked: false,
          events: {
            zoomed: (chartContext, { xaxis }) => {
              console.log('Chart zoomed... ', xaxis);
              this.$emit('onZoomed', xaxis);
            },
            scrolled: (chartContext, { xaxis }) => {
              console.log('Chart scrolled... ', xaxis);
              this.$emit('onScrolled', xaxis);
            },
            animationEnd: (chartContext) => {
              this.svg = chartContext.paper().svg();
              // console.log('This is SVG : ', this.svg);
              // console.log('Mantedd... ', this.$refs.chartRef.chart);
              chartContext.dataURI().then(({ imgURI }) => {
                this.png = imgURI;
                // console.log('This is image URI : ', imgURI, blob);
                // pdf.addImage(imgURI, 'PNG', 0, 0);
              });
            },
            legend: {
              show: false,
            },
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: true,
              reset: false,
              customIcons: [],
            },
            // export: {
            //   csv: {
            //     filename: undefined,
            //     columnDelimiter: ',',
            //     headerCategory: 'category',
            //     headerValue: 'value',
            //     dateFormatter(timestamp) {
            //       return new Date(timestamp).toDateString()
            //     }
            //   }
            // },
            // autoSelected: 'zoom'
          },
        },
        stroke: {
          width: [0, 0, 5, 5, 2, 2],
          curve: 'smooth',
        },
        plotOptions: {
          bar: {
            columnWidth: '80%',
          },
        },
        fill: {
          opacity: [0.75, 0.75, 1, 0.85, 1],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: 'vertical',
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        labels: [], // dynamic
        markers: {
          size: 0,
        },
        xaxis: {
          categories: this.customXAxis || [],
          labels: {
            show: true,
            formatter: (value) => {
              if (value) {
                if (this.isDailyReport) {
                  return value;
                }
                return dateUtil.toLocalDate(value);
              }
            },
          },
        },
        yaxis: [
          // {
          //   axisTicks: {
          //     show: false,
          //   },
          //   axisBorder: {
          //     show: false,
          //     color: '#008FFB',
          //   },
          //   labels: {
          //     show: false,
          //     style: {
          //       colors: '#008FFB',
          //     },
          //     formatter: (value) => value.toFixed(),
          //   },
          //   title: {
          //     text: 'RPM',
          //     style: {
          //       color: '#008FFB',
          //     },
          //   },
          //   tooltip: {
          //     enabled: true,
          //   },
          // },
          {
            labels: {
              show: false,
            },
          },
          {
            labels: {
              show: false,
            },
          },
          {
            labels: {
              show: true,
              formatter: (value) => value.toFixed(),
            },
            title: {
              text: 'Fuel Used',
            },
            min: 0,
          },
          {
            labels: {
              show: false,
            },
          },
          {
            opposite: true,
            labels: {
              show: true,
              formatter: (value) => value.toFixed(),
            },
            title: {
              text: 'Running Hour',
            },
            min: 0,
          },
          // {
          //   labels: {
          //     show: false,
          //   },
          // },
          // {
          //   opposite: true,
          //   labels: {
          //     show: true,
          //     formatter: (value) => (value * 0.00053996).toFixed(),
          //   },
          //   title: {
          //     text: 'Nautical Mile',
          //   },
          // },
        ],
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: (y) => {
              if (typeof y !== 'undefined') {
                return y.toFixed(0);
              }
              return y;
            },
          },
        },
      },
    };
  },
};
</script>
