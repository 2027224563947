<template>
  <div style="height: 100%;">
    <div v-if="isLoading" class="dt-loading">
      <b-spinner variant="default" size="sm" class="align-middle"></b-spinner>
      <p>Loading data...</p>
    </div>
    <b-table ref="aeConstTable" v-else :items="data" :fields="headers" id="aeConsTable">
      <template #thead-top="data">
        <b-tr class="thead-fixed">
          <b-th colspan="1"></b-th>
          <b-th colspan="3">AE 1</b-th>
          <b-th colspan="3">AE 2</b-th>
          <b-th colspan="3">AE 3</b-th>
        </b-tr>
      </template>
    </b-table>
  </div>
</template>

<style>
  .thead-fixed {
    position: sticky;
    text-align: center;
    top: 0;
    z-index: 1;
    background-color: #F1F3F5;
  }
</style>

<script>
export default {
  name: 'dt-ae-consumption',
  props: ['data', 'isLoading'],
  data() {
    return {
      headers: [
        {
          key: 'date',
          label: 'Date',
          thStyle: {
            background: '#F1F3F5', padding: '5px 0.75rem',
          },
        },
        {
          key: 'engineHour1',
          label: 'Eng. Hour',
          thStyle: {
            background: '#F1F3F5', padding: '5px 0.75rem',
          },
        },
        {
          key: 'flowRate1',
          label: 'Flow Rate',
          thStyle: {
            background: '#F1F3F5', padding: '5px 0.75rem',
          },
        },
        {
          key: 'consumption1',
          label: 'Cons.',
          thStyle: {
            background: '#F1F3F5', padding: '5px 0.75rem',
          },
        },
        {
          key: 'engineHour2',
          label: 'Eng. Hour',
          thStyle: {
            background: '#F1F3F5', padding: '5px 0.75rem',
          },
        },
        {
          key: 'flowRate2',
          label: 'Flow Rate',
          thStyle: {
            background: '#F1F3F5', padding: '5px 0.75rem',
          },
        },
        {
          key: 'consumption2',
          label: 'Cons.',
          thStyle: {
            background: '#F1F3F5', padding: '5px 0.75rem',
          },
        },
        {
          key: 'engineHour3',
          label: 'Eng. Hour',
          thStyle: {
            background: '#F1F3F5', padding: '5px 0.75rem',
          },
        },
        {
          key: 'flowRate3',
          label: 'Flow Rate',
          thStyle: {
            background: '#F1F3F5', padding: '5px 0.75rem',
          },
        },
        {
          key: 'consumption3',
          label: 'Cons.',
          thStyle: {
            background: '#F1F3F5', padding: '5px 0.75rem',
          },
        },
      ],
    };
  },
  methods: {
    // tableLoaded() {
    //   if (!this.$refs.aeConstTable) {
    //     return;
    //   }

    //   const headers = this.$refs.aeConstTable.$el.querySelectorAll('thead tr');
    //   if (headers.length > 1) {
    //     return; // nothing to do, header row already created
    //   }

    //   const topHeader = document.createElement('tr');
    //   topHeader.innerHTML = '<th></th><th colspan="3">AE 1</th><th colspan="3">AE 2</th><th colspan="3">AE 3</th>';
    //   // topHeader.style
    //   headers[0].parentNode.insertBefore(topHeader, headers[0]);
    // },
  },
};
</script>
