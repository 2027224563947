<template>
  <b-form-group>
    <v-select v-model="selected"
              :disabled="loading"
              :id="id"
              :label="label"
              :options="data"
              :placeholder="placeholder"
              @search="onFilterData">
      <template slot="no-options">
        {{placeholder}}
      </template>
    </v-select>
  </b-form-group>
</template>

<script>
export default {
  name: 'input-selector',
  props: ['data', 'id', 'label', 'loading', 'placeholder'],
  data() {
    return {
      selected: null,
    };
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onFilterData(v, loading) {
    },
  },
  watch: {
    selected(nv) {
      this.$emit('onChangeSelector', nv);
    },
  },
};
</script>

<style scoped>

</style>
