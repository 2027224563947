<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="row">
    <div class="col-md-12">
      <b-table
        bordered
        fixed
        small
        show-empty
        :current-page="currentPage"
        :fields="fields"
        :items="data"
        :per-page="perPage"
        class="bg-white text-center table-logs">
        <template #thead-top="data">
          <b-tr class="text-center">
            <b-th colspan="2" rowspan="2">Asset</b-th>
            <b-th colspan="4">GPS</b-th>
            <b-th colspan="6">Flowmeter (Liter)</b-th>
            <b-th colspan="4">Flowmeter (Density)</b-th>
            <b-th colspan="2" rowspan="2">RPM ME</b-th>
            <b-th colspan="8">AE</b-th>
          </b-tr>
          <b-tr class="text-center">
            <b-th colspan="2">Location</b-th>
            <b-th colspan="2">Movement</b-th>
            <b-th colspan="3">ME Port</b-th>
            <b-th colspan="3">ME Stbd</b-th>
            <b-th colspan="2">ME Port</b-th>
            <b-th colspan="2">ME Stbd</b-th>
            <b-th colspan="2">AE1</b-th>
            <b-th colspan="2">AE2</b-th>
            <b-th colspan="2">AE3</b-th>
            <b-th colspan="2">AE4</b-th>
          </b-tr>
        </template>
        <template v-slot:table-busy="">
          <div class="text-center text-danger my-2">
            <b-spinner variant="success" size="sm" class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(asset)="data">
          {{ asset.massName }}
        </template>
        <template #cell(latitude)="data">
          {{ data.item.coordinate[0] | latToDMS }}
        </template>
        <template #cell(longitude)="data">
          {{ data.item.coordinate[1] | longToDMS }}
        </template>
        <template #cell(fm-1-inlet)="data">
          {{ getReadingValue(data.item.readings, 'port-inlet', 'volumeFlowRate') }}
        </template>
        <template #cell(fm-2-outlet)="data">
          {{ getReadingValue(data.item.readings, 'port-outlet', 'volumeFlowRate') }}
        </template>
        <template #cell(fm12-consumption)="data">
          {{
            (getReadingValue(data.item.readings, 'port-inlet', 'volumeFlowRate') - getReadingValue(data.item.readings, 'port-outlet', 'volumeFlowRate')).toFixed(2)
          }}
        </template>
        <template #cell(fm-3-inlet)="data">
          {{ getReadingValue(data.item.readings, 'starboard-inlet', 'volumeFlowRate') }}
        </template>
        <template #cell(fm-4-outlet)="data">
          {{ getReadingValue(data.item.readings, 'starboard-outlet', 'volumeFlowRate') }}
        </template>
        <template #cell(fm34-consumption)="data">
          {{
            (getReadingValue(data.item.readings, 'starboard-inlet', 'volumeFlowRate') - getReadingValue(data.item.readings, 'starboard-outlet', 'volumeFlowRate')).toFixed(2)
          }}
        </template>
        <template #cell(fm-1d-inlet)="data">
          {{ getReadingValue(data.item.readings, 'port-inlet', 'density') }}
        </template>
        <template #cell(fm-2d-outlet)="data">
          {{ getReadingValue(data.item.readings, 'port-outlet', 'density') }}
        </template>
        <template #cell(fm-3d-inlet)="data">
          {{ getReadingValue(data.item.readings, 'starboard-inlet', 'density') }}
        </template>
        <template #cell(fm-4d-outlet)="data">
          {{ getReadingValue(data.item.readings, 'starboard-outlet', 'density') }}
        </template>
        <template #cell(rpm-port)="data">
          {{ getReadingValue(data.item.readings, 'port-RPM', 'value') }}
        </template>
        <template #cell(rpm-starboard)="data">
          {{ getReadingValue(data.item.readings, 'starboard-RPM', 'value') }}
        </template>
        <template #cell(ae1-value)="data">
          {{ getReadingValue(data.item.readings, 'AE1', 'value') }}
        </template>
        <template #cell(ae2-value)="data">
          {{ getReadingValue(data.item.readings, 'AE2', 'value') }}
        </template>
        <template #cell(ae3-value)="data">
          {{ getReadingValue(data.item.readings, 'AE3', 'value') }}
        </template>
        <template #cell(ae4-value)="data">
          {{ getReadingValue(data.item.readings, 'AE4', 'value') }}
        </template>
        <template #cell(ae1-runningTime)="data">
          {{ getReadingValue(data.item.readings, 'AE1', 'runningTime') }}
        </template>
        <template #cell(ae2-runningTime)="data">
          {{ getReadingValue(data.item.readings, 'AE2', 'runningTime') }}
        </template>
        <template #cell(ae3-runningTime)="data">
          {{ getReadingValue(data.item.readings, 'AE3', 'runningTime') }}
        </template>
        <template #cell(ae4-runningTime)="data">
          {{ getReadingValue(data.item.readings, 'AE4', 'runningTime') }}
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        pills
        size="sm"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataView',
  props: ['asset', 'series', 'isLoading'],
  data() {
    return {
      currentPage: 1,
      perPage: 5,
      filter: {},
      selected: {},
    };
  },
  computed: {
    fields() {
      return [
        'dateTime',
        'asset',
        'latitude',
        'longitude',
        'speed',
        'direction',
        {
          key: 'fm-1-inlet',
          label: 'Inlet',
        },
        {
          key: 'fm-2-outlet',
          label: 'Outlet',
        },
        {
          key: 'fm12-consumption',
          label: 'Cons.',
        },
        {
          key: 'fm-3-inlet',
          label: 'Inlet',
        },
        {
          key: 'fm-4-outlet',
          label: 'Outlet',
        },
        {
          key: 'fm34-consumption',
          label: 'Cons.',
        },
        {
          key: 'fm-1d-inlet',
          label: 'Inlet',
        },
        {
          key: 'fm-2d-outlet',
          label: 'Outlet',
        },
        {
          key: 'fm-3d-inlet',
          label: 'Inlet',
        },
        {
          key: 'fm-4d-outlet',
          label: 'Outlet',
        },
        {
          key: 'rpm-port',
          label: 'ME Port',
        },
        {
          key: 'rpm-starboard',
          label: 'ME Stbd',
        },
        {
          key: 'ae1-value',
          label: 'Value',
        },
        {
          key: 'ae1-runningTime',
          label: 'Running Time',
        },
        {
          key: 'ae2-value',
          label: 'Value',
        },
        {
          key: 'ae2-runningTime',
          label: 'Running Time',
        },
        {
          key: 'ae3-value',
          label: 'Value',
        },
        {
          key: 'ae3-runningTime',
          label: 'Running Time',
        },
        {
          key: 'ae4-value',
          label: 'Value',
        },
        {
          key: 'ae4-runningTime',
          label: 'Running Time',
        },
      ];
    },
    data() {
      if (!Array.isArray(this.series)) {
        return [];
      }

      return this.series.flatMap((v) => v.data);
    },
    totalRows() {
      return this.series.length;
    },
  },
  methods: {
    getReadingValue(readings, label, field) {
      const reading = readings.find((v) => v.label === label);
      if (typeof reading === 'undefined') return '-';
      return reading[field].toFixed(2);
    },
  },
};
</script>

<style scoped lang="scss">
.table-logs {
  display: block;
  font-size: 11px;
  overflow-x: auto;
  white-space: nowrap;
}
</style>
