// function capitalizeFirstLetter(string) {
//   return string.charAt(0).toUpperCase() + string.slice(1);
// }

export default (doc, data, startY, fontSizes, lineSpacing) => {
  // console.log('Inih... ', this);
  const startX = 25;
  const pageWidth = doc.internal.pageSize.width;
  const endX = pageWidth - startX;
  doc.setFontSize(fontSizes.TitleFontSize);
  const assetName = data.asset.massName;
  doc.text(assetName, endX, startY, 'right');
  startY += lineSpacing * 1.5;
  doc.setFontSize(fontSizes.SubTitleFontSize);
  const reportTitle = data.type.label;
  doc.text(reportTitle, endX, startY, 'right');
  startY += lineSpacing * 1;
  doc.setFontSize(fontSizes.SmallFontSize);
  const reportPeriod = data.period;
  doc.text(reportPeriod, endX, startY, 'right');
  startY += lineSpacing;
  doc.setDrawColor('#012b65').setLineWidth(2);
  doc.line(startX, startY, endX, startY);

  // const invoiceNrTxt = 'ABC';

  // doc.setFontType('normal');

  // startY = 243;
  // doc.text(invoiceNrTxt, startX, startY);

  // doc.setFontType('bold');

  // startX += doc.getStringUnitWidth(invoiceNrTxt) * fontSizes.SubTitleFontSize;
  // doc.text('123', startX, startY);

  // doc.setFontType('normal');

  startY += lineSpacing * 2;
  // startX = 57;

  // doc.setFontSize(fontSizes.TitleFontSize);
  // doc.text(data.label.invoice, startX, startY += lineSpacing + 2);
  // doc.text(data.invoice.subject, startX, startY += lineSpacing * 2);

  // doc.setDrawColor(157, 183, 128);
  // doc.setLineWidth(0.5);
  // console.log(lineSpacing);
  return startY;
};
