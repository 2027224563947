<script>
import { Pie } from 'vue-chartjs';

export default {
  name: 'Pie',
  extends: Pie,
  props: ['type', 'canId', 'data'],
  data: () => ({
    chartdata: {
      labels: ['First', 'Second', 'Third', 'Fourth'],
      datasets: [
        {
          backgroundColor: ['#4e73df', '#4e7', '#f87979', 'rgba(238, 238, 0, 1)'],
          data: [50, 30, 20, 10],
        },
      ],
    },
    options: {},
  }),

  mounted() {
    this.renderChart(this.chartdata, this.options);
  },
};
</script>

<style scoped>

</style>
