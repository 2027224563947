<template>
  <b-button-group>
    <button class="btn btn-success btn-sm"
            v-if="canUpdate(access) && hideEdit === false"
            :disabled="isRefreshed || isSubmit || isLoading"
            @click="onEditClicked">
      <i class="ri-edit-box-line"></i>
      Edit
    </button>
    <button class="btn btn-info btn-sm"
            v-if="canRead(access) && hideDetail === false"
            :disabled="isRefreshed || isSubmit || isLoading"
            @click="onViewClicked">
      <i class="ri-information-line"></i>
      Detail
    </button>
    <button class="btn btn-warning btn-sm"
            v-if="isAnalyticsEnabled"
            :disabled="isRefreshed || isSubmit || isLoading"
            @click="onAnalyticsClicked">
      <i class="ri-bar-chart-2-line"></i>
      Analytics
    </button>
    <button class="btn btn-danger btn-sm"
            v-if="canDelete(access) && hideDelete === false"
            :disabled="isRefreshed || isSubmit || isLoading"
            @click="onDeleteClicked">
      <i class="ri-delete-bin-7-line"></i>
      Delete
    </button>
    <button class="btn btn-light btn-sm"
            v-if="isBackEnabled"
            :disabled="isRefreshed || isSubmit || isLoading"
            @click="onBackClicked">
      <i class="ri-arrow-go-back-line"></i>
      Back
    </button>
    <button class="btn btn-primary btn-sm"
            v-if="canCreate(access) && hideAdd === false"
            :disabled="isRefreshed || isSubmit || isLoading"
            @click="onAddItemClicked">
      <i class="ri-add-line"></i>
      Add
    </button>
    <button class="btn btn-success btn-sm"
            v-if="isRefreshEnabled"
            :disabled="isRefreshed || isSubmit || isLoading"
            @click="onRefreshed">
      <i class="fa" :class="isRefreshed ? 'ri-loader-line' : 'ri-refresh-line'"></i>
      Refresh
    </button>
    <button class="btn btn-primary btn-sm"
            v-if="isSaveEnabled"
            :disabled="isRefreshed || isSubmit || isLoading"
            @click="onSubmit">
      <i class="fa" :class="isSubmit ? 'ri-loader-line' : 'ri-save-line'"></i>
      Save
    </button>
  </b-button-group>
</template>

<script>
export default {
  name: 'button-options',
  props: ['access',
    'hideEdit',
    'hideDelete',
    'hideAdd',
    'hideDetail',
    'isAnalyticsEnabled',
    'isBackEnabled',
    'isSaveEnabled',
    'isRefreshEnabled',
    'options',
    'isRefreshed',
    'isSubmit',
    'isLoading'],
  methods: {
    onAddItemClicked() {
      this.$emit('onAddItemClicked');
    },
    onAnalyticsClicked() {
      this.$emit('onAnalyticsClicked');
    },
    onBackClicked() {
      this.$router.back();
    },
    onDeleteClicked() {
      this.$emit('onDeleteClicked');
    },
    onEditClicked() {
      this.$emit('onEditClicked');
    },
    onRefreshed() {
      this.$emit('onRefreshed');
    },
    onSubmit() {
      this.$emit('onSubmit');
    },
    onViewClicked() {
      this.$emit('onViewClicked');
    },
  },
};
</script>

<style scoped>

</style>
