<template>
  <div id="map-list-scrollbar">
    <div class="iq-sidebar-menu">
      <ul class="iq-menu">
        <li class="iq-menu-title" style="border-bottom: 1px solid rgba(255,255,255,.1)">
          <div class="iq-map-menu-bt align-self-center row" @click="toggleMapSideBar">
            <div class="map-menu-wrapper sidebar-toggled">
              <div class="line-menu half start" style="background-color: black"></div>
              <div class="line-menu" style="background-color: black"></div>
              <div class="line-menu half end" style="background-color: black"></div>
            </div>
            <h6 class="ml-3">Devices List</h6>
          </div>
        </li>
        <div v-for="(data, index) in device" v-bind:key="`B-${index}`">
          <li id="`${data.deviceId}`"
              :class="devId === data.deviceId ? 'selected-ship' : ''">
            <a class="btn" href="#"
               @mouseover="getHover(index)"
               @mouseleave="getHover()"
               @click="onSelectedDevice(data.deviceId, getRecentLog(data))">
              <i class="ri-ship-2-fill" :style="{color: colors[index]}"></i>
              {{data.deviceId}}
            </a>
          </li>
        </div>

      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'List',
  props: ['device', 'colors', 'devId'],
  data() {
    return {};
  },
  methods: {
    getHover(e) {
      this.$emit('onHover', e);
    },
    // eslint-disable-next-line no-unused-vars
    getRecentLog(v) {
      // return v.logs[0];
    },
    onSelectedDevice(id, center) {
      if (this.devId !== id) {
        this.$emit('onSelectedDevice', id);
        this.$emit('updateCenter', center);
      } else if (this.devId === id) {
        this.$emit('onSelectedDevice', null);
      }
    },
    toggleMapSideBar() {
      const sidebar = document.querySelector('#map-sidebar');
      const toggler = document.querySelector('#map-toggler');
      sidebar.classList.add('d-none');
      toggler.classList.remove('d-none');
    },
  },
};
</script>

<style scoped>

  .iq-sidebar-menu .iq-menu .iq-menu-title {
    display: block;
    font-size: 15px;
    position: relative;
    padding: 15px 15px;
    font-weight: 500;
    line-height: 18px;
    color: black;
  }

  .iq-sidebar-menu .iq-menu li a {
    font-size: 14px;
    white-space: nowrap;
    display: block;
    position: relative;
    padding: 10px 15px;
    line-height: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
  }

  .iq-map-menu-bt {
    padding: 12px 10px;
    margin-right: 15px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }

  .map-menu-wrapper {
    color: #0084ff;
    width: 20px;
    height: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: transform 330ms ease-out;
  }
</style>
