import DeviceSvc from '@/services/DeviceSvc.js';

const SET_DEVICES = 'set_devices';
const SET_COORDINATES = 'set_coordinates';
const SET_MARKERS = 'set_markers';
const SET_CENTER_COORDINATES = 'set_center_coordinates';

function getDeviceId(v) {
  return v.map((i) => i.devcId);
}

function normalizeData(v) {
  const data = JSON.parse(v);
  return [data.latitude, data.longitude];
}

// noinspection JSUnusedGlobalSymbols
const coordinate = {
  namespaced: true,
  state: {
    devices: [],
    coordinates: [],
    markers: [],
    center: [],
  },
  getters: {
    devices(state) {
      return state.devices;
    },
    getCoordinates(state) {
      return state.coordinates;
    },
    getMarkers(state) {
      return state.markers;
    },
    getCenter(state) {
      return state.center;
    },
  },
  mutations: {
    [SET_DEVICES](state, v) {
      state.devices = v;
    },
    [SET_COORDINATES](state, v) {
      state.coordinates = v;
    },
    [SET_MARKERS](state, v) {
      state.markers = v;
    },
    [SET_CENTER_COORDINATES](state, v) {
      state.center = v;
    },
  },
  actions: {
    async fetchLog({ commit }) {
      const devId = await DeviceSvc.getDevices();
      let deviceId = null;
      deviceId = await getDeviceId(devId.data.data);
      const resp = await DeviceSvc.getSeriesDevicesWeekly(deviceId);
      // let devices = Array.from(new Set(resp.data.data.map((v) => v.device)));
      let { devices } = resp.data.data;
      devices = devices.map((v) => ({
        deviceId: v,
        detail: resp.data.data.series
          .filter((x) => x.device === v)
          .map((x) => JSON.parse(x.data)),
        logs: resp.data.data.series
          .filter((x) => x.device === v)
          .map((x) => normalizeData(x.data)),
      }));
      commit(SET_DEVICES, devices);
    },
    async setDevices({ commit }, v) {
      await commit(SET_DEVICES, v);
      // eslint-disable-next-line max-len
      const coordinates = v.map((i) => [i.deloDeviceRawData.latitude, i.deloDeviceRawData.longitude]);
      // eslint-disable-next-line max-len
      const markers = v.map((i) => [i.deloDeviceRawData.latitude, i.deloDeviceRawData.longitude]);
      const center = [v[0].deloDeviceRawData.latitude, v[0].deloDeviceRawData.longitude];
      await commit(SET_CENTER_COORDINATES, center);
      await commit(SET_COORDINATES, coordinates);
      await commit(SET_MARKERS, markers);
    },
  },
};

export default coordinate;
