<template>
  <div>
    <BatteryMeter
      v-if="devType === 'battery'"
      :canId="canvasId"
      :data="data"
      :dev="device"
      :type="devType"
      @dateRange="dateRange"
      @collectedData="collectedData"
      @fieldsData="fieldsData"/>
    <MapCanvas
      v-if="devType === 'jipies'"
      :type="devType"
      :canId="canvasId"
      :data="device"
      @fieldsData="fieldsData"/>
    <Compass v-if="devType === 'compass'" :type="devType" :canId="canvasId" :data="device"/>
    <SpeedMeter
      v-if="devType === 'engines'"
      :canId="canvasId"
      :data="data"
      :dev="device"
      :type="devType"
      @dateRange="dateRange"
      @collectedData="collectedData"
      @fieldsData="fieldsData"/>
    <Temperature
      v-if="devType === 'flowMeter'"
      :canId="canvasId"
      :data="data"
      :dev="device"
      :type="devType"
      @dateRange="dateRange"
      @collectedData="collectedData"
      @fieldsData="fieldsData"/>
    <Bar v-if="devType === 'bar'" :type="devType" :canId="canvasId" :data="device"/>
    <Doughnut v-if="devType === 'doughnut'" :type="devType" :canId="canvasId" :data="device"/>
    <div v-if="devType === 'linestatechart'">
      <LineStateChart
        :type="devType"
        :canId="canvasId"
        :data="device"/>
    </div>
    <Pie v-if="devType === 'pie'" :type="devType" :canId="canvasId" :data="device"/>
    <Polar v-if="devType === 'polar'" :type="devType" :canId="canvasId" :data="device"/>
    <Radar v-if="devType === 'radar'" :type="devType" :canId="canvasId" :data="device"/>
    <div v-if="devType === '' || devType == null">
      <div class="text-center">
        <h6>{{device.devcName}}</h6>
      </div>
    </div>
  </div>
</template>

<script>
import SpeedMeter from '../widgets/analog_gauge/SpeedMeter';
import Compass from '../widgets/analog_gauge/Compass';
import Temperature from '../widgets/analog_gauge/Temperature';
import Bar from '../widgets/charts/Bar';
import Doughnut from '../widgets/charts/Doughnut';
import LineStateChart from '../widgets/charts/LineStateChart';
import Pie from '../widgets/charts/Pie';
import Polar from '../widgets/charts/Polar';
import Radar from '../widgets/charts/Radar';
import MapCanvas from '../widgets/map/MapCanvas';
import BatteryMeter from '../widgets/analog_gauge/BatteryMeter';

export default {
  name: 'CanvasDirectives',
  components: {
    BatteryMeter,
    MapCanvas,
    Radar,
    Polar,
    Pie,
    LineStateChart,
    Doughnut,
    Bar,
    Temperature,
    Compass,
    SpeedMeter,
  },
  props: ['devType', 'canvasId', 'device', 'config', 'data'],
  data() {
    return {};
  },
  methods: {
    collectedData(data) {
      this.$emit('collectedData', data);
    },
    dateRange(date) {
      this.$emit('dateRange', date);
    },
    fieldsData(fields) {
      this.$emit('fieldsData', fields);
    },
  },
};
</script>

<style scoped>

</style>
