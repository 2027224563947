<template>
  <div style="height: 100%">
    <div class="dt-sidebar-header">
      <h3>Summary Data</h3>
    </div>
    <div class="dt-sidebar-filter">
      <!-- Select asset -->
      <div class="dt-form">
        <label for="data-table-asset">Asset</label>
        <select
          class="form-control mr-3 mb-0"
          style="font-size: 12px"
          placeholder="Choose asset"
          :selected="massId"
          @change="onChangeAsset"
        >
          <option
            v-for="(item, index) in assets"
            v-bind:key="`interval-${index}`"
            :value="item.massId"
          >
            {{ item.massName }}
          </option>
        </select>
      </div>
      <div class="dt-row-date">
        <!-- Select date range -->
        <DatePicker
          ref="pickie"
          v-model="localRange"
          mode="dateTime"
          :masks="masks"
          is-range
          is24hr
          :popover="popover"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <div class="dt-form" style="width: 270px;">
              <label for="data-table-asset">Date Range</label>
              <div class="dt-form-group">
                <input
                  class="dt-form-input form-control"
                  style="font-size: 12px;"
                  :value="inputValue.start"
                  v-on="inputEvents.start"
                />
                <label>-</label>
                <input
                  class="dt-form-input form-control"
                  style="font-size: 12px;"
                  :value="inputValue.end"
                  v-on="inputEvents.end"
                />
              </div>
            </div>
          </template>
          <template v-slot:footer>
            <button
              style="
                width: 100%;
                height: 32px;
                text-align: center;
                background-color: yellow;
                border: none;
              "
              @click.prevent="closePopup"
            >
              Done
            </button>
          </template>
        </DatePicker>
        <!-- Select interval -->
        <div class="dt-form">
          <label for="data-table-asset">Interval</label>
          <select
            class="form-control mr-3 mb-0"
            style="font-size: 12px"
            placeholder="Select interval"
            @change="onChangedInterval"
          >
            <option
              :selected="interval === item.value"
              v-for="(item, index) in dataIntervalOptions"
              v-bind:key="`interval-${index}`"
              :value="item.value"
            >
              {{ item.label }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <!-- menu items -->
    <div class="dt-menu-container" :style="`height: ${height - 140 - 45}px;`">
      <div
        @click="!isCurrLoading && handleClickItem(item.value)"
        v-for="item in menus"
        :key="item.value"
      >
        <div class="dt-menu-item" :class="activeMenu === item.value && 'dt-menu-active'">
          {{ item.label }}
          <span v-if="item.nested">
            <b-icon-chevron-up
              style="color: white"
              v-if="activeMenu === item.value"
            ></b-icon-chevron-up>
            <b-icon-chevron-down v-else></b-icon-chevron-down>
          </span>
        </div>
        <div v-if="item.nested && item.value === activeMenu">
          <div
            @click.stop="!isCurrLoading && handleClickChildrenItem({childValue: x, parentValue: item.value})"
            v-for="(x, index) in currChildMenu"
            :key="index"
            class="dt-menu-item-child"
            :class="activeChildMenu === x && 'dt-menu-active'"
          >
            {{ x }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dateUtil from '@/util/date';
import dataMenus from '@/util/data-table';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';

export default {
  name: 'dt-sidebar',
  components: { DatePicker },
  props: [
    'activeMenu',
    'range',
    'massId',
    'interval',
    'assets',
    'isCurrLoading',
    'height',
    'activeChildMenu',
    'currChildMenu',
    'isSingleEngine',
  ],
  data() {
    return {
      visibility: 'click',
      masks: {
        input: 'YYYY-MM-DD h:mm',
      },
      localRange: {
        start: new Date(),
        end: new Date(),
      },
      dataIntervalOptions: dateUtil.dataIntervalOptions,
      menus: dataMenus || [],
      popover: {
        positionFixed: true,
        placement: 'top-end',
      },
    };
  },
  mounted() {
    if (this.range) {
      this.localRange = this.range;
    }
  },
  watch: {
    range: {
      handler(val) {
        console.log('handle set curr date range', val);
        this.localRange.start = val.start;
        this.localRange.end = val.end;
      },
      deep: true,
    },
  },
  methods: {
    onChangeAsset(v) {
      this.$emit('changeAsset', v.target.value);
    },
    onChangedInterval(v) {
      this.$emit('changeInterval', v.target.value);
    },
    handleClickItem(v) {
      this.$emit('changeMenu', v);
    },
    handleClickChildrenItem(v) {
      this.$emit('changeMenuChildren', v);
    },
    closePopup() {
      this.$emit('changeDateRange', this.localRange);
      this.$nextTick(() => {
        this.$refs.pickie.hidePopover();
      });
    },
  },
};
</script>

<style scoped lang="scss">
.form-control {
  padding: 5px !important;
}
.dt-sidebar-header {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 400px;
  height: 45px;
  background: #ffffff;
  border-bottom: 3px solid #dee2e6;
  h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #002a64;
  }
}
.dt-sidebar-filter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;
  border-bottom: 3px solid #dee2e6;
  height: 140px;
}
.dt-form {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  label {
    line-height: 1.2;
    margin: 0;
    font-size: 12px;
  }
}
.dt-row-date {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}
.dt-form-group {
  display: flex;
  align-items: center;
  gap: 5px;
}
.dt-menu-container {
  overflow-y: auto;
  .dt-menu-item {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 10px;
    background: #ffffff;
    border-bottom: 1px solid #dee2e6;
    justify-content: space-between;
    cursor: pointer;
  }
  .dt-menu-item-child {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 10px 7px 15px;
    background: #ffffff;
    border-bottom: 1px solid #dee2e6;
    justify-content: space-between;
    cursor: pointer;
    margin-left: 25px;
  }
  .dt-menu-active {
    color: white !important;
    background: #002a64;
  }
}
</style>
