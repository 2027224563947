<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="row">
    <!-- <div class="col-md-12">
      <b-table
        bordered
        fixed
        small
        show-empty
        :current-page="currentPage"
        :fields="fields"
        :items="data"
        :per-page="perPage"
        class="bg-white text-center table-logs">
        <template #thead-top="data">
          <b-tr class="text-center">
            <b-th colspan="2" rowspan="2">Asset</b-th>
            <b-th colspan="4">GPS</b-th>
            <b-th colspan="6">Flowmeter (Liter)</b-th>
            <b-th colspan="4">Flowmeter (Density)</b-th>
            <b-th colspan="2" rowspan="2">RPM ME</b-th>
            <b-th colspan="8">AE</b-th>
          </b-tr>
          <b-tr class="text-center">
            <b-th colspan="2">Location</b-th>
            <b-th colspan="2">Movement</b-th>
            <b-th colspan="3">ME Port</b-th>
            <b-th colspan="3">ME Stbd</b-th>
            <b-th colspan="2">ME Port</b-th>
            <b-th colspan="2">ME Stbd</b-th>
            <b-th colspan="2">AE1</b-th>
            <b-th colspan="2">AE2</b-th>
            <b-th colspan="2">AE3</b-th>
            <b-th colspan="2">AE4</b-th>
          </b-tr>
        </template>
        <template v-slot:table-busy="">
          <div class="text-center text-danger my-2">
            <b-spinner variant="success" size="sm" class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(asset)="data">
          {{ asset.massName }}
        </template>
        <template #cell(latitude)="data">
          {{ asset }}
        </template>
        <template #cell(latitude)="data">
          {{ data.item.coordinate[0] | latToDMS }}
        </template>
        <template #cell(longitude)="data">
          {{ data.item.coordinate[1] | longToDMS }}
        </template>
        <template #cell(fm-1-inlet)="data">
          {{ getReadingValue(data.item.readings, 'port-inlet', 'volumeFlowRate') }}
        </template>
        <template #cell(fm-2-outlet)="data">
          {{ getReadingValue(data.item.readings, 'port-outlet', 'volumeFlowRate') }}
        </template>
        <template #cell(fm12-consumption)="data">
          {{
            (getReadingValue(data.item.readings, 'port-inlet', 'volumeFlowRate') - getReadingValue(data.item.readings, 'port-outlet', 'volumeFlowRate')).toFixed(2)
          }}
        </template>
        <template #cell(fm-3-inlet)="data">
          {{ getReadingValue(data.item.readings, 'starboard-inlet', 'volumeFlowRate') }}
        </template>
        <template #cell(fm-4-outlet)="data">
          {{ getReadingValue(data.item.readings, 'starboard-outlet', 'volumeFlowRate') }}
        </template>
        <template #cell(fm34-consumption)="data">
          {{
            (getReadingValue(data.item.readings, 'starboard-inlet', 'volumeFlowRate') - getReadingValue(data.item.readings, 'starboard-outlet', 'volumeFlowRate')).toFixed(2)
          }}
        </template>
        <template #cell(fm-1d-inlet)="data">
          {{ getReadingValue(data.item.readings, 'port-inlet', 'density') }}
        </template>
        <template #cell(fm-2d-outlet)="data">
          {{ getReadingValue(data.item.readings, 'port-outlet', 'density') }}
        </template>
        <template #cell(fm-3d-inlet)="data">
          {{ getReadingValue(data.item.readings, 'starboard-inlet', 'density') }}
        </template>
        <template #cell(fm-4d-outlet)="data">
          {{ getReadingValue(data.item.readings, 'starboard-outlet', 'density') }}
        </template>
        <template #cell(rpm-port)="data">
          {{ getReadingValue(data.item.readings, 'port-RPM', 'value') }}
        </template>
        <template #cell(rpm-starboard)="data">
          {{ getReadingValue(data.item.readings, 'starboard-RPM', 'value') }}
        </template>
        <template #cell(ae1-value)="data">
          {{ getReadingValue(data.item.readings, 'AE1', 'value') }}
        </template>
        <template #cell(ae2-value)="data">
          {{ getReadingValue(data.item.readings, 'AE2', 'value') }}
        </template>
        <template #cell(ae3-value)="data">
          {{ getReadingValue(data.item.readings, 'AE3', 'value') }}
        </template>
        <template #cell(ae4-value)="data">
          {{ getReadingValue(data.item.readings, 'AE4', 'value') }}
        </template>
        <template #cell(ae1-runningTime)="data">
          {{ getReadingValue(data.item.readings, 'AE1', 'runningTime') }}
        </template>
        <template #cell(ae2-runningTime)="data">
          {{ getReadingValue(data.item.readings, 'AE2', 'runningTime') }}
        </template>
        <template #cell(ae3-runningTime)="data">
          {{ getReadingValue(data.item.readings, 'AE3', 'runningTime') }}
        </template>
        <template #cell(ae4-runningTime)="data">
          {{ getReadingValue(data.item.readings, 'AE4', 'runningTime') }}
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        pills
        size="sm"
      ></b-pagination>
    </div> -->
    <div class="col-md-12">
      <!-- <pre>{{series}}</pre> -->
      <b-table striped hover fixed small :items="series" :fields="fields" responsive="true" class="bg-white text-center table-logs">
        <!-- <template #cell(deviceData.gps)="data">
          {{ data.item }}
        </template> -->
        <template #thead-top="data">
          <b-tr class="text-center">
            <b-th colspan="1"></b-th>
            <b-th colspan="3">GPS</b-th>
            <b-th colspan="2">RPM</b-th>
            <b-th colspan="3">AE</b-th>
            <b-th colspan="3">Port ME Flowmeter</b-th>
            <b-th colspan="3">Starboard ME Flowmeter</b-th>
            <b-th colspan="2">IOT</b-th>
          </b-tr>
          <!-- <b-tr class="text-center">
            <b-th colspan="2">Location</b-th>
            <b-th colspan="2">Movement</b-th>
            <b-th colspan="3">ME Port</b-th>
            <b-th colspan="3">ME Stbd</b-th>
            <b-th colspan="2">ME Port</b-th>
            <b-th colspan="2">ME Stbd</b-th>
            <b-th colspan="2">AE1</b-th>
            <b-th colspan="2">AE2</b-th>
            <b-th colspan="2">AE3</b-th>
            <b-th colspan="2">AE4</b-th>
          </b-tr> -->
        </template>
        <template #cell(datetime)="data">
          {{ data.item.timestamp | toLocalDate }}
        </template>
        <template #cell(coordinate)="data">
          <span v-if="data.item.deviceData&&data.item.deviceData.gps&&data.item.deviceData.gps[0]">{{ data.item.deviceData.gps[0].data.coordinate[0] | latToDMS }}, {{ data.item.deviceData.gps[0].data.coordinate[1] | latToDMS }}</span>
        </template>
        <template #cell(speed)="data">
          <span v-if="data.item.deviceData.gps">{{ data.item.deviceData.gps[0].data.speed | toKnot }}</span>
        </template>
        <template #cell(track)="data">
          <span v-if="data.item.deviceData.gps">{{ data.item.deviceData.gps[0].data.track }}</span>
        </template>
        <template #cell(battery)="data">
          {{ data.item.deviceData.power[0].data.batteryVoltage}} V
        </template>
        <template #cell(cpu)="data">
          {{ data.item.deviceData.gateway[0].data.cpuUsage}}
        </template>
        <template #cell(portRpm)="data">
          <span v-if="data.item.deviceData.rpm[0].data.port">{{ data.item.deviceData.rpm[0].data.port[0] }}</span>
          <span v-else>-</span>
        </template>
        <template #cell(starboardRpm)="data">
          <span v-if="data.item.deviceData.rpm[0].data.starboard">{{ data.item.deviceData.rpm[0].data.starboard[0]}}</span>
          <span v-else>-</span>
        </template>
        <template #cell(ae1)="data">
          {{ data.item.deviceData.ae[0].data[0]}}
        </template>
        <template #cell(ae2)="data">
          {{ data.item.deviceData.ae[0].data[1]}}
        </template>
        <template #cell(ae3)="data">
          {{ data.item.deviceData.ae[0].data[2]}}
        </template>
        <template #cell(fm1-massFlowRate)="data">
          <span v-if="data.item.deviceData.flowMeter['fm-3']">
            {{ (data.item.deviceData.flowMeter['fm-3'][0].data.volumeFlowRate / 60).toFixed(2) }}
          </span>
        </template>
        <template #cell(fm2-massFlowRate)="data">
          <span v-if="data.item.deviceData.flowMeter['fm-4']">
            {{ (data.item.deviceData.flowMeter['fm-4'][0].data.volumeFlowRate / 60).toFixed(2) }}
          </span>
        </template>
        <template #cell(in-massCons)="data">
          <span v-if="data.item.deviceData.flowMeter['fm-3']&&data.item.deviceData.flowMeter['fm-4']">
            {{ ((data.item.deviceData.flowMeter['fm-3'][0].data.volumeFlowRate - data.item.deviceData.flowMeter['fm-4'][0].data.volumeFlowRate) / 60).toFixed(2) }}
          </span>
        </template>
        <template #cell(fm3-massFlowRate)="data">
          <span v-if="data.item.deviceData.flowMeter['fm-1']">
            {{ (data.item.deviceData.flowMeter['fm-1'][0].data.volumeFlowRate / 60).toFixed(2) }}
          </span>
        </template>
        <template #cell(fm4-massFlowRate)="data">
          <span v-if="data.item.deviceData.flowMeter['fm-2']">
            {{ (data.item.deviceData.flowMeter['fm-2'][0].data.volumeFlowRate / 60).toFixed(2) }}
          </span>
        </template>
        <template #cell(out-massCons)="data">
          <span v-if="data.item.deviceData.flowMeter['fm-1']&&data.item.deviceData.flowMeter['fm-2']">
            {{ ((data.item.deviceData.flowMeter['fm-1'][0].data.volumeFlowRate - data.item.deviceData.flowMeter['fm-2'][0].data.volumeFlowRate) / 60).toFixed(2) }}
          </span>
        </template>
      </b-table>
      <!-- <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        pills
        size="sm"
      ></b-pagination> -->
    </div>
  </div>
</template>

<script>

// import SeriesSvc from '@/services/SeriesSvc';

export default {
  name: 'DataView',
  props: ['series', 'isLoading'],
  data() {
    return {
      currentPage: 1,
      perPage: 5,
      totalRows: 10,
      filter: {},
      selected: {},
      // series: [],
      fields: [
        {
          key: 'datetime',
          label: 'Date & Time',
          rowspan: 3,
        },
        'coordinate',
        'speed',
        'track',
        {
          key: 'portRpm',
          label: 'Avg Port RPM',
        },
        {
          key: 'starboardRpm',
          label: 'Avg Stb RPM',
        },
        {
          key: 'ae1',
          label: 'AE1',
        },
        {
          key: 'ae2',
          label: 'AE2',
        },
        {
          key: 'ae3',
          label: 'AE3',
        },
        {
          key: 'fm1-massFlowRate',
          label: 'Inlet',
        },
        {
          key: 'fm2-massFlowRate',
          label: 'Outlet',
        },
        {
          key: 'in-massCons',
          label: 'Cons',
        },
        {
          key: 'fm3-massFlowRate',
          label: 'Inlet',
        },
        {
          key: 'fm4-massFlowRate',
          label: 'Outlet',
        },
        {
          key: 'out-massCons',
          label: 'Cons',
        },
        'cpu',
        'battery',
        // 'deviceData.flowMeter',
      ],
    };
  },
  computed: {
    // fields() {
    //   return [
    //     'dateTime',
    //     'asset',
    //     'latitude',
    //     'longitude',
    //     'speed',
    //     'direction',
    //     {
    //       key: 'fm-1-inlet',
    //       label: 'Inlet',
    //     },
    //     {
    //       key: 'fm-2-outlet',
    //       label: 'Outlet',
    //     },
    //     {
    //       key: 'fm12-consumption',
    //       label: 'Cons.',
    //     },
    //     {
    //       key: 'fm-3-inlet',
    //       label: 'Inlet',
    //     },
    //     {
    //       key: 'fm-4-outlet',
    //       label: 'Outlet',
    //     },
    //     {
    //       key: 'fm34-consumption',
    //       label: 'Cons.',
    //     },
    //     {
    //       key: 'fm-1d-inlet',
    //       label: 'Inlet',
    //     },
    //     {
    //       key: 'fm-2d-outlet',
    //       label: 'Outlet',
    //     },
    //     {
    //       key: 'fm-3d-inlet',
    //       label: 'Inlet',
    //     },
    //     {
    //       key: 'fm-4d-outlet',
    //       label: 'Outlet',
    //     },
    //     {
    //       key: 'rpm-port',
    //       label: 'ME Port',
    //     },
    //     {
    //       key: 'rpm-starboard',
    //       label: 'ME Stbd',
    //     },
    //     {
    //       key: 'ae1-value',
    //       label: 'Value',
    //     },
    //     {
    //       key: 'ae1-runningTime',
    //       label: 'Running Time',
    //     },
    //     {
    //       key: 'ae2-value',
    //       label: 'Value',
    //     },
    //     {
    //       key: 'ae2-runningTime',
    //       label: 'Running Time',
    //     },
    //     {
    //       key: 'ae3-value',
    //       label: 'Value',
    //     },
    //     {
    //       key: 'ae3-runningTime',
    //       label: 'Running Time',
    //     },
    //     {
    //       key: 'ae4-value',
    //       label: 'Value',
    //     },
    //     {
    //       key: 'ae4-runningTime',
    //       label: 'Running Time',
    //     },
    //   ];
    // },
    // data() {
    //   if (!Array.isArray(this.series)) {
    //     return [];
    //   }

    //   return this.series.flatMap((v) => v.data);
    // },
    // totalRows() {
    //   return this.series.length;
    // },
  },
  // created() {
  //   console.log('Created....');
  // },
  // watch: {
  //   $route(to, from) {
  //     console.log('Route update... ', to, from);
  //     this.getSeries();
  //   },
  // },
  methods: {
    // getReadingValue(readings, label, field) {
    //   const reading = readings.find((v) => v.label === label);
    //   if (typeof reading === 'undefined') return '-';
    //   return reading[field].toFixed(2);
    // },
    // getSeries() {
    //   const promises = [];
    //   if (this.asset.devices) {
    //     Object.keys(this.asset.devices).forEach((deviceId) => {
    //       console.log('Mounted... ', this.asset);
    //       if (this.$route.query && this.$route.query.start && this.$route.query.end) { // if there is any start and and
    //         promises.push(SeriesSvc.getSeries(this.$route.query.start, this.$route.query.end, [this.asset.devices[deviceId].devcUniqueId], 60).then(((series) => {
    //           console.log('Default range series... ', series);
    //           if (series.data.data.count > 0) {
    //             series.data.data.series.forEach((serie) => {
    //               serie.data = JSON.parse(serie.data);
    //               if (serie.data.readings) {
    //                 if (!serie.deviceData) serie.deviceData = {};
    //                 serie.data.readings.forEach((reading) => {
    //                   if (reading.type === 'flowMeter') {
    //                     if (!serie.deviceData[reading.type]) serie.deviceData[reading.type] = {};
    //                     if (!serie.deviceData[reading.type][reading.deviceId]) serie.deviceData[reading.type][reading.deviceId] = [];
    //                     serie.deviceData[reading.type][reading.deviceId].push(reading);
    //                   } else {
    //                     if (!serie.deviceData[reading.type]) serie.deviceData[reading.type] = [];
    //                     serie.deviceData[reading.type].push(reading);
    //                   }
    //                   // console.log('S: ', reading);
    //                 });
    //               } else {
    //                 console.log('No readings Object...');
    //               }
    //               this.series.push(serie);
    //             });
    //           } else {
    //             console.log('No data...');
    //           }
    //         })));
    //       } else {
    //         promises.push(SeriesSvc.getSeriesByInterval(this.$route.query.unit, this.$route.query.duration, [this.asset.devices[deviceId].devcUniqueId], 10).then(((series) => {
    //           console.log('Default last series... ', series);
    //           if (series.data.data.count > 0) {
    //             series.data.data.series.forEach((serie) => {
    //               serie.data = JSON.parse(serie.data);
    //               if (serie.data.readings) {
    //                 if (!serie.deviceData) serie.deviceData = {};
    //                 serie.data.readings.forEach((reading) => {
    //                   if (reading.type === 'flowMeter') {
    //                     if (!serie.deviceData[reading.type]) serie.deviceData[reading.type] = {};
    //                     if (!serie.deviceData[reading.type][reading.deviceId]) serie.deviceData[reading.type][reading.deviceId] = [];
    //                     serie.deviceData[reading.type][reading.deviceId].push(reading);
    //                   } else {
    //                     if (!serie.deviceData[reading.type]) serie.deviceData[reading.type] = [];
    //                     serie.deviceData[reading.type].push(reading);
    //                   }
    //                   // console.log('S: ', reading);
    //                 });
    //               } else {
    //                 console.log('No readings Object...');
    //               }
    //               this.series.push(serie);
    //             });
    //           } else {
    //             console.log('No data...');
    //           }
    //         })));
    //       }
    //     });
    //   }
    //   return Promise.all(promises);
    // },
  },
  async mounted() {
    // await this.getSeries();
    // const response = await SeriesSvc.getSeriesByInterval('day', 30, [deviceId]);
  },
};
</script>

<style scoped lang="scss">
.table-logs {
  display: block;
  font-size: 11px;
  overflow-x: auto;
  white-space: nowrap;
}
</style>
