export default (doc, data, fontSize, lineSpacing) => {
  const pageHeight = doc.internal.pageSize.height;
  let startY = pageHeight - 30;

  const startX = 20;
  const pageWidth = doc.internal.pageSize.width;
  const endX = pageWidth - startX;
  // const thirdX = (pageWidth - startX * 2) / 3;

  doc.setDrawColor(157, 183, 128);
  doc.setDrawColor('#012b65').setLineWidth(1);
  doc.line(startX, startY, endX, startY);
  startY += lineSpacing * 1.1;

  doc.setFontSize(fontSize);
  // const totalPagesExp = '{total_pages_count_string}';
  // console.log(doc.putTotalPages());
  const pageNumber = `Page ${doc.internal.getNumberOfPages()}`;

  // doc.text(pageNumber, 57, 50);
  // doc.putTotalPages(pageNumber);
  // console.log(pageNumber);
  // Total page number plugin only available in jspdf v1.0+
  // if (typeof doc.putTotalPages === 'function') {
  //   str = str + ' of ' + totalPagesExp
  // }

  doc.text(pageNumber, startX, startY);

  // //-------Contact Details---------------------
  // footerItem(doc, data.addressSender, data.label.contact, startX, startY, lineSpacing);
  // startX += thirdX;

  // //-------Bank Details---------------------
  // footerItem(doc, data.personalInfo.bank, data.label.bank, startX, startY, lineSpacing);
  // startX += thirdX;

  // //-------Tax Details---------------------
  // footerItem(doc, data.personalInfo.taxoffice, data.label.taxinfo, startX, startY, lineSpacing);
};
