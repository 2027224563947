<template>
  <div :id="canId">
    <p class="text-center card-text">{{data.devcName}}</p>
    <img :src="logo" style="height: 201px; width: 100%" />
    <p class="text-right card-text">{{data.devcUpdatedTime}}</p>
  </div>
</template>

<script>
import logo from '@/assets/images/map/map_canvas.jpeg';

export default {
  name: 'MapCanvas',
  props: ['canId', 'data'],
  data() {
    return {
      logo,
    };
  },
  created() {
    const fields = [
      { key: 'datetime', label: 'Date', class: 'text-center' },
      { key: 'data.altitude', label: 'Altitude', class: 'text-center' },
      { key: 'data.heading', label: 'Heading', class: 'text-center' },
      { key: 'data.latitude', label: 'Latitude', class: 'text-center' },
      { key: 'data.longitude', label: 'Longitude', class: 'text-center' },
      { key: 'data.longitude', label: 'Longitude', class: 'text-center' },
      { key: 'data.speed', label: 'Speed', class: 'text-center' },
    ];
    this.$emit('fieldsData', fields);
  },
  watch: {
    data: {
      immediate: true,
      handler(v) {
        console.log('mapcanvas', v);
      },
    },
  },
};
</script>

<style scoped>

</style>
