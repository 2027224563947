<template>
  <b-row class="mb-3">
    <b-col md="12">
      <div class="text-center">
        <h3>UTILIZATION</h3>
      </div>
      <div class="table-responsive">
        <table class="table table-striped table-bordered">
          <thead>
          <tr>
            <td style="width: 40%">Description</td>
            <td style="width: 15%" class="text-center">Port</td>
            <td style="width: 15%" class="text-center">Center</td>
            <td style="width: 15%" class="text-center">Stbd</td>
            <td style="width: 15%" class="text-center">Unit</td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>ME Utilization</td>
            <td class="text-center">0.00</td>
            <td class="text-center text-white" style="background-color: gray;">N/A</td>
            <td>0.00</td>
            <td class="text-center">%</td>
          </tr>
          <tr>
            <td>AE Utilization</td>
            <td class="text-center">0.00</td>
            <td class="text-center">0.00</td>
            <td>0.00</td>
            <td class="text-center">%</td>
          </tr>
          </tbody>
        </table>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'Utilization',
};
</script>

<style scoped>

</style>
