import Vue from 'vue';

Vue.filter('toKnot', (value) => {
  if (value === undefined) return '';
  return (value / 1.852).toFixed(2);
});

Vue.filter('toKM', (value) => {
  if (value === undefined) return '';
  return (value / 1000).toFixed(2);
});

Vue.filter('toMiles', (value) => {
  if (value === undefined) return '';
  return (value * 0.00062137).toFixed(2);
});

Vue.filter('toNM', (value) => {
  if (value === undefined) return '';
  return (value * 0.00053996).toFixed(2);
});
