<template>
  <b-row class="mb-3">
    <b-col md="12">
      <div class="text-center">
        <h3>SUMMARY</h3>
      </div>
      <div class="table-responsive table-responsive-sm">
        <table class="table table-responsive-sm table-striped table-bordered">
          <thead>
          <tr>
            <td style="width: 30%">Description</td>
            <td style="width: 10%" class="text-center">Value</td>
            <td style="width: 10%" class="text-center">Unit</td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in gps" v-bind:key="`gps-${index}`">
            <td>{{item.device.devcName}}</td>
            <td class="text-center" :class="item.data[0] ? '' : 'text-white'" :style="item.data[0] ? '' : 'background-color: gray'">{{item.data[0]}}</td>
            <td class="text-center" :class="item.data[0] ? '' : 'text-white'" :style="item.data[0] ? '' : 'background-color: gray'">RPM</td>
          </tr>
          <tr v-for="(item, index) in battery" v-bind:key="`battery-${index}`">
            <td>{{item.device.devcName}}</td>
            <td class="text-center" :class="item.data[0] ? '' : 'text-white'" :style="item.data[0] ? '' : 'background-color: gray'">{{item.data[0]}}</td>
            <td class="text-center" :class="item.data[0] ? '' : 'text-white'" :style="item.data[0] ? '' : 'background-color: gray'">Hours</td>
          </tr>
          <tr v-for="(item, index) in flowMeter" v-bind:key="`flowMeter-${index}`">
            <td>{{item.device.devcName}}</td>
            <td class="text-center" :class="item.data[0] ? '' : 'text-white'" :style="item.data[0] ? '' : 'background-color: gray'">{{item.data[0]}}</td>
            <td class="text-center" :class="item.data[0] ? '' : 'text-white'" :style="item.data[0] ? '' : 'background-color: gray'">L</td>
          </tr>
          </tbody>
        </table>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'Summary',
  props: ['battery', 'flowMeter', 'gateway', 'gps'],
};
</script>

<style scoped>

</style>
