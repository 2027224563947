<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="table-responsive">
    <b-table
      class="table table-responsive table-responsive-sm table-responsive-md
                   table-responsive-lg table-responsive-xl"
      fixed
      show-empty
      responsive
      tbody-tr-class="one-line-td-table"
      :busy="loading"
      :fields="fields"
      :items="canRead(access) ? data : []">
      <template v-slot:table-busy="">
        <div class="text-center text-danger my-2">
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </div>
      </template>
      <template v-slot:head(devcStatus)="data">
        <div class="text-center">
          {{data.label}}
        </div>
      </template>
      <template v-slot:cell(action)="data">
        <div class="text-center">
          <role-edit
            :access="'master_device'"
            :role="data.item"
            @onDeleteClicked="onActionDelete"
            @onViewClicked="onActionView"
            @onEditClicked="onActionEdit"/>
        </div>
      </template>
      <template v-slot:cell(devcStatus)="data">
        <div class="text-center">
          {{normalizeStatus(data.value)}}
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="pagination.current"
      :per-page="pagination.pageSize"
      :total-rows="pagination.totalSize"
      @change="onPaginationChanged"
    ></b-pagination>
  </div>
</template>

<script>
import RoleEdit from './Edit';

export default {
  name: 'RoleList',
  components: { RoleEdit },
  props: ['access', 'data', 'group', 'loading', 'pagination'],
  data() {
    return {
      fields: [
        {
          key: 'roleName',
          label: 'Role Name',
          class: 'text-left',
        },
        {
          key: 'action',
          label: '',
        },
      ],
    };
  },
  methods: {
    normalizeStatus(v) {
      if (v === '1') {
        return 'Approved';
      }

      return 'Not Approved';
    },
    onPaginationChanged(page) {
      this.$emit('onPageChanged', page);
    },
    onActionDelete(data) {
      this.$emit('onActionDelete', data);
    },
    onActionEdit(data) {
      this.$emit('onActionEdit', data);
    },
    onActionView(data) {
      this.$emit('onActionView', data);
    },
  },
};
</script>

<style scoped>

</style>
