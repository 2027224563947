<template>
  <div class="content-body">
    <label class="text-left">Select company</label>

    <div v-if="data.length === 0">
      <div class="d-flex w-100 justify-content-between">
        <h5>
          No Company available.
        </h5>
      </div>
    </div>

    <div v-else class="company-list-item">
      <b-list-group
        v-for="(company, i) in data"
        :key="i">
        <b-list-group-item
          href="#"
          :active="selected && selected.compId === company.compId"
          :disabled="loading"
          class="flex-column align-items-start mb-1"
          @click="setSelectedCompany(company)">
          <div :name="company.compId" class="d-flex w-100 justify-content-between">
            <h5 :class="selected && selected.compId === company.compId ? 'selected-preference-item' : ''">
              {{ company.compName }}
            </h5>
            <i class="ri-arrow-right-s-line"></i>
          </div>
        </b-list-group-item>
      </b-list-group>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanySelection',
  props: {
    data: Array,
    loading: Boolean,
    selected: Object,
  },
  methods: {
    async setSelectedCompany(v) {
      this.$emit('onSelected', v);
    },
  },
};
</script>

<style scoped>
  .company-list-item {
    height: 250px;
    overflow-y: auto;
  }
</style>
