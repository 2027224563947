<template>
  <div class="iq-card mb-0" style="min-width: 300px;">
    <div class="iq-card-header d-flex justify-content-between">
      <div class="iq-header-title">
        <h4 class="card-title" v-if="data.roleId">Edit Role</h4>
        <h4 class="card-title" v-if="!data.roleId">Add New Role</h4>
      </div>
    </div>
    <div class="iq-card-body">
      <div class="row">
        <div role="group" class="form-group col-md-6">
          <label for="companyName" class="d-block form-label text-left">
            Name:
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <input id="companyName"
                     autocomplete="off"
                     v-model="data.roleName"
                     placeholder="Enter role name"
                     type="text"
                     :class="errors.roleName ? 'is-invalid' : ''"
                     class="form-control">
              <b-form-invalid-feedback v-if="errors.roleName">
                {{ Object.values(errors.roleName) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <b-button @click="onCancelAddClicked"
                  class="mr-1"
                  size="sm"
                  :disabled="isSubmit"
                  variant="outline-danger">
          <div class="mx-2">Cancel</div>
        </b-button>
        <b-button type="submit"
                  @click="onSubmitClicked"
                  :disabled="isSubmit"
                  variant="primary"
                  size="sm"
                  class="ml-1">
          <div class="mx-2">
            <i v-if="isSubmit" class="ri-loader-line"></i>
            Submit
          </div>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RoleForm',
  props: ['data', 'errors', 'isSubmit'],
  methods: {
    onCancelAddClicked() {
      this.$emit('onCancelAddClicked');
    },
    onSubmitClicked() {
      const data = { ...this.data };
      this.$emit('onSubmitClicked', data);
    },
  },
};
</script>

<style scoped>

</style>
