<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <div class="iq-card mb-3 mt-3" style="min-width: 300px;">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="card-title">Add Role Permission</h4>
        </div>
      </div>
      <div class="iq-card-body">
        <div class="row">
          <div class="col-md-6 form-group" role="group">
            <label class="d-block form-label text-left">
              Permission:
            </label>
            <v-select
              id="prmsId"
              label="prmsName"
              placeholder="Select Permission"
              v-model="selected.permission"
              :options="permissions"
              :disabled="isSubmit"
              :reduce="permission => permission.prmsId">
              <template slot="no-options">
                {{permissions.length > 0 ? 'Type to search permission' : 'No permission available'}}
              </template>
            </v-select>
          </div>
          <div class="col-md-6 form-group" role="group">
            <label class="d-block form-label text-left">
              Operations:
            </label>
            <b-form-group>
              <b-form-checkbox-group
                class="mt-2 d-flex justify-content-between"
                id="value"
                name="text"
                v-model="selected.operations"
                :disabled="isSubmit"
                :options="operations"
              ></b-form-checkbox-group>
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="iq-card-body">
        <div class="row">
          <div class="col-md-12 text-right">
            <b-button
              type="submit"
              @click="onSubmitClicked"
              :disabled="isSubmit"
              variant="primary"
              size="sm"
              class="ml-1">
              <div class="mx-2">
                <i v-if="isSubmit" class="ri-save-line"></i>
                Submit
              </div>
            </b-button>
          </div>
        </div>
      </div>
      <b-modal
        size="lg"
        hide-footer
        no-close-on-esc
        no-close-on-backdrop
        v-model="displayDeletion">
        <delete-item
          :isSubmit="isSubmit"
          @onCancelDeletion="displayDeletion = false"
          @onConfirmDeletion="onConfirmDeletion"/>
      </b-modal>
    </div>
    <div class="iq-card mb-3" style="min-width: 300px;">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="card-title">List Role Permission</h4>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="text-right">
              <button-options
                :access="canRead('master_role')"
                :hideDetail="true"
                :isBackEnabled="true"
                :isRefreshEnabled="true"
                :refreshed="isLoading"
                @onRefreshed="getData('Refreshed')"/>
            </div>
          </div>
        </div>
      </div>
      <div class="iq-card-body">
        <div class="table-responsive">
          <b-table
            class="table table-responsive table-responsive-sm table-responsive-md
                   table-responsive-lg table-responsive-xl"
            fixed
            show-empty
            responsive
            :busy="isLoading"
            :fields="['rperPrms.prmsName', 'operations', 'action']"
            :items="data">
            <template v-slot:table-busy="">
              <div class="text-center text-danger my-2">
                <b-spinner variant="success" size="sm" class="align-middle"></b-spinner>
              </div>
            </template>
            <template v-slot:head(rperPrms.prmsName)="data">
              Permission
            </template>
            <template v-slot:cell(operations)="data">
              <b-form-group>
                <b-form-checkbox-group
                  name="rperOperations"
                  v-model="roleOperations[data.index]"
                  :disabled="isSubmit || isLoading"
                  :options="JSON.parse(data.item.rperPrms.prmsOperations)"
                ></b-form-checkbox-group>
              </b-form-group>
            </template>
            <template v-slot:cell(action)="data">
              <button class="btn btn-primary m-1 btn-sm"
                      @click="saveRoleOperation(roleOperations[data.index], data.item)"
                      :disabled="isSubmit || isLoading">
                <i class="ri-save-line"></i>
                Submit
              </button>
              <button class="btn btn-light m-1 btn-sm"
                      :disabled="isSubmit || isLoading"
                      @click="openDelete(data.item)">
                <i class="ri-delete-bin-7-line"></i>
                Delete
              </button>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonOptions from '@/components/directives/button-options';
import DeleteItem from '@/components/directives/delete-item';
import RoleSvc from '@/services/RoleSvc';
import UserSvc from '@/services/UserSvc';

import requestBuilder from '@/util/request.builder';

export default {
  name: 'RoleDetail',
  components: { ButtonOptions, DeleteItem },
  data() {
    return {
      data: [],
      displayDeletion: false,
      errors: {},
      filter: {
        rperRoleId: this.$route.params.id,
      },
      id: this.$route.params.id,
      isLoading: false,
      isRefreshed: false,
      isSubmit: false,
      operations: [
        { text: 'Create', value: 'create' },
        { text: 'Read', value: 'read' },
        { text: 'Update', value: 'update' },
        { text: 'Delete', value: 'delete' },
      ],
      pagination: {
        current: 1,
        pageSize: 50,
        totalSize: 0,
      },
      permissions: [],
      roleOperations: [],
      selected: {},
      related: {},
    };
  },
  methods: {
    async saveRoleOperation(operations, item) {
      this.isSubmit = true;
      try {
        const data = {
          rperOperations: JSON.stringify(operations),
          rperRoleId: item.rperRoleId,
          rperPrmsId: item.rperPrmsId,
        };
        const res = await UserSvc.updateRolePermission(data, item.rperId);
        if (res.data.status.error === 0) {
          this.$toast.success('Updated');
        } else {
          this.errors = res.data.status.message;
        }
      } catch (e) {
        this.$toast.error(e.message);
      } finally {
        this.isSubmit = false;
      }
    },
    async onSubmitClicked() {
      const data = {
        rperOperations: JSON.stringify(this.selected.operations),
        rperRoleId: this.id,
        rperPrmsId: this.selected.permission,
      };
      this.errors = {};
      this.isSubmit = true;
      try {
        const resp = await UserSvc.addRolePermission(data);
        if (resp.data.status.error === 0) {
          await this.getData('Success');
        } else {
          const errors = resp.data.status.message;
          if (typeof errors === 'object') {
            this.errors = resp.data.status.message;
            this.$toast.error('Error found');
          } else {
            this.$toast.error(resp.data.status.message);
          }
        }
        this.pagination.current = 1;
        this.selected = {};
      } catch (e) {
        this.$toast.error(e.response.data.status.message);
      } finally {
        this.isSubmit = false;
      }
    },
    onConfirmDeletion() {
      this.deleteData('Deleted complete', this.selected);
    },
    async deleteData(notification, data) {
      this.isSubmit = true;
      try {
        const resp = await UserSvc.deleteRolePermission(data.rperId);
        if (resp.data.status.error === 0) {
          await this.$toast.success(notification);
          this.displayDeletion = await false;
          this.pagination.current = 1;
          this.getData();
        } else {
          this.$toast.error(resp.data.status.message);
        }
      } catch (e) {
        this.$toast.error(e.message);
      } finally {
        this.isSubmit = false;
      }
    },
    async getData(notification) {
      this.isLoading = true;
      this.isRefreshed = true;
      try {
        const resp = await RoleSvc.getRolePermissions({
          params: {
            ...requestBuilder.buildFilters(this.pagination, this.filter),
          },
        });
        if (notification) {
          this.$toast.success(notification);
        }
        this.data = resp.data.data;
        this.roleOperations = resp.data.data.map((o) => JSON.parse(o.rperOperations));
      } catch (e) {
        if (e.response.data) {
          if (e.response.data.status.message === 'Authentication Error') {
            this.doLogOut();
          } else {
            this.$toast.error(e.response.data.status.message);
          }
        } else {
          this.$toast.error('Network error');
        }
      } finally {
        this.isLoading = false;
        this.isRefreshed = false;
      }
    },
    async getPermissions() {
      const res = await UserSvc.getPermissions();
      this.related.permissions = res.data.data;
    },
    openDelete(data) {
      this.displayDeletion = true;
      this.selected = data;
    },
    getAvailablePermissions() {
      this.permissions = [];
      // const unAvailablePermissions = this.data.map((o) => o.rperId);
      const permissions = Object.values(this.related.permissions);
      // const availablePermissions = [];
      // console.log(unAvailablePermissions, permissions);
      for (let i = 0; i < permissions.length; i++) {
        // if (unAvailablePermissions.indexOf(permissions[i].prmsId) === -1) {
        this.permissions.push(permissions[i]);
        // }
        // console.log(permissions[i]);
        // const findIndex = permissions.findIndex((o) => o.compId === unAvailablePermissions[i]);
      //   permissions.splice(findIndex, 1);
      }
      // this.permissions = permissions;
    },
  },
  async mounted() {
    await this.getData();
    await this.getPermissions();
    await this.getAvailablePermissions();
  },
};
</script>

<style scoped>

</style>
