<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="table-responsive">
    <b-table
        class="table table-responsive table-responsive-sm table-responsive-md
                   table-responsive-lg table-responsive-xl"
        fixed
        show-empty
        responsive
        tbody-tr-class="one-line-td-table"
        :busy="loading"
        :fields="fields"
        :items="canRead(access) ? data : []">
      <template v-slot:table-busy="">
        <div class="text-center text-danger my-2">
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </div>
      </template>
      <template v-slot:head(code)="data">
        <div class="text-center">
          {{ data.label }}
        </div>
      </template>
      <template v-slot:head(compGrcpId)="data">
        <div class="text-left">
          {{data.label}}
        </div>
      </template>
      <template v-slot:head(compStatus)="data">
        <div class="text-center">
          {{data.label}}
        </div>
      </template>

      <template v-slot:cell(action)="data">
        <div class="text-center">
          <CompanyEdit
            :access="'master_company'"
            :company="data.item"
            @onDeleteClicked="onActionDelete"
            @onEditClicked="onActionEdit"
            @onViewClicked="onActionView"/>
        </div>
      </template>
      <template v-slot:cell(compName)="data">
        <div class="text-left">
          {{data.value}}
        </div>
      </template>
      <template v-slot:cell(compGrcpId)="data">
        <div class="text-left">
          {{normalizeGroup(data)}}
        </div>
      </template>
      <template v-slot:cell(compAddress)="data">
        <div class="text-left">
          {{data.value}}
        </div>
      </template>
      <template v-slot:cell(compPIC)="data">
        <div class="text-left">
          {{data.value}}
        </div>
      </template>
      <template v-slot:cell(compPICPhone)="data">
        <div class="text-left">
          {{data.value}}
        </div>
      </template>
      <template v-slot:cell(compStatus)="data">
        <div class="text-center">
          {{normalizeStatus(data.value)}}
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="pagination.current"
      :per-page="pagination.pageSize"
      :total-rows="pagination.totalSize"
      @change="onPaginationChanged"
    ></b-pagination>
  </div>
</template>

<script>
import CompanyEdit from './Edit';

export default {
  name: 'CompanyList',
  components: { CompanyEdit },
  props: ['access', 'data', 'group', 'loading', 'pagination'],
  data() {
    return {
      currentPage: 1,
      fields: [
        {
          key: 'compName',
          label: 'Name',
          sortable: false,
        },
        {
          key: 'compGrcpId',
          label: 'Link to Group',
        },
        {
          key: 'compStatus',
          label: 'Status',
        },
        {
          key: 'action',
          label: '',
        },
      ],
    };
  },
  methods: {
    normalizeGroup(v) {
      return v.item.compGrcp.grcpName;
    },
    normalizeStatus(v) {
      if (v === '1') {
        return 'Approved';
      }

      return 'Not Approved';
    },
    onPaginationChanged(page) {
      this.$emit('onPageChanged', page);
    },
    onActionDelete(data) {
      this.$emit('onActionDelete', data);
    },
    onActionEdit(data) {
      this.$emit('onActionEdit', data);
    },
    onActionView(data) {
      this.$emit('onActionView', data);
    },
  },
};
</script>

<style scoped>

</style>
