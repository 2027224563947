<template>
  <div>
    <div role="group" class="form-group">
      <div class="bv-no-focus-ring">
        <b-form-group label="Email address" label-for="inputTextEmail">
          <b-form-input
            id="inputTextEmail"
            type="email"
            placeholder="Enter email"
            v-model="form.email"
            :class="errors.email || $v.form.email.$error ? 'is-invalid' : ''">
          </b-form-input>
          <b-form-invalid-feedback v-if="errors.email">
            {{ errors.email }}
          </b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="!$v.form.email.required">
            Can not be empty
          </b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="!$v.form.email.email">
            Email format is email@ramus.com
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>
    <div role="group" class="form-group">
      <div class="bv-no-focus-ring">
        <b-form-group label="Password" label-for="inputTextPassword">
          <b-form-input
            id="inputTextPassword"
            type="password"
            placeholder="Enter password"
            v-model="form.password"
            :class="errors.password || $v.form.password.$error ? 'is-invalid' : ''">
          </b-form-input>
          <b-form-invalid-feedback v-if="errors.password">
            {{ errors.password }}
          </b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="!$v.form.password.required">
            Can not be empty
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>
    <div role="group" class="form-group">
      <div class="bv-no-focus-ring">
        <b-form-checkbox
          id="remember_email"
          v-model="remember_email"
          name="remember_email"
          :value="true"
          :unchecked-value="false"
        >
          Remember my email
        </b-form-checkbox>
      </div>
    </div>
    <div class="form-group">
      <button
        class="btn btn-primary w-100 btn-block"
        type="submit"
        @click="loginUser"
        :disabled="isSubmit">
        <i v-if="isSubmit" class="ri-loader-line"></i>
        <i v-if="isError" class="ri-alert"></i>
        Sign In
        <i class="ri-arrow-right-s-line"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import oktaConfig from '../../config/okta.js';

export default {
  data() {
    return {
      error: null,
      errors: {},
      form: {
        email: undefined,
        password: undefined,
      },
      isSubmit: false,
      isError: true,
      remember_email: true,
    };
  },
  mixins: [validationMixin],
  mounted() {
    const lastEmail = localStorage.getItem('ramus_smart_x_login_email');
    if (lastEmail) {
      this.form.email = lastEmail;
      // this.form.email = `adaemail_${lastEmail}`;
    }
  },
  methods: {
    async doneLogin(token) {
      try {
        await this.$store.dispatch('auth/signIn', token);
        this.isLoading = false;
        this.$emit('onSuccess');
      } catch (err) {
        console.log(err);
        this.isLoading = false;
        this.$emit('onFailure', err);
      }
    },
    async loginUser() {
      this.resetError();
      this.isSubmit = true;
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.isSubmit = false;
      }
      return this.$auth.signInWithCredentials({
        username: this.form.email,
        password: this.form.password,
      }).then((transaction) => {
        console.log(transaction);
        if (transaction.status === 'SUCCESS') {
          return this.$auth.token.getWithoutPrompt({
            clientId: oktaConfig.clientId,
            responseType: ['token', 'id_token'],
            scopes: ['openid', 'email'],
            sessionToken: transaction.sessionToken,
            redirectUri: oktaConfig.redirectUri,
          }).then((response) => {
            // save last email to localStorage
            if (this.remember_email) {
              localStorage.setItem('ramus_smart_x_login_email', this.form.email);
            } else {
              localStorage.removeItem('ramus_smart_x_login_email');
            }
            // console.log('Callback Return : ', JSON.stringify(response.tokens.accessToken));
            // localStorage.token = response.tokens.accessToken;
            // localStorage.idToken = response.tokens.idToken;
            this.doneLogin(response.tokens.accessToken.value);
          });
        }
      }).catch((err) => {
        console.info('error login', err);
        console.info('error login', err.response);
        console.info('error login', JSON.stringify(err));
        this.isSubmit = false;
        this.errors = {
          email: 'Invalid Username or Password',
          password: 'Invalid Username or Password',
        };
        this.$emit('onFailure', err.message);
      });
    },
    resetError() {
      this.errors = {};
    },
  },
  name: 'LoginForm',
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.email': function () {
      this.$v.form.email.$touch();
    },
    // eslint-disable-next-line func-names
    'form.password': function () {
      this.$v.form.password.$touch();
    },
  },
};
</script>

<style scoped>
</style>
