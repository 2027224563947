const dataMenus = [
  {
    label: 'Daily Consumption',
    value: 'daily_cons',
    nested: false,
  },
  {
    label: 'ME Consumption',
    value: 'me_cons',
    nested: true,
    children: [
      {
        label: 'PORT',
        value: 'port',
      },
      {
        label: 'ME2',
        value: 'me2',
      },
    ],
  },
  {
    label: 'AE Consumption',
    value: 'ae_cons',
    nested: false,
    children: [
      {
        label: 'PORT',
        value: 'port',
      },
      {
        label: 'AE2',
        value: 'ae2',
      },
    ],
  },
  {
    label: 'Fuel Usage Chart',
    value: 'fuel_usage',
    nested: false,
  },
  {
    label: 'Fuel Rate ME',
    value: 'fuel_rate',
    nested: true,
    children: [
      {
        label: 'PORT',
        value: 'port',
      },
      {
        label: 'STB',
        value: 'stb',
      },
    ],
  },
  {
    label: 'RPM',
    value: 'rpm',
    nested: true,
    children: [
      {
        label: 'PORT',
        value: 'port',
      },
      {
        label: 'STB',
        value: 'stb',
      },
    ],
  },
  {
    label: 'Tracking/Day',
    value: 'tracking',
    nested: false,
  },
];

export default dataMenus;
