import L from 'leaflet';

function getZoomLevel(bounds, mapDim) {
  // console.log(bounds, mapDim);
  const WORLD_DIM = { height: 512, width: 512 };
  const ZOOM_MAX = 21;
  function latRad(lat) {
    const sin = Math.sin((lat * Math.PI) / 180);
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }
  function zoom(mapPx, worldPx, fraction) {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  }
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  // console.log('NE: ', ne, sw);
  const latFraction = (latRad(ne.lat) - latRad(sw.lat)) / Math.PI;

  const lngDiff = ne.lng - sw.lng;
  const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

  const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
  const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);
  return Math.min(latZoom, lngZoom, ZOOM_MAX);
}

export default {
  constructMapData(assetObject, dom) {
    console.log('Construct map data based on this : ', assetObject, dom);
    const assetIds = Object.keys(assetObject);
    const points = [];
    const config = {};
    // let lastTime = 0;
    // let firstTime;
    assetIds.forEach((asset) => {
      if (assetObject[asset].datas) {
        // if (!assetObject[asset].points) assetObject[asset].points = [];
        assetObject[asset].points = [];
        const timeIndex = Object.keys(assetObject[asset].datas);
        timeIndex.forEach((time) => {
          // if (firstTime === undefined) firstTime = time;
          // if (parseInt(time) > lastTime) {
          //   lastTime = time;
          // }
          // if (parseInt(time) < firstTime) {
          //   firstTime = time;
          // }
          if (assetObject[asset].datas[time].gps) {
            if (assetObject[asset].datas[time].gps.latitude && assetObject[asset].datas[time].gps.longitude) {
              points.push([assetObject[asset].datas[time].gps.latitude, assetObject[asset].datas[time].gps.longitude]);
              assetObject[asset].points.push([assetObject[asset].datas[time].gps.latitude, assetObject[asset].datas[time].gps.longitude]);
            }
            // console.log(assetObject[asset].datas[time].gps);
          }
        });
        // assetObject[asset].endData = assetObject[asset].datas[lastTime];
        // assetObject[asset].startData = assetObject[asset].datas[firstTime];
        // console.log('first data : ', assetObject[asset].fisrt);
      }
      if (assetObject[asset].devices) {
        const deviceIds = Object.keys(assetObject[asset].devices);
        deviceIds.forEach((device) => {
          // console.log(assetObject[asset].devices[device].devcType);
          if (assetObject[asset].devices[device].devcType === 'gps' && assetObject[asset].devices[device].data && assetObject[asset].devices[device].data.length > 0) {
            assetObject[asset].devices[device].data.forEach((d) => {
              points.push([d.latitude, d.longitude]);
            });
            if (assetObject[asset].devices[device].data.length > 1) {
              console.log('Trail ?');
            }
            // console.log('AAAAAAAAA : ', assetObject[asset].devices[device].data);
          }
        });
        // const timeIndex = Object.keys(assetObject[asset].datas);
        // timeIndex.forEach((time) => {
        //   if (assetObject[asset].datas[time].gps) {
        //     if (assetObject[asset].datas[time].gps.latitude && assetObject[asset].datas[time].gps.longitude) {
        //       points.push([assetObject[asset].datas[time].gps.latitude, assetObject[asset].datas[time].gps.longitude]);
        //     }
        //     // console.log(assetObject[asset].datas[time].gps);
        //   }
        // });
      }
    });
    // response.data.data.series.forEach((serie) => {
    //   points.push([serie.latitude, serie.longitude]);
    // });
    if (points.length > 0) {
      const bounds = new L.LatLngBounds(points);
      config.center = bounds.getCenter();
      const targetZoom = getZoomLevel(bounds, { width: dom.clientHeight, height: dom.clientWidth });
      config.zoom = 14;
      if (targetZoom < config.zoom) {
        config.zoom = targetZoom;
      }
      // console.log(targetZoom);
      // console.log(getZoomLevel(bounds, { width: dom.clientHeight, height: dom.clientWidth }));
    }
    console.info('current config', config);
    return { assetObject, config };
  },
};
