<template>
    <div>
      <div v-if="isLoading" class="dt-loading">
        <b-spinner variant="default" size="sm" class="align-middle"></b-spinner>
        <p>Loading data...</p>
      </div>
      <b-table-simple v-else id="summaryTable" hover small responsive>
        <b-thead head-variant="dark">
          <b-tr>
            <b-th style="background-color: #002a64 !important; color: white;">Description</b-th>
            <b-th style="background-color: #002a64 !important; color: white;">Value</b-th>
            <b-th style="background-color: #002a64 !important; color: white;">Unit</b-th>
            <b-th style="background-color: #002a64 !important; color: white;">Description</b-th>
            <b-th style="background-color: #002a64 !important; color: white;">Value</b-th>
            <b-th style="background-color: #002a64 !important; color: white;">Unit</b-th>
          </b-tr>
        </b-thead>
        <b-tbody v-if="dataTables && dataTables.length > 0">
          <b-tr v-for="(data, index) in dataTables" :key="index">
            <b-th>{{ data.label1 }}</b-th>
            <b-th>{{ data.value1 }}</b-th>
            <b-th>{{ data.unit1 }}</b-th>
            <b-th>{{ data.label2 }}</b-th>
            <b-th>{{ data.value2 }}</b-th>
            <b-th>{{ data.unit2 }}</b-th>
          </b-tr>
        </b-tbody>
        <b-tbody v-else>
          <b-th colspan="6" variant="primary" class="text-center">No Data</b-th>
        </b-tbody>
      </b-table-simple>
    </div>
</template>
<script>

export default {
  name: 'Summary',
  props: ['data', 'isLoading'],
  data() {
    return {
      cruiseUnit: 'km',
      flowUnit: 'lph',
      speedUnit: 'knot',
      dataTables: [],
    };
  },
  mounted() {
    console.log('mounted summary>>>', this.data, this.isLoading);
    this.createTableData();
  },
  methods: {
    createTableData() {
      if (this.data && this.data.summaries) {
        const data = this.data;
        let totalFuelUsed = '0';
        if (data.summaries.flowmeter && (data.summaries.flowmeter.AE_IN || data.summaries.flowmeter.AE_OUT)) {
          totalFuelUsed = this.sumArray([data.summaries.flowmeter.portEngineCons, data.summaries.flowmeter.stbEngineCons, data.summaries.flowmeter.aeEngineCons]).toFixed(2);
        } else if (data.summaries.flowmeter && data.summaries.ae) {
          totalFuelUsed = this.sumArray([data.summaries.flowmeter.portEngineCons, data.summaries.flowmeter.stbEngineCons, data.summaries.ae.fuelConsumption.AE1, data.summaries.ae.fuelConsumption.AE2, data.summaries.ae.fuelConsumption.AE3]).toFixed(2);
        } else if (data.summaries.flowmeter && !data.summaries.ae) {
          totalFuelUsed = this.sumArray([data.summaries.flowmeter.portEngineCons, data.summaries.flowmeter.stbEngineCons]).toFixed(2);
        } else if (data.summaries.ae) {
          totalFuelUsed = this.sumArray([data.summaries.ae.fuelConsumption.AE1, data.summaries.ae.fuelConsumption.AE2, data.summaries.ae.fuelConsumption.AE3]).toFixed(2);
        }
        if (data.summaries.rpm.singleEngine) {
          this.dataTables = [
            {
              label1: 'ME Engine Speed',
              value1: data.summaries.rpm ? (data.summaries.rpm.average.MAINENGINE).toFixed(2) : 'N/A',
              unit1: 'RPM',
              label2: 'ME Total Fuel Used',
              value2: data.summaries.flowmeter && data.summaries.flowmeter.portEngineCons ? (data.summaries.flowmeter.portEngineCons).toFixed(2) : 'N/A',
              unit2: 'Liter',
            },
            {
              label1: 'ME Engine Running Hour',
              value1: data.summaries.rpm ? this.$options.filters.toHHMMSS(data.summaries.rpm.runningTime.MAINENGINE) : 'N/A',
              unit1: 'RPM',
              label2: 'Port AE Total Fuel Used',
              value2: data.summaries.ae && data.summaries.ae.fuelConsumption.AE1 ? (data.summaries.ae.fuelConsumption.AE1).toFixed(2) : 'N/A',
              unit2: 'Liter',
            },
            {
              label1: 'Port AE Running Hour',
              value1: data.summaries.ae && data.summaries.ae.runningSeconds.AE1 ? this.$options.filters.toHHMMSS(data.summaries.ae.runningSeconds.AE1) : 'N/A',
              unit1: 'Hour',
              label2: 'Ctr AE Total Fuel Used',
              value2: data.summaries.ae && data.summaries.ae.fuelConsumption.AE2 ? (data.summaries.ae.fuelConsumption.AE2).toFixed(2) : 'N/A',
              unit2: 'Liter',
            },
            {
              label1: 'Ctr AE Running Hour',
              value1: data.summaries.ae && data.summaries.ae.runningSeconds.AE2 ? this.$options.filters.toHHMMSS(data.summaries.ae.runningSeconds.AE2) : 'N/A',
              unit1: 'Hour',
              label2: 'Stb AE Total Fuel Used',
              value2: data.summaries.ae && data.summaries.ae.fuelConsumption.AE3 ? (data.summaries.ae.fuelConsumption.AE3).toFixed(2) : 'N/A',
              unit2: 'Liter',
            },
            {
              label1: 'Stb AE Running Hour',
              value1: data.summaries.ae && data.summaries.ae.runningSeconds.AE3 ? this.$options.filters.toHHMMSS(data.summaries.ae.runningSeconds.AE3) : 'N/A',
              unit1: 'Hour',
              label2: '',
              value2: '',
              unit2: '',
            },
            {
              label1: 'AE Total Fuel Used',
              value1: data.summaries.ae && data.summaries.ae.fuelConsumption ? ((data.summaries.ae.fuelConsumption.AE1 || 0) + (data.summaries.ae.fuelConsumption.AE2 || 0) + (data.summaries.ae.fuelConsumption.AE3 || 0)).toFixed(2) : 'N/A',
              unit1: 'Liter',
              label2: 'Total Fuel Used',
              value2: totalFuelUsed,
              unit2: 'Liter',
            },
          ];
        } else if (data.summaries && data.summaries.flowmeter && (data.summaries.flowmeter.AE_IN || data.summaries.flowmeter.AE_IN)) {
          this.dataTables = [
            {
              label1: 'Port ME Engine Speed',
              value1: data.summaries.rpm ? (data.summaries.rpm.average.PORT).toFixed(2) : 'N/A',
              unit1: 'RPM',
              label2: 'Port ME Total Fuel Used',
              value2: data.summaries.flowmeter && data.summaries.flowmeter.portEngineCons ? (data.summaries.flowmeter.portEngineCons).toFixed(2) : 'N/A',
              unit2: 'Liter',
            },
            {
              label1: 'Stb ME Engine Speed',
              value1: data.summaries.rpm ? (data.summaries.rpm.average.STARBOARD).toFixed(2) : 'N/A',
              unit1: 'RPM',
              label2: 'Stb ME Total Fuel Used',
              value2: data.summaries.flowmeter && data.summaries.flowmeter.stbEngineCons ? (data.summaries.flowmeter.stbEngineCons).toFixed(2) : 'N/A',
              unit2: 'Liter',
            },
            {
              label1: 'Port ME Running Hour',
              value1: data.summaries.rpm ? this.$options.filters.toHHMMSS(data.summaries.rpm.runningTime.PORT) : 'N/A',
              unit1: 'Hour',
            },
            {
              label1: 'Stb ME Running Hour',
              value1: data.summaries.rpm ? this.$options.filters.toHHMMSS(data.summaries.rpm.runningTime.STARBOARD) : 'N/A',
              unit1: 'Hour',
            },
            {
              label1: 'Port Running Hour',
              value1: data.summaries.ae && data.summaries.ae.runningSeconds.AE1 ? this.$options.filters.toHHMMSS(data.summaries.ae.runningSeconds.AE1) : 'N/A',
              unit1: 'Hour',
              label2: 'ME Total Fuel Used',
              value2: data.summaries.flowmeter ? this.sumArray([data.summaries.flowmeter.portEngineCons, data.summaries.flowmeter.stbEngineCons]).toFixed(2) : 'N/A',
              unit2: 'Liter',
            },
            {
              label1: 'Ctr Running Hour',
              value1: data.summaries.ae && data.summaries.ae.runningSeconds.AE2 ? this.$options.filters.toHHMMSS(data.summaries.ae.runningSeconds.AE2) : 'N/A',
              unit1: 'Hour',
              label2: 'AE Total Fuel Used',
              value2: data.summaries.flowmeter && data.summaries.flowmeter.aeEngineCons ? (data.summaries.flowmeter.aeEngineCons).toFixed(2) : 'N/A',
              unit2: 'Liter',
            },
            {
              label1: 'Stb Running Hour',
              value1: data.summaries.ae && data.summaries.ae.runningSeconds.AE3 ? this.$options.filters.toHHMMSS(data.summaries.ae.runningSeconds.AE3) : 'N/A',
              unit1: 'Hour',
              label2: 'Total Fuel Used',
              value2: totalFuelUsed,
              unit2: 'Liter',
            },
          ];
        } else {
          this.dataTables = [
            {
              label1: 'Port ME Engine Speed',
              value1: data.summaries.rpm ? (data.summaries.rpm.average.PORT).toFixed(2) : 'N/A',
              unit1: 'RPM',
              label2: 'Port ME Total Fuel Used',
              value2: data.summaries.flowmeter && data.summaries.flowmeter.portEngineCons ? (data.summaries.flowmeter.portEngineCons).toFixed(2) : 'N/A',
              unit2: 'Liter',
            },
            {
              label1: 'Ctr ME Engine Speed',
              value1: 'N/A',
              unit1: 'RPM',
              label2: 'Ctr ME Total Fuel Used',
              value2: 'N/A',
              unit2: 'Liter',
            },
            {
              label1: 'Stb ME Engine Speed',
              value1: data.summaries.rpm ? (data.summaries.rpm.average.STARBOARD).toFixed(2) : 'N/A',
              unit1: 'RPM',
              label2: 'Stb ME Total Fuel Used',
              value2: data.summaries.flowmeter && data.summaries.flowmeter.stbEngineCons ? (data.summaries.flowmeter.stbEngineCons).toFixed(2) : 'N/A',
              unit2: 'Liter',
            },
            {
              label1: 'Port ME Running Hour',
              value1: data.summaries.rpm ? this.$options.filters.toHHMMSS(data.summaries.rpm.runningTime.PORT) : 'N/A',
              unit1: 'Hour',
              label2: 'AE 1 Total Fuel Used',
              value2: data.summaries.ae && data.summaries.ae.fuelConsumption.AE1 ? (data.summaries.ae.fuelConsumption.AE1).toFixed(2) : 'N/A',
              unit2: 'Liter',
            },
            {
              label1: 'Ctr ME Running Hour',
              value1: 'N/A',
              unit1: 'Hour',
              label2: 'AE 2 Total Fuel Used',
              value2: data.summaries.ae && data.summaries.ae.fuelConsumption.AE2 ? (data.summaries.ae.fuelConsumption.AE2).toFixed(2) : 'N/A',
              unit2: 'Liter',
            },
            {
              label1: 'Stb ME Running Hour',
              value1: data.summaries.rpm ? this.$options.filters.toHHMMSS(data.summaries.rpm.runningTime.STARBOARD) : 'N/A',
              unit1: 'Hour',
              label2: 'AE 3 Total Fuel Used',
              value2: data.summaries.ae && data.summaries.ae.fuelConsumption.AE3 ? (data.summaries.ae.fuelConsumption.AE3).toFixed(2) : 'N/A',
              unit2: 'Liter',
            },
            {
              label1: 'AE 1 Running Hour',
              value1: data.summaries.ae && data.summaries.ae.runningSeconds.AE1 ? this.$options.filters.toHHMMSS(data.summaries.ae.runningSeconds.AE1) : 'N/A',
              unit1: 'Hour',
              label2: 'ME Total Fuel Used',
              value2: data.summaries.flowmeter ? this.sumArray([data.summaries.flowmeter.portEngineCons, data.summaries.flowmeter.stbEngineCons]).toFixed(2) : 'N/A',
              unit2: 'Liter',
            },
            {
              label1: 'AE 2 Running Hour',
              value1: data.summaries.ae && data.summaries.ae.runningSeconds.AE2 ? this.$options.filters.toHHMMSS(data.summaries.ae.runningSeconds.AE2) : 'N/A',
              unit1: 'Hour',
              label2: 'AE Total Fuel Used',
              value2: data.summaries.ae && data.summaries.ae.fuelConsumption ? ((data.summaries.ae.fuelConsumption.AE1 || 0) + (data.summaries.ae.fuelConsumption.AE2 || 0) + (data.summaries.ae.fuelConsumption.AE3 || 0)).toFixed(2) : 'N/A',
              unit2: 'Liter',
            },
            {
              label1: 'AE 3 Running Hour',
              value1: data.summaries.ae && data.summaries.ae.runningSeconds.AE3 ? this.$options.filters.toHHMMSS(data.summaries.ae.runningSeconds.AE3) : 'N/A',
              unit1: 'Hour',
              label2: 'Total Fuel Used',
              value2: totalFuelUsed,
              unit2: 'Liter',
            },
          ];
        }
      }
    },
    sumArray(array) {
      // console.log('Ayeee....', array);
      let out = 0;
      array.forEach((a) => {
        if (a !== undefined) {
          out += a;
        }
      });
      return out;
    },
  },
};
</script>

<style lang="scss">
  .dt-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 9999;
    background: #DEE2E6;
    height: 100%;
    p {
      margin-top: 15px;
    }
  }
  .table-logs {
    display: block;
    font-size: 11px;
    overflow-x: auto;
    white-space: nowrap;
  }
  #summaryTable td {
    border: 1px solid black !important;
  }
  #summaryTable th {
    border: 1px solid black !important;
  }
</style>
