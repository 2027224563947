<template>
  <b-container fluid>
    <div class="iq-card">
      <div class="iq-card-header">
        <div class="iq-header-title w-100">
          <div class="py-3 text-center">
            <h4 class="card-title mb-2">Account Preference</h4>
            <h6 class="text-center">Select your account preference to continue.</h6>
          </div>
        </div>
      </div>
      <div class="iq-card-body">
        <div class="row">
          <div class="col-md-6">
            <GroupSelection
              @onSelected="onGroupSelected"
              :groups="availableGroups"
              :loading="isLoading"
              :selected="selected.group"/>
          </div>
          <div class="col-md-6">
            <CompanySelection
              @onSelected="onCompanySelected"
              :data="companies"
              :loading="isLoading"
              :selected="selected.company"/>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="pull-right mt-3">
              <button
                @click="onConfirmSelection"
                :disabled="!selected.group || !selected.company"
                class="btn btn-primary mt-1">
                <i v-if="isLoading" class="ri-loader-line"></i>
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import CompanySelection from '@/components/user/preference/CompanySelection';
import GroupSelection from '@/components/user/preference/GroupSelection';

export default {
  name: 'CompanyActiveSelection',
  components: {
    CompanySelection,
    GroupSelection,
  },
  computed: {
    availableCompanies() {
      return this.$store.getters['auth/availableCompany'];
    },
    availableGroups() {
      return this.$store.getters['auth/availableGroup'];
    },
  },
  data() {
    return {
      companies: [],
      isLoading: false,
      selected: {
        group: undefined,
        company: undefined,
      },
    };
  },
  methods: {
    async onConfirmSelection() {
      await this.$store.dispatch('auth/changeActiveCompany', this.selected);
      this.$router.push({ name: 'Home' });
    },
    onCompanySelected(company) {
      this.selected.company = company;
    },
    onGroupSelected(group) {
      this.selected.group = group;
      this.companies = Object.values(this.availableCompanies).filter((v) => v.compGrcpId === group.grcpId);
    },
  },
};
</script>

<style scoped>

</style>
