import { render, staticRenderFns } from "./SpeedMeter.vue?vue&type=template&id=bdf68c74&scoped=true&"
import script from "./SpeedMeter.vue?vue&type=script&lang=js&"
export * from "./SpeedMeter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bdf68c74",
  null
  
)

export default component.exports