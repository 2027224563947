<template>
  <div :style="`height: 100%`">
    <div v-if="isLoading" class="dt-loading">
      <b-spinner variant="default" size="sm" class="align-middle"></b-spinner>
      <p>Loading data...</p>
    </div>
    <div v-else class="dt-container">
      <dt-sidebar
        :assets="assets"
        :activeMenu="activeMenu"
        :activeChildMenu="activeChildMenu"
        :range="range"
        :interval="interval"
        :massId="massId"
        :height="height"
        :currChildMenu="currChildMenu"
        :isCurrLoading="isCurrLoading"
        :isSingleEngine="isSingleEngine"
        @changeMenu="onChangeMenu"
        @changeMenuChildren="onChangeMenuChildren"
        @changeAsset="onChangeAsset"
        @changeInterval="onChangeInterval"
        @changeDateRange="onChangeDateRange"
        @refChange="refChange"
      ></dt-sidebar>
      <div class="dt-content">
        <div class="dt-content-header">
          <button @click="download" class="dt-button mr-2">Export PDF</button>
          <div>
            <i @click="onCloseDataTabel" class="ri-close-fill cursor-pointer fs-3" />
          </div>
        </div>
        <div class="dt-container-table" :style="`height: ${height - 45}px;`">
          <DailyConsumption
            v-if="activeMenu === 'daily_cons' && dataSummary"
            :data="dataSummary"
            class="overlay-dataview"
            :isLoading="isCurrLoading"
            :massId="activeAssetData.massId"
          />
          <Tracking
            v-if="activeMenu === 'tracking'"
            :gpsId="gpsId"
            :interval="interval"
            :range="range"
            :data="activeData"
            :isLoading="isCurrLoading"
          />
          <AEConsumption
            v-if="activeMenu === 'ae_cons'"
            :massId="massId"
            :interval="interval"
            :range="range"
            :data="activeData"
            :isLoading="isCurrLoading"
          />
          <MEConsumption
            v-if="activeMenu === 'me_cons'"
            :massId="massId"
            :interval="interval"
            :range="range"
            :data="activeData"
            :isLoading="isCurrLoading"
          />
          <Rpm
            v-if="activeMenu === 'rpm'"
            :massId="massId"
            :interval="interval"
            :range="range"
            :data="activeData"
            :isLoading="isCurrLoading"
          />
          <FuelRate
            v-if="activeMenu === 'fuel_rate'"
            :massId="massId"
            :interval="interval"
            :range="range"
            :data="activeData"
            :isLoading="isCurrLoading"/>

          <FuelUsage
            ref="fuelusage"
            v-if="activeMenu === 'fuel_usage'"
            :massId="massId"
            :interval="interval"
            :range="range"
            :height="height"
            :dataObject="assetObject[this.massId]"
            :isLoading="isCurrLoading"
            @refChange="refChange"
            @chartIndexUpdated="chartIndexUpdated"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeviceSvc from '@/services/DeviceSvc';
import PdfGenerator from '@/services/PdfGenerator';
import SeriesSvc from '@/services/SeriesSvc';
import AssetSvc from '@/services/AssetSvc';
import dateUtil from '@/util/date';
import dataMenus from '@/util/data-table';
import requestBuilder from '@/util/request.builder';
import moment from 'moment';
import { sofbox } from '../../config/pluginInit';
import DtSidebar from './DataTableSidebar.vue';
import Tracking from './data/Tracking.vue';
import AEConsumption from './data/AEConsumption.vue';
import FuelUsage from './data/FuelUsage.vue';
import FuelRate from './data/FuelRate.vue';
import MEConsumption from './data/MEConsumption.vue';
import Rpm from './data/Rpm.vue';
import DailyConsumption from './data/DailyConsumption.vue';

export default {
  name: 'dt-view',
  components: {
    DtSidebar,
    Tracking,
    AEConsumption,
    MEConsumption,
    FuelUsage,
    FuelRate,
    Rpm,
    DailyConsumption,
  },
  props: ['assets', 'isLoading', 'height'],
  data() {
    return {
      range: {
        start: moment().set({ hour: 0, minute: 0, second: 0 }).toString(),
        // start: moment().subtract(1, 'week').startOf('week').toString(),
        end: new Date(),
      },
      massId: this.assets && this.assets.length > 0 ? this.assets[0].massId : undefined,
      interval: 'HOUR',
      activeMenu: 'daily_cons',
      activeChildMenu: undefined,
      currChildMenu: undefined,
      gpsId: undefined,
      isCurrLoading: false,
      activeData: [],
      dataSummary: undefined,
      assetObject: {},
      activeAsset: {},
      devices: [],
      devicesObject: {},
      filter: {
        devcMassId: undefined,
      },
      dateParam: undefined,
      refValue: {},
      reportTypes: {
        daily_cons: {
          type: 'daily_cons',
          label: 'Daily Consumption',
          tableId: '#summaryTable',
        },
        me_cons: {
          type: 'me_cons',
          label: 'ME Consumption',
          tableId: '#meConsTable',
        },
        ae_cons: {
          type: 'ae_cons',
          label: 'AE Consumption',
          tableId: '#aeConsTable',
        },
        fuel_usage: {
          type: 'fuel_usage',
          label: 'Fuel Usage Chart',
          tableId: '#fuelUsageChart',
        },
        fuel_rate: {
          type: 'fuel_rate',
          label: 'Fuel Rate ME',
          tableId: '#fuelRateTable',
        },
        rpm: {
          type: 'rpm',
          label: 'RPM',
          tableId: '#rpmTable',
        },
        tracking: {
          type: 'tracking',
          label: 'Tracking/Day',
          tableId: '#trackingTable',
        },
      },
      activeAssetData: undefined,
      isSingleEngine: false,
    };
  },
  watch: {
    isLoading(v) {
      if (!v) {
        this.massId = this.assets[0].massId;
        console.log('===> this.massId', this.massId);
      }
    },
  },
  async mounted() {
    sofbox.index();
    await this.getAssets();
    await this.handleGetData();
  },
  methods: {
    async handleGetData(hold) {
      console.log('===> 0. this.assets', this.assets);
      console.log(`===> 0. init get data for ${this.massId}. parent: ${this.activeMenu}, child: ${this.activeChildMenu}, hold: ${hold}`);
      try {
        this.isCurrLoading = true;
        this.filter.devcMassId = this.massId;
        const devices = await DeviceSvc.getDevices({
          params: {
            ...requestBuilder.buildFilters({}, this.filter),
          },
        });
        console.log('===> 1. devices: ', devices);
        const currAssetDevices = devices.data.data.filter((item) => item.devcMassId === this.massId);
        this.activeAssetData = currAssetDevices[0].devcMass;
        console.log('===> 2. devices: ', currAssetDevices);
        console.log('===> 2.1 activeAssetData: ', this.activeAssetData);
        const dateParam = {
          aggregatedUnit: this.interval,
          mode: 'period', // period, last
          // value: 'custom',
          range: {
            start: moment(this.range.start).valueOf(),
            end: moment(this.range.end).valueOf(),
          },
          // duration: 1,
        };
        const seriesParam = dateUtil.autoMaxData(dateUtil.msToS(dateParam));
        seriesParam.typesToGet = ['gps', 'ae', 'flowmeter', 'rpm'];
        console.log('===> 3. seriesParam: ', seriesParam);
        const combinedData = await SeriesSvc.getCombinedData(
          currAssetDevices,
          seriesParam,
        );
        console.log('===> 4. combinedData: ', combinedData);
        if (this.combinedData && this.combinedData.summaries && this.combinedData.summaries.rpm && this.combinedData.summaries.rpm.singleEngine) {
          this.isSingleEngine = true;
        }
        const currDatas = [];
        if (this.activeMenu === 'daily_cons') {
          this.dataSummary = combinedData;
        } else if (this.activeMenu === 'fuel_usage') {
          // const asset = this.activeAsset;
          const massIds = Object.keys(this.assetObject);
          console.log('User has access to these assets : ', massIds);
          this.lastDataTimestamp = 0;
          this.showRealtimeChart = false;
          const resp = await DeviceSvc.getDevices({
            params: {
              ...requestBuilder.buildFilters({}, this.filter),
            },
          });
          this.devices = resp.data.data;
          for (let i = 0; i < this.devices.length; i++) {
            const device = this.devices[i];
            if (device.devcType === 'gps') {
              this.gpsId = device.devcUniqueId;
            }
            this.devicesObject[device.devcUniqueId] = device;
          }
          this.dateParam = dateUtil.byInterval('today');
          this.dateParam.aggregatedUnit = 'HOUR';
          const lastData = await SeriesSvc.getLastData('gps', this.gpsId);
          if (lastData && lastData.data && lastData.data.data && lastData.data.data.series && new Date().getTime() - (lastData.data.data.series[0].timestamp * 1000) > 86400000) {
            this.dateParam.range = {
              start: (lastData.data.data.series[0].timestamp * 1000) - 86400000,
              end: lastData.data.data.series[0].timestamp * 1000,
            };
          } else {
            console.log('Data period not defined... using today...');
            this.dateParam = dateUtil.byInterval('today');
          }
          this.dateParam.primaryFunctionName = 'LAST';
          const seriesParam = dateUtil.autoMaxData(dateUtil.msToS(this.dateParam));
          seriesParam.typesToGet = ['flowmeter', 'gps', 'rpm', 'ae'];
          // const combData = await SeriesSvc.getCombinedData(this.devices, seriesParam);
          console.log('===> combinedData', combinedData);
          console.log('thisasset object>>', this.assetObject[this.massId]);
          if (this.assetObject[this.massId].datas) this.assetObject[this.massId].datas = {};
          this.assetObject[this.massId] = this.lodash.merge(this.assetObject[this.massId], combinedData);
          console.log('All data combined : ', this.assetObject[this.massId]);
          if (this.assetObject[this.massId].lastData === undefined) {
            const lastData = await SeriesSvc.getLastData('gps', this.gpsId);
            this.lastAvailableGPSData = lastData.data.data.series[0];
            console.log('LD : ', this.lastAvailableGPSData);
          } else {
            this.lastAvailableGPSData = undefined;
          }
          // console.log('this asctive asset object>>', this.activeAsset);
          console.log('this asctive asset object>>', this.assetObject);
        } else {
          const keys = Object.keys(combinedData.datas);
          let tripNumber = 1;
          Object.keys(combinedData.datas).forEach((key) => {
            const data = combinedData.datas[key];
            // generate data Tracking/Day
            if (this.activeMenu === 'tracking') {
              console.log('push here');
              currDatas.push({
                date: data.gps.datetime,
                latitude: this.convertLatToDMS(data.gps.latitude),
                longitude: this.convertLatToDMS(data.gps.longitude),
                altitude: data.gps.altitude.toFixed(2),
                heading: this.degreesToRadians(data.gps.track).toFixed(2),
                dateRaw: parseInt(key),
              });
            } else if (this.activeMenu === 'ae_cons') {
              // generate data AE Consumption
              if (data.ae) {
                // const currChild = Object.keys(data.ae);
                // this.currChildMenu = currChild;
                // if (hold) {
                //   this.activeChildMenu = currChild[0];
                // }
                console.log('data ae dtable', data);
                currDatas.push({
                  date: data.ae.AE1.datetime,
                  engineHour1: `${(data.ae.AE1.runningSeconds / 3600).toFixed(2)} h`,
                  flowRate1: `${(data.ae.AE1.fuelConsumption * (data.ae.AE1.runningSeconds / 3600)).toFixed(2)} L/h`,
                  consumption1: `${data.ae.AE1.fuelConsumption.toFixed(2)} L`,
                  engineHour2: `${(data.ae.AE2.runningSeconds / 3600).toFixed(2)} h`,
                  flowRate2: `${(data.ae.AE2.fuelConsumption * (data.ae.AE2.runningSeconds / 3600)).toFixed(2)} L/h`,
                  consumption2: `${data.ae.AE2.fuelConsumption.toFixed(2)} L`,
                  engineHour3: `${(data.ae.AE3.runningSeconds / 3600).toFixed(2)} h`,
                  flowRate3: `${(data.ae.AE3.fuelConsumption * (data.ae.AE3.runningSeconds / 3600)).toFixed(2)} L/h`,
                  consumption3: `${data.ae.AE3.fuelConsumption.toFixed(2)} L`,
                  dateRaw: parseInt(key),
                });
              }
            } else if (this.activeMenu === 'me_cons') {
              // generate data ME Consumption
              const currChild = Object.keys(data.rpm);
              console.log('currChild', currChild);
              if (combinedData.summaries.rpm.singleEngine) {
                this.currChildMenu = ['MAINENGINE'];
              } else {
                this.currChildMenu = currChild;
              }
              console.log('>>>', currChild);
              if (hold) {
                this.activeChildMenu = currChild[0];
              }
              let consumption = null;
              if (data.flowmeter) {
                if (this.activeChildMenu === 'MAINENGINE') {
                  consumption = data.flowmeter.meFuelCons;
                } else if (this.activeChildMenu === 'STARBOARD') {
                  consumption = data.flowmeter.stbFuelCons;
                } else if (this.activeChildMenu === 'PORT') {
                  consumption = data.flowmeter.portFuelCons;
                }
              }
              // console.log('from me const', consumption);
              currDatas.push({
                date: moment.unix(key).format('YYYY-MM-DD hh:mm'),
                engineHour: data.rpm[this.activeChildMenu] ? `${(data.rpm[this.activeChildMenu].runningTime / 3600).toFixed(2)} h` : '-',
                flowRate: data.rpm[this.activeChildMenu] && consumption ? `${((data.rpm[this.activeChildMenu].runningTime / 3600) * consumption).toFixed(2)} L/h` : '-',
                consumption: data.rpm[this.activeChildMenu] && consumption ? `${consumption.toFixed(2)} L` : '-',
                dateRaw: parseInt(key),
              });
            } else if (this.activeMenu === 'rpm') {
              // generate data RPM
              const currChild = Object.keys(data.rpm);
              if (combinedData.summaries.rpm.singleEngine) {
                this.currChildMenu = ['MAINENGINE'];
              } else {
                this.currChildMenu = currChild;
              }
              if (hold) {
                this.activeChildMenu = currChild[0];
              }
              currDatas.push({
                date: moment.unix(key).format('YYYY-MM-DD hh:mm'),
                engineHour: data.rpm[this.activeChildMenu] ? `${(data.rpm[this.activeChildMenu].runningTime / 3600).toFixed(2)} h` : '-',
                rpm: data.rpm[this.activeChildMenu] ? data.rpm[this.activeChildMenu].rpm.toFixed(2) : '-',
                dateRaw: parseInt(key),
                minRpm: data.rpm[this.activeChildMenu] && data.rpm[this.activeChildMenu].additional.MIN_dlrpRpm_2 ? data.rpm[this.activeChildMenu].additional.MIN_dlrpRpm_2.toFixed(2) : '0.00',
                maxRpm: data.rpm[this.activeChildMenu] && data.rpm[this.activeChildMenu].additional.MAX_dlrpRpm_3 ? data.rpm[this.activeChildMenu].additional.MAX_dlrpRpm_3.toFixed(2) : '0.00',
              });
              // console.log('currdatas', currDatas);
            } else if (this.activeMenu === 'fuel_rate') {
              const currChild = Object.keys(data.rpm);
              if (combinedData.summaries.rpm.singleEngine) {
                this.currChildMenu = ['MAINENGINE'];
              } else {
                this.currChildMenu = currChild;
              }
              if (hold) {
                this.activeChildMenu = currChild[0];
              }
              let consumption = null;
              if (data.flowmeter) {
                if (this.activeChildMenu === 'STARBOARD') {
                  if (data.flowmeter.stbFuelCons === 0) {
                    if (combinedData.datas[(keys[(keys.indexOf(key) + 1) % keys.length])].flowmeter.stbFuelCons !== 0) {
                      tripNumber++;
                    }
                  }
                  consumption = data.flowmeter.stbFuelCons;
                } else if (this.activeChildMenu === 'PORT') {
                  if (data.flowmeter.portFuelCons === 0) {
                    if (combinedData.datas[(keys[(keys.indexOf(key) + 1) % keys.length])].flowmeter.portFuelCons !== 0) {
                      tripNumber++;
                    }
                  }
                  consumption = data.flowmeter.portFuelCons;
                } else {
                  consumption = data.flowmeter.portFuelCons;
                }
              }
              console.log('consumtion number', (data.rpm[this.activeChildMenu].runningTime / 3600) * consumption);
              // console.log('cek next>>', keys);
              currDatas.push({
                date: moment.unix(key).format('YYYY-MM-DD hh:mm'),
                engineHour: `${(data.rpm[this.activeChildMenu].runningTime / 3600).toFixed(2)} h`,
                flowRate: `${((data.rpm[this.activeChildMenu].runningTime / 3600) * consumption).toFixed(2)} L/h`,
                consumption: `${consumption.toFixed(2)} L`,
                dateRaw: parseInt(key),
                tripNumber: `Trip ${tripNumber}`,
              });
            }
          });
          console.log('===> 5. currDatas', currDatas);
          this.activeData = currDatas.sort((a, b) => a.dateRaw - b.dateRaw);
          console.log('===> 6. activeData', this.activeData);
        }
      } catch (error) {
        console.error('failed get data AE', error);
      } finally {
        this.isCurrLoading = false;
        if (!this.isCurrLoading) {
          console.log('ref from mounted>>>', this.$refs.fuelusage?.$refs?.stackedGraph);
          this.refValue = this.$refs.fuelusage?.$refs?.stackedGraph;
        }
      }
    },

    chartIndexUpdated(xaxis) {
      this.dateParam = dateUtil.byInterval('custom');
      this.dateParam.range = {};
      const timeIndex = Object.keys(this.assetObject[this.filter.devcMassId].datas);
      let updatedStart = timeIndex[xaxis.min];
      let updatedEnd = timeIndex[xaxis.max];
      if (!timeIndex[xaxis.min]) {
        updatedStart = timeIndex[0];
      }
      if (!timeIndex[xaxis.max]) {
        updatedEnd = timeIndex[timeIndex.length - 1];
      }
      if (xaxis.min < 0) {
        const indexBefore = xaxis.min * -1;
        const interval = parseInt((parseInt(updatedEnd) - parseInt(updatedStart)) / timeIndex.length);
        updatedStart -= indexBefore * interval;
      }

      this.dateParam.range.start = parseInt(updatedStart) * 1000;
      this.dateParam.range.end = parseInt(updatedEnd) * 1000;
      this.dateParam.aggregatedUnit = 'MINUTE';
      this.onSubmitRange(this.dateParam);
      console.log('date params', this.dateParam);
    },
    async getAssets() {
      this.isCurrLoading = true;
      try {
        const res = await AssetSvc.getAssets();
        if (res.data.status.error === 0) {
          this.assets = res.data.data;
          this.assets.forEach((asset) => {
            this.assetObject[asset.massId] = asset;
          });
        }
      } catch (e) {
        this.$toast.error(e.message);
        // this.doLogOut();
      } finally {
        this.isCurrLoading = true;
      }
    },
    async download() {
      let data;
      let printData = {};
      const type = this.reportTypes[this.activeMenu];
      if (this.activeMenu === 'daily_cons') {
        data = this.dataSummary;
        printData = {
          type,
          data,
          asset: this.activeAssetData,
          period: `${moment(this.range.start).format('DD MMM YYYY HH:mm')} to ${moment(this.range.end).format('DD MMM YYYY HH:mm')}`,
        };
      } else if (this.activeMenu === 'fuel_usage') {
        console.log('checkref', this.$refs.fuelusage.$refs.stackedGraph);
        data = this.activeData;
        printData = {
          type,
          data,
          chart: this.$refs.fuelusage.$refs.stackedGraph,
          asset: this.activeAssetData,
          period: `${moment(this.range.start).format('DD MMM YYYY HH:mm')} to ${moment(this.range.end).format('DD MMM YYYY HH:mm')}`,
        };
      } else if (dataMenus.filter((item) => item.nested).map((item) => item.value).includes(this.activeMenu)) {
        data = this.activeData;
        printData = {
          type,
          data,
          asset: this.activeAssetData,
          period: `${moment(this.range.start).format('DD MMM YYYY HH:mm')} to ${moment(this.range.end).format('DD MMM YYYY HH:mm')}`,
        };
      }
      console.info('bos', this.range.start);
      console.info('bos', moment(this.range.start).format('DD MMM YYYY HH:mm'));
      console.info('bos', printData);
      // if (this.activeMenu === 'gps') {
      //   await this.screenShoter.takeScreen('image', {
      //     mimeType: 'image/png',
      //   }).then((image) => {
      //     printData.map = image;
      //     // this.mapShot = image;
      //     // console.log('IMAGE: ', image);
      //   }).catch((e) => {
      //     console.error(e);
      //   });
      //   // printData.map = this.mapShot;
      // }
      // if (this.$refs.stackedGraph) {
      //   printData.chart = this.$refs.stackedGraph;
      // }
      // if (this.$refs.chartGraph) {
      //   printData.chart = this.$refs.chartGraph;
      // }
      // if (this.$refs.barlineGraph) {
      //   printData.chart = this.$refs.barlineGraph;
      // }
      // if (this.$refs.stackedGraphDaily) {
      //   printData.chart = this.$refs.stackedGraphDaily;
      // }
      PdfGenerator.generate(printData)
        // .output('dataurlnewwindow', 'report.pdf');
        .save(`${type.label.split(' ').join('_').toLowerCase()}_${moment(this.range.start).format('DD-MMM-YYYY (HH-mm)')}_${moment(this.end).format('DD-MMM-YYYY (HH-mm)')}`);
    },
    onCloseDataTabel() {
      this.$emit('closeDataTableSummary');
    },
    onChangeMenu(v) {
      this.activeMenu = v;
      this.currChildMenu = undefined;
      if (!dataMenus.filter((item) => item.nested).map((item) => item.value).includes(v)) {
        this.activeChildMenu = undefined;
      }
      this.handleGetData(true);
    },
    onChangeMenuChildren(v) {
      this.activeMenu = v.parentValue;
      this.activeChildMenu = v.childValue;
      this.handleGetData();
    },
    onChangeInterval(v) {
      this.interval = v;
      this.handleGetData();
    },
    onChangeAsset(v) {
      this.massId = v;
      this.handleGetData();
      console.log('===> new massId', v);
    },
    onChangeDateRange(range) {
      this.range = { ...range };
      this.handleGetData();
    },
    degreesToRadians(degrees) {
      const pi = Math.PI;
      return degrees * (pi / 180);
    },
    refChange(ref) {
      // console.log('ref value from ref change', ref);
      this.refValue = ref;
      // console.log('ref this refvalue refchange', this.refValue);
    },
    toDegreesMinutesAndSeconds(coordinate) {
      const absolute = Math.abs(coordinate);
      const degrees = Math.floor(absolute);
      const minutesNotTruncated = (absolute - degrees) * 60;
      const minutes = Math.floor(minutesNotTruncated);
      const seconds = Math.floor((minutesNotTruncated - minutes) * 60);

      return `${degrees}° ${minutes}' ${seconds}''`;
    },
    convertLatToDMS(value) {
      if (!value) return '';
      const cardinal = value > 0 ? 'N' : 'S';
      return `${this.toDegreesMinutesAndSeconds(value)} ${cardinal}`;
    },
    convertLongToDMS(value) {
      if (!value) return '';
      const cardinal = value > 0 ? 'E' : 'W';
      return `${this.toDegreesMinutesAndSeconds(value)} ${cardinal}`;
    },
  },
};
</script>

<style scoped lang="scss">
.dt-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 9999;
  background: #DEE2E6;
  height: 100%;
  p {
    margin-top: 15px;
  }
}
.dt-container {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 400px 1fr;
  position: relative;
  z-index: 9999;
  background: white;
  height: 100%;
}
.dt-content {
  flex: 1;
  border-left: 2px solid #DEE2E6;
  height: 100%;
}
.dt-content-header {
  height: 45px;
  background: #FFFFFF;
  border-bottom: 3px solid #DEE2E6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}
.dt-button {
  background-color: #002A64;
  padding: 2px 10px;
  border-radius: 4px;
  color: white;
  border: none;
}
.dt-container-table {
  height: 100%;
  overflow-y: auto;
}
</style>
