<template>
  <b-row>
    <b-col>
      <b-table
        bordered
        fixed
        small
        show-empty
        :current-page="currentPage"
        :fields="fields"
        :items="summaries"
        :per-page="perPage"
        class="bg-white text-center table-logs">
        <template #thead-top="data">
          <b-tr>
            <b-th></b-th>
            <b-th colspan="2">Speed</b-th>
            <b-th colspan="7">Fuel Consumption (Liter)</b-th>
            <b-th colspan="2">AVG RPM ME</b-th>
            <b-th colspan="6">Running Hours</b-th>
            <b-th colspan="3"></b-th>
          </b-tr>
        </template>
        <template #cell(avg-speed)="data">
          {{ data.item.speed.avg.toFixed(2) }} km/h
        </template>
        <template #cell(max-speed)="data">
          {{ data.item.speed.max.toFixed(2) }} km/h
        </template>
        <template #cell(fuel-me-port)="data">
          {{ data.item.fuelConsumption.mePort.toFixed(2) }}
        </template>
        <template #cell(fuel-me-stbd)="data">
          {{ data.item.fuelConsumption.meStarboard.toFixed(2) }}
        </template>
        <template #cell(ae1-value)="data">
          {{ data.item.fuelConsumption.ae1.toFixed(2) }}
        </template>
        <template #cell(ae2-value)="data">
          {{ data.item.fuelConsumption.ae2.toFixed(2) }}
        </template>
        <template #cell(ae3-value)="data">
          {{ data.item.fuelConsumption.ae3.toFixed(2) }}
        </template>
        <template #cell(ae4-value)="data">
          {{ data.item.fuelConsumption.ae4.toFixed(2) }}
        </template>
        <template #cell(total)="data">
          {{
            (Object.values(data.item.fuelConsumption)
              .reduce((x, y) => x + y, 0) / 6).toFixed(2)
          }}
        </template>
        <template #cell(rpm-port)="data">
          {{ data.item.rpm.mePort.toFixed(2) }}
        </template>
        <template #cell(rpm-starboard)="data">
          {{ data.item.rpm.meStarboard.toFixed(2) }}
        </template>
      </b-table>
    </b-col>
  </b-row>
</template>

<script>
import GeoPoint from 'geopoint';

export default {
  name: 'DataSummary',
  props: {
    asset: {
      required: true,
      type: Object,
    },
    series: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 5,
    };
  },
  computed: {
    fields() {
      return [
        'name',
        {
          key: 'avg-speed',
          label: 'Avg. Speed',
        },
        {
          key: 'max-speed',
          label: 'Max Speed',
        },
        {
          key: 'fuel-me-port',
          label: 'ME Port',
        },
        {
          key: 'fuel-me-stbd',
          label: 'ME Stbd',
        },
        {
          key: 'ae1-value',
          label: 'AE1',
        },
        {
          key: 'ae2-value',
          label: 'AE2',
        },
        {
          key: 'ae3-value',
          label: 'AE3',
        },
        {
          key: 'ae4-value',
          label: 'AE4',
        },
        'total',
        {
          key: 'rpm-port',
          label: 'ME Port',
        },
        {
          key: 'rpm-stbd',
          label: 'ME Stbd',
        },
        {
          key: 'me-port-runningTime',
          label: 'ME Port',
        },
        {
          key: 'me-stbd-runningTime',
          label: 'ME Stbd',
        },
        {
          key: 'ae1-runningTime',
          label: 'AE1',
        },
        {
          key: 'ae2-runningTime',
          label: 'AE2',
        },
        {
          key: 'ae3-runningTime',
          label: 'AE3',
        },
        {
          key: 'ae4-runningTime',
          label: 'AE4',
        },
        {
          key: 'totalDistances',
          label: 'Total Distances (KM)',
        },
        {
          key: 'numOfPositions',
          label: 'Num Of Positions',
        },
        {
          key: 'numOfData',
          label: 'Num Of Data',
        },
      ];
    },
    summaries() {
      if (!Array.isArray(this.series)) {
        return [];
      }
      const avgReading = (readings, label, field) => {
        const v = readings
          .filter((r) => r.label === label)
          .map((r) => r[field]);
        return (v.reduce((x, y) => x + y, 0) / v.length);
      };
      const sumReading = (readings, label, field) => readings
        .filter((r) => r.label === label)
        .map((r) => r[field])
        .reduce((x, y) => x + y, 0);

      const speedLists = this.series.map((v) => parseFloat(v.speed.replace(' km/h', '')));
      const readings = this.series.flatMap((s) => s.readings);
      const portInlet = sumReading(readings, 'port-inlet', 'volumeFlowRate');
      const portOutlet = sumReading(readings, 'port-outlet', 'volumeFlowRate');
      const starboardInlet = sumReading(readings, 'startboard-inlet', 'volumeFlowRate');
      const starboardOutlet = sumReading(readings, 'startboard-outlet', 'volumeFlowRate');
      console.log(this.series);
      if (this.series.length > 0) {
        const startLocation = new GeoPoint(this.series[0].coordinate[0], this.series[0].coordinate[1]);
        const lastCoordinate = this.series[this.series.length - 1].coordinate;
        const endLocation = new GeoPoint(lastCoordinate[0], lastCoordinate[1]);

        return {
          name: this.asset.massName,
          speed: {
            avg: speedLists.reduce((x, y) => x + y) / speedLists.length,
            max: Math.max(...speedLists),
          },
          fuelConsumption: {
            mePort: portInlet - portOutlet,
            meStarboard: starboardInlet - starboardOutlet,
            ae1: sumReading(readings, 'AE1', 'value'),
            ae2: sumReading(readings, 'AE2', 'value'),
            ae3: sumReading(readings, 'AE3', 'value'),
            ae4: sumReading(readings, 'AE4', 'value'),
          },
          rpm: {
            mePort: avgReading(readings, 'port-RPM', 'value'),
            meStarboard: avgReading(readings, 'starboard-RPM', 'value'),
          },
          totalDistances: startLocation.distanceTo(endLocation, true)
            .toFixed(2),
          numOfPositions: this.series.length,
          numOfData: readings.length,
        };
      }
      return false;
    },
  },
};
</script>

<style scoped>
.table-logs {
  display: block;
  font-size: 11px;
  overflow-x: auto;
  white-space: nowrap;
}
</style>
