<template>
  <div style="height: 100%;">
    <div v-if="isLoading" class="dt-loading">
      <b-spinner variant="default" size="sm" class="align-middle"></b-spinner>
      <p>Loading data...</p>
    </div>
    <!-- <pre>{{data.summaries}}</pre> -->
    <b-table-simple v-else-if="data.summaries.rpm.singleEngine || massId === '42'" id="summaryTable" hover small responsive>
      <b-thead head-variant="dark">
        <b-tr>
          <b-th>Description</b-th>
          <b-th>Value</b-th>
          <b-th>Unit</b-th>
          <b-th>Description</b-th>
          <b-th>Value</b-th>
          <b-th>Unit</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <template v-if="data.summaries.rpm">
            <b-th>Main Engine Engine Speed</b-th>
            <b-td>{{(data.summaries.rpm.average.MAINENGINE).toFixed(2)}}</b-td>
            <b-td>RPM</b-td>
          </template>
          <template v-if="data.summaries.flowmeter">
            <b-th>Main Engine Total Fuel Used</b-th>
            <b-td v-if="data.summaries.flowmeter.portEngineCons">{{(data.summaries.flowmeter.portEngineCons).toFixed(2)}}</b-td>
            <b-td v-else>0</b-td>
            <b-td>Liter</b-td>
          </template>
        </b-tr>
        <b-tr>
          <template v-if="data.summaries.rpm">
            <b-th>Main Engine Running Hour</b-th>
            <b-td>{{ data.summaries.rpm.runningTime.MAINENGINE | toHHMMSS }}</b-td>
            <b-td>Hours</b-td>
          </template>
          <template v-if="data.summaries.flowmeter">
            <b-td colspan="3"></b-td>
          </template>
        </b-tr>
        <template v-if="data.summaries.ae">
          <b-tr v-if="data.summaries.ae.fuelConsumption.AE1 || typeof data.summaries.ae.fuelConsumption.AE1 === 'number'">
            <b-th>Port AE Running Hour</b-th>
            <b-td>{{data.summaries.ae.runningSeconds.AE1 | toHHMMSS}}</b-td>
            <b-td>Hours</b-td>
            <b-th>Port AE Total Fuel Used</b-th>
            <b-td>{{(data.summaries.ae.fuelConsumption.AE1).toFixed(2)}}</b-td>
            <b-td>Liter</b-td>
          </b-tr>
          <b-tr v-if="data.summaries.ae.fuelConsumption.AE2 || typeof data.summaries.ae.fuelConsumption.AE2 === 'number'">
            <b-th>Ctr AE Running Hour</b-th>
            <b-td>{{data.summaries.ae.runningSeconds.AE2 | toHHMMSS}}</b-td>
            <b-td>Hours</b-td>
            <b-th>Ctr AE Total Fuel Used</b-th>
            <b-td>{{(data.summaries.ae.fuelConsumption.AE2).toFixed(2)}}</b-td>
            <b-td>Liter</b-td>
          </b-tr>
          <b-tr v-if="data.summaries.ae.fuelConsumption.AE3 || typeof data.summaries.ae.fuelConsumption.AE3 === 'number'">
            <b-th>Stb AE Running Hour</b-th>
            <b-td>{{data.summaries.ae.runningSeconds.AE3 | toHHMMSS}}</b-td>
            <b-td>Hours</b-td>
            <b-th>Stb AE Total Fuel Used</b-th>
            <b-td>{{(data.summaries.ae.fuelConsumption.AE3).toFixed(2)}}</b-td>
            <b-td>Liter</b-td>
          </b-tr>
          <b-tr v-if="(data.summaries.ae.fuelConsumption.AE1||typeof data.summaries.ae.fuelConsumption.AE1 === 'number')&&(data.summaries.ae.fuelConsumption.AE2||typeof data.summaries.ae.fuelConsumption.AE2 === 'number')&&(data.summaries.ae.fuelConsumption.AE3||typeof data.summaries.ae.fuelConsumption.AE3 === 'number')">
            <b-th colspan="3"></b-th>
            <b-th variant="secondary">AE Total Fuel Used</b-th>
            <b-td variant="secondary">{{(data.summaries.ae.fuelConsumption.AE1 + data.summaries.ae.fuelConsumption.AE2 + data.summaries.ae.fuelConsumption.AE3).toFixed(2)}}</b-td>
            <b-td variant="secondary">Liter</b-td>
          </b-tr>
        </template>
      </b-tbody>
      <b-tfoot v-if="data.summaries.rpm||data.summaries.flowmeter||data.summaries.ae">
        <b-tr>
          <b-th colspan="3" variant="primary"></b-th>
          <b-th variant="primary">Total Fuel Used</b-th>
          <b-th variant="primary" v-if="data.summaries.flowmeter&&data.summaries.ae">{{sumArray([data.summaries.flowmeter.portEngineCons, data.summaries.flowmeter.stbEngineCons, data.summaries.ae.fuelConsumption.AE1, data.summaries.ae.fuelConsumption.AE2, data.summaries.ae.fuelConsumption.AE3]).toFixed(2)}}</b-th>
          <b-th variant="primary" v-else-if="data.summaries.flowmeter&&!data.summaries.ae">{{sumArray([data.summaries.flowmeter.portEngineCons, data.summaries.flowmeter.stbEngineCons]).toFixed(2)}}</b-th>
          <b-th variant="primary" v-else-if="data.summaries.ae">{{sumArray([data.summaries.ae.fuelConsumption.AE1, data.summaries.ae.fuelConsumption.AE2, data.summaries.ae.fuelConsumption.AE3]).toFixed(2)}}</b-th>
          <b-th variant="primary">Liter</b-th>
        </b-tr>
      </b-tfoot>
      <b-tfoot v-else>
        <b-th colspan="6" variant="primary" class="text-center">No Data</b-th>
      </b-tfoot>
    </b-table-simple>
    <b-table-simple v-else id="summaryTable" hover small responsive>
      <b-thead head-variant="dark">
        <b-tr>
          <b-th>Description</b-th>
          <b-th>Value</b-th>
          <b-th>Unit</b-th>
          <b-th>Description</b-th>
          <b-th>Value</b-th>
          <b-th>Unit</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <template v-if="data.summaries.rpm">
            <b-th>Port ME Engine Speed</b-th>
            <b-td>{{(data.summaries.rpm.average.PORT).toFixed(2)}}</b-td>
            <b-td>RPM</b-td>
          </template>
          <template v-if="data.summaries.flowmeter">
            <b-th>Port ME Total Fuel Used</b-th>
            <b-td v-if="data.summaries.flowmeter.portEngineCons">{{(data.summaries.flowmeter.portEngineCons).toFixed(2)}}</b-td>
            <b-td v-else>0</b-td>
            <b-td>Liter</b-td>
          </template>
        </b-tr>
        <b-tr>
          <template v-if="data.summaries.rpm">
            <b-th>Stb ME Engine Speed</b-th>
            <b-td>{{(data.summaries.rpm.average.STARBOARD).toFixed(2)}}</b-td>
            <b-td>RPM</b-td>
          </template>
          <template v-if="data.summaries.flowmeter">
            <b-th>Stb ME Total Fuel Used</b-th>
            <b-td v-if="data.summaries.flowmeter.stbEngineCons">{{(data.summaries.flowmeter.stbEngineCons).toFixed(2)}}</b-td>
            <b-td v-else>0</b-td>
            <b-td>Liter</b-td>
          </template>
        </b-tr>
        <b-tr>
          <template v-if="data.summaries.rpm">
            <b-th>Port ME Running Hour</b-th>
            <b-td>{{ data.summaries.rpm.runningTime.PORT | toHHMMSS }}</b-td>
            <b-td>Hours</b-td>
          </template>
          <template v-if="data.summaries.flowmeter">
            <b-td colspan="3"></b-td>
          </template>
        </b-tr>
        <b-tr>
          <template v-if="data.summaries.rpm">
            <b-th>Stb ME Running Hour</b-th>
            <b-td>{{data.summaries.rpm.runningTime.STARBOARD | toHHMMSS }}</b-td>
            <b-td>Hours</b-td>
          </template>
          <template v-if="data.summaries.flowmeter">
            <b-th variant="secondary">ME Total Fuel Used</b-th>
            <b-td variant="secondary">{{sumArray([data.summaries.flowmeter.portEngineCons, data.summaries.flowmeter.stbEngineCons]).toFixed(2)}}</b-td>
            <b-td variant="secondary">Liter</b-td>
          </template>
        </b-tr>
        <template v-if="data.summaries.ae">
          <b-tr v-if="data.summaries.ae.fuelConsumption.AE1 || typeof data.summaries.ae.fuelConsumption.AE1 === 'number'">
            <b-th>Port AE Running Hour</b-th>
            <b-td>{{data.summaries.ae.runningSeconds.AE1 | toHHMMSS}}</b-td>
            <b-td>Hours</b-td>
            <b-th>Port AE Total Fuel Used</b-th>
            <b-td>{{(data.summaries.ae.fuelConsumption.AE1).toFixed(2)}}</b-td>
            <b-td>Liter</b-td>
          </b-tr>
          <b-tr v-if="data.summaries.ae.fuelConsumption.AE2 || typeof data.summaries.ae.fuelConsumption.AE2 === 'number'">
            <b-th>Ctr AE Running Hour</b-th>
            <b-td>{{data.summaries.ae.runningSeconds.AE2 | toHHMMSS}}</b-td>
            <b-td>Hours</b-td>
            <b-th>Ctr AE Total Fuel Used</b-th>
            <b-td>{{(data.summaries.ae.fuelConsumption.AE2).toFixed(2)}}</b-td>
            <b-td>Liter</b-td>
          </b-tr>
          <b-tr v-if="data.summaries.ae.fuelConsumption.AE3 || typeof data.summaries.ae.fuelConsumption.AE3 === 'number'">
            <b-th>Stb AE Running Hour</b-th>
            <b-td>{{data.summaries.ae.runningSeconds.AE3 | toHHMMSS}}</b-td>
            <b-td>Hours</b-td>
            <b-th>Stb AE Total Fuel Used</b-th>
            <b-td>{{(data.summaries.ae.fuelConsumption.AE3).toFixed(2)}}</b-td>
            <b-td>Liter</b-td>
          </b-tr>
          <b-tr v-if="(data.summaries.ae.fuelConsumption.AE1||typeof data.summaries.ae.fuelConsumption.AE1 === 'number')&&(data.summaries.ae.fuelConsumption.AE2||typeof data.summaries.ae.fuelConsumption.AE2 === 'number')&&(data.summaries.ae.fuelConsumption.AE3||typeof data.summaries.ae.fuelConsumption.AE3 === 'number')">
            <b-th colspan="3"></b-th>
            <b-th variant="secondary">AE Total Fuel Used</b-th>
            <b-td variant="secondary">{{(data.summaries.ae.fuelConsumption.AE1 + data.summaries.ae.fuelConsumption.AE2 + data.summaries.ae.fuelConsumption.AE3).toFixed(2)}}</b-td>
            <b-td variant="secondary">Liter</b-td>
          </b-tr>
        </template>
      </b-tbody>
      <b-tfoot v-if="data.summaries.rpm||data.summaries.flowmeter||data.summaries.ae">
        <b-tr>
          <b-th colspan="3" variant="primary"></b-th>
          <b-th variant="primary">Total Fuel Used</b-th>
          <b-th variant="primary" v-if="data.summaries.flowmeter&&data.summaries.ae">{{sumArray([data.summaries.flowmeter.portEngineCons, data.summaries.flowmeter.stbEngineCons, data.summaries.ae.fuelConsumption.AE1, data.summaries.ae.fuelConsumption.AE2, data.summaries.ae.fuelConsumption.AE3]).toFixed(2)}}</b-th>
          <b-th variant="primary" v-else-if="data.summaries.flowmeter&&!data.summaries.ae">{{sumArray([data.summaries.flowmeter.portEngineCons, data.summaries.flowmeter.stbEngineCons]).toFixed(2)}}</b-th>
          <b-th variant="primary" v-else-if="data.summaries.ae">{{sumArray([data.summaries.ae.fuelConsumption.AE1, data.summaries.ae.fuelConsumption.AE2, data.summaries.ae.fuelConsumption.AE3]).toFixed(2)}}</b-th>
          <b-th variant="primary">Liter</b-th>
        </b-tr>
      </b-tfoot>
      <b-tfoot v-else>
        <b-th colspan="6" variant="primary" class="text-center">No Data</b-th>
      </b-tfoot>
    </b-table-simple>
    <div class="row mb-3 px-3" v-if="data.summaries.gps">
      <div class="col-md-4">
        <h6 class="card-title text-uppercase text-secondary mb-0">Total Cruise</h6>
        <span class="h4 text-dark mb-0 counter d-inline-block w-100">
          <b-form inline>
            <span v-if="cruiseUnit === 'km'">{{data.summaries.gps.totalPolyLength | toKM}}</span>
            <span v-if="cruiseUnit === 'mile'">{{data.summaries.gps.totalPolyLength | toMiles}}</span>
            <span v-if="cruiseUnit === 'nmi'">{{data.summaries.gps.totalPolyLength | toNM}}</span>
            <b-form-select v-model="cruiseUnit" class="ml-2">
              <b-form-select-option value="km">KM</b-form-select-option>
              <b-form-select-option value="nmi">NM</b-form-select-option>
              <b-form-select-option value="mile">Miles</b-form-select-option>
            </b-form-select>
          </b-form>
        </span>
      </div>
      <div class="col-md-4">
        <h6 class="card-title text-uppercase text-secondary mb-0">Average Speed</h6>
        <span class="h4 text-dark mb-0 counter d-inline-block w-100">
          <b-form inline>
            <span v-if="speedUnit === 'knot'">{{data.summaries.gps.calculatedSpeedKMh | toKnot}}</span>
            <span v-else>{{(data.summaries.gps.calculatedSpeedKMh).toFixed(2)}}</span>
            <b-form-select v-model="speedUnit" class="ml-2">
              <b-form-select-option value="knot">Knot</b-form-select-option>
              <b-form-select-option value="kmh">KM/h</b-form-select-option>
            </b-form-select>
          </b-form>
        </span>
      </div>
      <div class="col-md-4">
        <h6 class="card-title text-uppercase text-secondary mb-0">Distance</h6>
        <span class="h4 text-dark mb-0 counter d-inline-block w-100">
          <span v-if="cruiseUnit === 'km'">{{data.summaries.gps.distance | toKM}} KM</span>
          <span v-if="cruiseUnit === 'nmi'">{{data.summaries.gps.distance | toNM}} NM</span>
          <span v-if="cruiseUnit === 'mile'">{{data.summaries.gps.distance | toMiles}} Miles</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dt-daily_consumption',
  props: ['data', 'isLoading', 'massId'],
  data() {
    return {
      cruiseUnit: 'km',
      flowUnit: 'lph',
      speedUnit: 'knot',
    };
  },
  mounted() {
    console.log('data>>>', this.data);
  },
  methods: {
    sumArray(array) {
      // console.log('Ayeee....', array);
      let out = 0;
      array.forEach((a) => {
        if (a !== undefined) {
          out += a;
        }
      });
      return out;
    },
  },
};
</script>

<style lang="scss">
.dt-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 9999;
  background: #DEE2E6;
  height: 100%;
  p {
    margin-top: 15px;
  }
}
.table-logs {
  display: block;
  font-size: 11px;
  overflow-x: auto;
  white-space: nowrap;
}
</style>
