<template>
  <div style="height: 100%;">
    <div v-if="isLoading" class="dt-loading">
      <b-spinner variant="default" size="sm" class="align-middle"></b-spinner>
      <p>Loading data...</p>
    </div>
    <b-table v-else :items="data" :fields="headers" fixed show-empty id="rpmTable">
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'dt-rpm',
  props: ['data', 'isLoading'],
  data() {
    return {
      headers: [
        {
          key: 'date',
          label: 'Date',
          thStyle: {
            background: '#F1F3F5', padding: '5px 0.75rem',
          },
        },
        {
          key: 'engineHour',
          label: 'Engine Hour',
          thStyle: {
            background: '#F1F3F5', padding: '5px 0.75rem',
          },
        },
        {
          key: 'minRpm',
          label: 'Min. RPM',
          thStyle: {
            background: '#F1F3F5', padding: '5px 0.75rem',
          },
        },
        {
          key: 'rpm',
          label: 'RPM',
          thStyle: {
            background: '#F1F3F5', padding: '5px 0.75rem',
          },
        },
        {
          key: 'maxRpm',
          label: 'Max. RPM',
          thStyle: {
            background: '#F1F3F5', padding: '5px 0.75rem',
          },
        },
      ],
    };
  },
  mounted() {
    console.log('rpmdata>>>', this.data);
  },
};
</script>
