export default {
  buildFilters(pagination, filter) {
    const params = {
      page: pagination.current,
      max: pagination.pageSize,
    };
    const keys = Object.keys(filter);
    for (let i = 0; i < keys.length; i++) {
      params[`finds[${keys[i]}]`] = filter[keys[i]];
    }
    return params;
  },
};
