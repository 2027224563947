<template>
  <div id="section-to-print">
    <div :style="isShowSummary ? `height: ${ heightWindow - heightSummary}px;` : 'height:100vh;'">
      <Map v-if="!isLoading&&assetAvailable"
        :center="related.centerMap ? related.centerMap : [-0.831, 117.25343666666667]" :data="assetObject" :combinedData="combinedData"
        @showDataLog="showDataLog" @showDataSummary="showDataSummary3" :mapConfig="mapConfig" :bounds="bounds"
        @toggleShipTrail="toggleShipTrail" @toggleShipTrailOpacity="toggleShipTrailOpacity"
        @toggleShipTrailWeight="toggleShipTrailWeight" @toggleShipLabel="toggleShipLabel"
        @changeShipStatus="changeShipStatus" @changeTrailLength="changeTrailLength" @mapGenerated="mapGenerated"
        style="
          height: 100% !important;
          width: 100%;
          overflow: hidden;
        " />
    </div>

    <div v-if="isShowSummary" :style="`height: ${heightSummary}px; z-index: 999;`">
      <dt-view
        @closeDataTableSummary="closeSummaryTable"
        :isLoading="isLoading"
        :assets="assets"
        :height="heightSummary"
      />
    </div>

    <!-- <div class="bg-white px-3 pt-1 pb-0" v-if="false" style="height: 45vh; z-index: 999;">
      <div class="d-flex flex-row align-items-center justify-content-between small">
        <div class="d-flex flex-row align-items-center my-1">
          <div>
            <b-form-select v-model="summaryFilter.devcMassId" :options="listShipOptions"></b-form-select>
            <select class="form-control mr-3 mb-0" v-model="summaryFilter.devcMassId" v-on:change="changeHandlerShip">
              <option v-bind:key="`ship-0000`" :value="undefined">
                Select Ship
              </option>
              <option v-for="(item, index) in listShipOptions" v-bind:key="`ship-${index}`" :value="item.value">
                {{ item.text }}
              </option>
            </select>
          </div>
          <div>
            <date-picker-2 :disabled="summaryFilter.devcMassId ? false : true" :dateParam="summaryFilter.dateParam"
              :isInline="true" :isLoading="isLoading" :enableInterval="true" @onSubmitRange="onSubmitRange" />
          </div>
          <div>
            <b-button variant="primary" @click="printMe" :disabled=" summaryFilter.devcMassId ? false : true">
              <b-icon-file-earmark-pdf></b-icon-file-earmark-pdf>
              <span class="ml-2">Export PDF</span>
            </b-button>
            <a href="#" class="nav-link btn-link" @click.prevent="printMe">{{ isLoadingDownloadSummary ? 'Loading...' :
              'ExportPDF' }}</a>
          </div>
        </div>
        <div class="d-flex flex-row justify-content-between align-items-center ml-2">
          <div class="overflow-auto px-1">
            <b-pagination class="mb-0" size="sm" v-model="summaryCurrentPage" :total-rows="summaryTotalData"
              :per-page="summaryPerPage" aria-controls="my-table"></b-pagination>
          </div>
          <div class="px-1">
            Per page
            <b-dropdown size="sm" :text="summaryPerPage" class="m-2">
              <b-dropdown-item-button onclick="changeSummaryPerPage(10)">10</b-dropdown-item-button>
              <b-dropdown-item-button onclick="changeSummaryPerPage(25)">25</b-dropdown-item-button>
              <b-dropdown-item-button onclick="changeSummaryPerPage(50)">50</b-dropdown-item-button>
              <b-dropdown-item-button onclick="changeSummaryPerPage(100)">100</b-dropdown-item-button>
            </b-dropdown>
          </div>
          <div class="px-1">
            1-7 of 7
          </div>
          <div class="px-2" @click="closeSummaryTable" style="cursor: pointer;">
            <b-icon-x-lg></b-icon-x-lg>
          </div>
        </div>
      </div>

      <div v-if="isLoadingTableSummary">
        <b-alert show variant="warning">Loading...</b-alert>
      </div>

      <b-table-simple v-if="!isLoadingTableSummary" id="summaryTable" small responsive>
        <b-thead head-variant="dark">
          <b-tr>
            <b-th variant="primary"
              style="background: #002A64; color: #ffffff; text-align: center; border-color: #fff; padding: 5px 0px;">
              Grouping</b-th>
            <b-th variant="primary"
              style="background: #002A64; color: #ffffff; text-align: center; border-color: #fff; padding: 5px 0px;">
              Total Cruise
            </b-th>
            <b-th variant="primary"
              style="background: #002A64; color: #ffffff; text-align: center; border-color: #fff; padding: 5px 0px;"
              colspan="2">
              Engine Hours (Hours)</b-th>
            <b-th variant="primary"
              style="background: #002A64; color: #ffffff; text-align: center; border-color: #fff; padding: 5px 0px;"
              colspan="4">Total
              Consumption (Liter)</b-th>
            <b-th variant="primary"
              style="background: #002A64; color: #ffffff; text-align: center; border-color: #fff; padding: 5px 0px;"
              colspan="2">Fuel
              Average (Liter/Hours)</b-th>
          </b-tr>
          <b-tr>
            <b-th variant="primary"
              style="background: #002A64; color: #ffffff; text-align: center; border-color: #fff; padding: 5px 0px;">
              Date</b-th>
            <b-th variant="primary"
              style="background: #002A64; color: #ffffff; text-align: center; border-color: #fff; padding: 5px 0px;">Km
            </b-th>
            <b-th variant="primary"
              style="background: #002A64; color: #ffffff; text-align: center; border-color: #fff; padding: 5px 0px;">E1
            </b-th>
            <b-th variant="primary"
              style="background: #002A64; color: #ffffff; text-align: center; border-color: #fff; padding: 5px 0px;">E2
            </b-th>
            <b-th variant="primary"
              style="background: #002A64; color: #ffffff; text-align: center; border-color: #fff; padding: 5px 0px;">E1
            </b-th>
            <b-th variant="primary"
              style="background: #002A64; color: #ffffff; text-align: center; border-color: #fff; padding: 5px 0px;">E2
            </b-th>
            <b-th variant="primary"
              style="background: #002A64; color: #ffffff; text-align: center; border-color: #fff; padding: 5px 0px;">AE
            </b-th>
            <b-th variant="primary"
              style="background: #002A64; color: #ffffff; text-align: center; border-color: #fff; padding: 5px 0px;">
              Total</b-th>
            <b-th variant="primary"
              style="background: #002A64; color: #ffffff; text-align: center; border-color: #fff; padding: 5px 0px;">E1
            </b-th>
            <b-th variant="primary"
              style="background: #002A64; color: #ffffff; text-align: center; border-color: #fff; padding: 5px 0px;">E2
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(item, index) in 40" v-bind:key="`ship-${index}`">
            <b-td style="text-align: center;">123</b-td>
            <b-td style="text-align: center;">123</b-td>
            <b-td style="text-align: center;">123</b-td>
            <b-td style="text-align: center;">123</b-td>
            <b-td style="text-align: center;">123</b-td>
            <b-td style="text-align: center;">123</b-td>
            <b-td style="text-align: center;">123</b-td>
            <b-td style="text-align: center;">123</b-td>
            <b-td style="text-align: center;">123</b-td>
            <b-td style="text-align: center;">123</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <b-table :items="summaryListData" :fields="summaryFields" responsive="sm" border show-empty id="summaryTable2">
        <template #thead-top="data">
          <b-tr>
            <b-th colspan="2"><span class="sr-only">Name and ID</span></b-th>
            <b-th variant="primary" style="background: #002A64; color: #ffffff; text-align: center; padding: 5px 0px;">
              Grouping</b-th>
            <b-th variant="primary" style="background: #002A64; color: #ffffff; text-align: center; padding: 5px 0px;">
              Total Cruise
            </b-th>
            <b-th variant="primary" style="background: #002A64; color: #ffffff; text-align: center; padding: 5px 0px;"
              colspan="2">
              Engine Hours (Hours)</b-th>
            <b-th variant="primary" style="background: #002A64; color: #ffffff; text-align: center; padding: 5px 0px;"
              colspan="4">Total
              Consumption (Liter)</b-th>
            <b-th variant="primary" style="background: #002A64; color: #ffffff; text-align: center; padding: 5px 0px;"
              colspan="2">Fuel
              Average (Liter/Hours)</b-th>
          </b-tr>
        </template>
        <template #empty="scope">
          <div class="d-flex justify-content-center align-items-center" style="min-height: 100px;">
            <div class="text-center text-secondary">
              <b-icon-info-circle font-scale="2"></b-icon-info-circle>
              <p style="font-style: italic;">Data not found<span
                  v-if="!summaryFilter.devcMassId && !summaryFilter.dateParam.range.start && !summaryFilter.dateParam.range.end">,
                  please select ship and date</span></p>
            </div>
          </div>
        </template>
        <template #emptyfiltered="scope">
          <h4>{{ scope.emptyFilteredText }}</h4>
        </template>
      </b-table>
    </div> -->

    <div style="z-index: 100; position: relative;" class="mt-3" v-if="assetAvailable === false">
      <b-alert show variant="danger">No Asset</b-alert>
    </div>
    <div class="row" v-if="displayDataLog">
      <div class="col-md-12">
        <div class="iq-card p-0 h-100">
          <div class="overlay px-3 pt-3">
            <b-row>
              <b-col size="12" class="mb-3">
                <date-picker :dateParam="dateParam" :isInline="true" :isLoading="isLoading" :enableInterval="true"
                  @onSubmitRange="onSubmitRange" />
              </b-col>
            </b-row>
            <b-row>
              <b-col size="12">
                <b-tabs content-class="mt-3">
                  <b-tab title="Data Log" active>
                    <DataView :asset="current.asset" :series="current.series" :isLoading="isLoading"
                      class="overlay-dataview" />
                  </b-tab>
                  <b-tab title="Data Summary">
                    <DataSummary v-if="current.asset" :asset="current.asset" :series="current.series"
                      class="overlay-dataview" />
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import L from 'leaflet';
import DtView from '@/components/data-table/DataTable';
import Map from '@/components/directives/iq-map';
import DatePicker from '@/components/directives/date-picker';
// import DatePicker2 from '@/components/directives/date-picker-2';
import dateUtil from '@/util/date';
import { SimpleMapScreenshoter } from 'leaflet-simple-map-screenshoter';
import PdfGenerator from '@/services/PdfGenerator';

import AssetSvc from '@/services/AssetSvc';
// import AssetSummarySvc from '@/services/AssetSummarySvc';
import DeviceSvc from '@/services/DeviceSvc';
import SeriesSvc from '@/services/SeriesSvc';
import requestBuilder from '@/util/request.builder';
import moment from 'moment';
import DataSummary from './DataSummary';
import DataView from './DataView';

export default {
  name: 'Home',
  components: {
    DataSummary,
    DataView,
    DatePicker,
    // DatePicker2,
    Map,
    DtView,
  },
  data() {
    return {
      search: '',
      calories: '',
      desserts: [
        {
          name: 'Donut',
          calories: 452,
          fat: 25.0,
          carbs: 51,
          protein: 4.9,
          iron: 22,
        },
      ],
      selectedCategory: null,
      assetAvailable: undefined,
      bounds: undefined,
      dateParam: {
        mode: 'last',
        duration: 1,
        unit: 'month',
        aggregatedUnit: 'DAY',
      },
      assetsLocation: [],
      mapConfig: {
        fullHeight: true,
      },
      assetObject: {},
      assets: [],
      current: {
        asset: null,
        series: [],
      },
      combinedData: [],
      data: [],
      devices: [],
      gpsIdArray: [],
      gpsIdMap: {},
      displayDataLog: false,
      filter: {
        date: {},
        devices: [],
      },
      isLoading: false,
      related: {},
      selected: {
        range: 'Month',
        rangeValue: 5,
      },
      heightSummary: window.innerHeight * 0.6,
      heightWindow: window.innerHeight,
      summaryListData: [],
      summaryFields: [
        {
          key: 'date',
          label: 'Date',
          thStyle: {
            background: '#002A64', color: '#ffffff', textAlign: 'center', padding: '5px 0px',
          },
        },
        {
          key: 'km',
          label: 'Km',
          thStyle: {
            background: '#002A64', color: '#ffffff', textAlign: 'center', padding: '5px 0px',
          },
        },
        {
          key: 'eh_e1',
          label: 'E1',
          thStyle: {
            background: '#002A64', color: '#ffffff', textAlign: 'center', padding: '5px 0px',
          },
        },
        {
          key: 'eh_e2',
          label: 'E2',
          thStyle: {
            background: '#002A64', color: '#ffffff', textAlign: 'center', padding: '5px 0px',
          },
        },
        {
          key: 'total_consumption_e1',
          label: 'E1',
          thStyle: {
            background: '#002A64', color: '#ffffff', textAlign: 'center', padding: '5px 0px',
          },
        },
        {
          key: 'total_consumption_e2',
          label: 'E2',
          thStyle: {
            background: '#002A64', color: '#ffffff', textAlign: 'center', padding: '5px 0px',
          },
        },
        {
          key: 'total_consumption_ae',
          label: 'AE',
          thStyle: {
            background: '#002A64', color: '#ffffff', textAlign: 'center', padding: '5px 0px',
          },
        },
        {
          key: 'total_consumption_total',
          label: 'Total',
          thStyle: {
            background: '#002A64', color: '#ffffff', textAlign: 'center', padding: '5px 0px',
          },
        },
        {
          key: 'full_avg_e1',
          label: 'E1',
          thStyle: {
            background: '#002A64', color: '#ffffff', textAlign: 'center', padding: '5px 0px',
          },
        },
        {
          key: 'full_avg_e2',
          label: 'E2',
          thStyle: {
            background: '#002A64', color: '#ffffff', textAlign: 'center', padding: '5px 0px',
          },
        },
      ],
      summaryFilter: {
        devcMassId: undefined,
        devcUniqueId: undefined,
        dateParam: {
          mode: 'last',
          duration: 1,
          unit: 'month',
          range: {
            start: undefined,
            end: undefined,
          },
          aggregatedUnit: 'MINUTE',
        },
      },
      listShipOptions: [
        // { value: null, text: 'Please select an option' },
        // { value: 'a', text: 'This is First option' },
        // { value: 'b', text: 'Selected Option' },
        // { value: { C: '3PO' }, text: 'This is an option with object value' },
        // { value: 'd', text: 'This one is disabled', disabled: true },
      ],
      summaryCurrentPage: 1,
      summaryPerPage: 25,
      summaryTotalData: 100,
      isShowSummary: false,
      deviceTypes: [
        {
          label: 'GPS',
          value: 'gps',
          checked: true,
          disabled: false,
        },
        {
          label: 'Flowmeter',
          value: 'flowmeter',
          checked: true,
          disabled: false,
        },
        {
          label: 'RPM',
          value: 'rpm',
          checked: true,
          disabled: false,
        },
        {
          label: 'AE',
          value: 'ae',
          checked: true,
          disabled: false,
        },
      ],
      isLoadingTableSummary: false,
      currentAssetObject: {},
      isLoadingDownloadSummary: false,
      isLoadingShipTrail: false,
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.getAssets();
    if (this.assetAvailable) {
      await this.getDevices();
      await this.getAssetLocations();

      // adjust for speed
      let seriesParam = {};
      seriesParam = dateUtil.msToS({
        label: 'Today',
        value: 'today',
        mode: 'period',
        // duration: 1,
        range: {
          start: moment().set({ hour: 0, minute: 0, second: 0 }),
          end: new Date(),
        },
        aggregatedUnit: 'MINUTE',
        aggregatedLength: 8,
        typesToGet: ['gps'],
      });
      const promises = Object.keys(this.assetObject).map(async (key) => {
        const devcMassId = key;
        const devices = await DeviceSvc.getDevices({
          params: {
            ...requestBuilder.buildFilters({}, { devcMassId }),
          },
        });
        const combinedData = await SeriesSvc.getCombinedData(devices.data.data, seriesParam);
        return combinedData;
      });

      const results = await Promise.all(promises);
      this.combinedData = results;
    }
    this.isLoading = false;
  },
  methods: {
    async getAssets() {
      try {
        const resp = await AssetSvc.getAssets();
        if (resp.data.data.length > 0) {
          this.assetAvailable = true;
          this.assets = resp.data.data;
        } else {
          this.assetAvailable = false;
        }
        resp.data.data.forEach((asset) => {
          this.assetObject[asset.massId] = asset;
        });
        console.log('Got available user asset... ', this.assetObject);

        const tmpList = [];
        Object.values(this.assetObject).forEach((value) => {
          tmpList.push({
            value: value.massId,
            text: value.massName,
          });
        });
        console.log('Got available tmpList... ', tmpList);

        this.listShipOptions = [...tmpList];
      } catch (err) {
        console.log('Handle error nih... ', err.response);
        this.doLogOut();
      }
    },
    async getDevices() {
      const massIds = Object.keys(this.assetObject);
      const params = requestBuilder.buildFilters({
        current: 1,
        pageSize: 25,
      }, {
        devcMassId: massIds,
        devcType: 'gps',
      });
      const resp = await DeviceSvc.getDevices({ params });
      const devices = resp.data.data;
      devices.forEach((device) => {
        if (!this.assetObject[device.devcMassId].devices) this.assetObject[device.devcMassId].devices = {};
        this.assetObject[device.devcMassId].devices[device.devcUniqueId] = { ...device, isHide: false };
      });
      console.log('Got available devices... ', this.assetObject);
    },
    async getAssetLocations() {
      const promises = [];
      Object.keys(this.assetObject).forEach((massId) => {
        if (this.assetObject[massId].devices) {
          Object.keys(this.assetObject[massId].devices).forEach((devcUniqueId) => {
            if (this.assetObject[massId].devices[devcUniqueId].devcType === 'gps') {
              this.gpsIdArray.push(devcUniqueId);
              this.gpsIdMap[devcUniqueId] = massId;
              // promises.push(SeriesSvc.getLastData(this.assetObject[massId].devices[devcUniqueId].devcType, devcUniqueId)
              //   .then((lastData) => {
              //     this.assetObject[massId].devices[devcUniqueId].data = lastData.data.data.series;
              //     this.setDateFilter(lastData.data.data.series[0]);
              //   }));
              // console.log('Get last data of each GPS : ', this.assetObject[massId].devices[devcUniqueId]);
            }
          });
        }
      });
      promises.push(SeriesSvc.getLastData('gps', this.gpsIdArray)
        .then((lastData) => {
          if (lastData.data && lastData.data.data && lastData.data.data.series) {
            lastData.data.data.series.forEach((serie) => {
              // console.log('LD : ', serie.deviceId, this.assetObject[this.gpsIdMap[serie.deviceId]]);
              // console.log('GOT : ', this.assetObject[this.gpsIdArrayMassId[serieIndex]].devices[serie.deviceId], serie, this.gpsIdArrayMassId[serieIndex]);
              this.assetObject[this.gpsIdMap[serie.deviceId]].devices[serie.deviceId].data = [serie];
            });
          }
          this.setDateFilter(lastData.data.data.series[0]);
        }));
      return Promise.all(promises);
    },
    setDateFilter(serie) {
      console.log('Set date picker last date from here : ', serie);
      const tsObject = serie.timestamp * 1000;
      this.dateParam.mode = 'period';
      this.dateParam.range = {
        start: new Date(tsObject).setDate(new Date(tsObject).getDate() - 30),
        end: new Date(tsObject).getTime(),
      };
    },
    async showDataLog({
      showTrail, device, dateParam, disabledUpdateMap,
    }) {
      console.log('Show data log for : ', device, dateParam);
      this.isLoadingShipTrail = true;
      this.assetObject[device.devcMassId].devices[device.devcUniqueId].isLoadingShipTrail = true;
      if (showTrail) {
        this.assetObject[device.devcMassId].devices[device.devcUniqueId].showTrail = true;
        const response = await SeriesSvc.getAggregatedData(device, dateParam);
        console.log('R: ', response.data.data.series, this.assetObject[device.devcMassId].devices[device.devcUniqueId].data);
        this.assetObject[device.devcMassId].devices[device.devcUniqueId].data = response.data.data.series;
        // const points = [];
        // response.data.data.series.forEach((serie) => {
        //   points.push([serie.latitude, serie.longitude]);
        // });
        // this.bounds = new L.LatLngBounds(points);
        // this.mapConfig.zoom = 20;
        // console.log('Update map based on this ... ', this.$refs.homeMap);
      } else {
        console.info('hide single trail');
        this.assetObject[device.devcMassId].devices[device.devcUniqueId].showTrail = false;
      }
      this.assetObject[device.devcMassId].devices[device.devcUniqueId].isLoadingShipTrail = false;
      this.$refs.homeMap.updateMap(disabledUpdateMap);
      this.isLoadingShipTrail = false;
    },
    getEnabledDeviceTypes() {
      const enabled = [];
      this.deviceTypes.forEach((type) => {
        if (type.checked) {
          enabled.push(type.value);
        }
      });
      return enabled;
    },
    async getDevices2(devcMassId) {
      /* eslint-disable */
      console.log('Home Summary:  Getting gateway device id on asset no : ', devcMassId);
      const params = requestBuilder.buildFilters(
        {
          current: 1,
          pageSize: 50,
        },
        {
          devcMassId: devcMassId,
          devcType: this.getEnabledDeviceTypes(),
        },
      );
      return DeviceSvc.getDevices({ params });
      // this.devices = resp.data.data;
    },
    showDataSummary3() {
      this.isShowSummary = !this.isShowSummary;
    }, 
    async showDataSummary2({ showSummary, device, dateParam }) {
      this.isLoadingTableSummary = true;
      console.log('Show data summary for : ', showSummary, device, dateParam);
      if (!device.devcMassId || !device.devcUniqueId) {
        console.log('0.1 !device.devcMassId || !device.devcUniqueId');
        if (showSummary) {
          console.log('0.2 showSummary exist but no data device');
          this.isShowSummary = true;
          this.summaryFilter.devcMassId = undefined;
          this.summaryFilter.devcUniqueId = undefined;
          this.summaryFilter.dateParam = {
            mode: 'last',
            duration: 1,
            unit: 'month',
            range: {
              start: undefined,
              end: undefined,
            },
            aggregatedUnit: 'DAY',
          };
          this.isLoadingTableSummary = false;
        } else {
          console.log('0.3 showSummary not exist and no data device');
          this.isShowSummary = false;
        }
        return;
      }
      console.info('1.0.1 assetObject', this.assetObject);
      this.assetObject[device.devcMassId].devices[device.devcUniqueId].isLoadingShipSummary = true;

      if (showSummary) {
        console.log('1.1 showSummary exist');
        this.summaryFilter.devcMassId = device.devcMassId;
        this.summaryFilter.devcUniqueId = device.devcUniqueId;
        this.summaryFilter.dateParam = dateParam;

        this.isShowSummary = true;

        const resp = await this.getDevices2(device.devcMassId);
        this.devices = resp.data.data;
        console.log('Home Summary: dateParam : ', JSON.stringify(dateParam));
        console.log('Home Summary: param devices : ', JSON.stringify(resp.data.data));
        console.log('Home Summary: param dates : ', JSON.stringify({
          ...dateParam,
          aggregatedUnit: 'DAY',
          typesToGet: ['ae']
        }));
        const combinedData = await SeriesSvc.getCombinedData(this.devices, {
          ...dateParam,
          aggregatedUnit: 'DAY',
          typesToGet: ['ae']
        });
        console.info('response getCombinedData', combinedData, JSON.stringify(combinedData));
        this.currentAssetObject = combinedData;
        this.assetObject[device.devcMassId].devices[device.devcUniqueId].showSummary = true;
      } else {
        console.log('1.2 showSummary not exist but data device exist');
        this.isShowSummary = false;
        this.isLoadingTableSummary = false;
        this.assetObject[device.devcMassId].devices[device.devcUniqueId].showSummary = false;
      }
      this.assetObject[device.devcMassId].devices[device.devcUniqueId].isLoadingShipSummary = false;
      this.$refs.homeMap.updateMap();
      this.isLoadingTableSummary = false;
    },
    async showDataSummary({ showSummary }) {
      console.log('Show data summary for : ', showSummary);
      this.isShowSummary = true;
      // if (!device.devcMassId || !device.devcUniqueId) {
      //   console.log('0.1 !device.devcMassId || !device.devcUniqueId');
      //   if (showSummary) {
      //     console.log('0.2 showSummary exist');
      //     this.summaryFilter.devcMassId = undefined;
      //     this.summaryFilter.devcUniqueId = undefined;
      //     this.summaryFilter.dateParam = {
      //       mode: 'last',
      //       duration: 1,
      //       unit: 'month',
      //       range: {
      //         start: undefined,
      //         end: undefined,
      //       },
      //       aggregatedUnit: 'MINUTE',
      //     };
      //   } else {
      //     console.log('0.3 showSummary not exist');
      //     this.isShowSummary = false;
      //   }
      //   return;
      // }
      // console.info('1.0.1 assetObject', this.assetObject);
      // this.assetObject[device.devcMassId].devices[device.devcUniqueId].isLoadingShipSummary = true;

      // if (showSummary) {
      //   console.log('1.1 showSummary exist');
      //   this.summaryFilter.devcMassId = device.devcMassId;
      //   this.summaryFilter.devcUniqueId = device.devcUniqueId;
      //   this.summaryFilter.dateParam = dateParam;

      //   this.isShowSummary = true;

      //   this.assetObject[device.devcMassId].devices[device.devcUniqueId].showSummary = true;
      //   const response = await SeriesSvc.getCombinedData([device], dateParam);
      //   console.info('response getCombinedData', response);
      //   // console.log('R: ', response.data.data.series, this.assetObject[device.devcMassId].devices[device.devcUniqueId].data);
      //   // const points = [];
      //   // response.data.data.series.forEach((serie) => {
      //   //   points.push([serie.latitude, serie.longitude]);
      //   // });
      //   // this.bounds = new L.LatLngBounds(points);
      //   // this.mapConfig.zoom = 20;
      //   // console.log('Update map based on this ... ', this.$refs.homeMap);
      // } else {
      //   console.log('1.2 showSummary not exist');
      //   this.isShowSummary = false;
      //   this.assetObject[device.devcMassId].devices[device.devcUniqueId].showSummary = false;
      // }
      // this.assetObject[device.devcMassId].devices[device.devcUniqueId].isLoadingShipSummary = false;
      // this.$refs.homeMap.updateMap();
    },
    getZoomLevel(bounds, mapDim) {
      // console.log(bounds, mapDim);
      const WORLD_DIM = { height: 256, width: 256 };
      const ZOOM_MAX = 21;
      function latRad(lat) {
        const sin = Math.sin((lat * Math.PI) / 180);
        const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
      }
      function zoom(mapPx, worldPx, fraction) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
      }
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;
      const lngDiff = ne.lng() - sw.lng();
      const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;
      const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
      const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);
      return Math.min(latZoom, lngZoom, ZOOM_MAX);
    },
    doLogOut() {
      this.$toast.error('Please re-login');
      this.$store.dispatch('auth/logout');
      this.$router.replace({ name: 'Login' });
    },
    async getSeries(filter) {
      console.log('Get data series using filter : ', filter);
      this.isLoading = true;
      let resp = {};
      if (!filter) {
        resp = await SeriesSvc.getAggregatedData('gps', 'month', 6, {
          params: {
            device: ['gps-1'],
            aggregatedUnit: 'MONTH',
            aggregatedLength: 6,
            primaryFunctionName: 'LAST',
          },
        });
      } else {
        resp = await SeriesSvc.getWeeklySeries(filter, this.filter.devices);
      }
      this.data = resp.data.data.devices.map((v) => {
        const data = {};
        data.device = this.devices.find((d) => d.devcUniqueId === v);
        // data.devcType = v;
        // data.series = resp.data.data.series.filter((o) => o.deviceId === 'gps-1');
        // data.series = data.series
        // .map((s) => JSON.parse(s.data))
        // .map((s) => {
        // s.asset = data.device.asset;
        // return s;
        // });
        // data.logs = data.series.map((l) => l.coordinate);
        // data.logs = data.logs.reverse();
        data.device.series = resp.data.data.series;
        // this.related.centerMap = data.logs[0];
        console.log('RRR : ', v, data.device);
        return data;
      });
      // console.log(this.data);
      this.isLoading = false;
    },
    onChangeRange(range) {
      switch (range) {
        case 'Week':
          this.selected.range = range;
          break;
        case 'Month':
          this.selected.range = range;
          break;
      }
      if (this.filter.devices.length > 0 && this.selected.rangeValue) {
        this.getSeries();
      }
    },
    onChangeRangeValue() {
      if (this.filter.devices.length > 0 && this.selected.range) {
        this.getSeries();
      }
    },
    changeHandlerShip(value) {
      console.info('1.1. changeHandlerShip', value);
      const selectedDevcMassId = value.target.value
      this.summaryFilter.devcUniqueId = selectedDevcMassId;
      const selectedDevsUniqueId = Object.keys(this.assetObject[selectedDevcMassId].devices)[0]
      console.info('xxx selectedDevsUniqueId', selectedDevsUniqueId);
      console.info('xxx final', );
      this.assetObject[selectedDevcMassId].devices[selectedDevsUniqueId].permanent = true
      // this.assetObject[device.devcMassId].devices[device.devcUniqueId].isLoadingShipSummary = false;
    },
    onSubmitRange(date) {
      console.info('2. onSubmitRange', date, this.summaryFilter);
      const selectedDevice = this.assetObject[this.summaryFilter.devcMassId].devices;
      const devcUniqueId = Object.keys(selectedDevice)[0];
      
      console.info('2.1 onSubmitRange', selectedDevice, devcUniqueId);

      if (summaryFilter && devcUniqueId) {
        this.showDataSummary2({
          showSummary: true,
          device: { devcMassId: this.summaryFilter.devcMassId, devcUniqueId },
          dateParam: date,
        });
      }
      // this.getSeries(date);
    },
    onDeviceClicked(devcUniqueId) {
      const devices = this.filter.devices.find((o) => o === devcUniqueId);
      let devIndex;
      if (devices) {
        devIndex = this.filter.devices.indexOf(devcUniqueId);
        this.filter.devices.splice(devIndex, 1);
        this.data.splice(devIndex, 1);
      } else {
        this.filter.devices.push(devcUniqueId);
      }
      if (this.filter.devices.length > 0 && this.selected.rangeValue) {
        this.getSeries();
      } else {
        this.data = [];
      }
    },
    changeSummaryPerPage(newVal) {
      this.summaryPerPage = newVal;
    },
    closeSummaryTable() {
      this.isShowSummary = false;
      // this.showDataSummary2({
      //   showSummary: false,
      //   device: {
      //     devcMassId: this.summaryFilter.devcMassId,
      //     devcUniqueId: this.summaryFilter.devcUniqueId,
      //   },
      // });
    },
    // printMe() {
    //   window.print();
    // },
    mapGenerated(mapObject) {
      console.info('mapGenerated', mapObject)
      this.screenShoter = new SimpleMapScreenshoter().addTo(mapObject);
    },
    async printMe() {
      this.isLoadingDownloadSummary = true;
      try {
        const printData = {
          type: {
            type: 'summary-home',
            label: 'Summary',
            tableId: '#summaryTable'
          },
          asset: this.devices[0].devcMass,
          data: this.currentAssetObject,
          period: `${dateUtil.toLocalDate(this.currentAssetObject.start)} to ${dateUtil.toLocalDate(this.currentAssetObject.end)}`,
        };
        console.log('Download report... ', printData);
        await this.screenShoter.takeScreen('image', {
          mimeType: 'image/png',
        }).then((image) => {
          printData.map = image;
          // this.mapShot = image;
          // console.log('IMAGE : ', image);
        }).catch((e) => {
          console.error(e);
        });
        PdfGenerator.generate(printData)
          // .output('dataurlnewwindow', 'report.pdf');
          // .save(`${this.activeType}_${this.assetObject[this.summaryFilter.devcUniqueId].start}-${this.assetObject[this.summaryFilter.devcUniqueId].end}`);
          .save(`report_summary_${this.currentAssetObject.start}-${this.currentAssetObject.end}`);
      } catch (error) {
        console.error('failed download', error);
      } finally {
        this.isLoadingDownloadSummary = false
      }
    },
    toggleShipLabel(value) {
      console.info('reconstruct toggleShipLabel');
      for (const key in this.assetObject) {
        if (Object.prototype.hasOwnProperty.call(this.assetObject, key)) {
          const element = this.assetObject[key];
          const currentDevicesValue = Object.values(element.devices)[0];
          currentDevicesValue['showTooltip'] = value;
        }
      }
      console.info('reconstruct toggleShipLabel done');
      this.$refs.homeMap.updateMap(value);
    },
    toggleShipTrail(value) {
      console.info('reconstruct toggleShipTrail');
      for (const key in this.assetObject) {
        if (Object.prototype.hasOwnProperty.call(this.assetObject, key)) {
          const element = this.assetObject[key];
          const currentDevicesValue = Object.values(element.devices)[0];
          currentDevicesValue['showTrail'] = value;
        }
      }
      console.info('reconstruct toggleShipTrail done');
      this.$refs.homeMap.updateMap();
    },
    toggleShipTrailOpacity(value) {
      console.info('reconstruct toggleShipTrailOpacity');
      for (const key in this.assetObject) {
        if (Object.prototype.hasOwnProperty.call(this.assetObject, key)) {
          const element = this.assetObject[key];
          const currentDevicesValue = Object.values(element.devices)[0];
          currentDevicesValue['trailOpacity'] = Number(value);
        }
      }
      this.$refs.homeMap.updateMap();
    },
    toggleShipTrailWeight(value) {
      let a = {}
      for (const key in this.assetObject) {
        if (Object.prototype.hasOwnProperty.call(this.assetObject, key)) {
          const element = this.assetObject[key];
          const currentDevicesValue = Object.values(element.devices)[0];
          currentDevicesValue['trailWeight'] = Number(value);
        }
        // const b = {
        //   ...element,
        //   devices: {
        //     [Object.keys(element.devices)[0]]: currentDevicesValue
        //   },
        // };
        // a = {...a, [key]: {...b}};
      }
      this.$refs.homeMap.updateMap();
      console.info('reconstruct toggleShipTrailWeight', a);
    },
    changeShipStatus(value) {
      console.info('ship status', value);
      let a = {}
      for (const key in this.assetObject) {
        if (Object.prototype.hasOwnProperty.call(this.assetObject, key)) {
          const element = this.assetObject[key];
          const currentDevicesValue = Object.values(element.devices)[0];
          if (value.type === 'offline') {
            if (new Date().getTime() - (currentDevicesValue.data[currentDevicesValue.data.length - 1].timestamp * 1000) > 600000) {
              currentDevicesValue['isHide'] = !value.value;
            }
            console.info('meraho');
          } else if (value.type === 'online' && new Date().getTime() - (currentDevicesValue.data[currentDevicesValue.data.length - 1].timestamp * 1000) < 600000) {
            console.info('hijao');
            currentDevicesValue['isHide'] = !value.value;
          }
        }
      }
      // this.assetObject = a;
      console.info('this.assetObject', this.assetObject);
      this.$refs.homeMap.updateMap();
    },
    changeTrailLength(value) {
      this.toggleShipTrail(true)
      console.info('2 changeTrailLength', value);
      this.showDataLog(value);
    }
  },
};
</script>
<style scoped lang="scss">
  body, html {
    height: 100% !important;
  }

  .overlay {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff;

    &-dataview {
      bottom: 0;
      height: auto;
      z-index: 2;
      max-height: 300px;
      padding-bottom: 20px;
    }
  }
</style>
