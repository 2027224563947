<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="mt-3">
    <div class="iq-card mb-3" style="min-width: 300px;">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="card-title" v-if="data.userCreatedTime">Edit User</h4>
          <h4 class="card-title" v-if="!data.userCreatedTime">Add New User</h4>
        </div>
      </div>
      <div class="iq-card-body">
        <div class="row">
          <div role="group" class="form-group col-md-6">
            <label for="userId" class="d-block form-label text-left">
              Email:
            </label>
            <div class="bv-no-focus-ring">
              <div role="group" class="input-group">
                <input
                  class="form-control"
                  :disabled="data.userCreatedTime"
                  type="text"
                  id="userId"
                  placeholder="Email"
                  v-model="data.userId"
                  :class="errors.userId ? 'is-invalid' : ''">
                <b-form-invalid-feedback v-if="errors.userId">
                  {{ Object.values(errors.userId) }}
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
          <div role="group" class="form-group col-md-6">
            <label for="userStatus" class="d-block form-label text-left">
              Status:
            </label>
            <div class="bv-no-focus-ring">
              <div role="group"
                   id="userStatus"
                   class="input-group">
                <select
                  class="form-control"
                  v-model="data.userStatus"
                  :disabled="isSubmit"
                  :class="errors.userStatus ? 'is-invalid' : ''">
                  <option disabled="disabled" value="null"> -- Please Select Status --</option>
                  <option value="1">Approved</option>
                  <option value="0">Not Approved</option>
                </select>
                <b-form-invalid-feedback v-if="errors.userStatus">
                  {{ Object.values(errors.userStatus) }}
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group text-right">
          <b-button type="submit"
                    @click="onSubmitEditClicked('Updated')"
                    :disabled="isSubmit"
                    variant="primary"
                    size="sm"
                    class="ml-1">
            <div class="mx-2">
              <i class="fa" :class="isSubmit ? 'ri-loader-line' : 'ri-save-line'"></i>
              Submit
            </div>
          </b-button>
        </div>
      </div>
    </div>
    <div>
      <b-tabs content-class="mt-3">
        <b-tab title="User Company" active>
          <div class="iq-card mb-3" style="min-width: 300px;">
            <div class="iq-card-header d-flex justify-content-between">
              <div class="iq-header-title">
                <h4 class="card-title">{{id}} Company Detail</h4>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="text-right">
                    <button-options
                      :access="canRead('master_user')"
                      :hideDetail="true"
                      :isBackEnabled="true"
                      :isRefreshEnabled="true"
                      :refreshed="isLoading"
                      @onRefreshed="getUserCompanies('Refreshed')"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="iq-card-body">
              <div class="row">
                <div role="group" class="form-group col-md-6">
                  <label for="userId-company" class="d-block form-label text-left">
                    Email:
                  </label>
                  <div class="bv-no-focus-ring">
                    <div role="group" class="input-group">
                      <input
                        type="text"
                        id="userId-company"
                        placeholder="Email"
                        v-model="data.userId"
                        disabled
                        :class="errors.userId ? 'is-invalid' : ''"
                        class="form-control">
                      <b-form-invalid-feedback v-if="errors.userId">
                        {{ Object.values(errors.userId) }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </div>
                <div role="group" class="form-group col-md-6">
                  <label for="compId" class="d-block form-label text-left">
                    Company:
                  </label>
                  <v-select
                    id="compId"
                    label="compName"
                    placeholder="Select Company"
                    v-model="data.compId"
                    :class="errors.compId ? 'is-invalid' : ''"
                    :disabled="isSubmit"
                    :options="companies"
                    :reduce="company => company.compId"
                    @search="filterCompanies">
                    <template slot="no-options">
                      {{companies.length > 0 ? 'Type to search company' : 'No company available'}}
                    </template>
                  </v-select>
                  <b-form-invalid-feedback v-if="errors.compId">
                    {{ Object.values(errors.compId) }}
                  </b-form-invalid-feedback>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-right">
                  <b-button
                    class="ml-1"
                    size="sm"
                    type="submit"
                    variant="primary"
                    :disabled="isSubmit"
                    @click="onCompanySubmitClicked">
                    <div class="mx-2">
                      <i class="fa" :class="isSubmit ? 'ri-loader-line' : 'ri-save-line'"></i>
                      Submit
                    </div>
                  </b-button>
                </div>
              </div>
            </div>
            <div class="iq-card-body">
              <div class="row">
                <div class="col-md-12">
                  <b-table
                    class="table table-responsive table-responsive-sm table-responsive-md
                   table-responsive-lg table-responsive-xl"
                    fixed
                    show-empty
                    responsive
                    :busy="isLoading"
                    :fields="['uscoCompId', 'action']"
                    :items="userCompanies">
                    <template v-slot:table-busy="">
                      <div class="text-center text-danger my-2">
                        <b-spinner variant="success" size="sm" class="align-middle"></b-spinner>
                      </div>
                    </template>
                    <template v-slot:head(uscoCompId)="">
                      <div class="text-left">
                        List of User Company
                      </div>
                    </template>
                    <template v-slot:cell(uscoCompId)="data">
                      <div class="text-left">
                        {{getCompanyName(data.value)}}
                      </div>
                    </template>
                    <template v-slot:head(action)="">
                      <div class="text-center">
                      </div>
                    </template>
                    <template v-slot:cell(action)="data">
                      <div class="text-left">
                        <button class="btn btn-light m-1 btn-sm"
                                :disabled="isSubmit || isLoading"
                                @click="openDelete(data.item, 'company')">
                          <i class="ri-delete-bin-7-line"></i>
                          Delete
                        </button>
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
            <div class="iq-card-body">
              <div class="row">
                <div class="col-md-12">
                  <b-pagination
                    v-model="company.pagination.current"
                    per-page="10"
                    :total-rows="company.pagination.totalSize"
                    @change="onUserPaginationChanged"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="User Role">
          <div class="iq-card mb-3" style="min-width: 300px;">
            <div class="iq-card-header d-flex justify-content-between">
              <div class="iq-header-title">
                <h4 class="card-title">{{id}} Role Detail</h4>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="text-right">
                    <button-options
                      :access="canRead('master_role')"
                      :hideDetail="true"
                      :isBackEnabled="true"
                      :isRefreshEnabled="true"
                      :refreshed="isLoading"
                      @onRefreshed="getUserRoles('Refreshed')"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="iq-card-body">
              <div class="row">
                <div role="group" class="form-group col-md-6">
                  <label for="userId-role" class="d-block form-label text-left">
                    Email:
                  </label>
                  <div class="bv-no-focus-ring">
                    <div role="group" class="input-group">
                      <input
                        type="text"
                        id="userId-role"
                        placeholder="Email"
                        v-model="data.userId"
                        disabled
                        :class="errors.usroUserId ? 'is-invalid' : ''"
                        class="form-control">
                      <b-form-invalid-feedback v-if="errors.usroUserId">
                        {{ Object.values(errors.usroUserId) }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </div>
                <div role="group" class="form-group col-md-6">
                  <label for="roleId" class="d-block form-label text-left">
                    Role:
                  </label>
                  <div class="bv-no-focus-ring">
                    <v-select
                      id="roleId"
                      label="roleName"
                      v-model="data.usroRoleId"
                      placeholder="Select Role"
                      :options="roles"
                      :disabled="isSubmit"
                      :reduce="role => role.roleId"
                      :class="errors.usroRoleId ? 'is-invalid' : ''"
                      @search="filterRoles">
                      <template slot="no-options">
                        {{roles.length > 0 ? 'Type to search role' : 'No role available'}}
                      </template>
                    </v-select>
                  </div>
                  <b-form-invalid-feedback v-if="errors.usroRoleId">
                    {{ Object.values(errors.usroRoleId) }}
                  </b-form-invalid-feedback>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-right">
                  <b-button
                    class="ml-1"
                    size="sm"
                    type="submit"
                    variant="primary"
                    :disabled="isSubmit"
                    @click="onRoleSubmitClicked">
                    <div class="mx-2">
                      <i class="fa" :class="isSubmit ? 'ri-loader-line' : 'ri-save-line'"></i>
                      Submit
                    </div>
                  </b-button>
                </div>
              </div>
            </div>
            <div class="iq-card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive">
                    <b-table class="table table-responsive table-responsive-sm table-responsive-md
                   table-responsive-lg table-responsive-xl"
                             fixed
                             show-empty
                             responsive
                             :busy="isLoading"
                             :fields="['usroRole.roleName', 'action']"
                             :items="userRoles">
                      <template v-slot:table-busy="">
                        <div class="text-center text-danger my-2">
                          <b-spinner variant="success" size="sm" class="align-middle"></b-spinner>
                        </div>
                      </template>
                      <template v-slot:head(usroRole.roleName)="">
                        <div class="text-left">
                          List of User Role
                        </div>
                      </template>
                      <template v-slot:head(action)="">
                        <div class="text-center">
                        </div>
                      </template>
                      <template v-slot:cell(action)="data">
                        <div class="text-left">
                          <button class="btn btn-light m-1 btn-sm"
                                  :disabled="isSubmit"
                                  @click="openDelete(data.item, 'Role')">
                            <i class="ri-delete-bin-7-line"></i>
                            Delete
                          </button>
                        </div>
                      </template>
                    </b-table>
                  </div>
                </div>
              </div>
            </div>
            <div class="iq-card-body">
              <div class="row">
                <div class="col-md-12">
                  <b-pagination
                    v-model="role.pagination.current"
                    :per-page="role.pagination.pageSize"
                    :total-rows="role.pagination.totalSize"
                    @change="onRolePaginationChanged"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <b-modal
      size="lg"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      v-model="displayDeletion">
      <delete-item
        :isSubmit="isSubmit"
        @onCancelDeletion="displayDeletion = false"
        @onConfirmDeletion="onConfirmDeletion"/>
    </b-modal>
  </div>
</template>

<script>
import ButtonOptions from '@/components/directives/button-options';
import CompanySvc from '@/services/CompanySvc';
import DeleteItem from '@/components/directives/delete-item';
import RoleSvc from '@/services/RoleSvc';
import UserSvc from '@/services/UserSvc';

import requestBuilder from '@/util/request.builder';

export default {
  name: 'UserDetail',
  components: { ButtonOptions, DeleteItem },
  data() {
    return {
      data: {},
      displayDeletion: false,
      errors: {},
      filter: {
        userId: this.$route.params.id,
      },
      id: this.$route.params.id,
      isLoading: false,
      isRefreshed: false,
      isSubmit: false,
      related: {
        companies: [],
        deleteType: undefined,
        table: {
          companies: [],
        },
      },
      selected: {},
      pagination: {
        current: 1,
        pageSize: 25,
        totalSize: 0,
      },
      company: {
        pagination: {
          current: 1,
          pageSize: 25,
          totalSize: 0,
        },
      },
      role: {
        pagination: {
          current: 1,
          pageSize: 25,
          totalSize: 0,
        },
      },
      roles: [],
      companies: [],
      userCompanies: [],
      userRoles: [],
    };
  },
  methods: {
    async onCompanySubmitClicked() {
      const data = {
        uscoCompId: this.data.compId,
        uscoUserId: this.data.userId,
      };
      this.errors = {};
      this.isSubmit = true;
      try {
        const resp = await UserSvc.addUserCompany(data);
        if (resp.data.status.error === 0) {
          await this.getCompanies();
          await this.getUserCompanies('Company added');
          await this.getAvailableCompanies();
        } else {
          const errors = resp.data.status.message;
          if (typeof errors === 'object') {
            this.errors = resp.data.status.message;
            this.$toast.error('Error found');
          } else {
            this.$toast.error(resp.data.status.message);
          }
        }
        this.pagination.current = 1;
        this.selected = {};
        this.data.compId = null;
      } catch (e) {
        this.$toast.error(e.response.data.status.message);
      } finally {
        this.isSubmit = false;
      }
    },
    async onRoleSubmitClicked() {
      const data = {
        usroRoleId: this.data.usroRoleId,
        usroUserId: this.data.userId,
      };
      this.errors = {};
      this.isSubmit = true;
      try {
        const resp = await UserSvc.addUserRole(data);
        if (resp.data.status.error === 0) {
          await this.getRoles();
          await this.getUserRoles('Role added');
          await this.getAvailableRoles();
        } else {
          const errors = resp.data.status.message;
          if (typeof errors === 'object') {
            this.errors = resp.data.status.message;
            this.$toast.error('Error found');
          } else {
            this.$toast.error(resp.data.status.message);
          }
        }
        this.pagination.current = 1;
        this.selected = {};
        this.data.usroRoleId = null;
      } catch (e) {
        this.$toast.error(e.response.data.status.message);
      } finally {
        this.isSubmit = false;
      }
    },
    onConfirmDeletion() {
      this.deleteData('Delete complete', this.selected);
    },
    async deleteData(notification, data) {
      this.isSubmit = true;
      const type = this.related.type;
      try {
        let resp;
        if (type === 'company') {
          resp = await UserSvc.deleteUserCompany(data.uscoId);
        } else {
          resp = await UserSvc.deleteUserRole(data.usroId);
        }
        if (resp.data.status.error === 0) {
          this.displayDeletion = await false;
          this.pagination.current = 1;
          if (type === 'company') {
            await this.getUserCompanies(notification);
            await this.getCompanies();
            await this.getAvailableCompanies();
          } else {
            await this.getUserRoles(notification);
            await this.getRoles();
            await this.getAvailableRoles();
          }
        } else {
          this.$toast.error(resp.data.status.message);
        }
      } catch (e) {
        this.$toast.error(e.message);
      } finally {
        this.isSubmit = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    async filterCompanies(v, loading) {
      const param = {
        findField: 'compName',
        findValue: v,
      };
      loading = true;
      try {
        const res = await CompanySvc.getCompanyByName(param);
        if (res.data.status.error === 0) {
          this.permissions = res.data.data;
        }
        loading = false;
      } catch (e) {
        loading = false;
        this.$toast.error(e.message);
      }
    },
    // eslint-disable-next-line no-unused-vars
    async filterRoles(v, loading) {
      const param = {
        findField: 'roleName',
        findValue: v,
      };
      loading = true;
      try {
        const res = await UserSvc.getRoleByName(param);
        if (res.data.status.error === 0) {
          this.permissions = res.data.data;
        }
        loading = false;
      } catch (e) {
        loading = false;
        this.$toast.error(e.message);
      }
    },
    async getData(notification) {
      this.isLoading = true;
      this.isRefreshed = true;
      try {
        const resp = await UserSvc.getUsers({
          params: {
            ...requestBuilder.buildFilters(this.pagination, this.filter),
          },
        });
        if (notification) {
          this.$toast.success(notification);
        }
        this.data = resp.data.data.find((o) => o.userId === this.id);
      } catch (e) {
        if (e.response.data) {
          if (e.response.data.status.message === 'Authentication Error') {
            this.doLogOut();
          } else {
            this.$toast.error(e.response.data.status.message);
          }
        } else {
          this.$toast.error('Network error');
        }
      } finally {
        this.isLoading = false;
        this.isRefreshed = false;
      }
    },
    openDelete(data, type) {
      this.displayDeletion = true;
      this.related.type = type;
      this.selected = data;
    },
    async getCompanies() {
      try {
        const res = await CompanySvc.getCompanies({
          params: {
            ...requestBuilder.buildFilters(this.pagination, {
              // compStatus: 1,
            }),
          },
        });
        if (res.data.status.error === 0) {
          this.related.companies = res.data.data;
        }
      } catch (e) {
        this.$toast.error(e.message);
      }
    },
    onUserPaginationChanged(page) {
      this.company.pagination.current = page;
      this.getUserCompanies();
    },
    async getUserCompanies(notification) {
      this.isLoading = true;
      this.isRefreshed = true;
      try {
        const filter = {
          uscoUserId: this.id,
        };
        const resp = await UserSvc.getUserCompany({
          params: {
            ...requestBuilder.buildFilters(this.company.pagination, filter),
          },
        });
        if (notification) {
          this.$toast.success(notification);
        }
        this.userCompanies = resp.data.data;
        this.company.pagination.totalSize = resp.data.pagination.totalData;
      } catch (e) {
        if (e.response.data) {
          if (e.response.data.status.message === 'Authentication Error') {
            this.doLogOut();
          } else {
            this.$toast.error(e.response.data.status.message);
          }
        } else {
          this.$toast.error('Network error');
        }
      } finally {
        this.isLoading = false;
        this.isRefreshed = false;
      }
    },
    onRolePaginationChanged(page) {
      this.role.pagination.current = page;
      this.getUserRoles();
    },
    async onSubmitEditClicked(notification) {
      const form = {
        userId: this.data.userId,
        userStatus: this.data.userStatus,
      };
      this.errors = {};
      this.isSubmit = true;
      try {
        const resp = await UserSvc.update(this.id, form);
        if (resp.data.status.error === 0) {
          await this.getData(notification);
        } else {
          const errors = resp.data.status.message;
          if (typeof errors === 'object') {
            this.errors = resp.data.status.message;
            this.$toast.error('Error found');
          } else {
            this.$toast.error(resp.data.status.message);
          }
        }
        this.isSubmit = false;
      } catch (e) {
        this.$toast.error(e.response.data.status.message);
        this.isSubmit = false;
      }
    },
    async getUserRoles(notification) {
      this.isLoading = true;
      this.isRefreshed = true;
      try {
        const filter = {
          usroUserId: this.id,
        };
        const resp = await RoleSvc.getUserRoles({
          params: {
            ...requestBuilder.buildFilters(this.role.pagination, filter),
          },
        });
        if (notification) {
          this.$toast.success(notification);
        }
        this.userRoles = resp.data.data;
        this.role.pagination.totalSize = resp.data.pagination.totalData;
      } catch (e) {
        if (e.response.data) {
          if (e.response.data.status.message === 'Authentication Error') {
            this.doLogOut();
          } else {
            this.$toast.error(e.response.data.status.message);
          }
        } else {
          this.$toast.error('Network error');
        }
      } finally {
        this.isLoading = false;
        this.isRefreshed = false;
      }
    },
    getCompanyName(v) {
      const comp = this.related.companies.find((o) => o.compId === v);
      return comp ? comp.compName : '';
    },
    async getRoles() {
      this.isLoading = true;
      this.isRefreshed = true;
      try {
        const resp = await UserSvc.getRoles({
          params: {
            ...requestBuilder.buildFilters(this.pagination, this.filter),
          },
        });
        this.related.roles = resp.data.data;
      } catch (e) {
        if (e.response.data) {
          if (e.response.data.status.message === 'Authentication Error') {
            this.doLogOut();
          } else {
            this.$toast.error(e.response.data.status.message);
          }
        } else {
          this.$toast.error('Network error');
        }
      } finally {
        this.isLoading = false;
        this.isRefreshed = false;
      }
    },
    getAvailableRoles() {
      this.roles = [];
      const unAvailableRoles = this.userRoles.map((o) => o.usroRole.roleId);
      const roles = Object.values(this.related.roles);
      for (let i = 0; i < unAvailableRoles.length; i++) {
        const findIndex = roles.findIndex((o) => o.roleId === unAvailableRoles[i]);
        roles.splice(findIndex, 1);
      }
      this.roles = roles;
    },
    getAvailableCompanies() {
      this.companies = [];
      const unAvailableCompanies = this.userCompanies.map((o) => o.uscoCompId);
      const companies = Object.values(this.related.companies);
      for (let i = 0; i < unAvailableCompanies.length; i++) {
        const findIndex = companies.findIndex((o) => o.compId === unAvailableCompanies[i]);
        companies.splice(findIndex, 1);
      }
      this.companies = companies;
    },
    doLogOut() {
      this.$toast.error('Please re-login');
      this.$store.dispatch('auth/logout');
      this.$router.replace({ name: 'Login' });
    },
  },
  async mounted() {
    await this.getCompanies();
    await this.getUserCompanies();
    await this.getData();
    await this.getRoles();
    await this.getUserRoles();
    await this.getAvailableRoles();
    await this.getAvailableCompanies();
  },
};
</script>

<style scoped>

</style>
