<template>
  <div style="height: 100%;">
    <div v-if="isLoading" class="dt-loading">
      <b-spinner variant="default" size="sm" class="align-middle"></b-spinner>
      <p>Loading data...</p>
    </div>
    <b-table
      v-else
      :items="data"
      :fields="headers"
      fixed
      show-empty
      id="trackingTable"
    >
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'dt-tracking',
  props: ['data', 'isLoading'],
  data() {
    return {
      headers: [
        {
          key: 'date',
          label: 'Date',
          thStyle: {
            background: '#F1F3F5', padding: '5px 0.75rem',
          },
        },
        {
          key: 'latitude',
          label: 'Latitude',
          thStyle: {
            background: '#F1F3F5', padding: '5px 0.75rem',
          },
        },
        {
          key: 'longitude',
          label: 'Longitude',
          thStyle: {
            background: '#F1F3F5', padding: '5px 0.75rem',
          },
        },
        {
          key: 'altitude',
          label: 'Altitude',
          thStyle: {
            background: '#F1F3F5', padding: '5px 0.75rem',
          },
        },
        {
          key: 'heading',
          label: 'Heading',
          thStyle: {
            background: '#F1F3F5', padding: '5px 0.75rem',
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.dt-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 9999;
  background: #DEE2E6;
  height: 100%;
  p {
    margin-top: 15px;
  }
}
</style>
