<template>
  <canvas :id="canId"></canvas>
</template>

<script>
import { RadialGauge } from 'canvas-gauges';

export default {
  name: 'Compass',
  props: ['type', 'canId', 'data'],
  data() {
    return {
      compass: null,
      value: 24,
    };
  },
  mounted() {
    this.compass = new RadialGauge({
      renderTo: this.canId,
      minValue: 0,
      maxValue: 360,
      animateOnInit: true,
      animatedValue: true,
      majorTicks: [
        'N',
        'NE',
        'E',
        'SE',
        'S',
        'SW',
        'W',
        'NW',
        'N',
      ],
      minorTicks: 22,
      ticksAngle: 360,
      startAngle: 180,
      strokeTicks: false,
      highlights: false,
      colorPlate: '#4e73df',
      colorMajorTicks: '#f5f5f5',
      colorMinorTicks: '#ddd',
      colorNumbers: '#ccc',
      colorNeedle: 'rgba(240, 128, 128, 1)',
      colorNeedleEnd: 'rgba(255, 160, 122, .9)',
      valueBox: false,
      valueTextShadow: false,
      colorCircleInner: '#fff',
      colorNeedleCircleOuter: '#ccc',
      needleCircleSize: 15,
      needleCircleOuter: false,
      animationRule: 'linear',
      needleType: 'line',
      needleStart: 75,
      needleEnd: 99,
      needleWidth: 3,
      borders: true,
      borderInnerWidth: 0,
      borderMiddleWidth: 0,
      borderOuterWidth: 10,
      colorBorderOuter: '#ccc',
      colorBorderOuterEnd: '#ccc',
      colorNeedleShadowDown: '#222',
      borderShadowWidth: 0,
      animationTarget: 'plate',
      units: '',
      value: this.value,
      title: '',
      fontTitleSize: 19,
      colorTitle: '#f5f5f5',
      animationDuration: 1500,
    }).draw();
  },
  beforeDestroy() {
    this.compass.destroy();
  },
  watch: {
    value(val) {
      this.compass.value = val;
    },
  },
};
</script>

<style scoped>

</style>
