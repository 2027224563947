<template>
  <div class="container transition-opacity">
    <h4 class="title">
      <div>
        <i class="ri-settings-5-line title-icon" />
        Map Styles
      </div>
      <i @click="onCloseButtonClicked" class="ri-close-fill cursor-pointer" />
    </h4>
    <div class="items">
      <div class="item-container" :class="{ 'item-container-collapse': !isMapStyleExpand }">
        <div class="item-title">
          <h5>Select map style</h5>
          <i @click="setIsMapStyleExpand(!isMapStyleExpand)" class="cursor-pointer"
            :class="{ 'ri-arrow-up-s-line': isMapStyleExpand, 'ri-arrow-down-s-line': !isMapStyleExpand }" />
        </div>
        <div class="item" style="cursor: pointer;" @click="handleChangeStyle(0)">
          Satellite
          <label class="radio-container">
            <input type="radio" name="map-style" :checked="mapStyleIdx === 0" />
            <span class="checkmark" />
          </label>
        </div>
        <div class="item" style="cursor: pointer;" @click="handleChangeStyle(1)">
          Standard
          <label class="radio-container">
            <input type="radio" name="map-style" :checked="mapStyleIdx === 1" />
            <span class="checkmark" />
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'map-styles',
  data() {
    return {
      isMapStyleExpand: true,
    };
  },
  props: {
    isShown: { type: Boolean, default: false },
    mapStyleIdx: { type: Number, default: 0 },
  },
  methods: {
    onCloseButtonClicked() {
      this.$emit('hideMapStyles');
    },
    setIsMapStyleExpand(state) {
      this.isMapStyleExpand = state;
    },
    handleChangeStyle(value) {
      console.info('0 changed', value);
      this.$emit('changeMapStyle', value);
    },
  },
};
</script>

<style scoped>
.z-10000{
    z-index: 10000 !important;
}

.container {
  position: absolute;
  z-index: 10000;
  right: 70px;
  /* top: 135px; */
  top: 60px;
  width: 300px;
  padding: 14px;
  background-color: #ecf1fc;
  border-radius: 8px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-icon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  color: #93a1b0;
}

.items {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  margin-top: 14px;
}

.item-container {
  padding: 14px;
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  background-color: white;
  border-radius: 14px;
}

.item-container-collapse {
  max-height: 50px;
  overflow: hidden;
}

.item-title {
  display: flex;
  justify-content: space-between;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* The container */
.radio-container {
  display: block;
  position: relative;
  padding-left: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border-radius: 50%;
  border: #002A64 solid 1px;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #002A64;
}
</style>
