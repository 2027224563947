<template>
  <div class="text-center">
    <p class="text-center card-text">{{dev.devcName}}</p>
    <div :id="canId" style="height: 201px; width: 100%">
      <span>Uptime : {{battery.data.uptime}}</span>
      <span>Battery : {{battery.data.battery.level}}% - {{battery.data.battery.state}}</span>
      <span>Free Space : {{battery.data.memory.free}}({{battery.data.memory.freePercentage}}%)</span>
      <span>Total Space : {{battery.data.memory.total}}</span>
    </div>
    <p class="text-right card-text">{{device.recentSeries ? recentSeries.datetime : ''}}</p>
  </div>
</template>

<script>
import SeriesSvc from '@/services/SeriesSvc';

export default {
  name: 'BatteryMeter',
  props: ['type', 'canId', 'dev', 'data'],
  data() {
    return {
      device: {},
      recentSeries: {},
      battery: {
        data: {
          battery: {},
          memory: {},
        },
      },
    };
  },
  methods: {
    dateRange(date) {
      this.$emit('dateRange', date);
    },
    async getData(devcUniqueId) {
      if (devcUniqueId) {
        const filter = {
          start: 1593561600,
          end: parseInt((new Date().getTime() / 1000).toFixed(0)),
        };
        const resp = await SeriesSvc.getWeeklySeries(filter, devcUniqueId);
        const range = {
          from: resp.data.data.fromString,
          to: resp.data.data.toString,
        };
        this.dateRange(range);
        this.onFinishedGetData(resp.data.data);
        let log = resp.data.data.devices.map((v) => {
          const data = {};
          data.device = this.dev;
          data.devcType = v;
          data.series = resp.data.data.series.filter((o) => o.deviceId === v);
          data.series = data.series.map((s) => {
            s.data = JSON.parse(s.data);
            return s;
          });
          data.recentSeries = data.series[0];
          return data;
        });
        log = log.find((o) => o.devcType === devcUniqueId);
        this.device = log;
        this.recentSeries = resp.data.data.series[0];
        this.generateWidget(this.recentSeries);
      }
    },
    generateWidget(log) {
      this.battery = log;
    },
    onFinishedGetData(series) {
      const fields = [
        { key: 'datetime', label: 'Date', class: 'text-center' },
        { key: 'data.uptime', label: 'Uptime', class: 'text-center' },
        { key: 'data.memory.total', label: 'Total Memory', class: 'text-center' },
        { key: 'data.memory.free', label: 'Free Memory', class: 'text-center' },
        { key: 'data.memory.freePercentage', label: 'Free Memory Percentage', class: 'text-center' },
        { key: 'data.battery.state', label: 'Battery State', class: 'text-center' },
        { key: 'data.battery.level', label: 'Battery Level (%)', class: 'text-center' },
      ];
      const logs = series.series.map((s) => {
        const log = {};
        log.datetime = s.datetime;
        log.id = s.id;
        log.data = JSON.parse(s.data);
        return log;
      });
      this.$emit('collectedData', logs);
      this.$emit('fieldsData', fields);
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(devcUniqueId) {
        this.getData(devcUniqueId);
      },
    },
  },
};
</script>

<style scoped>

</style>
