<template>
  <!-- Sidebar  -->
  <div class="iq-sidebar"
    :style="small && width < 1300 ? 'width: 80px; border-right: 1px solid #e6e6e6; display: block; z-index: 999; left:0;' : small ? 'width: 80px; border-right: 1px solid #e6e6e6;' : 'border-right: 1px solid #e6e6e6;'">
    <div class="iq-sidebar-logo d-flex justify-content-between border-bottom pb-2"
      :style="!small ? 'padding-left: 20px;' : 'padding-left: 12px;'">
      <router-link to="/">
        <img :src="logo" alt="logo" :style="small ? 'height: 20px;' : 'height: 30px;'">
      </router-link>
      <!-- <div class="iq-menu-bt align-self-center">
        <div class="wrapper-menu">
          <div class="line-menu half start"></div>
          <div class="line-menu"></div>
          <div class="line-menu half end"></div>
        </div>
      </div> -->
    </div>
    <div id="sidebar-scrollbar">
      <b-container class="bv-example-row pb-2 border-bottom cursor-pointer" @click="onCompanyActiveClicked">
        <b-row align-v="center">
          <b-col :lg="small ? 12 : 4">
            <a v-if="userAvatar" href="#" class="search-toggle iq-waves-effect text-white">
              <img :src="userAvatar" class="img-fluid rounded" alt="user" style="border: 2px solid #002A64;">
            </a>
          </b-col>
          <b-col lg="6" style="color: #000000;" class="px-0" v-if="!small">
            {{ activeCompany.compName }}
          </b-col>
          <b-col lg="1" style="font-size: 20px;" class="text-black" v-if="!small">
            <!-- <b-icon icon="chevron-compact-down" variant="dark" style="color: #444444;" /> -->
            <b-icon-chevron-right></b-icon-chevron-right>
          </b-col>
        </b-row>
      </b-container>
      <nav class="iq-sidebar-menu" :class="horizontal ? 'd-xl-none' : ''">
        <SideBarList :items="items" :open="true" :horizontal="horizontal" :small="small" />
      </nav>
      <div class="p-3"></div>
    </div>

    <b-container class="bv-example-row px-3 py-2 border-top bg-white" @click="onMaxMinSidebar"
      style="position: absolute; bottom: 0px;">
      <b-row align-v="center" :align-h="small ? 'center' : undefined">
        <b-col v-if="!small" lg="10" style="color: #000000;" class="text-secondary">
          Minimize
        </b-col>
        <b-col lg="2"
          :style="small ? 'font-size: 20px; font-weight: bold; text-align: center; padding:0px;' : 'font-size: 20px; font-weight: bold; text-align: right;'"
          class="text-black">
          <b-icon-chevron-right v-if="small"></b-icon-chevron-right>
          <b-icon-chevron-left v-else></b-icon-chevron-left>
        </b-col>
      </b-row>
    </b-container>
    <user-dropdown :isShown="isShowUserDropdown" @hideUserDropdown="setIsUserDropdown(false)" :isSmall="small" />
  </div>
</template>

<script>
// import UserDropdown from '@/components/navigations/UserDropdown';
import rasAvatar from '@/assets/images/user/1.jpeg';
import assAvatar from '@/assets/images/user/7.jpg';
import kanakaAvatar from '@/assets/images/user/kanaka.jpg';
import aasAvatar from '@/assets/images/user/aas.jpeg';
import mdmAvatar from '@/assets/images/user/mdm.jpg';
import brlAvatar from '@/assets/images/user/brl.png';
import citraAvatar from '@/assets/images/user/citra.png';
import meratusAvatar from '@/assets/images/user/meratus.jpeg';
import plaAvatar from '@/assets/images/user/pla.png';
import SideBarList from './SideBarList';
import UserDropdown from './UserDropdown.vue';

export default {
  name: 'SideBar',
  props: {
    items: { type: Array },
    logo: { type: String, default: require('@/assets/images/logo/logo.png') },
    horizontal: { type: Boolean },
    small: { type: Boolean, default: false },
    width: { type: Number, default: 0 },
  },
  data() {
    return {
      userAvatar: undefined,
      isShowUserDropdown: false,
    };
  },
  components: {
    SideBarList,
    UserDropdown,
  },
  mounted() {
    this.userAvatar = rasAvatar;
    if (this.activeCompany.compId === '7') {
      this.userAvatar = assAvatar;
    } else if (this.activeCompany.compId === '8') {
      this.userAvatar = kanakaAvatar;
    } else if (this.activeCompany.compId === '10') {
      this.userAvatar = aasAvatar;
    } else if (this.activeCompany.compId === '14') {
      this.userAvatar = mdmAvatar;
    } else if (this.activeCompany.compId === '24') {
      this.userAvatar = meratusAvatar;
    } else if (this.activeCompany.compId === '25') {
      this.userAvatar = brlAvatar;
    } else if (this.activeCompany.compId === '15') {
      this.userAvatar = citraAvatar;
    } else if (this.activeCompany.compId === '30') {
      this.userAvatar = plaAvatar;
    }
    // console.log(this.activeCompany.compId);
  },
  computed: {
    activeCompany() {
      return this.$store.getters['auth/activeCompany'];
    },
  },
  methods: {
    onCompanyActiveClicked() {
      this.setIsUserDropdown(!this.isShowUserDropdown);
    },
    setIsUserDropdown(state) {
      this.isShowUserDropdown = state;
    },
    onMaxMinSidebar() {
      this.$emit('onMaxMinSidebar');
    },
  },
};
</script>

<style scoped>
  @import url("../../assets/css/custom.css");
</style>
