import Vue from 'vue';

// copy from https://stackoverflow.com/questions/37893131/how-to-convert-lat-long-from-decimal-degrees-to-dms-format
function toDegreesMinutesAndSeconds(coordinate) {
  const absolute = Math.abs(coordinate);
  const degrees = Math.floor(absolute);
  const minutesNotTruncated = (absolute - degrees) * 60;
  const minutes = Math.floor(minutesNotTruncated);
  const seconds = Math.floor((minutesNotTruncated - minutes) * 60);

  return `${degrees}° ${minutes}' ${seconds}''`;
}

Vue.filter('latToDMS', (value) => {
  if (!value) return '';
  const cardinal = value > 0 ? 'N' : 'S';
  return `${toDegreesMinutesAndSeconds(value)} ${cardinal}`;
});

Vue.filter('longToDMS', (value) => {
  if (!value) return '';
  const cardinal = value > 0 ? 'E' : 'W';
  return `${toDegreesMinutesAndSeconds(value)} ${cardinal}`;
});
