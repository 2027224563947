<template>
  <div v-if="ready">
    <apexchart type="line" :options="chartOptions" :series="series" :height="customHeight"></apexchart>
  </div>
</template>
<script>

import dateUtil from '@/util/date';

export default {
  name: 'report-bunker',
  props: {
    data: { type: Object },
    customHeight: { type: Number },
    isDailyReport: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    console.log('halooo', this.data, this.isDailyReport);
    if (this.data) {
      const chartOptions = { ...this.constructChartData(this.data), chart: { animations: { enabled: !this.isDailyReport } } };
      console.log('Mounted... ', chartOptions);
      this.ready = true;
    }
  },
  methods: {
    constructChartData(data) {
      const constructedData = {};
      Object.keys(data.datas).forEach((timeStamp, timestampIndex) => {
        if (this.isDailyReport) {
          this.chartOptions.labels.push(++this.count);
        } else {
          this.chartOptions.labels.push(timeStamp); // labels time must be unix code
        }
        if (data.datas[timeStamp].flowmeter.BUNKER_IN) {
          Object.keys(data.datas[timeStamp].flowmeter.BUNKER_IN).forEach((params) => {
            switch (params) {
              case 'massFlowrate':
                if (!constructedData.mfr) {
                  constructedData.mfr = {
                    name: 'Mass Flow Rate',
                    type: 'line',
                    data: [],
                  };
                }
                constructedData.mfr.data[timestampIndex] = 0;
                if (data.datas[timeStamp].flowmeter.BUNKER_IN.massFlowrate) {
                  constructedData.mfr.data[timestampIndex] = data.datas[timeStamp].flowmeter.BUNKER_IN.massFlowrate;
                }
                break;
              case 'density':
                if (!constructedData.density) {
                  constructedData.density = {
                    name: 'Density',
                    type: 'line',
                    data: [],
                  };
                }
                constructedData.density.data[timestampIndex] = 0;
                if (data.datas[timeStamp].flowmeter.BUNKER_IN.density) {
                  constructedData.density.data[timestampIndex] = data.datas[timeStamp].flowmeter.BUNKER_IN.density;
                }
                break;
              case 'driveGain':
                if (!constructedData.dg) {
                  constructedData.dg = {
                    name: 'Drive Gain',
                    type: 'line',
                    data: [],
                  };
                }
                constructedData.dg.data[timestampIndex] = 0;
                if (data.datas[timeStamp].flowmeter.BUNKER_IN.driveGain) {
                  constructedData.dg.data[timestampIndex] = data.datas[timeStamp].flowmeter.BUNKER_IN.driveGain;
                }
                break;
              case 'temperature':
                if (!constructedData.temp) {
                  constructedData.temp = {
                    name: 'Temperature',
                    type: 'line',
                    data: [],
                  };
                }
                constructedData.temp.data[timestampIndex] = 0;
                if (data.datas[timeStamp].flowmeter.BUNKER_IN.temperature) {
                  constructedData.temp.data[timestampIndex] = data.datas[timeStamp].flowmeter.BUNKER_IN.temperature;
                }
                break;
            }
          });
        }
      });
      this.series = [];
      Object.keys(constructedData).forEach((dev) => {
        // console.log(dev);
        this.series.unshift(constructedData[dev]);
      });
      console.log(this.series);
      return {};
    },
  },
  data() {
    return {
      count: 0,
      ready: false,
      series: [],
      chartOptions: {
        chart: {
          animations: {
            enabled: !this.isDailyReport,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: !this.isDailyReport,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: !this.isDailyReport,
              speed: 350,
            },
          },
          height: 'auto',
          type: 'line',
          stacked: false,
          events: {
            zoomed: (chartContext, { xaxis }) => {
              this.$emit('onZoomed', xaxis);
            },
            scrolled: (chartContext, { xaxis }) => {
              this.$emit('onScrolled', xaxis);
            },
            animationEnd: (chartContext) => {
              this.svg = chartContext.paper().svg();
              chartContext.dataURI().then(({ imgURI }) => {
                this.png = imgURI;
              });
            },
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: true,
              reset: false,
              customIcons: [],
            },
          },
        },
        stroke: {
          width: [2, 2, 2, 2, 2, 2],
          curve: 'smooth',
        },
        plotOptions: {
          bar: {
            columnWidth: '80%',
          },
        },
        fill: {
          opacity: [0.75, 0.75, 1, 0.85, 1],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: 'vertical',
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        labels: [], // dynamic
        markers: {
          size: 0,
        },
        xaxis: {
          labels: {
            show: true,
            formatter: (value) => {
              if (value) {
                if (this.isDailyReport) {
                  return value;
                }
                return dateUtil.toLocalDate(value);
              }
            },
          },
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#008FFB',
            },
            labels: {
              style: {
                colors: '#008FFB',
              },
              formatter: (value) => value.toFixed(),
            },
            title: {
              text: 'Temperature (°C)',
              style: {
                color: '#008FFB',
              },
            },
            tooltip: {
              enabled: true,
            },
          },
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#83F1CC',
            },
            labels: {
              show: true,
              formatter: (value) => value.toFixed(),
            },
            title: {
              text: 'Density (Kg/m³)',
              style: {
                color: '#83F1CC',
              },
            },
            tooltip: {
              enabled: true,
            },
          },
          {
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#FAB93C',
            },
            labels: {
              show: true,
              formatter: (value) => value.toFixed(),
            },
            title: {
              text: 'Mass Flow Rate (MT/HR)',
              style: {
                color: '#FAB93C',
              },
            },
            tooltip: {
              enabled: true,
            },
          },
        ],
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: (y) => {
              if (typeof y !== 'undefined') {
                return y.toFixed(0);
              }
              return y;
            },
          },
        },
      },
    };
  },
};
</script>
