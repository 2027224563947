<template>
  <b-container fluid>
    <AssetTypeList/>
  </b-container>
</template>

<script>
import AssetTypeList from './List';

export default {
  name: 'AssetTypeIndex',
  components: { AssetTypeList },
  data() {
    return {
      breadcrumb: [
        {
          text: 'Dashboard',
          href: '/home',
        },
        {
          text: 'Asset',
          href: '/assets',
        },
        {
          text: 'Type',
          active: true,
        },
      ],
    };
  },
};
</script>

<style scoped>

</style>
