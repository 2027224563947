<template>
  <div class="map-popup-container rounded">
    <b-tabs class="bg-popup" id="tab-map-pupup-2">
      <b-tab v-for="(device, uniqueId) in asset" :key="uniqueId" :title="device.devcMass.massName" active
        class="bg-popup">
        <b-container class="pb-2 px-0">
          <b-row align-v="center">
            <b-col cols="1">
              <span class="bg-white"
                style="width: 40px; height: 40px; background: #ffffff; padding: 5px; border-radius: 40px;">
                <b-icon-clock></b-icon-clock>
              </span>
            </b-col>
            <b-col cols="10">
              <div class="text-secondary">
                <small>Last Updated</small>
              </div>
              <div>
                <small>{{device.data[device.data.length-1].timestamp | toLocalDate}} GMT +0700</small>
                <!-- <small>{{convertDateToStandard(new Date(device.data[device.data.length-1].timestamp * 1000))}}</small> -->
              </div>
            </b-col>
          </b-row>
        </b-container>

        <b-container class="bg-white py-2 px-3 border-radius-card">
          <b-row>
            <b-col class="text-secondary">
              Status
            </b-col>
            <b-col class="text-right">
              <span v-if="isOnline(device)">
                <b-icon-circle-fill variant="success" scale="0.9"></b-icon-circle-fill>
                Online
              </span>
              <span v-else>
                <b-icon-circle-fill variant="danger" scale="0.9"></b-icon-circle-fill>
                Offline
              </span>
            </b-col>
          </b-row>
        </b-container>

        <!-- <div class="table-responsive mapTab mt-1" v-if="device.data && device.devcType === 'flowmeter'">
          <div class="mt-1">
           <small>Record Date {{device.data[1][device.data[0].length - 1].timestamp | toLocalDate}}</small>
          </div>
        </div>
        <div class="table-responsive mapTab mt-1" v-if="device.data && device.devcType === 'rpm'">
          <table class="table table-bordered table-sm">
            <tbody>
              <tr>
                <th>Port</th>
                <th>Starboard</th>
              </tr>
              <tr v-if="device.data[0]">
                <td>{{device.data[0][device.data[0].length - 1].rpm}}</td>
                <td>{{device.data[1][device.data[0].length - 1].rpm}}</td>
              </tr>
            </tbody>
          </table>
          <div class="mt-1">
           <small>Record Date {{device.data[1][device.data[0].length - 1].timestamp | toLocalDate}}</small>
          </div>
        </div> -->

        <div class="bg-white mt-2 px-3 py-3 border-radius-card">
          <div class="text-secondary mb-1">Ship Detail</div>

          <b-container class="p-0">
            <b-row>
              <b-col cols="6" class="mt-2">
                <div class="text-secondary">
                  Latitude
                </div>
                <div>
                  {{device.data[device.data.length-1].latitude | latToDMS}}
                </div>
              </b-col>
              <b-col cols="6" class="mt-2">
                <div class="text-secondary">
                  Longitude
                </div>
                <div>
                  {{device.data[device.data.length-1].longitude | longToDMS}}
                </div>
              </b-col>
              <b-col cols="6" class="mt-2">
                <div class="text-secondary">
                  Altitude
                </div>
                <div>
                  {{device.data[device.data.length-1].altitude}}
                </div>
              </b-col>
              <b-col cols="6" class="mt-2">
                <div class="text-secondary">
                  Heading
                </div>
                <div>
                  {{device.data[device.data.length-1].track}} °
                </div>
              </b-col>
              <b-col cols="6" class="mt-2">
                <div class="text-secondary">
                  Speed
                </div>
                <div>
                  {{combinedData.summaries.gps.averageSpeed.toFixed(2) | toKnot}} Knot
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>

        <!-- <div class="table-responsive mapTab mt-1" v-if="device.data && device.devcType === 'gps'">
          <table class="table table-bordered table-sm">
            <tbody>
              <tr v-if="device.data[device.data.length-1].latitude">
                <td>Latitude</td>
                <td>{{device.data[device.data.length-1].latitude | latToDMS}}</td>
              </tr>
              <tr v-if="device.data[device.data.length-1].longitude">
                <td>Longitude</td>
                <td>{{device.data[device.data.length-1].longitude | longToDMS}}</td>
              </tr>
              <tr v-if="device.data[device.data.length-1].altitude">
                <td>Altitude</td>
                <td>{{device.data[device.data.length-1].altitude}}</td>
              </tr>
              <tr v-if="device.data[device.data.length-1].track">
                <td>Heading</td>
                <td>{{device.data[device.data.length-1].track}} °</td>
              </tr>
              <tr v-if="device.data[device.data.length-1].speed !== undefined">
                <td>Speed</td>
                <td>{{device.data[device.data.length-1].speed}}</td>
              </tr>
            </tbody>
          </table>
        </div> -->

        <div class="bg-white mt-2 px-3 py-3 border-radius-card">
          <div class="text-secondary mb-1">Action</div>

          <b-container class="p-0">
            <b-row class="mb-2">
              <b-col>
                <img src="@/assets/icons/map-settings/label.svg" style="margin-right: 10px;" />
                <span>Ship Label</span>
              </b-col>
              <b-col cols="3">
                <b-form-checkbox class="cursor-pointer" v-model="isShowShipLabel" name="check-button"
                  button-variant="success" switch></b-form-checkbox>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col>
                <img src="@/assets/icons/map-settings/trail.svg" style="margin-right: 10px;" />
                <span>Ship Trail<span v-if="isLoadingShipTrail">
                    <b-spinner class="ml-1" small variant="secondary" label="Loading Ship Trail"
                      v-if="isLoadingShipTrail" style="width: 0.8rem; height: 0.8rem;"></b-spinner>
                  </span>
                </span>
              </b-col>
              <b-col cols="3">
                <b-form-checkbox class="cursor-pointer" v-model="isShowShipTrail" name="check-button"
                  button-variant="success" switch :disabled="isLoadingShipTrail"></b-form-checkbox>
              </b-col>
            </b-row>
            <!-- <b-row>
              <b-col>
                <b-icon-table></b-icon-table>
                <span class="ml-3">Ship Summary<span v-if="isLoadingShipSummary">
                    <b-spinner class="ml-1" small variant="secondary" label="Loading Ship Summary"
                      v-if="isLoadingShipSummary" style="width: 0.8rem; height: 0.8rem;"></b-spinner>
                  </span>
                </span>
              </b-col>
              <b-col cols="3">
                <b-form-checkbox v-model="isShowShipSummary" name="check-button" button-variant="success" switch>
                </b-form-checkbox>
              </b-col>
            </b-row> -->
            <div class="list-unstyled mt-2">
              <!-- <button type="button" block @click.prevent="showDataLog" class="btn mr-2 btn-outline-primary rounded-pill btn-sm">Trail</button> -->
              <!-- <button type="button" @click.prevent="showAnalytic(device)" class="btn mr-2 btn-outline-danger rounded-pill btn-sm">Analytics</button> -->
              <!-- <button type="button" @click.prevent="showReport(device)" class="btn mr-2 btn-outline-danger rounded-pill btn-sm">Report</button>
                <button type="button" @click.prevent="showDashboard(device)" class="btn mr-1 btn-outline-success rounded-pill btn-sm">Dashboard</button> -->
              <b-button block variant="primary" @click.prevent="showReport(device)">Report</b-button>
              <b-button block variant="outline-dark" @click.prevent="showDashboard(device)">Dashboard</b-button>
            </div>
            <!-- <b-row>
            </b-row> -->
          </b-container>
        </div>

      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { convertDateToStandard } from '../../../util/date';

export default {
  name: 'MapPopUp2',
  props: ['asset', 'showTrail', 'showSummary', 'devcMassId', 'activeDevcMassId', 'showAllTooltip', 'combinedData', 'indexx'],
  data() {
    return {
      isShowShipTrail: false,
      isShowShipSummary: false,
      isLoadingShipTrail: false,
      isLoadingShipSummary: false,
      isShowShipLabel: false,
      currentIndex: 0,
      data: null,
    };
  },
  computed: {
  },
  watch: {
    // whenever question changes, this function will run
    showTrail(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isShowShipTrail = newVal;
        this.isLoadingShipTrail = false;
      }
    },
    showSummary(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isShowShipSummary = newVal;
        this.isLoadingShipSummary = false;
      }
    },
    isShowShipTrail(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.onChangeShipTrail(newVal);
        this.isLoadingShipTrail = false;
      }
    },
    isShowShipLabel(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.showSingleTooltip(newVal);
      }
    },
    isShowShipSummary(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.onChangeShipSummary(newVal);
      }
    },
    activeDevcMassId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isShowShipLabel = newVal;
      }
    },
  },
  methods: {
    getIndex(uniqueId) {
      if (!(uniqueId in this.asset)) {
        this.asset[uniqueId] = this.currentIndex;
        this.currentIndex++;
      }
      return this.asset[uniqueId];
    },
    showSingleTooltip(value) {
      this.$emit('showSingleTooltip', {
        value,
        currentDevcMassId: this.devcMassId,
      });
    },
    showDashboard(device) {
      console.log('Show Dashboard for this device : ', device);
      this.$router.push({
        name: 'Dashboard',
        query: {
          massId: device.devcMassId,
          //   start: this.$date.utc(new Date(device.data[device.data.length - 1].datetime).setDate(-1)).unix(),
          //   end: this.$date.utc(device.data[device.data.length - 1].datetime).unix(),
        },
      });
    },
    showAnalytic(device) {
      // console.log('Show Analytics for this device : ', device);
      this.$router.push({ name: 'AssetAnalytics', params: { id: device.devcMassId } });
      // this.$router.push({
      //   name: 'Dashboard',
      //   // query: {
      //   //   start: this.$date.utc(new Date(device.data[device.data.length - 1].datetime).setDate(-1)).unix(),
      //   //   end: this.$date.utc(device.data[device.data.length - 1].datetime).unix(),
      //   // },
      // });
    },
    showReport(device) {
      // console.log('Show Analytics for this device : ', device);
      this.$router.push({ name: 'ReportView', params: { id: device.devcMassId, type: 'table' } });
      // this.$router.push({
      //   name: 'Dashboard',
      //   // query: {
      //   //   start: this.$date.utc(new Date(device.data[device.data.length - 1].datetime).setDate(-1)).unix(),
      //   //   end: this.$date.utc(device.data[device.data.length - 1].datetime).unix(),
      //   // },
      // });
    },
    getReadingValue(readings, label, field) {
      const reading = readings.find((v) => v.label === label);
      if (typeof reading === 'undefined') return '-';
      return reading[field].toFixed(2);
    },
    showDataLog(showTrail, days) {
      this.isLoadingShipTrail = true;
      const tsObject = this.asset[Object.keys(this.asset)[0]].data[this.asset[Object.keys(this.asset)[0]].data.length - 1].timestamp * 1000;
      console.log('Load asset id : ', this.asset[Object.keys(this.asset)[0]].data[this.asset[Object.keys(this.asset)[0]].data.length - 1].timestamp, this.$date.utc(new Date(tsObject).setDate(new Date(tsObject).getDate() - (days ? Number(days) : 1))).unix(), days);
      console.log('this.asset[Object.keys(this.asset)[0]]', this.asset[Object.keys(this.asset)[0]]);
      this.$emit('showDataLog', {
        // deviceId: Object.keys(this.asset)[0],
        showTrail,
        device: this.asset[Object.keys(this.asset)[0]],
        dateParam: {
          mode: 'period',
          aggregatedUnit: 'MINUTE',
          aggregatedLength: 5,
          range: {
            // start: this.$date.utc(new Date(tsObject).setDate(new Date(tsObject).getDate() - 7)).unix(), // awalnya 1 minggu
            start: this.$date.utc(new Date(tsObject).setDate(new Date(tsObject).getDate() - (days ? Number(days) : 2))).unix(), // jadi 1 hari saja
            end: this.$date.utc(new Date(tsObject).getTime()).unix(),
          },
        },
        disabledUpdateMap: true,
      });
    },
    showDataSummary(showSummary) {
      this.isLoadingShipSummary = true;
      console.log('Load asset id : ', this.asset[Object.keys(this.asset)[0]].data[this.asset[Object.keys(this.asset)[0]].data.length - 1].timestamp);
      const tsObject = this.asset[Object.keys(this.asset)[0]].data[this.asset[Object.keys(this.asset)[0]].data.length - 1].timestamp * 1000;
      console.log('this.asset[Object.keys(this.asset)[0]]', this.asset[Object.keys(this.asset)[0]]);
      this.$emit('showDataSummary', {
        // deviceId: Object.keys(this.asset)[0],
        showSummary,
        device: this.asset[Object.keys(this.asset)[0]],
        dateParam: {
          mode: 'period',
          aggregatedUnit: 'MINUTE',
          aggregatedLength: 5,
          range: {
            start: this.$date.utc(new Date(tsObject).setDate(new Date(tsObject).getDate() - 7)).unix(), // awalnya 1 minggu
            // start: this.$date.utc(new Date(tsObject).setDate(new Date(tsObject).getDate() - 1)).unix(), // jadi 1 hari saja
            end: this.$date.utc(new Date(tsObject).getTime()).unix(),
          },
        },
      });
    },
    onChangeShipTrail(newVal) {
      this.showDataLog(newVal);
    },
    onChangeShipSummary(newVal) {
      this.showDataSummary(newVal);
    },
    isOnline(device) {
      const offline = device.data && device.devcType === 'gps' && (new Date().getTime() - (device.data[device.data.length - 1].timestamp * 1000)) > 600000;

      return !offline;
    },
    convertDateToStandard(date) {
      return convertDateToStandard(date);
    },
  },
};
</script>

<style scoped>
.bg-popup {
  background: #ECF1FC !important;
}
.nav-tabs .nav-item .nav-link.active {
  background-color: #ECF1FC !important;
}

.border-radius-card {
  border-radius: 14px;
}
</style>
