<template>
  <ul class="navbar-list user-dropdown transition-opacity"
    :class="{ 'opacity-100': isShown, 'opacity-0': !isShown, 'user-dropdown-minimized': isSmall }"
    :style="isShown ? 'display: block' : 'display: none'">
    <li>
      <div class="iq-sub-dropdown iq-user-dropdown">
        <div class="iq-card shadow-none m-0 bg-transparent">
          <div class="iq-card-body p-3 user-dropdown-list-container">
            <h4 class="mb-3 d-flex align-items-center justify-content-between">
              Profile & Company
              <i @click="onCloseButtonClicked" class="ri-close-fill cursor-pointer" />
            </h4>
            <div class="user-list-item-container">
              <b-container @click="onChangeCompanyButtonClicked" class="iq-sub-card bg-white cursor-pointer">
                <div class="media align-items-center">
                  <div class="fs-5 user-list-item-icon">
                    <i class="ri-flag-fill" />
                  </div>
                  <div class="media-body ml-3">
                    <h6 class="mb-0 ">Change Company</h6>
                    <p class="mb-0 font-size-12">Select your company preference</p>
                  </div>
                  <i class="ri-arrow-right-s-line" />
                </div>
              </b-container>
            </div>
            <div class="user-list-item-container">
              <a href="#" class="iq-sub-card bg-white">
                <div class="media align-items-center">
                  <div class="fs-5 user-list-item-icon">
                    <i class="ri-file-user-fill" />
                  </div>
                  <div class="media-body ml-3">
                    <h6 class="mb-0 ">My Profile</h6>
                    <p class="mb-0 font-size-12">View personal profile details</p>
                  </div>
                </div>
              </a>
              <a href="#" class="iq-sub-card bg-white">
                <div class="media align-items-center">
                  <div class="fs-5 user-list-item-icon">
                    <i class="ri-profile-fill" />
                  </div>
                  <div class="media-body ml-3">
                    <h6 class="mb-0 ">Edit Profile</h6>
                    <p class="mb-0 font-size-12">Modify your personal details</p>
                  </div>
                </div>
              </a>
              <a href="#" class="iq-sub-card bg-white">
                <div class="media align-items-center">
                  <div class="fs-5 user-list-item-icon">
                    <i class="ri-account-box-fill" />
                  </div>
                  <div class="media-body ml-3">
                    <h6 class="mb-0 ">Account settings</h6>
                    <p class="mb-0 font-size-12">Manage your account parameters</p>
                  </div>
                </div>
              </a>
              <a href="#" class="iq-sub-card bg-white">
                <div class="media align-items-center">
                  <div class="fs-5 user-list-item-icon">
                    <i class="ri-lock-fill" />
                  </div>
                  <div class="media-body ml-3">
                    <h6 class="mb-0 ">Privacy Settings</h6>
                    <p class="mb-0 font-size-12">Control your privacy parameters</p>
                  </div>
                </div>
              </a>
            </div>
            <button @click="logout" class="w-100 py-12 btn text-center bg-red text-white rounded-14">
              Sign out
            </button>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import rasAvatar from '@/assets/images/user/1.jpeg';
import assAvatar from '@/assets/images/user/7.jpg';
import kanakaAvatar from '@/assets/images/user/kanaka.jpg';
import aasAvatar from '@/assets/images/user/aas.jpeg';
import mdmAvatar from '@/assets/images/user/mdm.jpg';
import brlAvatar from '@/assets/images/user/brl.png';
import citraAvatar from '@/assets/images/user/citra.png';
import plaAvatar from '@/assets/images/user/pla.png';
import meratusAvatar from '@/assets/images/user/meratus.jpeg';

export default {
  name: 'UserDropdown',
  props: {
    isShown: { type: Boolean, default: false },
    isSmall: { type: Boolean, default: false },
  },
  data() {
    return {
      userAvatar: undefined,
    };
  },
  mounted() {
    this.userAvatar = rasAvatar;
    if (this.activeCompany.compId === '7') {
      this.userAvatar = assAvatar;
    } else if (this.activeCompany.compId === '8') {
      this.userAvatar = kanakaAvatar;
    } else if (this.activeCompany.compId === '10') {
      this.userAvatar = aasAvatar;
    } else if (this.activeCompany.compId === '14') {
      this.userAvatar = mdmAvatar;
    } else if (this.activeCompany.compId === '24') {
      this.userAvatar = meratusAvatar;
    } else if (this.activeCompany.compId === '25') {
      this.userAvatar = brlAvatar;
    } else if (this.activeCompany.compId === '15') {
      this.userAvatar = citraAvatar;
    } else if (this.activeCompany.compId === '30') {
      this.userAvatar = plaAvatar;
    }
  },
  computed: {
    activeCompany() {
      return this.$store.getters['auth/activeCompany'];
    },
  },
  methods: {
    onCloseButtonClicked() {
      this.$emit('hideUserDropdown');
    },
    onChangeCompanyButtonClicked() {
      this.$router.push({ name: 'CompanyActiveSelection' });
    },
    logout() {
      this.$store.dispatch('auth/logout');
      this.$router.replace({ name: 'Login' });
    },
  },
};
</script>

<style scoped>
.user-dropdown {
  position: fixed;
  top: 50px;
  left: 275px;
  z-index: 10000;
}
.user-dropdown-minimized {
  position: fixed;
  top: 50px;
  left: 100px !important;
  z-index: 10000;
}

.user-dropdown-list-container {
  background-color: #ECF1FC;
  border-radius: 14px;
}

.user-list-item-container {
  margin-bottom: 14px;
  border-radius: 14px;
  overflow: hidden;
}

.user-list-item-icon {
  color: #002A64;
}
</style>
