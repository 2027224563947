<template>
  <b-container fluid>
    <b-row class="row-eq-height">
      <b-col md="12" class="mt-3">
        <iq-card class="">
          <template v-slot:headerTitle>
            <h4 class="card-title">Data Log</h4>
          </template>
          <template v-slot:headerAction>
            <ul class="nav nav-pills">
              <!-- <li class="nav-item mr-3"> -->
              <!-- <li class="nav-item mr-3">
                <a href="#" class="nav-link" @click.prevent="showSummary">Show Summary</a>
              </li> -->
              <li class="nav-item">
                <b-dropdown  id="dropdownMenuButton4" right variant="none" data-toggle="dropdown">
                  <template v-slot:button-content>
                    <span><i class="ri-more-2-fill"></i></span>
                  </template>
                  <b-dropdown-item href="#"><i class="ri-file-download-fill mr-2"></i>Download</b-dropdown-item>
                </b-dropdown>
              </li>
            </ul>
          </template>
          <template v-slot:body>
            <div class="mb-3" v-if="dateParam">
              <date-picker
                :dateParam="dateParam"
                :isInline="true"
                :isLoading="isLoading"
                :enableInterval="true"
                @onSubmitRange="onSubmitRange"/>
            </div>
            <div v-if="isLoading">
              <b-alert show variant="warning">Loading...</b-alert>
            </div>
            <div v-else>
              <DataLogTable
                v-if="series&&series.length > 0"
                :series="series"
                class="overlay-dataview"/>
              <div v-else>
                <b-alert show variant="danger">No Data</b-alert>
              </div>
            </div>
            <b-modal id="summary-modal">
              <!-- <pre>{{series}}</pre> -->
              <h1>Summary yay...</h1>
            </b-modal>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import requestBuilder from '@/util/request.builder';
import DeviceSvc from '@/services/DeviceSvc';

import DatePicker from '@/components/directives/date-picker';
import dateUtil from '@/util/date';

import SeriesSvc from '@/services/SeriesSvc';
import DataLogTable from './DataLogTable';

export default {
  name: 'AssetType',
  components: {
    DataLogTable,
    DatePicker,
  },
  data() {
    return {
      isLoading: false,
      asset: undefined,
      assetObject: {},
      query: {},
      series: undefined,
      /* Default Date Parameter */
      dateParam: undefined,
    };
  },
  async mounted() {
    console.log('Datalog mounted...');
    await this.getDevices();
    await this.load();
  },
  methods: {
    async load() {
      this.isLoading = true;
      if (this.$route.query && this.$route.query.start) {
        console.log('Data period defined... using query...');
        this.dateParam = dateUtil.queryToDateParam(this.$route.query);
      } else {
        console.log('Data period not defined... using today...');
        this.dateParam = dateUtil.byInterval('today');
      }
      const seriesParameter = dateUtil.msToS(this.dateParam);
      const dataSeries = await SeriesSvc.getSeries(seriesParameter.range.start, seriesParameter.range.end, Object.keys(this.asset.devices)[0]);
      if (dataSeries.data.data) {
        this.series = SeriesSvc.parseSeries(dataSeries.data.data);
      }
      this.isLoading = false;
    },
    async getDevices() {
      this.isLoading = true;
      const massId = this.$route.params.id;
      console.log('Getting gateway device id on asset no : ', massId);
      const params = requestBuilder.buildFilters({
        current: 1,
        pageSize: 50,
      }, {
        devcMassId: massId,
        devcType: 'gateway',
      });
      const resp = await DeviceSvc.getDevices({ params });
      const device = resp.data.data[0];
      console.log('Got devices : ', resp.data.data[0]);
      if (!this.asset) this.asset = { devices: {} };
      this.asset.devices[device.devcUniqueId] = device;
      this.isLoading = false;
    },
    onSubmitRange(date) {
      console.log('Range submit : ', date);
      this.$router.replace({
        name: 'AssetDataLog',
        query: dateUtil.dateParamToQuery(date),
      }).catch((err) => {
        console.log('Duplicate Route, Skipping... ', err);
      });
      this.load();
    },
    showSummary() {
      // console.log('Show summary for : ', this.series);
      this.$bvModal.show('summary-modal');
    },
  },
};
</script>
