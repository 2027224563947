<template>
  <div>
    <h2 class="rp-title" >Daily Report</h2>
    <div class="rp-header-wrapper">
        <div class="rp-header-left">
            <h4>Vessel: {{ massName }}</h4>
            <div class="rp-info-date">Analyzed data: {{start}} to {{end}} GMT +007</div>
        </div>
        <img src="@/assets/images/ramus_logo.png" alt="ramus logo">
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportHeader',
  props: ['massName', 'start', 'end'],
};
</script>

<style scoped lang="scss">
.rp-title {
  font-size: 22px;
  color: black;
  font-weight: bold;
  text-align: center;
  padding-top: 30px;
}
.rp-header-wrapper {
  border-bottom: 3px solid #012b65;
  padding-bottom: 5px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h4 {
    color: black;
    margin: 0;
    font-weight: bold;
    font-size: 18px;
  }
  img {
    width: 200px;
  }
}
.rp-info-date {
  font-size: 14px;
  color: black;
}
</style>
