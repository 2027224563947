<template>
  <!-- <section class="sign-in-page bg-white">
    <b-container fluid class="h-100">
    </b-container>
  </section> -->
  <router-view></router-view>
</template>

<script>

// import { sofbox } from '@/config/pluginInit';
export default {
  name: 'ExportDailyReportLayout',
};
</script>
