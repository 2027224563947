<template>
    <div style="height: 100%;" ref="parentref">
      <div v-if="isLoading" class="dt-loading">
        <b-spinner variant="default" size="sm" class="align-middle"></b-spinner>
        <p>Loading data...</p>
      </div>
      <div v-if="!isLoading" ref="childref">
        <RGraph
          :customHeight="height - 60"
          ref="stackedGraph"
          @onZoomed="chartIndexUpdated"
          @onScrolled="chartIndexUpdated"
          :data="dataObject"/>
      </div>
    </div>
  </template>

<script>
import RGraph from '@/components/directives/report-graph';

export default {
  name: 'fu-chart',
  props: ['dataObject', 'isLoading', 'height'],
  components: {
    RGraph,
  },
  mounted() {
    console.log('ref from fu', this.$refs.parentref.childref.stackedGraph);
    this.refChange();
  },
  methods: {
    chartIndexUpdated(v) {
      this.$emit('chartIndexUpdated', v);
    },
    refChange() {
      this.$emit('refChange', this.$refs.stackedGraph);
    },
  },
};
</script>
  <style lang="scss">
  .dt-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 9999;
    background: #DEE2E6;
    height: 100%;
    p {
      margin-top: 15px;
    }
  }
  </style>
