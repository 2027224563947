<template>
  <div class="mapTab">
    <!-- <pre>{{asset}}</pre> -->
    <b-tabs content-class="mt-3">
      <b-tab v-for="(summary, deviceType) in asset.summaries" :key="deviceType" :title="deviceType" active>
        <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-danger" v-if="asset.fromNow > 600">
          <div class="iq-alert-icon">
            <i class="ri-information-line"></i>
          </div>
          <div class="iq-alert-text">Device <b>OFFLINE</b>!</div>
        </div>
        <div v-else>
          <div>Active {{ asset.fromNow | toHHMMSS }} ago</div>
        </div>
        <h4>{{ asset.massName }}</h4>
        <div class="table-responsive mapTab mt-1" v-if="deviceType === 'flowmeter'">
          <table class="table table-bordered table-sm">
            <tbody>
              <tr>
                <th>Port Engine Consumption</th>
                <th>Starboard Engine Consumption</th>
              </tr>
              <tr>
                <td v-if="summary.portEngineCons">{{summary.portEngineCons.toFixed(2)}}</td>
                <td v-if="summary.stbEngineCons">{{summary.stbEngineCons.toFixed(2)}}</td>
              </tr>
            </tbody>
          </table>
          <div class="mt-1">
           <small>From {{summary.start | toLocalDate}} to {{asset.latestDataTime | toLocalDate}}</small>
          </div>
        </div>
        <div class="table-responsive mapTab mt-1" v-if="deviceType === 'gps'">
          <table class="table table-bordered table-sm">
            <tbody>
              <tr>
                <th>Average Speed</th>
                <td>{{summary.averageSpeed.toFixed(2)}}</td>
              </tr>
              <tr>
                <th>Point Distance</th>
                <td>{{summary.distance | toKM}} KM</td>
              </tr>
              <tr>
                <th>Travel Distance</th>
                <td>{{summary.totalDistance | toKM}} KM</td>
              </tr>
            </tbody>
          </table>
          <div class="mt-1">
           <small>From {{summary.start | toLocalDate}} to {{asset.latestDataTime | toLocalDate}}</small>
          </div>
        </div>
        <div class="table-responsive mapTab mt-1" v-if="deviceType === 'ae'">
          <table class="table table-bordered table-sm">
            <tbody>
              <tr>
                <th></th>
                <th>Time</th>
                <th>Cons</th>
              </tr>
              <tr v-for="(runningTime, aeNo) in summary.runningTime" :key="aeNo">
                <th>{{aeNo}}</th>
                <td>{{runningTime | toHHMMSS}}</td>
                <td>{{summary.fuelConsumption[aeNo].toFixed(2)}}</td>
              </tr>
            </tbody>
          </table>
          <div class="mt-1">
           <small>From {{summary.start | toLocalDate}} to {{asset.latestDataTime | toLocalDate}}</small>
          </div>
        </div>
        <div class="table-responsive mapTab mt-1" v-if="deviceType === 'rpm'">
          <table class="table table-bordered table-sm">
            <tbody>
              <tr>
                <th></th>
                <th>Time</th>
                <th>Cons</th>
              </tr>
              <tr v-for="(rpm, location) in summary.totalRPM" :key="location">
                <th>{{location}}</th>
                <td>{{summary.runningTime[location] | toHHMMSS}}</td>
                <td>{{rpm.toFixed(2)}}</td>
              </tr>
            </tbody>
          </table>
          <div class="mt-1">
           <small>From {{summary.start | toLocalDate}} to {{asset.latestDataTime | toLocalDate}}</small>
          </div>
        </div>
        <!-- <div class="table-responsive mapTab mt-1" v-if="device.data && device.devcType === 'flowmeter'">
          <div class="mt-1">
           <small>Record Date {{device.data[1][device.data[0].length - 1].timestamp | toLocalDate}}</small>
          </div>
        </div>
        <div class="table-responsive mapTab mt-1" v-if="device.data && device.devcType === 'rpm'">
          <table class="table table-bordered table-sm">
            <tbody>
              <tr>
                <th>Port</th>
                <th>Starboard</th>
              </tr>
              <tr v-if="device.data[0]">
                <td>{{device.data[0][device.data[0].length - 1].rpm}}</td>
                <td>{{device.data[1][device.data[0].length - 1].rpm}}</td>
              </tr>
            </tbody>
          </table>
          <div class="mt-1">
           <small>Record Date {{device.data[1][device.data[0].length - 1].timestamp | toLocalDate}}</small>
          </div>
        </div>
        <div class="table-responsive mapTab mt-1" v-if="device.data && device.devcType === 'gps'">
          <table class="table table-bordered table-sm">
            <tbody>
              <tr v-if="device.data[device.data.length-1].latitude">
                <td>Latitude</td>
                <td>{{device.data[device.data.length-1].latitude | latToDMS}}</td>
              </tr>
              <tr v-if="device.data[device.data.length-1].longitude">
                <td>Longitude</td>
                <td>{{device.data[device.data.length-1].longitude | longToDMS}}</td>
              </tr>
              <tr v-if="device.data[device.data.length-1].altitude">
                <td>Altitude</td>
                <td>{{device.data[device.data.length-1].altitude}}</td>
              </tr>
              <tr v-if="device.data[device.data.length-1].track">
                <td>Heading</td>
                <td>{{device.data[device.data.length-1].track}} °</td>
              </tr>
              <tr v-if="device.data[device.data.length-1].speed !== undefined">
                <td>Speed</td>
                <td>{{device.data[device.data.length-1].speed}}</td>
              </tr>
            </tbody>
          </table>
          <div class="mt-1">
           <small>Record Date {{device.data[device.data.length-1].timestamp | toLocalDate}}</small>
          </div>
        </div> -->
        <ul class="list-unstyled mt-3">
          <li>
            <!-- <button type="button" @click.prevent="showDataLog" class="btn mr-2 btn-outline-primary rounded-pill btn-sm">Trail</button> -->
            <button type="button" @click.prevent="showReport" class="btn mr-2 btn-outline-danger rounded-pill btn-sm">View Report</button>
            <!-- <button type="button" @click.prevent="showDashboard" class="btn mr-1 btn-outline-success rounded-pill btn-sm">Dashboard</button> -->
          </li>
        </ul>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>

export default {
  name: 'SummaryPopUp',
  props: ['asset'],
  computed: {
  },
  methods: {
    // showDashboard() {
    //   console.log('Show Dashboard for this device : ', this.asset);
    //   this.$router.push({
    //     name: 'Dashboard',
    //     // query: {
    //     //   start: this.$date.utc(new Date(device.data[device.data.length - 1].datetime).setDate(-1)).unix(),
    //     //   end: this.$date.utc(device.data[device.data.length - 1].datetime).unix(),
    //     // },
    //   });
    // },
    showReport() {
      this.$router.push({
        name: 'ReportView',
        params: {
          id: this.asset.massId,
          type: 'summary',
        },
      }).catch((err) => {
        console.log('Duplicate Route, Skipping... ', err);
      });
      // this.$router.push({
      //   name: 'ReportView',
      //   params: { id: this.asset.massId },
      //   // query: dateUtil.dateParamToQuery(date),
      // });
    },
    showAnalytic() {
      console.log('Show Analytics for this device : ', this.asset);
      this.$router.push({ name: 'AssetAnalytics', params: { id: this.asset.massId } });
      // this.$router.push({
      //   name: 'Dashboard',
      //   // query: {
      //   //   start: this.$date.utc(new Date(device.data[device.data.length - 1].datetime).setDate(-1)).unix(),
      //   //   end: this.$date.utc(device.data[device.data.length - 1].datetime).unix(),
      //   // },
      // });
    },
    getReadingValue(readings, label, field) {
      const reading = readings.find((v) => v.label === label);
      if (typeof reading === 'undefined') return '-';
      return reading[field].toFixed(2);
    },
    showDataLog() {
      console.log('Show Trail for this device : ', this.asset);
      // console.log('Load asset id : ', this.asset[Object.keys(this.asset)[0]].data[this.asset[Object.keys(this.asset)[0]].data.length - 1].timestamp);
      // const tsObject = this.asset[Object.keys(this.asset)[0]].data[this.asset[Object.keys(this.asset)[0]].data.length - 1].timestamp * 1000;
      this.$emit('showDataLog', {
        // deviceId: Object.keys(this.asset)[0],
        device: this.asset,
        dateParam: {
          mode: 'period',
          aggregatedUnit: 'MINUTE',
          aggregatedLength: 5,
          range: {
            start: this.asset.start,
            end: this.asset.end,
          },
        },
      });
    },
  },
};
</script>

<style scoped>

</style>
