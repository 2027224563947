<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <div class="row mb-3" v-if="rpmSummary.show">
      <div class="col-md-3">
        <h6 class="card-title text-uppercase text-secondary mb-0">Port Average RPM</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <span>{{(rpmSummary.averagePortRpm).toFixed(2)}}</span>
        </span>
      </div>
      <div class="col-md-3">
        <h6 class="card-title text-uppercase text-secondary mb-0">Port Running Hour</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <span>{{ rpmSummary.portRunningTime | toHHMMSS }}</span>
        </span>
      </div>
      <div class="col-md-3">
        <h6 class="card-title text-uppercase text-secondary mb-0">Stb Average RPM</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <span>{{(rpmSummary.averageStbRpm).toFixed(2)}}</span>
        </span>
      </div>
      <div class="col-md-3">
        <h6 class="card-title text-uppercase text-secondary mb-0">Stb Running Hour</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <span>{{rpmSummary.stbRunningTime | toHHMMSS }}</span>
        </span>
      </div>
    </div>
    <div class="row mb-3" v-if="gpsSummary.show">
      <div class="col-md-4">
        <h6 class="card-title text-uppercase text-secondary mb-0">Total Cruise</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <span>{{gpsSummary.totalCruise | toKM}} KM</span>
        </span>
      </div>
      <div class="col-md-4">
        <h6 class="card-title text-uppercase text-secondary mb-0">Average Speed</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <span>{{gpsSummary.averageSpeed | toKnot}} Knot</span>
        </span>
      </div>
      <div class="col-md-4">
        <h6 class="card-title text-uppercase text-secondary mb-0">Distance</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <span>{{gpsSummary.distance | toKM}} KM</span>
        </span>
      </div>
    </div>
    <div class="row mb-3" v-if="flowmeterSummary.show">
      <div class="col-md-4">
        <h6 class="card-title text-uppercase text-secondary mb-0">Average Port Flow</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <span>{{(flowmeterSummary.averagePortFlow).toFixed(2)}} L/h</span>
        </span>
      </div>
      <div class="col-md-4">
        <h6 class="card-title text-uppercase text-secondary mb-0">Average Stb Flow</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <span>{{(flowmeterSummary.averageStbFlow).toFixed(2)}} L/h</span>
        </span>
      </div>
      <div class="col-md-4">
        <h6 class="card-title text-uppercase text-secondary mb-0">Main Engine Cons</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <span>{{(flowmeterSummary.totalMainEngineCons).toFixed(2)}} Liters</span>
        </span>
      </div>
    </div>
    <div class="row mb-3" v-if="aeSummary.show">
      <div class="col-md-4">
        <h6 class="card-title text-uppercase text-secondary mb-0">AE1 Running Time</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <span>{{aeSummary.runningTimes[0] | toHHMMSS}}</span>
        </span>
      </div>
      <div class="col-md-4">
        <h6 class="card-title text-uppercase text-secondary mb-0">AE2 Running Time</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <span>{{aeSummary.runningTimes[1] | toHHMMSS}}</span>
        </span>
      </div>
      <div class="col-md-4">
        <h6 class="card-title text-uppercase text-secondary mb-0">AE3 Running Time</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <span>{{aeSummary.runningTimes[2] | toHHMMSS}}</span>
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <!-- <pre>{{series}}</pre> -->
        <b-table striped hover fixed small :items="series" :fields="fields" responsive="true" class="bg-white text-center table-logs">
          <!-- <template #cell(deviceData.gps)="data">
            {{ data.item }}
          </template> -->
          <!-- <template #thead-top="data">
            <b-tr class="text-center">
              <b-th colspan="1"></b-th>
              <b-th colspan="3">GPS</b-th>
              <b-th colspan="2">RPM</b-th>
              <b-th colspan="3">AE</b-th>
              <b-th colspan="3">Port ME Flowmeter</b-th>
              <b-th colspan="3">Starboard ME Flowmeter</b-th>
              <b-th colspan="2">IOT</b-th>
            </b-tr>
            <b-tr class="text-center">
              <b-th colspan="2">Location</b-th>
              <b-th colspan="2">Movement</b-th>
              <b-th colspan="3">ME Port</b-th>
              <b-th colspan="3">ME Stbd</b-th>
              <b-th colspan="2">ME Port</b-th>
              <b-th colspan="2">ME Stbd</b-th>
              <b-th colspan="2">AE1</b-th>
              <b-th colspan="2">AE2</b-th>
              <b-th colspan="2">AE3</b-th>
              <b-th colspan="2">AE4</b-th>
            </b-tr>
          </template> -->
          <template #cell(datetime)="data">
            {{ data.item.timestamp | toLocalDate }}
          </template>
          <template #cell(coordinate)="data">
            <span v-if="data.item.latitude && data.item.longitude">{{ data.item.latitude | latToDMS }}, {{ data.item.longitude | latToDMS }}</span>
          </template>
          <template #cell(track)="data">
            {{ data.item.track.toFixed() }}
          </template>
          <template #cell(speed)="data">
            <span>{{ data.item.speed | toKnot }}</span>
          </template>
          <template #cell(distance)="data">
            <span>{{ data.item.distance | toKM }}</span>
          </template>
          <template #cell(portRPM)="data">
            <span>{{ data.item.PORT.rpm.toFixed(2) }}</span>
          </template>
          <template #cell(portRunningTime)="data">
            <span>{{ data.item.PORT.runningTime | toHHMMSS}}</span>
          </template>
          <template #cell(stbRPM)="data">
            <span>{{ data.item.STARBOARD.rpm.toFixed(2) }}</span>
          </template>
          <template #cell(stbRunningTime)="data">
            <span>{{ data.item.STARBOARD.runningTime | toHHMMSS}}</span>
          </template>
          <template #cell(AE0)="data">
            <span v-if="data.item[0]">{{ data.item[0].runningTime | toHHMMSS }}</span>
          </template>
          <template #cell(AE1)="data">
            <span v-if="data.item[1]">{{ data.item[1].runningTime | toHHMMSS }}</span>
          </template>
          <template #cell(AE2)="data">
            <span v-if="data.item[2]">{{ data.item[2].runningTime | toHHMMSS }}</span>
          </template>
          <template #cell(AE3)="data">
            <span v-if="data.item[3]">{{ data.item[3].runningTime | toHHMMSS }}</span>
          </template>
          <template #cell(portFuelCons)="data">
            <span>{{ data.item.portFuelCons.toFixed(2) }}</span>
          </template>
          <template #cell(stbFuelCons)="data">
            <span>{{ data.item.stbFuelCons.toFixed(2) }}</span>
          </template>
          <template #cell(portInFlow)="data">
            <span>{{ data.item.portInFlow.toFixed(2) }}</span>
          </template>
          <template #cell(portInDens)="data">
            <span>{{ data.item.portInDens.toFixed(2) }}</span>
          </template>
          <template #cell(portOutFlow)="data">
            <span>{{ data.item.portOutFlow.toFixed(2) }}</span>
          </template>
          <template #cell(portOutDens)="data">
            <span>{{ data.item.portOutDens.toFixed(2) }}</span>
          </template>
          <template #cell(stbInFlow)="data">
            <span>{{ data.item.stbInFlow.toFixed(2) }}</span>
          </template>
          <template #cell(stbInDens)="data">
            <span>{{ data.item.stbInDens.toFixed(2) }}</span>
          </template>
          <template #cell(stbOutFlow)="data">
            <span>{{ data.item.stbOutFlow.toFixed(2) }}</span>
          </template>
          <template #cell(stbOutDens)="data">
            <span>{{ data.item.stbOutDens.toFixed(2) }}</span>
          </template>
          <template #cell(meFuelCons)="data">
            <span>{{ data.item.meFuelCons.toFixed(2) }}</span>
          </template>
          <!--
          <template #cell(track)="data">
            <span v-if="data.item.deviceData.gps">{{ data.item.deviceData.gps[0].data.track }}</span>
          </template>
          <template #cell(battery)="data">
            {{ data.item.deviceData.power[0].data.batteryVoltage}} V
          </template>
          <template #cell(cpu)="data">
            {{ data.item.deviceData.gateway[0].data.cpuUsage}}
          </template>
          <template #cell(portRpm)="data">
            <span v-if="data.item.deviceData.rpm[0].data.port">{{ data.item.deviceData.rpm[0].data.port[0] }}</span>
            <span v-else>-</span>
          </template>
          <template #cell(starboardRpm)="data">
            <span v-if="data.item.deviceData.rpm[0].data.starboard">{{ data.item.deviceData.rpm[0].data.starboard[0]}}</span>
            <span v-else>-</span>
          </template>
          <template #cell(ae1)="data">
            {{ data.item.deviceData.ae[0].data[0]}}
          </template>
          <template #cell(ae2)="data">
            {{ data.item.deviceData.ae[0].data[1]}}
          </template>
          <template #cell(ae3)="data">
            {{ data.item.deviceData.ae[0].data[2]}}
          </template>
          <template #cell(fm1-massFlowRate)="data">
            <span v-if="data.item.deviceData.flowMeter['fm-3']">
              {{ (data.item.deviceData.flowMeter['fm-3'][0].data.volumeFlowRate / 60).toFixed(2) }}
            </span>
          </template>
          <template #cell(fm2-massFlowRate)="data">
            <span v-if="data.item.deviceData.flowMeter['fm-4']">
              {{ (data.item.deviceData.flowMeter['fm-4'][0].data.volumeFlowRate / 60).toFixed(2) }}
            </span>
          </template>
          <template #cell(in-massCons)="data">
            <span v-if="data.item.deviceData.flowMeter['fm-3']&&data.item.deviceData.flowMeter['fm-4']">
              {{ ((data.item.deviceData.flowMeter['fm-3'][0].data.volumeFlowRate - data.item.deviceData.flowMeter['fm-4'][0].data.volumeFlowRate) / 60).toFixed(2) }}
            </span>
          </template>
          <template #cell(fm3-massFlowRate)="data">
            <span v-if="data.item.deviceData.flowMeter['fm-1']">
              {{ (data.item.deviceData.flowMeter['fm-1'][0].data.volumeFlowRate / 60).toFixed(2) }}
            </span>
          </template>
          <template #cell(fm4-massFlowRate)="data">
            <span v-if="data.item.deviceData.flowMeter['fm-2']">
              {{ (data.item.deviceData.flowMeter['fm-2'][0].data.volumeFlowRate / 60).toFixed(2) }}
            </span>
          </template>
          <template #cell(out-massCons)="data">
            <span v-if="data.item.deviceData.flowMeter['fm-1']&&data.item.deviceData.flowMeter['fm-2']">
              {{ ((data.item.deviceData.flowMeter['fm-1'][0].data.volumeFlowRate - data.item.deviceData.flowMeter['fm-2'][0].data.volumeFlowRate) / 60).toFixed(2) }}
            </span>
          </template> -->
        </b-table>
        <!-- <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          pills
          size="sm"
        ></b-pagination> -->
      </div>
    </div>
  </div>
</template>

<script>
/*
  Analytics Data

  - Date & Time | timestamp

  - Track | track
  - Coordinate | latitude, longitude
  - Speed | speed
    Min
    Max
    Average
  - Distance | last coordinate with current coordinate

  - Port
    Fuel Consumption | portFuelCons = PORT_IN.volume - PORT_OUT.volume
    Inlet Flow Rate (L/h) | portInFlow = PORT_IN.volumeFlowrate
      Average
    Inlet Density | portInDens = PORT_IN.density
      Average
    Outlet Flow Rate (L/h) | portOutFlow = PORT_OUT.volumeFlowrate
      Average
    Outlet Density | portOutDens = PORT_OUT.density
      Agerage

    RPM
      Min
      Max
      Average
    Running Hour

  - Starboard
    Fuel Consumption | stbFuelCons = STARBOARD_IN.volume - STARBOARD_OUT.volume
    Inlet Flow Rate (L/h) | stbInFlow = STARBOARD_IN.volumeFlowrate
      Average
    Inlet Density | stbInDens = STARBOARD_IN.density
      Average
    Outlet Flow Rate (L/h) | stbOutFlow = STARBOARD_OUT.volumeFlowrate
      Average
    Outlet Density | stbOutDens = STARBOARD_OUT.density
      Agerage

    RPM
      Min
      Max
      Average
    Running Hour

  - Auxialary Engine
    [each]
      Running Hour
      Fuel Consumption
  - Total Fuel Consumption
*/

// import SeriesSvc from '@/services/SeriesSvc';
import LatLon from 'geodesy/latlon-spherical.js';

export default {
  name: 'AnalyticTable',
  props: ['data', 'isLoading'],
  data() {
    return {
      ready: false,
      currentPage: 1,
      perPage: 5,
      totalRows: 10,
      filter: {},
      selected: {},
      series: [],
      dataIndex: {},
      fields: [],
      gpsSummary: {
        show: false,
        totalCruise: 0,
        averageSpeed: 0,
        distance: 0,
      },
      flowmeterSummary: {
        show: false,
        averagePortFlow: 0,
        averageStbFlow: 0,
        totalMainEngineCons: 0,
      },
      rpmSummary: {
        show: false,
        averagePortRpm: 0,
        portRunningTime: 0,
        averageStbRpm: 0,
        stbRunningTime: 0,
      },
      aeSummary: {
        show: false,
        runningTimes: [0, 0, 0],
      },
    };
  },
  methods: {
    parseData() {
      const data = this.data;
      if (data) {
        console.log('Data Series... ', Object.keys(data.devices));
        this.fields.push(
          {
            key: 'datetime',
            label: 'Date & Time',
          },
        );
        Object.keys(data.devices).forEach((device) => {
          switch (data.devices[device].devcType) {
            case 'gps':
              this.fields.push(
                {
                  key: 'coordinate',
                  label: 'Coordinate',
                },
                {
                  key: 'track',
                  label: 'Heading',
                },
                {
                  key: 'speed',
                  label: 'Speed',
                },
                {
                  key: 'distance',
                  label: 'Distance',
                },
              );
              // this.series = data.devices[device].data;
              this.gpsSummary.show = true;
              data.devices[device].data.forEach((serie, index) => {
                if (index !== 0) {
                  serie.distance = new LatLon(
                    data.devices[device].data[index - 1].latitude,
                    data.devices[device].data[index - 1].longitude,
                  ).distanceTo(new LatLon(
                    serie.latitude,
                    serie.longitude,
                  ));
                  // console.log(serie);
                } else {
                  serie.distance = 0;
                }
                this.gpsSummary.totalCruise += serie.distance;
                this.gpsSummary.averageSpeed += serie.speed;
                if (!this.dataIndex[serie.timestamp]) this.dataIndex[serie.timestamp] = {};
                this.dataIndex[serie.timestamp] = Object.assign(this.dataIndex[serie.timestamp], serie);
              });
              this.gpsSummary.averageSpeed = this.gpsSummary.averageSpeed / data.devices[device].data.length;
              this.gpsSummary.distance = new LatLon(
                data.devices[device].data[data.devices[device].data.length - 1].latitude,
                data.devices[device].data[data.devices[device].data.length - 1].longitude,
              ).distanceTo(new LatLon(
                data.devices[device].data[0].latitude,
                data.devices[device].data[0].longitude,
              ));
              break;
            case 'rpm':
              this.rpmSummary.show = true;
              this.fields.push(
                {
                  key: 'portRPM',
                  label: 'Port RPM',
                },
                {
                  key: 'portRunningTime',
                  label: 'Port Run Time',
                },
                {
                  key: 'stbRPM',
                  label: 'Stb RPM',
                },
                {
                  key: 'stbRunningTime',
                  label: 'Stb Run Time',
                },
              );
              data.devices[device].data.forEach((serie) => {
                if (!this.dataIndex[serie.timestamp]) this.dataIndex[serie.timestamp] = { timestamp: serie.timestamp, datetime: serie.datetime };
                if (!this.dataIndex[serie.timestamp][serie.type]) this.dataIndex[serie.timestamp][serie.type] = {};
                this.dataIndex[serie.timestamp][serie.type] = serie;
                this.dataIndex[serie.timestamp][serie.type].runningTime = 0;
                if (this.dataIndex[serie.timestamp][serie.type].rpm !== 0) {
                  this.dataIndex[serie.timestamp][serie.type].runningTime += data.devices[device].interval;
                }
                if (serie.type === 'PORT') {
                  this.rpmSummary.portRunningTime += this.dataIndex[serie.timestamp][serie.type].runningTime;
                  this.rpmSummary.averagePortRpm += this.dataIndex[serie.timestamp][serie.type].rpm;
                }
                if (serie.type === 'STARBOARD') {
                  this.rpmSummary.stbRunningTime += this.dataIndex[serie.timestamp][serie.type].runningTime;
                  this.rpmSummary.averageStbRpm += this.dataIndex[serie.timestamp][serie.type].rpm;
                }
              });
              this.rpmSummary.averagePortRpm = this.rpmSummary.averagePortRpm / Object.keys(this.dataIndex).length;
              this.rpmSummary.averageStbRpm = this.rpmSummary.averageStbRpm / Object.keys(this.dataIndex).length;
              break;
            case 'ae':
              this.aeSummary.show = true;
              data.devices[device].data.forEach((serie) => {
                // console.log(serie.rpm * (data.devices[device].interval / 60));
                if (!this.dataIndex[serie.timestamp]) this.dataIndex[serie.timestamp] = { datetime: serie.datetime, timestamp: serie.timestamp };
                if (!this.dataIndex[serie.timestamp][serie.no]) {
                  this.dataIndex[serie.timestamp][serie.no] = {};
                  this.fields.push(
                    {
                      key: `AE${serie.no}`,
                      label: `AE${serie.no + 1} Run Time`,
                    },
                  );
                }
                this.dataIndex[serie.timestamp][serie.no] = serie;
                this.dataIndex[serie.timestamp][serie.no].runningTime = serie.rpm * (data.devices[device].interval / 60);
                this.aeSummary.runningTimes[serie.no] += this.dataIndex[serie.timestamp][serie.no].runningTime;
              });
              break;
            case 'flowmeter':
              this.flowmeterSummary.show = true;
              this.fields.push(
                {
                  key: 'portFuelCons',
                  label: 'Port Fuel Cons',
                },
                {
                  key: 'portInFlow',
                  label: 'Port In Flow',
                },
                {
                  key: 'portOutFlow',
                  label: 'Port Out Flow',
                },
                {
                  key: 'portInDens',
                  label: 'Port In Density',
                },
                {
                  key: 'portOutDens',
                  label: 'Port Out Density',
                },
                {
                  key: 'stbFuelCons',
                  label: 'Stb Fuel Cons',
                },
                {
                  key: 'stbInFlow',
                  label: 'Stb In Flow',
                },
                {
                  key: 'stbOutFlow',
                  label: 'Stb Out Flow',
                },
                {
                  key: 'stbInDens',
                  label: 'Stb In Density',
                },
                {
                  key: 'stbOutDens',
                  label: 'Stb Out Density',
                },
                {
                  key: 'meFuelCons',
                  label: 'Main Engine Cons',
                },
              );
              data.devices[device].data.forEach((serie, index) => {
                if (index !== 0) {
                  serie.volume = serie.volumeInventory - data.devices[device].data[index - 1].volumeInventory;
                } else {
                  serie.volume = 0;
                }
                if (!this.dataIndex[serie.timestamp]) this.dataIndex[serie.timestamp] = { datetime: serie.datetime, timestamp: serie.timestamp };
                if (!this.dataIndex[serie.timestamp][data.devices[device].devcLabel]) this.dataIndex[serie.timestamp][data.devices[device].devcLabel] = {};
                this.dataIndex[serie.timestamp][data.devices[device].devcLabel] = serie;
                if (this.dataIndex[serie.timestamp].PORT_IN && this.dataIndex[serie.timestamp].PORT_OUT && this.dataIndex[serie.timestamp].STARBOARD_IN && this.dataIndex[serie.timestamp].STARBOARD_OUT) {
                  this.dataIndex[serie.timestamp].portFuelCons = this.dataIndex[serie.timestamp].PORT_IN.volume - this.dataIndex[serie.timestamp].PORT_OUT.volume;
                  this.dataIndex[serie.timestamp].portInFlow = this.dataIndex[serie.timestamp].PORT_IN.volumeFlowrate;
                  this.dataIndex[serie.timestamp].portInDens = this.dataIndex[serie.timestamp].PORT_IN.density;
                  this.dataIndex[serie.timestamp].portOutFlow = this.dataIndex[serie.timestamp].PORT_OUT.volumeFlowrate;
                  this.dataIndex[serie.timestamp].portOutDens = this.dataIndex[serie.timestamp].PORT_OUT.density;
                  this.dataIndex[serie.timestamp].stbFuelCons = this.dataIndex[serie.timestamp].STARBOARD_IN.volume - this.dataIndex[serie.timestamp].STARBOARD_OUT.volume;
                  this.dataIndex[serie.timestamp].stbInFlow = this.dataIndex[serie.timestamp].STARBOARD_IN.volumeFlowrate;
                  this.dataIndex[serie.timestamp].stbInDens = this.dataIndex[serie.timestamp].STARBOARD_IN.density;
                  this.dataIndex[serie.timestamp].stbOutFlow = this.dataIndex[serie.timestamp].STARBOARD_OUT.volumeFlowrate;
                  this.dataIndex[serie.timestamp].stbOutDens = this.dataIndex[serie.timestamp].STARBOARD_OUT.density;
                  this.dataIndex[serie.timestamp].meFuelCons = this.dataIndex[serie.timestamp].portFuelCons + this.dataIndex[serie.timestamp].stbFuelCons;
                  this.flowmeterSummary.totalMainEngineCons += this.dataIndex[serie.timestamp].meFuelCons;
                  this.flowmeterSummary.averagePortFlow += this.dataIndex[serie.timestamp].PORT_IN.volumeFlowrate - this.dataIndex[serie.timestamp].PORT_OUT.volumeFlowrate;
                  this.flowmeterSummary.averageStbFlow += this.dataIndex[serie.timestamp].STARBOARD_IN.volumeFlowrate - this.dataIndex[serie.timestamp].STARBOARD_OUT.volumeFlowrate;
                  this.flowmeterSummary.averagePortFlow += this.dataIndex[serie.timestamp].PORT_IN.volumeFlowrate - this.dataIndex[serie.timestamp].PORT_OUT.volumeFlowrate;
                  /*
                    - Port
                      Fuel Consumption | portFuelCons = PORT_IN.volume - PORT_OUT.volume
                      Inlet Flow Rate (L/h) | portInFlow = PORT_IN.volumeFlowrate
                        Average
                      Inlet Density | portInDens = PORT_IN.density
                        Average
                      Outlet Flow Rate (L/h) | portOutFlow = PORT_OUT.volumeFlowrate
                        Average
                      Outlet Density | portOutDens = PORT_OUT.density
                        Agerage

                    - Starboard
                      Fuel Consumption | stbFuelCons = STARBOARD_IN.volume - STARBOARD_OUT.volume
                      Inlet Flow Rate (L/h) | stbInFlow = STARBOARD_IN.volumeFlowrate
                        Average
                      Inlet Density | stbInDens = STARBOARD_IN.density
                        Average
                      Outlet Flow Rate (L/h) | stbOutFlow = STARBOARD_OUT.volumeFlowrate
                        Average
                      Outlet Density | stbOutDens = STARBOARD_OUT.density
                        Agerage
                  */
                  // console.log(Object.keys(this.dataIndex[serie.timestamp]));
                }
              });
              this.flowmeterSummary.averageStbFlow = this.flowmeterSummary.averageStbFlow / Object.keys(this.dataIndex).length;
              this.flowmeterSummary.averagePortFlow = this.flowmeterSummary.averagePortFlow / Object.keys(this.dataIndex).length;
              // if (this.flowmeterSummary.averageStbFlow === 0) {
              // console.log(this.flowmeterSummary.averageStbFlow, Object.keys(this.dataIndex).length);
              // }
              // this.flowmeterSummary.averageStbFlow = this.flowmeterSummary.averageStbFlow / this.dataIndex.length;
              break;
          }
          // console.log(data.devices[device]);
        });
        Object.keys(this.dataIndex).forEach((timestamp) => {
          this.series.push(this.dataIndex[timestamp]);
          // console.log(this.dataIndex[timestamp]);
        });
        // console.log(this.dataIndex);
        // this.series = this.data.devices['gps-1'].data;
      }
    },
  },
  mounted() {
    // console.log('ABC DEF');
    this.parseData();
    // await this.getSeries();
    // const response = await SeriesSvc.getSeriesByInterval('day', 30, [deviceId]);
  },
};
</script>

<style scoped lang="scss">
.table-logs {
  display: block;
  font-size: 11px;
  overflow-x: auto;
  white-space: nowrap;
}
</style>
