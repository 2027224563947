<template>
  <div class="iq-card mb-0" style="min-width: 300px;">
    <div class="iq-card-header d-flex justify-content-between">
      <div class="iq-header-title">
        <h4 class="card-title" v-if="data.compCreatedTime">Edit Company</h4>
        <h4 class="card-title" v-if="!data.compCreatedTime">Add New Company</h4>
      </div>
    </div>
    <div class="iq-card-body">
      <div class="row">
        <div role="group" class="form-group col-md-6">
          <label for="companyName" class="d-block form-label text-left">
            Name:
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <input
                autocomplete="off"
                id="companyName"
                placeholder="Enter company name"
                type="text"
                v-model="data.compName"
                :disabled="isSubmit"
                :class="errors.compName ? 'is-invalid' : ''"
                class="form-control">
              <b-form-invalid-feedback v-if="errors.compName">
                {{ Object.values(errors.compName) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <div role="group" class="form-group col-md-6">
          <label for="grcpName" class="d-block form-label text-left">
            Group:
          </label>
          <v-select
            id="grcpName"
            label="grcpName"
            placeholder="Select Group"
            v-model="data.compGrcpId"
            :class="errors.compGrcpId ? 'is-invalid' : ''"
            :disabled="isSubmit"
            :options="groups"
            :reduce="group => group.grcpId"
            @search="filterGroups">
            <template slot="no-options">
              Type to search group
            </template>
          </v-select>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <b-form-invalid-feedback v-if="errors.compGrcpId">
                {{ Object.values(errors.compGrcpId) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div role="group" class="form-group col-md-12">
          <label for="companyAddress" class="d-block form-label text-left">
            Address:
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
                  <textarea
                    autocomplete="off"
                    class="form-control"
                    id="companyAddress"
                    placeholder="Enter company address"
                    type="text"
                    v-model="data.compAddress"
                    :class="errors.compAddress ? 'is-invalid' : ''"
                    :disabled="isSubmit"></textarea>
              <b-form-invalid-feedback v-if="errors.compAddress">
                {{ Object.values(errors.compAddress) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div role="group" class="form-group col-md-6">
          <label class="d-block form-label text-left">
            Status:
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <select class="form-control"
                      v-model="data.compStatus"
                      :disabled="isSubmit"
                      :class="errors.compStatus ? 'is-invalid' : ''">
                <option disabled="disabled" value="null"> -- Please Select Status --</option>
                <option value="0">Not Approved</option>
                <option value="1">Approved</option>
              </select>
              <b-form-invalid-feedback v-if="errors.compStatus">
                {{ Object.values(errors.compStatus) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <div role="group" class="form-group col-md-6">
          <label for="companyPICPhone" class="d-block form-label text-left">
            Company Phone:
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <input
                autocomplete="off"
                class="form-control"
                id="companyPhone"
                placeholder="085255XXXXXX"
                type="text"
                v-model="data.compPhone"
                :class="errors.compPhone ? 'is-invalid' : ''"
                :disabled="isSubmit">
              <b-form-invalid-feedback v-if="errors.compPhone">
                {{ Object.values(errors.compPhone) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div role="group" class="form-group col-md-6">
          <label for="companyPIC" class="d-block form-label text-left">
            PIC:
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <input
                autocomplete="off"
                class="form-control"
                id="companyPIC"
                placeholder="PIC name"
                type="text"
                v-model="data.compPIC"
                :class="errors.compPIC ? 'is-invalid' : ''"
                :disabled="isSubmit">
              <b-form-invalid-feedback v-if="errors.compPIC">
                {{ Object.values(errors.compPIC) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <div role="group" class="form-group col-md-6">
          <label for="companyPICPhone" class="d-block form-label text-left">
            PIC Phone:
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <input
                autocomplete="off"
                class="form-control"
                id="companyPICPhone"
                placeholder="085255XXXXXX"
                type="text"
                v-model="data.compPICPhone"
                :class="errors.compPICPhone ? 'is-invalid' : ''"
                :disabled="isSubmit">
              <b-form-invalid-feedback v-if="errors.compPICPhone">
                {{ Object.values(errors.compPICPhone) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <b-button
          class="mr-1"
          size="sm"
          variant="outline-danger"
          :disabled="isSubmit"
          @click="onCancelAddClicked">
          <div class="mx-2">Cancel</div>
        </b-button>
        <b-button
          class="ml-1"
          size="sm"
          type="submit"
          variant="primary"
          :disabled="isSubmit"
          @click="onSubmitClicked">
          <div class="mx-2">
            <i v-if="isSubmit" class="ri-loader-line"></i>
            Submit
          </div>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

import GroupSvc from '@/services/GroupSvc';

export default {
  name: 'CompanyForm',
  data() {
    return {
      groups: [],
    };
  },
  props: ['data', 'errors', 'isSubmit'],
  methods: {
    // eslint-disable-next-line no-unused-vars
    async filterGroups(v, loading) {
      loading = true;
      const param = {
        findField: 'grcpName',
        findValue: v,
      };
      try {
        const res = await GroupSvc.getGroupById(param);
        if (res.data.status.error === 0) {
          this.groups = res.data.data;
        }
        loading = false;
      } catch (e) {
        loading = false;
        this.$toast.error(e.message);
      }
    },
    async getGroups() {
      try {
        const resp = await GroupSvc.getGroups();
        this.groups = resp.data.data;
      } catch (e) {
        if (e.response.data) {
          if (e.response.data.status.message === 'Authentication Error') {
            this.doLogOut();
          } else {
            this.$toast.error(e.response.data.status.message);
          }
        } else {
          this.$toast.error('Network error');
        }
      }
    },
    onCancelAddClicked() {
      this.$emit('onCancelAddClicked');
    },
    onSubmitClicked() {
      const data = { ...this.data };
      this.$emit('onSubmitClicked', data);
    },
  },
  mounted() {
    this.getGroups();
  },
};
</script>

<style scoped>

</style>
