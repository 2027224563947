<script>
import { Line } from 'vue-chartjs';

export default {
  name: 'LineStateChart',
  extends: Line,
  props: ['type', 'canId', 'data'],
  data() {
    return {
      chartdata: {
        labels: ['January', 'February', 'March', 'April'],
        datasets: [
          {
            label: 'Detail Log Fuel Used [L] (Different)',
            data: [20, 40, 30, 60],
          },
        ],
      },
      options: {
        scales: {
          yAxes: [{
            stacked: true,
          }],
        },
        responsive: true,
        maintainAspectRatio: true,
      },
    };
  },
  mounted() {
    this.renderChart(this.chartdata, this.options);
  },
};
</script>

<style scoped>

</style>
