import axios from 'axios';

export default {
  getGroups(config) {
    return axios.get('/master/group_company', config);
  },
  getGroupById(params) {
    return axios.get('/master/group_company', { params });
  },
  getAll(params) {
    return axios.get('/master/group_company', params);
  },
  getById(groupId) {
    return axios.get(`/master/group_company/${groupId}`);
  },
  create(data) {
    return axios.post('/master/group_company', data);
  },
  update(groupId, data) {
    return axios.put(`/master/group_company/${groupId}`, data);
  },
  delete(groupId) {
    return axios.delete(`/master/group_company/${groupId}`);
  },
};
