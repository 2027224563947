module.exports = {
  isAuthenticated: (store) => async (to, from, next) => {
    const isAuthenticated = parseInt(sessionStorage.getItem('isAuthenticated'));
    if (!isAuthenticated) {
      // next('/auth/export');
      next('/auth/login');
    }
    await store.dispatch('auth/getUser');
    next();
  },
};
