const SET_BREADCRUMB = 'set_breadcrumb';

// noinspection JSUnusedGlobalSymbols
const breadcrumb = {
  namespaced: true,
  state: {
    breadcrumb: [],
  },
  getters: {
    data(state) {
      return state.breadcrumb;
    },
  },
  mutations: {
    [SET_BREADCRUMB](state, v) {
      state.breadcrumb = v;
    },
  },
  actions: {
    setBreadCrumb({ commit }, v) {
      commit(SET_BREADCRUMB, v);
    },
  },
};

export default breadcrumb;
