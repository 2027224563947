<template>
  <!-- <ApexChart element="report-graph" :chartOption="chartOption"/> -->
  <div v-if="ready">
    <!-- <pre>{{data}}</pre> -->
    <apexchart type="line" :options="chartOptions" :series="series" :height="customHeight"></apexchart>
  </div>
</template>
<script>

import dateUtil from '@/util/date';

export default {
  name: 'report-graph',
  props: {
    data: { type: Object },
    customHeight: { type: Number },
    isDailyReport: {
      type: Boolean,
      default: false,
    },
    customXAxis: {
      type: Array,
    },
  },
  mounted() {
    if (this.data) {
      // const chartOptions = { ...this.constructChartData(this.data), chart: { animations: { enabled: !this.isDailyReport } } };
      // if (this.data.summaries.rpm.singleEngine) {
      //   const chartOptions = { ...this.constructChartData(this.data), chart: { animations: { enabled: !this.isDailyReport } } };
      //   console.log('Mounted... ', chartOptions);
      // } else {
      //   const chartOptions = { ...this.constructChartData(this.data), chart: { animations: { enabled: !this.isDailyReport } } };
      //   console.log('Mounted... ', chartOptions);
      // }
      console.log('das columns', this.data);
      console.log('czx columns', this.customXAxis);
      const chartOptions = { ...this.constructChartData(this.data) };
      // if (this.data) {
      //   this.chartOptions.labels = Object.keys(this.data.datas);
      // }
      console.log('Mounted... ', chartOptions);
      console.log('Mounted chart options... ', this.chartOptions);

      this.ready = true;
    }
    // const portRpmSeries = [1, 2, 3];
    // const starboardRpmSeries = [1, 2, 3];
    // const portUsageSeries = [1, 2, 3];
    // const starboardUsageSeries = [1, 2, 3];
    // // this.data.PORT.rpm.forEach((d, idx) => {
    // //   portRpmSeries.push(d.rpm);
    // //   if (this.data.STARBOARD.rpm[idx] !== undefined) {
    // //     starboardRpmSeries.push(this.data.STARBOARD.rpm[idx].rpm);
    // //   }
    // //   if (this.data.PORT.fm.IN[idx].volumeFlowrate !== undefined && this.data.PORT.fm.OUT[idx].volumeFlowrate !== undefined) {
    // //     portUsageSeries.push(this.data.PORT.fm.IN[idx].volumeFlowrate - this.data.PORT.fm.OUT[idx].volumeFlowrate);
    // //   }
    // //   if (this.data.STARBOARD.fm.IN[idx].volumeFlowrate !== undefined && this.data.STARBOARD.fm.OUT[idx].volumeFlowrate !== undefined) {
    // //     starboardUsageSeries.push(this.data.STARBOARD.fm.IN[idx].volumeFlowrate - this.data.STARBOARD.fm.OUT[idx].volumeFlowrate);
    // //   }
    // //   this.chartOption.labels.push(`${new Date(d.timestamp * 1000).getHours().toString().padStart(2, '0')}:${new Date(d.timestamp * 1000).getMinutes().toString().padStart(2, '0')}`);
    // // });
    // if (portRpmSeries.length === 0 && starboardRpmSeries.length === 0 && portUsageSeries.length === 0 && starboardUsageSeries.length === 0) {
    //   this.noData = true;
    // }
    // this.chartOption.series.push({
    //   name: 'Port',
    //   type: 'column',
    //   data: portRpmSeries,
    // });
    // this.chartOption.series.push({
    //   name: 'Starboard',
    //   type: 'column',
    //   data: starboardRpmSeries,
    // });
    // this.chartOption.series.push({
    //   name: 'Port Flow',
    //   type: 'line',
    //   data: portUsageSeries,
    // });
    // this.chartOption.series.push({
    //   name: 'Starboard Flow',
    //   type: 'line',
    //   data: starboardUsageSeries,
    // });
  },
  // computed: {
  //   series() {
  //     const series = [];
  //     if (this.data) {
  //     //   Object.keys(this.data.devices).forEach((device) => {
  //     //     // console.log('AAAAAAAA : ', this.data.devices[device]);
  //     //     const speedSeries = {
  //     //       name: 'Speed',
  //     //       type: 'line',
  //     //       data: [],
  //     //     };
  //     //     const portRPMSeries = {
  //     //       name: 'Port RPM',
  //     //       type: 'column',
  //     //       data: [],
  //     //     };
  //     //     const stbRPMSeries = {
  //     //       name: 'Stb RPM',
  //     //       type: 'column',
  //     //       data: [],
  //     //     };
  //     //     const consumptionSeries = {
  //     //       name: 'Consumption',
  //     //       type: 'area',
  //     //       data: [],
  //     //     };
  //     //     switch (this.data.devices[device].devcType) {
  //     //       case 'gps':
  //     //         console.log('Draw GPS Data : ', this.data.devices[device].data);
  //     //         this.data.devices[device].data.forEach((d) => {
  //     //           speedSeries.data.push(d.speed);
  //     //         });
  //     //         series[2] = speedSeries;
  //     //         break;
  //     //       case 'rpm':
  //     //         console.log('Draw RPM Data :', this.data.devices[device].data);
  //     //         this.data.devices[device].data.forEach((d) => {
  //     //           if (d.type === 'PORT') {
  //     //             portRPMSeries.data.push(d.rpm);
  //     //           } else {
  //     //             stbRPMSeries.data.push(d.rpm);
  //     //           }
  //     //         });
  //     //         series[0] = portRPMSeries;
  //     //         series[1] = stbRPMSeries;
  //     //         break;
  //     //       case 'ae':
  //     //         break;
  //     //       case 'flowmeter':
  //     //         console.log('Draw flowmeter Data :', this.data.devices[device]);
  //     //         if (this.data.devices[device].devcLabel === 'STARBOARD_OUT') {
  //     //           this.data.devices[device].data.forEach((d) => {
  //     //             consumptionSeries.data.push(d.massFlowrate);
  //     //           });
  //     //         }
  //     //         series.push(consumptionSeries);
  //     //         break;
  //     //     }
  //     //   });
  //       // console.log(this.data);
  //     }
  //     return series;
  //     // return [
  //     //   {
  //     //     name: 'PORT RPM',
  //     //     type: 'column',
  //     //     data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
  //     //   },
  //     //   {
  //     //     name: 'STB RPM',
  //     //     type: 'column',
  //     //     data: [12, 11, 22, 12, 13, 22, 12, 21, 12, 22, 30],
  //     //   },
  //     //   {
  //     //     name: 'Fuel Cons',
  //     //     type: 'area',
  //     //     data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
  //     //   },
  //     //   {
  //     //     name: 'Speed',
  //     //     type: 'line',
  //     //     data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
  //     //   },
  //     // ];
  //   },
  // },
  methods: {
    constructChartData(data) {
      console.log('Data to construct : ', data.summaries.rpm.singleEngine);
      const constructedData = {};
      // if (data.summaries.rpm && data.summaries.rpm.singleEngine) {
      //   this.chartOptions.chart.animations.enabled = false;
      // }
      Object.keys(data.datas).forEach((timeStamp, timestampIndex) => {
        if (this.isDailyReport) {
          this.chartOptions.labels.push(++this.count);
        } else {
          this.chartOptions.labels.push(timeStamp);
        }
        Object.keys(data.datas[timeStamp]).forEach((deviceType) => {
          // console.log('timestap', timestampIndex);
          let totalAERunningSeconds = 0;
          switch (deviceType) {
            case 'gps':
              if (!constructedData.speed) {
                constructedData.speed = {
                  name: 'Speed',
                  type: 'line',
                  data: [],
                };
              }
              constructedData.speed.data[timestampIndex] = 0;
              if (!constructedData.distance) {
                constructedData.distance = {
                  name: 'Distance',
                  type: 'line',
                  data: [],
                };
              }
              constructedData.distance.data[timestampIndex] = 0;
              // console.log('TS : ', data.datas[timeStamp][deviceType].polyLength);
              if (data.datas[timeStamp][deviceType]) {
                constructedData.speed.data[timestampIndex] = data.datas[timeStamp][deviceType].speed;
                constructedData.distance.data[timestampIndex] = data.datas[timeStamp][deviceType].polyLength;
              }
              break;
            case 'rpm':
              if (!data.summaries.rpm.singleEngine) {
                if (!constructedData.port) {
                  constructedData.port = {
                    name: 'PORT RPM',
                    type: 'column',
                    data: [],
                  };
                }
                if (!constructedData.stb) {
                  constructedData.stb = {
                    name: 'STB RPM',
                    type: 'column',
                    data: [],
                  };
                }
                if (data.datas[timeStamp][deviceType].PORT) {
                  constructedData.port.data[timestampIndex] = data.datas[timeStamp][deviceType].PORT.rpm;
                }
                if (data.datas[timeStamp][deviceType].STARBOARD) {
                  constructedData.stb.data[timestampIndex] = data.datas[timeStamp][deviceType].STARBOARD.rpm;
                }
              } else {
                if (!constructedData.me) {
                  constructedData.me = {
                    name: 'ME RPM',
                    type: 'column',
                    data: [],
                  };
                }

                if (!constructedData.runningTime) {
                  constructedData.runningTime = {
                    name: 'MERunning Time',
                    type: 'column',
                    data: [],
                  };
                }

                if (data.datas[timeStamp][deviceType].MAINENGINE) {
                  constructedData.me.data[timestampIndex] = data.datas[timeStamp][deviceType].MAINENGINE.rpm;
                  constructedData.runningTime.data[timestampIndex] = data.datas[timeStamp][deviceType].MAINENGINE.runningTime;
                }
              }
              break;
            case 'flowmeter':
              if (!constructedData.fm) {
                constructedData.fm = {
                  name: 'Fuel Cons',
                  type: 'area',
                  data: [],
                };
              }
              if (!data.summaries.rpm.singleEngine) {
                if (data.datas[timeStamp][deviceType].meFuelCons > 0) {
                  constructedData.fm.data[timestampIndex] = data.datas[timeStamp][deviceType].meFuelCons;
                } else {
                  constructedData.fm.data[timestampIndex] = 0;
                }
              } else {
                constructedData.fm.data[timestampIndex] = data.datas[timeStamp][deviceType].portFuelCons;
                // if (data.datas[timeStamp][deviceType].portFuelCons > 0) {
                // } else {
                //   constructedData.fm.data[timestampIndex] = 0;
                // }
              }
              // console.log('TS : ', data.datas[timeStamp][deviceType]);
              break;
            case 'ae':
              if (data.summaries.rpm && !data.summaries.rpm.singleEngine) {
                if (!constructedData.ae) {
                  constructedData.ae = {
                    name: 'AE Running Time',
                    type: 'line',
                    data: [],
                  };
                }
                Object.keys(data.datas[timeStamp][deviceType]).forEach((ae) => {
                  totalAERunningSeconds += data.datas[timeStamp][deviceType][ae].runningTime;
                  // console.log(data.datas[timeStamp][deviceType][ae]);
                });
                constructedData.ae.data[timestampIndex] = totalAERunningSeconds / 3600;
              }

              // console.log('TS : ', data.datas[timeStamp][deviceType], totalAERunningSeconds);
              break;
          }
        });
        // this.series[0].data[timestampIndex] = 0;
        // this.series[1].data[timestampIndex] = 0;
        // this.series[2].data[timestampIndex] = 0;
        // this.series[3].data[timestampIndex] = 0;
        // this.series[4].data[timestampIndex] = 0;
        // this.series[5].data[timestampIndex] = 0;
        // this.series[6].data[timestampIndex] = 0;
        // Object.keys(data.datas[timeStamp]).forEach((deviceType) => {
        //   // console.log('timestap', timestampIndex);
        //   let totalAERunningSeconds = 0;
        //   switch (deviceType) {
        //     case 'gps':
        //       // console.log('TS : ', data.datas[timeStamp][deviceType].polyLength);
        //       if (data.datas[timeStamp][deviceType]) {
        //         this.series[3].data[timestampIndex] = data.datas[timeStamp][deviceType].speed;
        //         this.series[5].data[timestampIndex] = data.datas[timeStamp][deviceType].polyLength;
        //       }
        //       break;
        //     case 'rpm':
        //       // console.log('TS : ', data.datas[timeStamp][deviceType]);
        //       if (data.datas[timeStamp][deviceType].MAINENGINE) {
        //         this.series[0].data[timestampIndex] = data.datas[timeStamp][deviceType].MAINENGINE.rpm;
        //       }
        //       if (data.datas[timeStamp][deviceType].PORT) {
        //         this.series[1].data[timestampIndex] = data.datas[timeStamp][deviceType].PORT.rpm;
        //       }
        //       if (data.datas[timeStamp][deviceType].STARBOARD) {
        //         this.series[2].data[timestampIndex] = data.datas[timeStamp][deviceType].STARBOARD.rpm;
        //       }
        //       break;
        //     case 'flowmeter':
        //       if (data.datas[timeStamp][deviceType].meFuelCons > 0) {
        //         this.series[2].data[timestampIndex] = data.datas[timeStamp][deviceType].meFuelCons;
        //       } else {
        //         this.series[2].data[timestampIndex] = 0;
        //       }
        //       // console.log('TS : ', data.datas[timeStamp][deviceType]);
        //       break;
        //     case 'ae':
        //       Object.keys(data.datas[timeStamp][deviceType]).forEach((ae) => {
        //         totalAERunningSeconds += data.datas[timeStamp][deviceType][ae].runningTime;
        //         // console.log(data.datas[timeStamp][deviceType][ae]);
        //       });
        //       this.series[4].data[timestampIndex] = totalAERunningSeconds / 3600;
        //       // console.log('TS : ', data.datas[timeStamp][deviceType], totalAERunningSeconds);
        //       break;
        //   }
        // });
      });
      this.series = [];
      Object.keys(constructedData).forEach((dev) => {
        // console.log(dev);
        this.series.unshift(constructedData[dev]);
      });
      console.log(this.series);
      console.log('chartOptions', this.chartOptions);
      console.log('constructedData', constructedData);
      // this.ready = true;
      return {};
    },
  },
  data() {
    return {
      count: 0,
      ready: false,
      series: [],
      // series: [
      //   {
      //     name: 'MAIN ENGINE RPM',
      //     type: 'column',
      //     data: [],
      //   },
      //   {
      //     name: 'PORT RPM',
      //     type: 'column',
      //     data: [],
      //   },
      //   {
      //     name: 'STB RPM',
      //     type: 'column',
      //     data: [],
      //   },
      //   {
      //     name: 'Fuel Cons',
      //     type: 'area',
      //     data: [],
      //   },
      //   {
      //     name: 'Speed',
      //     type: 'line',
      //     data: [],
      //   },
      //   {
      //     name: 'AE Running Time',
      //     type: 'line',
      //     data: [],
      //   },
      //   {
      //     name: 'Distance',
      //     type: 'line',
      //     data: [],
      //   },
      // ],
      chartOptions: {
        // colors: ['#0084ff', '#00ca00', '#ffca00', '#FF8C00'],
        // colors: ['#ff0000', '#ffa500', '#ffff00', '#008000', '#0000ff', '#4b0082', '#ee82ee'],
        chart: {
          animations: {
            // enabled: false,
            // speed: 1,
          // },
            enabled: !this.isDailyReport,
            // easing: 'easeinout',
            // speed: 800,
            animateGradually: {
              enabled: false,
              delay: 100,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 100,
            },
          },
          height: 'auto',
          type: 'line',
          stacked: false,
          events: {
            zoomed: (chartContext, { xaxis }) => {
              console.log('Chart zoomed... ', xaxis);
              this.$emit('onZoomed', xaxis);
            },
            scrolled: (chartContext, { xaxis }) => {
              console.log('Chart scrolled... ', xaxis);
              this.$emit('onScrolled', xaxis);
            },
            animationEnd: (chartContext) => {
              this.svg = chartContext.paper().svg();
              // console.log('This is SVG : ', this.svg);
              // console.log('Mantedd... ', this.$refs.chartRef.chart);
              chartContext.dataURI().then(({ imgURI }) => {
                this.png = imgURI;
                // console.log('This is image URI : ', imgURI, blob);
                // pdf.addImage(imgURI, 'PNG', 0, 0);
              });
            },
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: true,
              reset: false,
              customIcons: [],
            },
            // export: {
            //   csv: {
            //     filename: undefined,
            //     columnDelimiter: ',',
            //     headerCategory: 'category',
            //     headerValue: 'value',
            //     dateFormatter(timestamp) {
            //       return new Date(timestamp).toDateString()
            //     }
            //   }
            // },
            // autoSelected: 'zoom'
          },
        },
        stroke: {
          width: [2, 2, 2, 2, 2, 2],
          curve: 'smooth',
        },
        plotOptions: {
          bar: {
            columnWidth: '80%',
          },
        },
        fill: {
          opacity: [0.75, 0.75, 1, 0.85, 1],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: 'vertical',
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        labels: [], // dynamic
        markers: {
          size: 0,
        },
        xaxis: {
          categories: this.customXAxis || [],
          labels: {
            show: true,
            formatter: (value) => {
              if (value) {
                if (this.isDailyReport) {
                  return value;
                }
                return dateUtil.toLocalDate(value);
              }
            },
          },
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#008FFB',
            },
            labels: {
              style: {
                colors: '#008FFB',
              },
              formatter: (value) => value.toFixed(),
            },
            title: {
              text: 'RPM',
              style: {
                color: '#008FFB',
              },
            },
            tooltip: {
              enabled: true,
            },
            min: 0,
          },
          {
            labels: {
              show: false,
            },
          },
          {
            labels: {
              show: true,
              formatter: (value) => value.toFixed(),
            },
            title: {
              text: 'Liters',
            },
            min: 0,
          },
          {
            opposite: true,
            labels: {
              show: true,
              formatter: (value) => value.toFixed(),
            },
            title: {
              text: 'Knot',
            },
            min: 0,
          },
          {
            labels: {
              show: false,
            },
          },
          {
            opposite: true,
            labels: {
              show: true,
              formatter: (value) => (value * 0.00053996).toFixed(),
            },
            title: {
              text: 'Nautical Mile',
            },
            min: 0,
          },
        ],
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: (y) => {
              if (typeof y !== 'undefined') {
                return y.toFixed(0);
              }
              return y;
            },
          },
        },
      },
    };
  },
};
</script>
