<template>
  <b-container fluid>
    <WidgetsIndex/>
  </b-container>
</template>

<script>

import WidgetsIndex from '../components/widgets/Index';

export default {
  name: 'Widgets',
  components: { WidgetsIndex },
};
</script>

<style scoped>

</style>
