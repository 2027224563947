<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <div class="row mb-3 mt-3" v-if="data.summaries.rpm && !data.summaries.rpm.singleEngine">
      <div class="col-md-3">
        <h6 class="card-title text-uppercase text-secondary mb-0">Port Average RPM</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <span>{{(data.summaries.rpm.average.PORT).toFixed(2)}}</span>
        </span>
      </div>
      <div class="col-md-3">
        <h6 class="card-title text-uppercase text-secondary mb-0">Port Running Hour</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <span>{{ data.summaries.rpm.runningTime.PORT | toHHMMSS }}</span>
        </span>
      </div>
      <div class="col-md-3">
        <h6 class="card-title text-uppercase text-secondary mb-0">Stb Average RPM</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <span>{{(data.summaries.rpm.average.STARBOARD).toFixed(2)}}</span>
        </span>
      </div>
      <div class="col-md-3">
        <h6 class="card-title text-uppercase text-secondary mb-0">Stb Running Hour</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <span>{{data.summaries.rpm.runningTime.STARBOARD | toHHMMSS }}</span>
        </span>
      </div>
    </div>
    <div class="row mb-3 mt-3" v-else>
      <div class="col-md-4">
        <h6 class="card-title text-uppercase text-secondary mb-0">Main Engine Average RPM</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <span>{{(data.summaries.rpm.average.MAINENGINE).toFixed(2)}}</span>
        </span>
      </div>
      <div class="col-md-4">
        <h6 class="card-title text-uppercase text-secondary mb-0">Main Engine Running Hour</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <span>{{ data.summaries.rpm.runningTime.MAINENGINE | toHHMMSS }}</span>
        </span>
      </div>
      <div class="col-md-4">
        <h6 class="card-title text-uppercase text-secondary mb-0">Main Engine Cons</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <span v-if="data.summaries.flowmeter.portEngineCons !== undefined">{{(data.summaries.flowmeter.portEngineCons).toFixed(2)}} Liters</span>
          <span v-else>0</span>
        </span>
      </div>
    </div>
    <div class="row mb-3" v-if="data.summaries.flowmeter && !data.summaries.rpm.singleEngine">
      <div class="col-md-3" v-if="data.summaries.rpm">
        <h6 class="card-title text-uppercase text-secondary mb-0">Average Starboard Flow</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <b-form inline>
            <span v-if="data.summaries.flowmeter.stbEngineCons !== undefined && data.summaries.flowmeter.stbEngineCons !== 0 && data.summaries.rpm.runningTime.STARBOARD !==0 && flowUnit === 'lph'">{{(data.summaries.flowmeter.stbEngineCons / (data.summaries.rpm.runningTime.STARBOARD / 3600)).toFixed(2)}}</span>
            <span v-else>
              <span v-if="data.summaries.flowmeter.stbEngineCons !== undefined && data.summaries.flowmeter.stbEngineCons !== 0 && data.summaries.rpm.runningTime.STARBOARD !==0 && flowUnit === 'lpm'">{{((data.summaries.flowmeter.stbEngineCons / (data.summaries.rpm.runningTime.STARBOARD / 3600)) / 60).toFixed(2)}}</span>
              <span v-else>0</span>
            </span>
            <b-form-select v-model="flowUnit" class="ml-2">
              <b-form-select-option value="lph">L/h</b-form-select-option>
              <b-form-select-option value="lpm">L/m</b-form-select-option>
            </b-form-select>
          </b-form>
        </span>
      </div>
      <div class="col-md-3">
        <h6 class="card-title text-uppercase text-secondary mb-0">Total Starboard Engine Cons</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <span v-if="data.summaries.flowmeter.stbEngineCons !== undefined">{{(data.summaries.flowmeter.stbEngineCons).toFixed(2)}} Liters</span>
          <span v-else>0</span>
        </span>
      </div>
      <div class="col-md-3" v-if="data.summaries.rpm">
        <h6 class="card-title text-uppercase text-secondary mb-0">Average Port Flow</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <span v-if="data.summaries.flowmeter.portEngineCons !== 0  && data.summaries.rpm.runningTime.PORT">
            <span v-if="flowUnit === 'lph'">{{(data.summaries.flowmeter.portEngineCons / (data.summaries.rpm.runningTime.PORT / 3600)).toFixed(2)}} L/h</span>
            <span v-if="flowUnit === 'lpm'">{{((data.summaries.flowmeter.portEngineCons / (data.summaries.rpm.runningTime.PORT / 3600)) / 60).toFixed(2)}} L/m</span>
          </span>
          <span v-else>0</span>
        </span>
      </div>
      <div class="col-md-3">
        <h6 class="card-title text-uppercase text-secondary mb-0">Total Port Engine Cons</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <span v-if="data.summaries.flowmeter.portEngineCons !== undefined">{{(data.summaries.flowmeter.portEngineCons).toFixed(2)}} Liters</span>
          <span v-else>0</span>
        </span>
      </div>
    </div>
    <div class="row mb-3" v-if="data.summaries.gps">
      <div class="col-md-4">
        <h6 class="card-title text-uppercase text-secondary mb-0">Total Cruise</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <b-form inline>
            <span v-if="cruiseUnit === 'km'">{{data.summaries.gps.totalDistance | toKM}}</span>
            <span v-if="cruiseUnit === 'mile'">{{data.summaries.gps.totalDistance | toMiles}}</span>
            <span v-if="cruiseUnit === 'nmi'">{{data.summaries.gps.totalDistance | toNM}}</span>
            <b-form-select v-model="cruiseUnit" class="ml-2">
              <b-form-select-option value="km">KM</b-form-select-option>
              <b-form-select-option value="nmi">NM</b-form-select-option>
              <b-form-select-option value="mile">Miles</b-form-select-option>
            </b-form-select>
          </b-form>
        </span>
      </div>
      <div class="col-md-4">
        <h6 class="card-title text-uppercase text-secondary mb-0">Average Speed</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <b-form inline>
            <span v-if="speedUnit === 'knot'">{{data.summaries.gps.averageSpeed | toKnot}}</span>
            <span v-else>{{(data.summaries.gps.averageSpeed).toFixed(2)}}</span>
            <b-form-select v-model="speedUnit" class="ml-2">
              <b-form-select-option value="knot">Knot</b-form-select-option>
              <b-form-select-option value="kmh">KM/h</b-form-select-option>
            </b-form-select>
          </b-form>
        </span>
      </div>
      <div class="col-md-4">
        <h6 class="card-title text-uppercase text-secondary mb-0">Distance</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <span v-if="cruiseUnit === 'km'">{{data.summaries.gps.distance | toKM}} KM</span>
          <span v-if="cruiseUnit === 'mile'">{{data.summaries.gps.distance | toMiles}} Miles</span>
          <span v-if="cruiseUnit === 'nmi'">{{data.summaries.gps.distance | toNM}} NM</span>
        </span>
      </div>
    </div>
    <div class="row mb-3" v-if="data.summaries.ae && data.summaries.ae.runningSeconds">
      <div class="col-md-4" v-for="(aeTime, index) in data.summaries.ae.runningSeconds" :key="index">
      <!-- <pre>{{data.summaries.ae.runningSeconds[index]}}</pre> -->
        <h6 class="card-title text-uppercase text-secondary mb-0">{{index}} Running Time</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <span>{{data.summaries.ae.runningSeconds[index] | toHHMMSS}}</span>
        </span>
      </div>
    </div>
    <div class="row mb-3" v-if="data.summaries.ae && !data.summaries.rpm.singleEngine && massId !== '43'">
      <div class="col-md-4" v-for="(aeFC, aeFCIn) in data.summaries.ae.fuelConsumption" :key="aeFCIn">
        <h6 class="card-title text-uppercase text-secondary mb-0">{{aeFCIn}} Fuel Consumption</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <span v-if="aeFC">{{(aeFC).toFixed(2)}}</span>
          <span v-else>0</span>
        </span>
      </div>
    </div>
    <div class="row mb-3" v-if="data.summaries.flowmeter && data.summaries.flowmeter.aeEngineCons">
      <div class="col-md-4" >
        <h6 class="card-title text-uppercase text-secondary mb-0">AE Fuel Consumption</h6>
        <span class="h2 text-dark mb-0 counter d-inline-block w-100">
          <span v-if="data.summaries.flowmeter.aeEngineCons">{{(data.summaries.flowmeter.aeEngineCons).toFixed(2)}}</span>
          <span v-else>0</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ReportSummary',
  props: ['data', 'massId'],
  data() {
    return {
      cruiseUnit: 'km',
      flowUnit: 'lph',
      speedUnit: 'knot',
    };
  },
};
</script>

<style scoped lang="scss">
.table-logs {
  display: block;
  font-size: 11px;
  overflow-x: auto;
  white-space: nowrap;
}
</style>
